import axios from 'axios'
import config from '@/config'
import store from '@/store'
// 创建axios实例
import { getCookie } from '@/utils/cookie'

const service = axios.create({
    baseURL: config.BASEURL, // api的base_url
    timeout: 40000, // 请求超时时间,
    headers: {
        // "Content-Type": "application/x-www-form-urlencoded"
        "Content-Type": "application/json"
    },
})
// request拦截器
service.interceptors.request.use(configs => {
    configs.headers = {
        ...configs.headers,
        "SET-LANGUAGE": getCookie("lang"),
        "APP-LOGIN-TOKEN": getCookie('user-cookie'),
        // "APP-ANALOG": getCookie('account-mode')
        "APP-ANALOG": localStorage.getItem("account-type") == 'demo'
    }
    return configs;
}, error => {
    Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
    response => {
        if (response.status == 200) {
            if (response.data.errCode == 100007) {
                if (response.config.url == "/api/app/user/get/info") {
                    return response.data
                } else {
                    store.commit('setUserInfo',{})
                    store.commit('setIsLogin', false)
                    location.href = '/#/login'
                }
                // location.href = '/login'
                return
            } else {
                return response.data
            }

        }
    },
    async error => {
        return Promise.reject(error)
    }
)




export default service