import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Chiński uproszczony",
        dialogDetermine: "Chiński uproszczony",
        dreivatives: "Dostawa",
        markets: "cytaty",
        assets: "aktywa",
        liquidity: "Wydobywanie płynności",
        helpCentre: "Centrum pomocy",
        announcementCenter: "Centrum ogłoszeń",
        about: "o",
        bulletinCentre: "Centrum ogłoszeń",
        serviceAgreement: "Umowa serwisowa",
        support: "wsparcie",
        onlineSupportCustomerService: "serwis internetowy",
        suggestionsAndFeedback: "Sugestie i opinie",
        service: "Podawać",
        download: "pobierać",
        coinApplication: "Aplikacja do wystawiania monet",
        privacyStatement: "Oświadczenie o ochronie prywatności",
        trade: "handel kontraktowy",
        standardConteact: "Zrównoważony",
        signOut: "Wyloguj się",
        assetWallets: "Portfel aktywów",
        financeRecords: "Zapisy zmian konta",
        identification: "Uwierzytelnianie",
        personalCenter: "Centrum osobiste",
        chooseLanguage: "Wybierz język",
        tips: "wskazówka",
        dark: "tryb ciemny",
        light: "tryb dzienny",
        languageTitle: "język",
        convertCurrency: "Przeliczaj walutę",
        cancel: "Anulować",
        aboutUs: "o nas",
        standard: "Ciągłość standardu",
        share: "udział",
        introduce: "Wprowadzenie do platformy",
        set: "organizować coś",
        changePassword: "Zmień hasło logowania",
        denomination: "Waluta notowań",
        color: "Konfiguracja kolorów",
        code: "numer wersji",
        color1: "Czerwień wznosi się, a zieleń opada",
        color2: "Zielony rośnie, a czerwony opada",
        setLangue: "ustawienie języka",
        vip: "Korzyści VIP",
        vip2: "Aktualny poziom VIP",
        safeMenu: "Centrum Bezpieczeństwa",
        conventional: "standardowy",
        other: "Inny",
        quickTips: "Dodaj maksymalnie 3 przyciski funkcyjne skrótów",
        quickTransaction: "Szybka transakcja",
        safeConvenient: "Bezpieczny i wygodny",
        timeSharing: "dzielenie czasu",
        currentLevel: "aktualny poziom",
        levelBenefits: "Dochód na poziomie %{level}",
        teamLevel: "Poziom zespołu",
        levelLower: "%{level}podwładny",
        rules: "Opis reguły",
        bindWithdrawalAddress: "Powiąż adres wypłaty",
        tipsBindWithdrawalAddress: "Nie zapisałeś jeszcze swojego adresu wypłaty, proszę go najpierw zawiązać",
        walletAddressFormatIncorrect: "Format adresu portfela jest nieprawidłowy",
        tradeRules1: "Przewiduj wzrosty i spadki w przyszłym okresie. Za cenę początkową przyjmuje się cenę z chwili złożenia zamówienia, a za cenę rozliczeniową cenę z ostatniej sekundy okresu prognozy. Jeżeli cena rozliczeniowa jest wyższa od ceny wywoławczej, oznacza to podwyżkę. Jeżeli rozliczenie cena jest niższa od ceny wywoławczej, oznacza to obniżkę.",
        tradeRules2: "1.Co to jest umowa?",
        tradeRules3: "Platforma ta oferuje kontrakty wieczyste oparte na USDT. Użytkownicy mogą ocenić wzrost lub spadek i zdecydować się na zakup długich lub sprzedaż krótkich kontraktów, aby uzyskać zyski ze wzrostu/spadku cen aktywów cyfrowych. Kontrakty bezterminowe nie mają daty dostawy i użytkownicy mogą je przechowywać na zawsze.",
        tradeRules4: "2.Co to jest marża",
        tradeRules5: "Na wirtualnym rynku kontraktów użytkownicy muszą jedynie wpłacić niewielką kwotę środków w określonej proporcji według ceny kontraktu jako gwarancję finansową wykonania umowy, a następnie mogą uczestniczyć w kupnie i sprzedaży kontraktu. Ten rodzaj środków to wirtualna lokata kontraktowa. \nKontrakty na tej platformie przyjmują model oddzielnej marży dla oddzielnych kont. Każda waluta odpowiada rachunkowi kontraktu. Aktywa i pozycje rachunku w różnych walutach kontraktu są od siebie niezależne, a przelewy i transakcje pomiędzy różnymi rachunkami kontraktowymi nie wpływają na siebie. Po otwarciu konta kontraktowego użytkownika ryzyko i korzyści wszystkich pozycji znajdujących się na rachunku kontraktowym zostaną obliczone łącznie.",
        tradeRules6: "3.Jak obliczyć marżę",
        tradeRules7: "Depozyt pozycji = wartość nominalna kontraktu * liczba otwartych kontraktów / mnożnik dźwigni\nzamrożony margines = suma depozytów zabezpieczających wszystkich pomyślnie złożonych zleceń i zleceń otwierających w bieżącym zleceniu\dostępny depozyt zabezpieczający = stan konta - depozyt pozycji - zamrożony depozyt zabezpieczający",
        tradeRules8: "4.Jak obliczyć stopę depozytu zabezpieczającego",
        tradeRules9: "Stopa marży jest miarą ryzyka aktywów użytkownika. \nStawka marży = (Niezrealizowane zyski i straty + Marża pozycji + Dostępna marża + Marża zamrożenia - Opłata likwidacyjna) / Marża pozycji \nIm mniejsza stopa marży, tym wyższe ryzyko konta. Gdy stopa marży jest mniejsza lub równa 0%, uruchomiona zostanie wymuszona likwidacja.",
        tradeRules10: "5.Jaka jest szacunkowa cena z wymuszonym parytetem?",
        tradeRules11: "Szacunkowa cena przymusowej likwidacji, czyli cena wyliczona przez system, która teoretycznie powoduje przymusową likwidację, ma charakter wyłącznie poglądowy i może nieznacznie odbiegać od wartości rzeczywistej.",
        tradeRules12: "6.Co to jest całkowity kapitał na rachunku oraz niezrealizowane zyski i straty?",
        tradeRules13: "Całkowity kapitał na koncie = saldo konta + niezrealizowane zyski i straty\nNiezrealizowane zyski i straty to zyski i straty z tytułu pozycji aktualnie zajmowanej przez użytkownika w kontrakcie walutowym. Niezrealizowane zyski i straty ulegną zmianie wraz z ostatnimi zmianami cen transakcyjnych. \nNiezrealizowane zyski i straty na pozycjach długich = (1/średnia cena pozycji - 1/ostatnia cena transakcji) * liczba kontraktów na długich pozycjach * wartość nominalna kontraktu * ostatnia cena transakcji \n niezrealizowane zyski i straty na krótkich pozycjach = (1/ cena ostatniej transakcji - 1/ średnia cena pozycji) * Liczba krótkich kontraktów * Wartość nominalna kontraktu * Cena ostatniej transakcji",
        text1: "Ochrona Konta",
        text2: "tryb dzienny i nocny",
        text3: "Wyczyść pamięć podręczną",
        text4: "Czy na pewno chcesz się wylogować?",
        text5: "Czy na pewno chcesz wyczyścić pamięć podręczną?",
        text6: "Wielojęzyczny",
        text7: "Aby chronić swoje bezpieczeństwo, zalecamy włączenie co najmniej jednego uwierzytelniania dwuskładnikowego"
    },
    login: {
        login: "Zaloguj sie",
        register: "rejestr",
        title1: "Handluj w dowolnym miejscu i czasie i przeglądaj warunki rynkowe w czasie rzeczywistym",
        title2: "Handluj w dowolnym miejscu i czasie i przeglądaj warunki rynkowe w czasie rzeczywistym",
        title3: "Najbardziej zaufana platforma handlu kryptowalutami",
        formTitle1: "Login do konta",
        formTitle2: "Witamy spowrotem! Zaloguj się podając swój adres e-mail, numer telefonu",
        btn1: "komórka",
        btn2: "Poczta",
        btn3: "Zaloguj sie",
        btn4: "rejestr",
        btn5: "zapomnij hasła？",
        field1: "Numer telefonu",
        field2: "hasło",
        field3: "Poczta",
        placeholder1: "naród",
        placeholder2: "Proszę podać numer telefonu",
        placeholder3: "Proszę wpisać hasło",
        placeholder4: "proszę podać swój adres e-mail",
        content12: "Proszę podać numer telefonu",
        content13: "Proszę wpisać hasło",
        content14: "proszę podać swój adres e-mail",
        content16: "Usługi finansowe dotyczące aktywów cyfrowych",
        loginSuccess: "Logowanie zakończone sukcesem ",
        chooseArea: "Proszę wybrać numer kierunkowy",
        placeholder5: "Proszę wybrać kod kraju",
        placeholder6: "Pamiętaj moje hasło",
        placeholder7: "Pobierz aplikację",
        placeholder8: "Biuro obsługi klienta",
        placeholder9: "Wybierz kraj lub region",
        placeholder10: "numer kierunkowy",
        placeholder11: "Logowanie do portfela",
        placeholder12: "Operacja nie powiodła się. Przejdź na",
        placeholder13: "portfel"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "o",
        aboutCoinbeadCenterDetails: "Posiada wiodącą na świecie platformę handlu aktywami typu blockchain i obsługuje cały ekosystem.",
        footDetails1: "dzienny wolumen obrotu",
        footDetails2: "transakcji/sekundę",
        footDetails3: "Centrum pomocy",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Jest to znana na całym świecie międzynarodowa witryna internetowa poświęcona aktywom cyfrowym, udostępniająca użytkownikom na całym świecie głównie monety i instrumenty pochodne aktywów cyfrowych, takich jak Bitcoin (BTC), Litecoin (LTC) i Ethereum (ETH).",
        coinbeadCenterDetailsm: "Przyjmując zaawansowane technologie, takie jak GSLB, serwery sieciowe, transakcje sieciowe, sieci, wielomaszynowe, szybkie silniki handlu pamięcią, zimne portfele, inteligentne gorące portfele offline itp., obsługujemy naszych klientów za pomocą wielu terminali, takich jak telefony komórkowe i komputery internetowe Zapewnij bezpieczne, stabilne i niezawodne cyfrowe usługi transakcji taryfowych. Jednocześnie ulepszamy i unowocześniamy produkty i usługi w oparciu o sugestie użytkowników i potrzeby, aby zapewnić lepsze usługi każdemu klientowi, a ulepszanie innowacyjnego doświadczenia użytkownika nie ma końca.",
        infrastructureCenterTitle: "infrastruktura",
        infrastructureCenterDetails: "Naszą misją jest świadczenie usług infrastrukturalnych dla całego ekosystemu blockchain",
        freeCenterTitle: "bezpłatny",
        freeCenterDetails: "Naszą wizją jest zapewnienie swobodnego przepływu pieniędzy na całym świecie. Wierzymy, że szerzenie tej wolności może uczynić świat lepszym miejscem",
        futureCenterTitle: "Pionier przyszłości technologii",
        futureCenterDetails: "Otwórz przyszłość technologii",
    },
    applicationForListing: {
        title: "Aplikacja do wystawiania monet",
        projectName: "Nazwa Projektu",
        tokenName: "Nazwa tokenu",
        projectDescription: "Opis Projektu",
        contractAddress: "Adres umowy",
        officialWebsite: "oficjalna strona internetowa",
        ContactAndEmail: "Adres e-mail do kontaktu",
        ContactAndName: "Nazwa Kontaktu",
        file: "Prześlij załącznik",
        pleaseEnter: "Podaj",
        enterFile: "Proszę przesłać pliki",
        upload1: "Obecnie ograniczony do 1 wyboru pliku,",
        upload2: "Tym razem wybrałem",
        upload3: "akta，",
        upload4: "Wybrano ogółem",
        upload5: "akta",
    },
    public: {
        submit: "składać",
        copySuccessfully: "Skopiowano pomyślnie",
        copyFailed: "Kopiowanie nie powiodło się",
        noDate: "Brak danych",
        submitSuccessfully: "Przesłano pomyślnie",
        goBack: "wróć do ostatniej strony",
        cancel: "Anulować",
        home: "pierwsza strona",
        loading: "Ładowanie...",
        confirm: "potwierdzać",
        loadingFailed: "anomalia sieci",
        nomore: "już nie",
        tryAgain: "Odśwież stronę/wyjdź z programu i prześlij ponownie!",
        hot: "Popularne obszary",
    },
    inviteFriends: {
        inviteFriends: "Podziel się z przyjaciółmi",
        freedomOfWealth: "Niech bogactwo będzie razem wolne",
        myInvitationCode: "mój kod zaproszenia",
        clickCopy: "Kliknij, aby skopiować",
        myInvitationLink: "Mój link z zaproszeniem",
        clicktoSaveTheQRCode: "Kliknij, aby zapisać kod QR",
        saveQRCode: "Zaproś kod QR",
        numberOfSubordinates: "Liczba podwładnych",
        rebateIncome: "Dochód z rabatu",
        people: "ludzie",
        myPromotionRevenue: "Mój dochód z promocji",
        numberOfDirectSubordinates: "Liczba bezpośrednich podwładnych",
        totalNumberOfSubordinates: "Całkowita liczba podwładnych",
        directSubordinateReturnsCommission: "Podwładny rabat",
        theTotalCommission: "całkowity rabat",
        checkHistory: "Wyświetl rekordy rabatów",
        history: "Protokół Komisji",
        theLowerAccount: "Konto podrzędne",
        type: "typ：",
        serviceCharge: "opłata manipulacyjna：",
        percentageofCommissionReturned: "Stosunek rabatu：",
        commissionrebateAmount: "Kwota rabatu：",
        time: "czas：",
        typeName1: "Doładowanie i prowizja",
        typeName2: "Opłata za obsługę zleceń MT jest dystrybuowana na trzech poziomach",
        typeName3: "MT Zyskowna trzypoziomowa dystrybucja",
        typeName4: "Nagrody za polecenie",
        typeName30: "Doładowanie i prowizja",
        typeName31: "Okresowy rabat od zysku handlowego",
        typeName32: "Rabat na zysk z wydobycia płynności",
        typeName45: "Zniżka na opłatę transakcyjną",
        typeName46: "Rabat prowizyjny od własnej opłaty manipulacyjnej",
        typeName50: "Zniżka na opłatę manipulacyjną dotyczącą umowy dostawy",
        typeName51: "Zniżka na opłatę manipulacyjną dotyczącą umów bezterminowych",
        typeName52: "Dodatkowa obniżka prowizji za opłaty manipulacyjne związane z umową dostawy",
        typeName53: "Dodatkowy rabat prowizyjny za opłaty manipulacyjne związane z umowami wieczystymi",
        typeName54: "Dodatkowe rabaty na opłaty manipulacyjne związane z umowami wieczystymi",
        shareRules1: "Instrukcja zaproszenia",
        shareRules2: "Wejdź do centrum zaproszeń, skopiuj link lub kod zaproszenia i udostępnij go znajomym. Znajomi mogą zostać Twoimi podwładnymi, rejestrując się przy użyciu Twojego kodu zaproszenia.",
        shareRules3: "Zarabiaj rabaty",
        shareRules4: "Kiedy podwładni przeprowadzają transakcje, możesz otrzymać odpowiednie prowizje, obsługiwane są maksymalnie trzy poziomy podwładnych. Na przykład, jeśli zaprosisz przyjaciela A, A zaprosi B, a B zaprosi C, wówczas A, B i C będą mogli zawierać umowy i inne transakcje na platformie, a wy wszyscy możecie otrzymać odpowiednie prowizje.",
        shareRules5: "Poziom zespołu",
        shareRules6: "Im więcej awansujesz podwładnych pierwszego poziomu, tym wyższy poziom zespołu i tym wyższy rabat możesz otrzymać. Poziomy zespołów podzielone są na LV1-LV6.Zasady aktualizacji przedstawiono w poniższej tabeli, gdzie „N” to liczba podwładnych pierwszego poziomu, którzy doładowali i ukończyli uwierzytelnianie przy użyciu prawdziwego imienia i nazwiska.",
        shareRules7: "Kiedy podwładni obstawiają kontrakty na dostawę, możesz otrzymać prowizję proporcjonalnie do ich zakładów.",
        upgradeConditions: "Wymagać",
        recommendedNumberOfPeople: "Zalecana liczba osób"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Sugestie i opinie",
        text: "Jeśli podczas użytkowania napotkasz błędy lub masz sugestie dotyczące produktów, prześlij nam swoją opinię.",
        email: "adres e-mail",
        enterEmail: "Proszę podać adres e-mail",
        enterCorrectEmail: "Proszę wpisać poprawny adres e-mail",
        title: "tytuł",
        enterTitle: "Wprowadź tytuł pytania",
        describeTheProblem: "Opisz problem",
        enterDescribeTheProblem: "Wprowadź opis problemu",
        submit: "składać",
    },
    help: {
        enterSearch: "szukaj",
        title: "Centrum pomocy",
        search: "Proszę wpisać swoje pytanie",
        text1: "Zrozum swoje potrzeby i kontynuuj zapewnianie wygodniejszych usług"
    },
    announcement: {
        enterSearch: "szukaj",
        title: "ogłoszenie",
        search: "Proszę wpisać swoje pytanie"
    },
    privacy: {
        title: "Oświadczenie o ochronie prywatności",
        content1: "1. Jakie dane osobowe użytkowników są gromadzone?",
        content2: "Dane osobowe są gromadzone w celu dostarczania produktów i usług oraz ciągłego ich ulepszania.",
        content3: "Poniżej przedstawiono rodzaje danych osobowych, które gromadzimy：",
        content4: "Informacje, które nam przekazujesz: Otrzymujemy i przechowujemy wszelkie informacje, które nam przekazujesz w związku z Platformą. Możesz zrezygnować z podawania pewnych informacji, ale w rezultacie możesz nie być w stanie korzystać z niektórych „usług tej platformy”. Przykładowe informacje, które nam przekazujesz obejmują:",
        content5: "Nazwa",
        content6: "adres",
        content7: "Obywatelstwo",
        content8: "zdjęcie identyfikacyjne",
        content9: "Numer telefonu komórkowego/numer e-mail",
        content10: "Inne informacje, które pomagają nam Cię zidentyfikować",
        content11: "Informacje zbierane automatycznie: automatycznie zbieramy i przechowujemy określone rodzaje informacji na temat korzystania przez Ciebie z Usług Platformy.Podobnie jak wiele stron internetowych, używamy plików cookie i innych unikalnych identyfikatorów. Pozyskujemy określone rodzaje informacji, gdy Twoja przeglądarka internetowa lub urządzenie uzyskuje dostęp do Platformy. Przykłady informacji, które zbieramy i analizujemy, obejmują:",
        content12: "Adres protokołu internetowego (IP), który łączy komputer osobisty z Internetem;",
        content13: "Dane logowania, adres e-mail, hasło i lokalizacja urządzenia osobistego lub komputera;",
        content14: "Ustawienia wersji i strefy czasowej；",
        content15: "Historia transakcji。",
        content16: "Informacje z innych źródeł: Możemy zbierać informacje o Tobie z innych źródeł, takich jak historia kredytowa z biur informacji kredytowej. Wykorzystamy te informacje w celu zapobiegania i wykrywania oszustw.",
        content17: "2. Czy mogą go stosować dzieci?",
        content18: "Platforma ta nie pozwala na korzystanie z „Usługi” osobom poniżej 18 roku życia.",
        content19: "3. Do czego służą pliki cookie i inne identyfikatory?",
        content20: "Używamy plików cookie i podobnych narzędzi w celu optymalizacji doświadczenia użytkownika, świadczenia naszych usług i zrozumienia, w jaki sposób klienci korzystają z naszych usług, abyśmy mogli wprowadzać ukierunkowane ulepszenia. Używamy plików cookie, aby nasz system mógł rozpoznać Twoją przeglądarkę lub urządzenie i świadczyć Ci usługi.",
        content21: "Wykorzystujemy operacyjne pliki cookies i podobne narzędzia (zwane łącznie „Cookies”) w celu świadczenia usług takich jak:",
        content22: "Aby Cię zidentyfikować, gdy logujesz się i korzystasz z naszych usług.",
        content23: "Zapewnij dostosowane funkcje i usługi.",
        content24: "Chroń przed oszukańczą działalnością.",
        content25: "Popraw bezpieczeństwo.",
        content26: "Śledź swoje preferencje (takie jak waluta i język).",
        content27: "Używamy również plików cookie, aby zrozumieć, w jaki sposób użytkownicy korzystają z naszych usług, abyśmy mogli wprowadzać ukierunkowane ulepszenia.",
        content28: "4. Czy moje dane osobowe zostaną udostępnione?",
        content29: "Informacje o użytkownikach są ważną częścią naszej działalności i nie będziemy sprzedawać danych osobowych użytkowników innym osobom. Niniejsza platforma będzie udostępniać dane osobowe użytkowników swoim spółkom zależnym lub stowarzyszonym wyłącznie w następujących okolicznościach: Te spółki zależne lub stowarzyszone są zobowiązane do przestrzegania niniejszego oświadczenia o ochronie prywatności lub przynajmniej stosowania praktyk, które zapewniają taką samą ochronę jak środki ochrony opisane w tym oświadczeniu o ochronie prywatności.",
        content30: "Zewnętrzni dostawcy usług: zatrudniamy inne firmy i osoby fizyczne do wykonywania funkcji w naszym imieniu. Przykładami takich funkcji są: analiza danych, udzielanie pomocy marketingowej, przetwarzanie płatności, przesyłanie treści oraz ocena i zarządzanie ryzykiem kredytowym. Ci zewnętrzni dostawcy usług mają dostęp do danych osobowych niezbędnych do wykonywania ich funkcji, ale nie mogą ich wykorzystywać do żadnych innych celów. Ponadto muszą przetwarzać dane osobowe zgodnie z niniejszym oświadczeniem o ochronie prywatności i obowiązującymi przepisami o ochronie danych.",
        content31: "Przeniesienie działalności: W miarę rozwoju naszej firmy możemy sprzedawać lub kupować inne firmy lub usługi. W przypadku takich transakcji informacje o użytkowniku zazwyczaj należą do przenoszonych aktywów biznesowych, ale podlegają obietnicom zawartym we wszelkich istniejących wcześniej oświadczeniach o ochronie prywatności (chyba że użytkownik oczywiście zgodzi się inaczej). Ponadto, w przypadku nabycia naszej firmy lub zasadniczo wszystkich jej aktywów, dane użytkownika również zostaną przekazane. Ochrona naszej firmy i innych osób: Jeżeli uważamy, że udostępnienie konta i innych danych osobowych jest zgodne z prawem lub naszymi obowiązkami regulacyjnymi, egzekwowanie lub stosowanie naszych Warunków użytkowania i innych umów lub praw, własności lub bezpieczeństwa naszych użytkowników lub inne osoby. Kiedy udostępnimy konto i inne dane osobowe. Obejmuje to wymianę informacji z innymi firmami i organizacjami w celu ochrony przed oszustwami i zmniejszenia ryzyka kredytowego.",
        content32: "5. Międzynarodowe przekazywanie danych osobowych",
        content33: "Możemy przekazywać Twoje dane poza Europejski Obszar Gospodarczy („EOG”). Wprowadzimy odpowiednie zabezpieczenia, aby zapewnić, że takie przekazywanie danych będzie zgodne z obowiązującymi przepisami o ochronie danych, chyba że Komisja Europejska potwierdzi, że kraj, do którego dane są przekazywane, zapewnia odpowiedni poziom ochrony.",
        content34: "6. Czy moje dane osobowe są bezpieczne?",
        content35: "Zaprojektowaliśmy nasze systemy z myślą o Twoim bezpieczeństwie i prywatności. Używamy protokołów szyfrowania i oprogramowania, aby chronić bezpieczeństwo danych osobowych podczas transmisji.",
        content36: "Zawsze stosujemy zabezpieczenia fizyczne, elektroniczne i proceduralne podczas gromadzenia, przechowywania i ujawniania danych osobowych. Nasze procedury bezpieczeństwa oznaczają, że być może będziemy musieli zweryfikować Twoją tożsamość przed ujawnieniem Ci danych osobowych.",
        content37: "Najważniejszą rzeczą, jaką możesz zrobić, to chronić hasła do swoich kont osobistych przed nieautoryzowanym dostępem. Zalecamy ustawienie unikalnego hasła do swojego konta, różniącego się od innych kont online. Pamiętaj też o wylogowaniu się po korzystaniu ze wspólnego komputera.",
        content38: "7. Jak powinienem chronić dane osobowe?",
        content39: "Jeśli masz jakiekolwiek pytania lub zastrzeżenia dotyczące sposobu, w jaki zbieramy i przetwarzamy dane osobowe, skontaktuj się z obsługą klienta",
        content40: "Po wyrażeniu zgody na przetwarzanie przez nas Twoich danych osobowych w określonym celu, możesz w każdej chwili wycofać swoją wolę, a my zaprzestaniemy przetwarzania Twoich danych w tym celu.",
        content41: "Ponadto masz prawo żądać dostępu do danych osobowych, ich sprostowania i usunięcia, a także żądania przeniesienia danych, z zastrzeżeniem obowiązujących przepisów prawa. Możesz także sprzeciwić się przetwarzaniu przez nas Twoich danych osobowych lub zażądać ograniczenia przetwarzania Twoich danych osobowych w określonych okolicznościach.",
        content42: "8. RODO UE i brytyjskie prawo o ochronie danych – podstawa prawna",
        content43: "Unijne RODO i brytyjskie prawo o ochronie danych wymagają od nas przestrzegania podstawy prawnej podczas wykorzystywania danych osobowych. Nasza podstawa zależy od konkretnych celów, dla których wykorzystywane są dane osobowe. Do tych podstaw zaliczają się:",
        content44: "Wykonanie umowy – w celu dostarczenia lub komunikowania się z Tobą w sprawie produktów lub usług, w tym wykorzystywania przez nas Twoich danych osobowych w celu przyjmowania i przetwarzania zamówień oraz przetwarzania płatności.",
        content45: "Nasze uzasadnione interesy biznesowe i interesy użytkowników — wykrywamy oszustwa i nadużycia oraz zapobiegamy im, aby chronić bezpieczeństwo naszych użytkowników, nas samych i innych.",
        content46: "Twoja zgoda – Wymagamy Twojej zgody na przetwarzanie Twoich danych osobowych w określonych celach, o których jesteś poinformowany. Po udzieleniu nam zgody na przetwarzanie danych osobowych w określonym celu, możesz ją w każdej chwili wycofać, a my zaprzestaniemy przetwarzania Twoich danych w tym celu.",
        content47: "Przestrzegaj obowiązków prawnych – Wykorzystujemy Twoje dane osobowe zgodnie z wymogami prawa. Na przykład zbieramy informacje o koncie bankowym w celu weryfikacji tożsamości.",
        content48: "Powyższe i inne podstawy prawne zależą od konkretnych celów, dla których wykorzystujemy dane osobowe.",
        content49: "9. Warunki użytkowania, uwagi i zmiany",
        content50: "Korzystanie przez Ciebie i wszelkie spory dotyczące prywatności podlegają niniejszemu Oświadczeniu i naszym Warunkom użytkowania. Jeśli masz jakiekolwiek wątpliwości dotyczące prywatności tej platformy, skontaktuj się z nami, podając szczegółowe wyjaśnienie, a chętnie rozwiążemy problem. Masz również prawo skontaktować się z lokalnym organem odpowiedzialnym za ochronę danych.",
        content51: "Nasza działalność stale się zmienia, podobnie jak nasze oświadczenie o ochronie prywatności. Należy często odwiedzać naszą stronę internetową, aby zobaczyć najnowsze zmiany. Jeśli nie zgadzasz się ze zmienioną treścią, powinieneś natychmiast przerwać dostęp. Po opublikowaniu zaktualizowanej wersji Polityki prywatności, dalszy dostęp oznacza, że ​​zgadzasz się z zaktualizowaną treścią i zgadzasz się przestrzegać zaktualizowanej Polityki prywatności. O ile nie określono inaczej, aktualne Oświadczenie o ochronie prywatności ma zastosowanie do wszystkich informacji, które posiadamy o Tobie i Twoim koncie.",
    },
    serviceAgreement: {
        title: "Umowa serwisowa",
        content1: "Niniejsza witryna internetowa jest platformą przeznaczoną specjalnie dla użytkowników do przeprowadzania transakcji na aktywach cyfrowych i świadczenia powiązanych usług (zwanych dalej „usługą” lub „usługą”). Dla wygody opisywania tej umowy, na tej stronie internetowej zbiorczo używamy w tej umowie słowa „my” lub innych zaimków pierwszoosobowych. O ile osoba fizyczna lub inny podmiot logujący się do serwisu jest użytkownikiem tego serwisu, dla wygody wyrażenia niniejszej zgody poniżej będziemy używali określenia „Ty” lub inna druga osoba. Dla wygody wyrażania niniejszej Umowy, my i Ty jesteśmy w niniejszej Umowie łącznie zwani „Stronami”, a my lub Ty indywidualnie jesteśmy zwani „Stroną”.",
        content2: "ważna wskazówka：",
        content3: "Chcielibyśmy Państwu w tym miejscu przypomnieć：",
        content4: "1 Same aktywa cyfrowe nie są emitowane przez żadną instytucję finansową, firmę ani tę stronę internetową",
        content5: "2 Rynki aktywów cyfrowych są nowe, niepotwierdzone i mogą nie rosnąć;",
        content6: "3 Aktywa cyfrowe są szeroko wykorzystywane głównie przez spekulantów, stosunkowo niewiele na rynkach detalicznych i komercyjnych. Transakcje na aktywach cyfrowych są niezwykle ryzykowne. Handel nimi odbywa się w sposób ciągły przez cały dzień, bez ograniczeń wzrostu i spadku, a ceny łatwo podlegają wpływom rynkowym decydenci i globalna polityka rządowa fluktuacja;",
        content7: "4  Firma zastrzega sobie prawo do zawieszenia lub zamknięcia Twojego konta w dowolnym momencie, jeśli według własnego uznania stwierdzi, że naruszyłeś niniejszą Umowę lub że usługi świadczone przez tę witrynę lub korzystanie przez Ciebie z usług świadczonych przez tę witrynę są niezgodne z prawem obowiązującym w Twojej jurysdykcji. lub zawiesić lub zakończyć korzystanie z usług lub transakcji na aktywach cyfrowych udostępnianych przez tę witrynę. Osobom z [Chin kontynentalnych, Tajwanu, Izraela, Iraku, Bangladeszu, Boliwii, Ekwadoru, Kirgistanu, Sewastopola i Wielkiej Brytanii (użytkownicy detaliczni)] nie wolno korzystać z usług transakcji kontraktowych świadczonych przez tę witrynę. Powyższa lista krajów lub regionów będzie się zmieniać w zależności od zasad i typów produktów różnych krajów lub regionów. Możemy nie powiadomić Cię w tym czasie, dlatego prosimy o terminowe aktualizowanie niniejszej umowy.",
        content8: "Handel aktywami cyfrowymi niesie ze sobą niezwykle wysokie ryzyko i nie jest odpowiedni dla większości ludzi. Rozumiesz i rozumiesz, że ta transakcja może skutkować częściową lub całkowitą stratą, dlatego powinieneś określić kwotę transakcji w oparciu o stopień straty, na jaki możesz sobie pozwolić. Wiesz i rozumiesz, że aktywa cyfrowe będą generować ryzyko instrumentów pochodnych, dlatego w przypadku jakichkolwiek pytań zaleca się najpierw zwrócić się o pomoc do profesjonalnych doradców. Oprócz ryzyk wymienionych powyżej, istnieją również ryzyka, których nie można przewidzieć. Przed podjęciem jakiejkolwiek decyzji o zakupie lub sprzedaży aktywów cyfrowych należy dokładnie rozważyć i kierować się jasnym osądem, aby ocenić swoją sytuację finansową i wyżej wymienione ryzyko, a także ponieść wszelkie wynikające z tego straty. Nie ponosimy za to żadnej odpowiedzialności.",
        content9: "Proszę zanotować:",
        content10: "1 Rozumiesz, że ta witryna internetowa służy wyłącznie jako miejsce, w którym możesz uzyskać informacje o zasobach cyfrowych, znaleźć strony transakcji, negocjować i przeprowadzać transakcje na zasobach cyfrowych. Ta witryna nie uczestniczy w żadnej z Twoich transakcji, dlatego powinieneś kierować się własnym, ostrożnym osądem w celu ustalenia odpowiednich zasobów cyfrowych i/lub transakcji lub autentyczności, legalności i ważności informacji i samodzielnie ponosi wynikającą z tego odpowiedzialność i straty.",
        content11: "2 Wszelkie opinie, aktualności, dyskusje, analizy, ceny, rekomendacje i inne informacje zawarte na tej stronie mają charakter ogólnych komentarzy rynkowych i nie stanowią porady inwestycyjnej. Nie ponosimy odpowiedzialności za jakiekolwiek straty wynikające bezpośrednio lub pośrednio z polegania na tych informacjach, w tym między innymi za utratę zysków.",
        content12: "3 Niniejsza witryna zastrzega sobie prawo do samodzielnego podejmowania decyzji, modyfikowania lub zmiany treści w dowolnym momencie. Podjęliśmy uzasadnione działania w celu zapewnienia prawidłowości informacji zawartych na stronie, jednak nie możemy zagwarantować ich dokładności i nie będziemy ponosić odpowiedzialności za wszelkie straty lub szkody spowodowane przez informacje zawarte na tej stronie internetowej lub brak informacji.Bezpośrednie lub pośrednie straty wynikające z opóźnień lub awarii w łączeniu się z Internetem, przesyłaniu lub odbieraniu jakichkolwiek powiadomień i informacji.",
        content13: "4 Korzystanie z internetowych systemów transakcyjnych wiąże się również z ryzykiem, w tym między innymi awariami oprogramowania, sprzętu i połączenia internetowego. Ponieważ nie mamy kontroli nad niezawodnością i dostępnością Internetu, nie ponosimy odpowiedzialności za zakłócenia, opóźnienia i awarie połączeń.",
        content14: "5 Zabrania się korzystania z tej witryny internetowej w celu angażowania się w jakąkolwiek nielegalną działalność handlową lub działalność nielegalną, taką jak pranie pieniędzy, przemyt, przekupstwo handlowe itp. W przypadku wykrycia jakichkolwiek podejrzeń nielegalnych transakcji lub nielegalnych działań, niniejsza witryna zastosuje różne dostępne środki, w tym ale nie ogranicza się do zamrożenia kont i powiadomienia odpowiednich stron.Władze itp., nie przejmujemy wszelkiej odpowiedzialności z tego wynikającej i zastrzegamy sobie prawo do dochodzenia odpowiedzialności od odpowiednich osób.",
        content15: "6 Zabrania się korzystania z tej witryny w celu złośliwej manipulacji rynkiem, niewłaściwych transakcji i innych nieetycznych działań handlowych. W przypadku wykrycia takich incydentów, witryna będzie ostrzegać, ograniczać transakcje i eliminować wszelkie nieetyczne zachowania, takie jak złośliwa manipulacja cenami i złośliwy wpływ na rynek System transakcyjny Nie ponosimy wszelkiej odpowiedzialności wynikającej z zapobiegawczych środków ochronnych, takich jak zawieszenie konta i zastrzegamy sobie prawo do dochodzenia odpowiedzialności od odpowiednich osób.",
        content16: "1. Zasady ogólne",
        content17: "1.1 „Umowa z użytkownikiem” (zwana dalej „niniejszą Umową” lub „niniejszymi Warunkami”) składa się z tekstu głównego, „Warunków prywatności”, „Poznaj swojego klienta i Polityki przeciwdziałania praniu pieniędzy” oraz różnych innych polityk, które mają zostały opublikowane lub mogą zostać w przyszłości opublikowane na tej stronie Regulamin zajęć, oświadczenia, opisy itp.",
        content18: "1.2 Przed skorzystaniem z usług świadczonych przez tę witrynę należy dokładnie zapoznać się z niniejszą umową.Jeśli czegoś nie rozumiesz lub jest to konieczne z innego powodu, skonsultuj się z profesjonalnym prawnikiem. Jeśli w dowolnym momencie nie zgadzasz się z niniejszą Umową i/lub jej modyfikacjami, prosimy o natychmiastowe zaprzestanie korzystania z usług świadczonych przez tę witrynę lub nielogowanie się już na tej stronie. Po zalogowaniu się na tej stronie, skorzystaniu z jakichkolwiek usług tej witryny lub innych podobnych zachowań, oznacza to, że zrozumiałeś i w pełni zgodziłeś się na treść niniejszej Umowy, w tym wszelkie modyfikacje wprowadzone przez tę witrynę do niniejszej Umowy w dowolnym momencie.",
        content19: "1.3 Możesz zostać członkiem tej witryny (zwanym dalej „członkiem”), wypełniając odpowiednie informacje zgodnie z wymogami tej witryny i pomyślnie rejestrując się po innych odpowiednich procedurach. Klikając przycisk „Zgadzam się” podczas rejestracji procesu, podpisujesz elektronicznie Formularz umowy z firmą lub gdy klikniesz dowolny przycisk oznaczony „Zgadzam się” lub o podobnym znaczeniu podczas korzystania z tej witryny lub faktycznie korzystasz z usług świadczonych przez tę witrynę w inny sposób dozwolony przez tę witrynę oznacza to, że w pełni rozumiesz, zgadzasz się i akceptujesz wszystkie warunki niniejszej Umowy. Brak Twojego odręcznego podpisu nie będzie mieć wpływu na prawnie wiążącą moc niniejszej Umowy w stosunku do Ciebie.",
        content20: "1.4 Po zostaniu członkiem tej witryny otrzymasz konto członkowskie i odpowiednie hasło, które są przez Ciebie przechowywane; będziesz prawnie odpowiedzialny za wszystkie działania i wydarzenia przeprowadzane w ramach Twojego konta.",
        content21: "1.5 Tylko członkowie, którzy zostali członkami tej witryny, mogą korzystać z platformy handlu aktywami cyfrowymi udostępnianej przez tę witrynę w celu dokonywania transakcji i korzystania z innych usług określonych w tej witrynie, które są dostępne tylko dla członków; osoby niebędące członkami mogą jedynie logować się na stronie i przeglądać witrynę i inne usługi określone w tej witrynie.usługi.",
        content22: "1.6 Rejestrując się i korzystając z usług i funkcji udostępnianych przez tę witrynę, uznaje się, że przeczytałeś, zrozumiałeś i:",
        content23: "1.6.1 Wyrażam zgodę na przestrzeganie wszystkich warunków niniejszej Umowy.",
        content24: "1.6.2 Potwierdzasz, że masz ukończone 18 lat lub jesteś w wieku wymaganym do zawierania umów zgodnie z obowiązującymi przepisami.Twoja rejestracja na tej stronie, sprzedaż lub zakupy, publikowanie informacji itp. oraz akceptacja usług tej witryny powinna być zgodna z Twoimi wymaganiami. Mieć jurysdykcję nad odpowiednimi przepisami ustawowymi i wykonawczymi suwerennego kraju lub regionu oraz mieć pełną zdolność do akceptowania niniejszych warunków, zawierania transakcji i korzystania z tej witryny w celu przeprowadzania transakcji na aktywach cyfrowych.",
        content25: "1.6.3 Gwarantujesz, że wszystkie zasoby cyfrowe należące do Ciebie biorące udział w transakcji zostały legalnie uzyskane i stanowią własność.",
        content26: "1.6.4 Zgadzasz się, że ponosisz wyłączną odpowiedzialność za swoją własną działalność handlową lub niehandlową oraz za wszelkie zyski i straty.",
        content27: "1.6.5 Potwierdzasz, że informacje podane podczas rejestracji są prawdziwe i dokładne.",
        content28: "1.6.6 Zgadzasz się przestrzegać wymogów wszelkich odpowiednich przepisów, w tym zgłaszać wszelkie zyski handlowe dla celów podatkowych.",
        content29: "1.6.7 Zgadzasz się nie angażować ani nie uczestniczyć w zachowaniach lub działaniach, które szkodzą interesom tej witryny lub firmy w dowolnym momencie, niezależnie od tego, czy są one związane z usługami świadczonymi przez tę witrynę.",
        content30: "1.6.8 Niniejsza umowa reguluje wyłącznie prawa i obowiązki pomiędzy Tobą a nami i nie obejmuje stosunków prawnych ani sporów prawnych wynikających z transakcji na aktywach cyfrowych pomiędzy użytkownikami tej witryny i innych witryn internetowych a Tobą.",
        content31: "2. Zmiany umowy",
        content32: "Zastrzegamy sobie prawo do okresowej zmiany niniejszej Umowy i ogłoszenia jej na stronie internetowej bez osobnego powiadamiania. Po dokonaniu zmiany czas zmiany zostanie zaznaczony w [Czasie ostatniej aktualizacji] wyświetlonym w niniejszej Umowie i zajmie to automatycznie obowiązywać po opublikowaniu na stronie internetowej. Powinieneś od czasu do czasu przeglądać i zwracać uwagę na czas aktualizacji i zaktualizowaną treść niniejszej Umowy.Jeśli nie zgadzasz się z odpowiednimi zmianami, powinieneś natychmiast zaprzestać korzystania z usług tej witryny; dalsze korzystanie z usług tej oznacza, że ​​akceptujesz i zgadzasz się na przestrzeganie zmienionej umowy.",
        content33: "3. Rejestracja",
        content34: "3.1 Kwalifikacje rejestracyjne",
        content35: "Potwierdzasz i obiecujesz, że po zakończeniu procesu rejestracji lub faktycznym korzystaniu z usług świadczonych przez tę witrynę w inny sposób dozwolony przez tę witrynę, powinieneś mieć możliwość podpisania niniejszej umowy i korzystania z usług tej witryny zgodnie z obowiązującymi przepisami. osoba fizyczna, osoba prawna lub inna organizacja. Gdy klikniesz przycisk Zgadzam się na rejestrację, oznacza to, że Ty lub Twój upoważniony agent zgodziliście się na treść umowy oraz zostaliście zarejestrowani i korzystaliście z usług tej witryny przez swojego agenta. Jeśli nie posiadasz wyżej wymienionych kwalifikacji przedmiotowych, Ty i Twój upoważniony agent poniesiecie wszelkie wynikające z tego konsekwencje, a firma zastrzega sobie prawo do anulowania lub trwałego zamrożenia Twojego konta oraz pociągnięcia Ciebie i Twojego upoważnionego agenta do odpowiedzialności.",
        content36: "3.2 Cel rejestracji",
        content37: "Potwierdzasz i obiecujesz, że Twoja rejestracja na tej stronie nie ma na celu naruszenia przepisów ustawowych i wykonawczych ani zakłócenia porządku transakcji na aktywach cyfrowych na tej stronie.",
        content38: "3.3Proces rejestracji",
        content39: "3.3.1 Wyrażasz zgodę na podanie prawidłowego adresu e-mail, numeru telefonu komórkowego i innych informacji wymaganych na stronie rejestracji użytkownika w tej witrynie. Możesz użyć adresu e-mail, numeru telefonu komórkowego, który podałeś lub potwierdziłeś, lub innych metod dozwolonych w tej witrynie jako loginu sposób wejścia na tę stronę. Jeśli to konieczne, zgodnie z odpowiednimi przepisami ustawowymi i wykonawczymi różnych jurysdykcji, musisz podać swoje prawdziwe imię i nazwisko, dokument tożsamości i inne istotne informacje określone w przepisach ustawowych i wykonawczych, klauzulach dotyczących prywatności i klauzulach dotyczących przeciwdziałania praniu pieniędzy, a także stale aktualizować dane rejestracyjne w w sposób terminowy, szczegółowy i dokładny. Wszystkie pierwotnie wpisane informacje będą traktowane jako informacje rejestracyjne. Ponoszą Państwo odpowiedzialność za autentyczność, kompletność i dokładność takich informacji oraz ponoszą wszelkie bezpośrednie lub pośrednie straty i niekorzystne konsekwencje z nich wynikające.",
        content40: "3.3.2 Jeżeli przepisy ustawowe, wykonawcze, zasady, zarządzenia i inne regulacje suwerennego kraju lub regionu, w którym się znajdujesz, wymagają podawania prawdziwych nazwisk w numerach telefonów komórkowych, zgadzasz się, że podany zarejestrowany numer telefonu komórkowego został zarejestrowany pod prawdziwym nazwiskiem. nie przekażesz ich zgodnie z przepisami, wszelkie wyrządzone Ci konsekwencje będą stratami bezpośrednimi lub pośrednimi, a niekorzystne konsekwencje poniesiesz Ty.",
        content41: "3.3.3 Jeżeli podałeś(aś) informacje wymagane do rejestracji w sposób zgodny z prawem, kompletny i skuteczny oraz je zweryfikowałeś, masz prawo otrzymać numer konta i hasło do tej witryny.Po uzyskaniu numeru konta i hasła do tej witryny, Twoja rejestracja jest uważana za zakończyło się sukcesem i możesz zalogować się jako członek na tej stronie internetowej.",
        content42: "3.3.4 Wyrażasz zgodę na otrzymywanie e-maili i/lub krótkich wiadomości wysyłanych przez tę witrynę, związanych z zarządzaniem i działaniem tej witryny.",
        content43: "4. Serwis",
        content44: "Niniejsza witryna internetowa zapewnia wyłącznie usługi platformy handlu online w zakresie działalności związanej z handlem aktywami cyfrowymi (w tym między innymi handlu aktywami cyfrowymi i innych usług) za pośrednictwem tej witryny.",
        content45: "4.1 Treść usługi",
        content46: "4.1.1 Masz prawo przeglądać w czasie rzeczywistym warunki rynkowe i informacje o transakcjach dotyczących różnych produktów dotyczących aktywów cyfrowych na tej stronie internetowej, a także masz prawo do przesyłania instrukcji dotyczących transakcji na aktywach cyfrowych i przeprowadzania transakcji na aktywach cyfrowych za pośrednictwem tej witryny.",
        content47: "4.1.2 Masz prawo wglądu do informacji znajdujących się na Twoim koncie członkowskim w tej witrynie oraz do korzystania z funkcji udostępnianych przez tę witrynę.",
        content48: "4.1.3 Masz prawo brać udział w działaniach serwisu organizowanych przez ten serwis, zgodnie z zasadami działalności opublikowanymi na tym serwisie.",
        content49: "4.1.4 Ta strona internetowa obiecuje świadczyć inne usługi.",
        content50: "4.2 Regulamin świadczenia usług Zobowiązujesz się przestrzegać następujących zasad świadczenia usług tej witryny:",
        content51: "4.2.1 Będziesz przestrzegać przepisów ustawowych, wykonawczych i wymogów polityki, zapewnić legalność źródeł wszystkich zasobów cyfrowych na swoim koncie i nie będziesz angażować się w nielegalne lub inne działania, które szkodzą prawom i interesom tej witryny lub stron trzecich na tej stronie lub korzystając z jej usług, w tym między innymi: Nie jesteś ograniczony do wysyłania lub otrzymywania jakichkolwiek nielegalnych, nielegalnych lub naruszających informacje na temat praw innych osób, wysyłania lub otrzymywania piramid finansowych lub innych szkodliwych informacji lub uwag, używania lub fałszowania wiadomości e-mail informacje w nagłówku tej witryny bez autoryzacji z tej witryny itp.",
        content52: "4.2.2 Powinieneś przestrzegać praw i przepisów oraz właściwie używać i przechowywać swój numer konta, hasło do logowania, hasło do funduszu, numer telefonu komórkowego powiązany z Tobą podczas rejestracji oraz kod weryfikacyjny telefonu komórkowego otrzymany przez Twój telefon komórkowy. Ponosisz pełną odpowiedzialność za wszelkie operacje i konsekwencje korzystania ze swojego konta oraz hasła logowania, hasła do funduszu i kodu weryfikacyjnego telefonu komórkowego. Jeśli odkryjesz, że numer konta, hasło do logowania, hasło do funduszu lub kod weryfikacyjny tej witryny są używane przez osobę trzecią bez Twojej autoryzacji lub występują inne problemy związane z bezpieczeństwem konta, powinieneś natychmiast i skutecznie powiadomić tę witrynę i poprosić tę witrynę o zawieszenie obsługa konta w tym serwisie. . Niniejsza witryna internetowa ma prawo podjąć działania na Twoją prośbę w rozsądnym terminie, jednakże nie ponosi ona żadnej odpowiedzialności za konsekwencje (w tym między innymi za wszelkie poniesione przez Ciebie straty), które wystąpiły przed podjęciem działań. Nie możesz oddawać, pożyczać, dzierżawić, przekazywać ani w inny sposób zbywać swojego konta w tej witrynie innym osobom bez zgody tej witryny.",
        content53: "4.2.3 Wyrażasz zgodę na odpowiedzialność za wszystkie działania podejmowane w ramach Twojego konta i hasła na tej stronie internetowej (w tym między innymi ujawnianie informacji, publikowanie informacji, kliknięcia online w celu wyrażenia zgody lub złożenia różnych zasad i umów, odnawianie umów online lub zakup usługi itp.).",
        content54: "4.2.4 Podczas przeprowadzania transakcji na aktywach cyfrowych na tej stronie internetowej nie wolno złośliwie zakłócać normalnego przebiegu transakcji na aktywach cyfrowych ani zakłócać porządku transakcji; nie wolno zakłócać normalnego działania tej witryny ani zakłócać korzystania z niej przez innych użytkowników usług za pomocą jakichkolwiek środków technicznych lub innych; nie wolno używać fikcyjnych, złośliwych zniesławień dobrej woli tej witryny internetowej za pomocą faktów lub innych środków.",
        content55: "4.2.5 Jeśli masz spór z innymi użytkownikami w związku z transakcjami online, nie możesz żądać od tej witryny internetowej dostarczenia odpowiednich informacji kanałami pozasądowymi lub administracyjnymi.",
        content56: "4.2.6 Będziesz wyłącznie oceniać i ponosić wszelkie należne podatki, a także cały sprzęt, oprogramowanie, usługi i inne wydatki poniesione podczas korzystania z usług świadczonych przez tę witrynę.",
        content57: "4.2.7 Powinieneś przestrzegać niniejszej umowy oraz innych warunków świadczenia usług i zasad działania publikowanych i aktualizowanych od czasu do czasu przez tę witrynę, a także mieć prawo do zakończenia korzystania z usług świadczonych przez tę witrynę w dowolnym momencie.",
        content58: "4.3 zasady produktu",
        content59: "4.3.1 Przeglądaj informacje o transakcjach",
        content60: "Przeglądając informacje o transakcjach na tej stronie internetowej, należy uważnie zapoznać się z całą treścią zawartą w informacjach o transakcji, w tym między innymi z ceną, wysokością prowizji, opłatą manipulacyjną, kierunkiem kupna lub sprzedaży, i dopiero po pełnej akceptacji całej treści zawartej w informacje o transakcji Transakcje można przeprowadzać za pomocą kliknięcia przycisku.",
        content61: "4.3.2 Zobacz szczegóły transakcji",
        content62: "Możesz przeglądać odpowiednie zapisy transakcji za pośrednictwem swojego konta.",
        content63: "5. Prawa i obowiązki tej witryny",
        content64: "5.1 Jeśli nie posiadasz kwalifikacji rejestracyjnych określonych w niniejszej umowie, ta strona internetowa ma prawo odmówić Twojej rejestracji. W przypadku tych, którzy już się zarejestrowali, ta strona ma prawo anulować Twoje konto członkowskie. Niniejsza witryna zastrzega sobie prawo do zatrzymania Cię lub Twój upoważniony agent odpowiedzialny ma prawo. Jednocześnie niniejsza strona internetowa zastrzega sobie prawo do podjęcia decyzji o przyjęciu Twojej rejestracji w innych okolicznościach.",
        content65: "5.2 Na podstawie własnej oceny tej witryny, jeśli ta witryna stwierdzi, że Ty lub użytkownicy powiązanego z Tobą konta nie nadają się do inwestycji wysokiego ryzyka, mamy prawo zawiesić lub zamknąć Twoje konto i możliwość korzystania ze wszystkich powiązanych kont.",
        content66: "5.3 Gdy ta witryna odkryje, że użytkownik konta nie jest pierwotnym rejestrującym konto, ma prawo zawiesić lub zakończyć korzystanie z konta.",
        content67: "5.4 Jeżeli ta strona internetowa ma uzasadnione podejrzenia, że ​​podane przez Ciebie informacje są błędne, nieprawdziwe, nieważne lub niekompletne w drodze testów technicznych, ręcznego pobierania próbek i innych metod testowania, ma prawo powiadomić Cię o poprawieniu lub aktualizacji informacji lub o zawieszeniu lub zakończeniu świadczenia ta witryna internetowa świadczy dla Ciebie usługi.",
        content68: "5.5 Niniejsza strona internetowa zastrzega sobie prawo do poprawiania wszelkich informacji wyświetlanych na tej stronie, jeśli okaże się, że występuje oczywisty błąd.",
        content69: "5.6 Niniejsza witryna internetowa zastrzega sobie prawo do modyfikacji, zawieszenia lub zakończenia świadczenia usług tej witryny w dowolnym momencie. Niniejsza witryna internetowa nie musi powiadamiać użytkownika z wyprzedzeniem, aby skorzystać z prawa do modyfikacji lub zakończenia usług. Jeśli ta witryna internetowa zakończy jedną lub więcej usług tej witryny, stronie internetowej, wypowiedzenie nastąpi na tej stronie internetowej. Obowiązuje od dnia publikacji ogłoszenia o wypowiedzeniu.",
        content70: "5.7 Niniejsza strona internetowa podejmie niezbędne środki techniczne i środki zarządzania, aby zapewnić normalne działanie tej witryny, zapewnić niezbędne i niezawodne środowisko handlowe i usługi handlowe oraz utrzymać porządek transakcji na aktywach cyfrowych.",
        content71: "5.8 Jeśli nie zalogujesz się do tej witryny przy użyciu swojego konta członkowskiego i hasła przez jeden rok z rzędu, ta witryna ma prawo do usunięcia Twojego konta. Po anulowaniu konta ta strona internetowa ma prawo udostępnić odpowiednią nazwę członkowską innym użytkownikom w celu rejestracji i użytkowania.Jeśli nie zalogujesz się do tej witryny przy użyciu swojego konta członkowskiego i hasła przez jeden rok z rzędu, ta witryna ma prawo do usunięcia Twojego konta. Po anulowaniu konta ta strona internetowa ma prawo udostępnić odpowiednią nazwę członkowską innym użytkownikom w celu rejestracji i użytkowania.",
        content72: "5.9 Ta witryna zapewnia bezpieczeństwo Twoich zasobów cyfrowych poprzez wzmocnienie inwestycji w technologię, poprawę środków bezpieczeństwa i innych środków, a także powiadomi Cię z wyprzedzeniem, gdy na Twoim koncie pojawią się przewidywalne zagrożenia bezpieczeństwa.",
        content73: "5.10 Niniejsza witryna internetowa ma prawo w dowolnym momencie usunąć wszelkiego rodzaju informacje zawarte w tej witrynie, które nie są zgodne z przepisami ustawowymi i wykonawczymi lub regulaminem tej witryny.Ta witryna nie wymaga wcześniejszego powiadamiania, aby skorzystać z takich praw.",
        content74: "5.11 Niniejsza witryna internetowa ma prawo żądać od Ciebie dostarczenia dodatkowych informacji lub materiałów zgodnie z przepisami ustawowymi, wykonawczymi, zasadami, zarządzeniami i innymi wymogami regulacyjnymi obowiązującymi w Twoim suwerennym kraju lub regionie, a także podjęcia uzasadnionych kroków w celu zapewnienia zgodności z lokalnymi wymogami regulacyjnymi. są zobowiązani do współpracy; ta strona internetowa ma prawo zawiesić lub trwale zaprzestać udostępniania niektórych lub wszystkich usług tej witryny dla Ciebie zgodnie z wymogami przepisów ustawowych, wykonawczych, zasad, zarządzeń i innych specyfikacji Twojego suwerennego kraju lub regionu.",
        content75: "5.12 Niniejsza witryna zastrzega sobie prawo do zamknięcia Twojego konta z siedmiodniowym wyprzedzeniem, według własnego uznania. Dlatego masz siedem dni roboczych na anulowanie zleceń i zamknięcie pozycji. Jeśli nie anulowałeś zamówienia i nie zamknąłeś pozycji po upływie tego okresu, siłą anulujemy zlecenie i zamkniemy pozycję, a także zwrócimy Ci pozostałe zasoby cyfrowe na Twoim koncie.",
        content76: "5.13 Aby chronić prawa i interesy przedsiębiorców, ta strona internetowa ma prawo wprowadzać zmiany w produktach online w szczególnych okolicznościach (takich jak awaria systemu, awaria sieci, ekstremalne warunki rynkowe itp.), takich jak: umowy o wcześniejszej dostawie i rozliczenia, rodzaje umów oraz umowy o wcześniejszą dostawę i rozliczenie.Okres oraz cena rozliczenia i dostawy podlegają ogłoszeniu na tej stronie internetowej.",
        content77: "6. Odszkodowanie",
        content78: "6.1 W żadnym wypadku nasza odpowiedzialność wobec Ciebie za szkody bezpośrednie nie przekroczy całkowitych opłat za usługi, które pobraliśmy od Ciebie za korzystanie z tej witryny przez okres trzech (3) miesięcy.",
        content79: "6.2 Jeśli naruszysz niniejszą Umowę lub inne przepisy i regulacje, musisz zrekompensować nam co najmniej 2 miliony dolarów amerykańskich i ponieść wszystkie poniesione koszty (w tym honoraria adwokackie itp.). Jeśli to nie wystarczy do zrekompensowania rzeczywistych strat, musisz dokonać zależeć od tego.",
        content80: "7. Prawo do uzyskania nakazu sądowego",
        content81: "Zarówno my, jak i Ty, uznajemy, że środki powszechnie obowiązujące w przypadku naruszenia przez Ciebie umowy lub potencjalnego naruszenia umowy mogą nie wystarczyć do zrekompensowania wszystkich poniesionych przez nas strat, dlatego mamy prawo ubiegać się o nakaz sądowy, zgodnie z prawem zwyczajowym lub słusznością w w przypadku naruszenia przez Ciebie umowy lub możliwego naruszenia umowy, wszelkie inne środki zaradcze.",
        content82: "8. Ograniczenie odpowiedzialności i wyłączenie odpowiedzialności",
        content83: "8.1 Rozumiesz i zgadzasz się, że w żadnych okolicznościach nie będziemy ponosić odpowiedzialności za następujące kwestie:",
        content84: "8.1.1 strata dochodów;",
        content85: "8.1.2 zyski handlowe lub straty na kontraktach;",
        content86: "8.1.3 Straty spowodowane przerwą w działalności;",
        content87: "8.1.4 utrata przewidywanych oszczędności pieniężnych;",
        content88: "8.1.5 Straty spowodowane problemami informacyjnymi;",
        content89: "8.1.6 utrata możliwości, wartości firmy lub reputacji;",
        content90: "8.1.7 uszkodzenie lub utrata danych;",
        content91: "8.1.8 koszt zakupu produktów lub usług zastępczych;",
        content92: "8.1.9 Wszelkie pośrednie, szczególne lub przypadkowe straty lub szkody wynikające z czynu niedozwolonego (w tym zaniedbania), naruszenia umowy lub z jakiejkolwiek innej przyczyny, niezależnie od tego, czy taka strata lub szkoda jest przez nas racjonalnie przewidywalna; niezależnie od tego, czy zostaliśmy poinformowani o istnieniu takich z wyprzedzeniem Możliwość utraty lub uszkodzenia.",
        content93: "8.1.1 Artykuły do ​​8.1.9 są od siebie niezależne.",
        content94: "8.2 Rozumiesz i zgadzasz się, że nie ponosimy odpowiedzialności za jakiekolwiek szkody wyrządzone Tobie w wyniku którejkolwiek z następujących okoliczności:",
        content95: "8.2.1 Twoje konkretne transakcje mogą wiązać się z poważnymi naruszeniami prawa lub naruszeniami umowy.",
        content96: "8.2.2 Twoje zachowanie na tej stronie może być nielegalne lub nieetyczne.",
        content97: "8.2.3 Koszty i straty poniesione w wyniku zakupu lub uzyskania jakichkolwiek danych, informacji lub przeprowadzenia transakcji za pośrednictwem usług tej witryny lub w wyniku działań zastępczych.",
        content98: "8.2.4 Twoje niezrozumienie usług tej witryny.",
        content99: "8.2.5 Wszelkie inne straty związane z usługami świadczonymi przez tę witrynę, które nie są spowodowane przez nas.",
        content100: "8.3 Odpowiadamy za konserwację sprzętu sieci informatycznej, awarię połączenia z siecią informatyczną, awarię komputera, komunikacji lub innego systemu, awarię zasilania, warunki pogodowe, wypadki, strajki, spory pracownicze, zamieszki, powstania, zamieszki, niewystarczającą produktywność lub materiały produkcyjne, pożar, powódź , burze, eksplozje, wojny, przyczyny bankowe lub innych partnerów, załamanie rynku aktywów cyfrowych, działania rządu, nakazy organów sądowych lub administracyjnych, inne działania, na które nie mamy wpływu lub na które nie mamy wpływu, lub z powodów osób trzecich. nie ponosisz żadnej odpowiedzialności za niemożność lub opóźnienie świadczenia usługi, ani za Twoje straty.",
        content101: "8.4 Nie możemy zagwarantować, że wszystkie informacje, programy, teksty itp. zawarte na tej stronie są całkowicie bezpieczne i nie zostaną zakłócone ani zniszczone przez żadne wirusy, konie trojańskie i inne złośliwe programy.Dlatego jeśli zalogujesz się i skorzystasz z jakichkolwiek usług na ta strona internetowa lub pobieranie i korzystanie z pobranych programów, informacji, danych itp. to Twoje własne decyzje i ponosisz ryzyko i możliwe straty na własne ryzyko.",
        content102: "8.5 Nie udzielamy żadnych gwarancji ani zobowiązań w odniesieniu do jakichkolwiek informacji, produktów i usług stron internetowych osób trzecich, do których prowadzą linki do tej witryny, ani żadnych innych form treści, które nie należą do nas. Jeśli korzystasz z jakichkolwiek usług, informacji i usług świadczonych przez osoby trzecie Strony internetowe, Produkty itp. są Twoimi własnymi decyzjami i ponosisz całą odpowiedzialność z nich wynikającą.",
        content103: "8.6 Nie udzielamy żadnych wyraźnych ani dorozumianych gwarancji dotyczących korzystania przez Ciebie z usług tej witryny, w tym między innymi przydatności usług świadczonych przez tę witrynę, braku błędów lub pominięć, ciągłości, dokładności, niezawodności i przydatności dla konkretnego zamiar. Jednocześnie nie podejmujemy żadnych zobowiązań ani gwarancji co do ważności, dokładności, poprawności, niezawodności, jakości, stabilności, kompletności i aktualności technologii i informacji związanych z usługami udostępnianymi przez tę witrynę. To, czy zalogować się, czy skorzystać z usług świadczonych przez tę witrynę, jest Twoją osobistą decyzją i ponosisz własne ryzyko i możliwe straty. Nie udzielamy żadnych wyraźnych ani dorozumianych gwarancji dotyczących rynku, wartości i ceny aktywów cyfrowych. Rozumiesz i rozumiesz, że rynek aktywów cyfrowych jest niestabilny. Ceny i wartości mogą w dowolnym momencie ulegać wahaniom lub znacznym spadkom. Handel aktywami cyfrowymi jest Twoja wolność osobista.Wybierasz i decydujesz na własne ryzyko i możliwe straty.",
        content104: "8.7 Nasze gwarancje i zobowiązania określone w niniejszej Umowie są jedynymi gwarancjami i oświadczeniami złożonymi przez nas w związku z niniejszą Umową i usługami świadczonymi na tej stronie internetowej i zastępują wszelkie gwarancje i zobowiązania powstałe w jakikolwiek inny sposób, pisemny lub ustny, wyraźny lub dorozumiane. Wszystkie te gwarancje i oświadczenia stanowią wyłącznie nasze własne zobowiązania i gwarancje i nie gwarantują, że jakakolwiek strona trzecia będzie przestrzegać gwarancji i zobowiązań wynikających z niniejszej Umowy.",
        content105: "8.8 Nie zrzekamy się żadnych praw niewymienionych w niniejszej Umowie, aby ograniczyć, wyłączyć lub zrównoważyć naszą odpowiedzialność za szkody w najszerszym zakresie obowiązującego prawa.",
        content106: "8.9 Po dokonaniu rejestracji wyrażasz zgodę na wszelkie operacje, które wykonujemy zgodnie z zasadami określonymi w niniejszej umowie, a wszelkie powstałe ryzyko ponosisz Ty.",
        content107: "9. Rozwiązanie Umowy",
        content108: "9.1 Ta strona internetowa ma prawo zakończyć wszystkie usługi tej witryny zgodnie z niniejszą umową. Niniejsza umowa zostanie rozwiązana w dniu, w którym wszystkie usługi tej witryny zostaną zakończone.",
        content109: "9.2 Po rozwiązaniu niniejszej umowy nie masz prawa wymagać od tej witryny internetowej dalszego świadczenia jakichkolwiek usług ani wykonywania jakichkolwiek innych obowiązków, w tym między innymi wymagania od tej witryny internetowej zatrzymywania lub ujawniania jakichkolwiek informacji znajdujących się na pierwotnym koncie witryny Tobie lub osobie trzeciej.Osoba trzecia przekazuje wszelkie informacje, których nie przeczytała lub nie wysłała.",
        content110: "9.3 Rozwiązanie niniejszej umowy nie będzie miało wpływu na inne obowiązki strony nienaruszającej wobec strony naruszającej.",
        content111: "10. Prawa własności intelektualnej",
        content112: "10.1 Wszelkie osiągnięcia intelektualne zawarte na tej stronie obejmują między innymi logo stron, bazy danych, projekty stron internetowych, tekst i grafikę, oprogramowanie, zdjęcia, filmy, muzykę, dźwięki i ich kombinacje, kompilacje oprogramowania, powiązane kody źródłowe i oprogramowanie (w tym małe aplikacje ) Prawa własności intelektualnej programów i skryptów) należą do tej witryny. Zabronione jest reprodukowanie, zmienianie, kopiowanie, wysyłanie lub wykorzystywanie powyższych materiałów lub treści w celach komercyjnych.",
        content113: "10.2 Wszelkie prawa zawarte w nazwie tej witryny internetowej (w tym między innymi wartość firmy i znaki towarowe, logo) należą do firmy.",
        content114: "10.3 Twoja akceptacja tej umowy będzie traktowana jako Twoja inicjatywa przeniesienia praw autorskich do jakiejkolwiek formy informacji, które publikujesz na tej stronie, w tym między innymi: praw do reprodukcji, praw do dystrybucji, praw do najmu, praw do wystaw, praw do występów, praw do wyświetlania, prawa do transmisji, prawa do transmisji informacji w Internecie, prawa do filmowania, prawa do adaptacji, prawa do tłumaczeń, prawa do kompilacji i inne prawa zbywalne, które powinny przysługiwać posiadaczowi praw autorskich, są przenoszone wyłącznie na tę stronę internetową bezpłatnie.Ta strona internetowa ma prawo do złożenia niezależnego pozwać dowolny podmiot za naruszenie i uzyskać pełne odszkodowanie. Niniejsza umowa obowiązuje w przypadku wszelkich treści chronionych prawem autorskim, które publikujesz na tej stronie, niezależnie od tego, czy treść powstała przed podpisaniem tej umowy, czy po jej podpisaniu.",
        content115: "10.4 Korzystając z usług tej witryny, nie możesz nielegalnie wykorzystywać ani zbywać praw własności intelektualnej tej witryny lub innych. Nie możesz publikować ani upoważniać innych stron internetowych (i mediów) do korzystania z informacji opublikowanych na tej stronie w jakiejkolwiek formie.",
        content116: "11. Ochrona informacji",
        content117: "Zastosowanie mają postanowienia „Warunków Prywatności” opublikowanych odrębnie na tej stronie.",
        content118: "12. Obliczenia",
        content119: "Wszystkie wyniki rozliczeń transakcji zostały przez nas zweryfikowane, a wszystkie metody rozliczeń zostały opublikowane w serwisie, jednakże nie gwarantujemy, że korzystanie z serwisu nie będzie zakłócone i wolne od błędów.",
        content120: "13. Kontrola eksportu",
        content121: "Rozumiesz i potwierdzasz, że zgodnie z odpowiednimi przepisami prawa nie wolno Ci eksportować, reeksportować, importować ani przesyłać żadnych materiałów (w tym oprogramowania) na tej stronie, dlatego gwarantujesz, że nie będziesz aktywnie wdrażać, pomagać ani uczestniczyć w żadnym z wyżej wymieniony eksport lub działalność z nim związana, naruszająca przepisy prawa i regulacje Transfer lub inne naruszenia obowiązujących przepisów prawa i regulacji – w przypadku wykrycia takiej sytuacji należy aktywnie i terminowo zgłosić ją nam i pomóc nam sobie z nią poradzić.",
        content122: "14. Przeniesienie",
        content123: "Prawa i obowiązki określone w niniejszej Umowie wiążą także cesjonariuszy, spadkobierców, wykonawców i zarządców stron, które korzystają z takich praw i obowiązków. Nie możesz przekazywać ich osobom trzecim bez naszej zgody, ale możemy przenieść nasze prawa i obowiązki wynikające z niniejszej Umowy na jakąkolwiek osobę trzecią w dowolnym momencie i powiadomić Cię o tym.",
        content124: "15. Podzielność",
        content125: "Jeżeli którekolwiek postanowienie niniejszej Umowy zostanie uznane przez jakikolwiek sąd właściwej jurysdykcji za niewykonalne, nieważne lub niezgodne z prawem, nie będzie to miało wpływu na ważność pozostałych postanowień niniejszej Umowy.",
        content126: "16. Relacja pozaagencyjna",
        content127: "Żadne z postanowień niniejszej Umowy nie będzie uważane za tworzenie, sugerowanie lub w inny sposób ustanawianie nas jako Twojego agenta, powiernika lub innego przedstawiciela, chyba że w niniejszej Umowie określono inaczej.",
        content128: "17. Wstrzymanie się od głosu",
        content129: "Zrzeczenie się przez nas lub Ciebie odpowiedzialności jakiejkolwiek strony za naruszenie umowy lub innej odpowiedzialności określonej w niniejszej Umowie nie będzie uważane ani interpretowane jako zrzeczenie się innej odpowiedzialności za naruszenie umowy; nieskorzystanie z jakiegokolwiek prawa lub środka prawnego nie będzie w żadnym wypadku sposób można interpretować jako zrzeczenie się takich praw lub środków zaradczych.",
        content130: "18. Tytuł",
        content131: "Wszystkie nagłówki służą wyłącznie wygodzie wyrażenia umowy i nie służą do rozszerzania lub ograniczania treści lub zakresu warunków umowy.",
        content132: "19. Obowiązujące prawo",
        content133: "19.1 Przed wniesieniem sporu lub roszczenia potwierdzasz i zgadzasz się najpierw skontaktować z tą witryną za pośrednictwem poczty elektronicznej, aby nieformalnie rozwiązać spór. Postaramy się jak najszybciej rozwiązać Twój spór wewnętrznie; obie strony zgadzają się rozwiązać spór w drodze negocjacji w dobrej wierze (dyskusje będą poufne i chronione obowiązującymi przepisami i nie będą wykorzystywane jako dowód w żadnym postępowaniu sądowym).",
        content134: "19.2 Użytkownik przyjmuje do wiadomości i zgadza się, że w przypadku jakiegokolwiek sporu, kontrowersji, nieporozumień lub roszczeń, w tym istnienia, ważności, interpretacji, wykonania, naruszenia lub rozwiązania niniejszych Warunków lub jakiegokolwiek sporu wynikającego z niniejszych Warunków lub w związku z nimi („Spór” ), wyżej wymienione strony poddadzą spór Międzynarodowemu Sądowi Arbitrażowemu Międzynarodowej Izby Handlowej („Międzynarodowy Trybunał Arbitrażowy ICC”) zgodnie z obowiązującym wówczas „Regulaminem Arbitrażowym ICC”. Miejscem arbitrażu będzie Belize, a prawo właściwe dla arbitrażu zostanie określone zgodnie z Regulaminem Arbitrażowym Międzynarodowej Izby Handlowej. O ile strony nie uzgodnią inaczej, zgodnie z Regulaminem Arbitrażowym ICC zostanie wyznaczony tylko jeden arbiter. Arbiter będzie miał wyłączne uprawnienia do orzekania na temat swojej jurysdykcji, w tym między innymi w sprawie wszelkich zastrzeżeń co do istnienia, zakresu lub ważności umowy o arbitraż lub arbitrażu wszelkich roszczeń lub roszczeń wzajemnych. Wszelkie arbitraże będą prowadzone w języku angielskim. Niezależnie od sposobu prowadzenia arbitrażu arbiter wyda pisemne orzeczenie, które będzie rozsądne i wystarczające do wyjaśnienia wyroku oraz ewentualnych zasadniczych wniosków, na których opiera się orzeczenie. Orzeczenie arbitrażowe będzie ostateczne i wiążące dla stron i może zostać wykonane przed dowolnym sądem właściwym.",
        content135: "20. Skuteczność i interpretacja umowy",
        content136: "20.1 Niniejsza umowa wchodzi w życie z chwilą kliknięcia strony rejestracyjnej tej witryny, w celu wyrażenia zgody na rejestrację, dokończenia procesu rejestracji oraz uzyskania konta i hasła do tej witryny.Jest ona wiążąca zarówno dla tej witryny, jak i dla Ciebie.",
        content137: "20.2 Ostateczne prawo do interpretacji niniejszej umowy należy do tej strony internetowej.",
        content138: "Poznaj swojego klienta i politykę przeciwdziałania praniu pieniędzy",
        content139: "1. Wstęp",
        content140: "1.1 Obiecujemy dokładnie przestrzegać przepisów ustawowych i wykonawczych związanych z zasadą „Poznaj swojego klienta” i przeciwdziałaniem praniu pieniędzy i nie będziemy celowo naruszać zasad „Poznaj swojego klienta i polityki przeciwdziałania praniu pieniędzy”. W ramach naszej rozsądnej kontroli podejmiemy niezbędne środki i technologie, aby zapewnić Ci bezpieczne usługi i w jak największym stopniu chronić Cię przed stratami spowodowanymi praniem pieniędzy przez osoby podejrzane o popełnienie przestępstwa.",
        content141: "1.2 Nasza polityka „Poznaj swojego klienta” i „Zapobieganie praniu pieniędzy” to kompleksowy międzynarodowy system zasad obejmujący zasady „Poznaj swojego klienta” i „Zapobieganie praniu pieniędzy” dla różnych jurysdykcji prawnych, z którymi jesteś powiązany.",
        content142: "2. Poznaj swojego klienta i zasady przeciwdziałania praniu pieniędzy są następujące:",
        content143: "2.1 Publikuj zasady „znaj swojego klienta” i zasady przeciwdziałania praniu pieniędzy oraz od czasu do czasu aktualizuj je, aby spełniały standardy określone w odpowiednich przepisach ustawowych i wykonawczych;",
        content144: "2.2 Ogłaszaj i aktualizuj niektóre zasady przewodnie i zasady obsługi tej witryny, a nasi pracownicy będą świadczyć usługi zgodnie z wytycznymi tych zasad i zasad;",
        content145: "2.3 Opracuj i wykonaj procedury wewnętrznego monitorowania i kontroli transakcji, takie jak weryfikacja tożsamości za pomocą rygorystycznych środków i zorganizowanie profesjonalnego zespołu odpowiedzialnego za prace związane z przeciwdziałaniem praniu pieniędzy;",
        content146: "2.4 Stosować metody zapobiegania ryzyku w celu przeprowadzenia należytej staranności i bieżącego nadzoru nad klientami;",
        content147: "2.5 Przeglądaj i regularnie sprawdzaj, które miały miejsce transakcje;",
        content148: "2.6 Zgłaszaj podejrzane transakcje właściwym organom;",
        content149: "2.7 Dowód tożsamości, adres i rejestr transakcji będą przechowywane przez co najmniej sześć lat i mogą zostać przedłożone organom regulacyjnym bez dalszego powiadamiania.",
        content150: "3. Informacje o tożsamości i weryfikacja",
        content151: "3.1 Informacje o tożsamości",
        content152: "3.1.1 Zgodnie z różnymi przepisami obowiązującymi w różnych jurysdykcjach i różnych typach podmiotów, treść gromadzonych przez nas informacji może być niespójna.Zasadniczo od zarejestrowanych osób będą zbierane następujące informacje:",
        content153: "Podstawowe dane osobowe: imię i nazwisko, adres (i adres stały, jeśli jest inny), data urodzenia i obywatelstwo oraz inne dostępne informacje. Weryfikacja tożsamości powinna opierać się na dokumentach wydanych przez władze urzędowe lub inne podobne organy, takich jak paszporty, dowody osobiste lub inne dokumenty identyfikacyjne wymagane i wymagane przez różne jurysdykcje. Podany przez Ciebie adres zostanie zweryfikowany za pomocą odpowiednich metod, takich jak sprawdzenie biletu przewozu pasażerskiego, noty odsetkowej czy sprawdzenie rejestru wyborców.",
        content154: "Ważne zdjęcie: Przed rejestracją musisz dostarczyć zdjęcie, na którym trzymasz dowód tożsamości na piersi;",
        content155: "Dane kontaktowe: Numer telefonu/komórki i/lub ważny adres e-mail.",
        content156: "3.1.2 Jeśli jesteś korporacją lub inną osobą prawną, zbieramy następujące informacje w celu zidentyfikowania Ciebie lub ostatecznego beneficjenta Twojego rachunku powierniczego.",
        content157: "Rejestracja firmy i świadectwo rejestracji; kopie statutu i memorandum spółki; szczegółowe materiały poświadczające strukturę kapitałową spółki i opis własności, potwierdzające uchwałę zarządu uprawnionego mocodawcy, która określa otwarcie i realizację rachunku na tej stronie internetowej; firma dyrektorów, których należy przedstawić w miarę potrzeb, dokumenty tożsamości większościowego akcjonariusza oraz osoby upoważnionej do podpisywania konta w tym serwisie, główny adres prowadzenia działalności spółki, jeżeli jest inny niż adres korespondencyjny spółki, należy podać adres korespondencyjny. Jeśli lokalny adres firmy jest niezgodny z jej głównym adresem prowadzenia działalności, jest ona traktowana jako klient podwyższonego ryzyka i będzie musiała przedstawić dodatkową dodatkową dokumentację.",
        content158: "W zależności od różnych przepisów obowiązujących w różnych jurysdykcjach i różnych typach podmiotów, wymagamy innych zaświadczeń i dokumentów wydanych przez urzędy, a także dokumentów, które uznamy za niezbędne.",
        content159: "3.1.3 Akceptujemy wyłącznie angielską lub chińską wersję informacji o tożsamości. Jeśli nie, prosimy o przetłumaczenie informacji o tożsamości na język angielski i poświadczenie notarialne.",
        content160: "3.2 Potwierdź weryfikację",
        content161: "3.2.1 Wymagamy podania pełnej zawartości strony dokumentu tożsamości.",
        content162: "3.2.2 Prosimy o zdjęcie, na którym trzymasz dokument tożsamości przy piersi.",
        content163: "3.2.3 Kopie dokumentów potwierdzających należy zasadniczo porównać z oryginałami dowodów. Jednakże kopia jest dopuszczalna, jeśli odpowiedni i godny zaufania podmiot poświadczający może poświadczyć, że kopia jest dokładną i kompletną reprodukcją oryginalnego dokumentu. Do takich osób certyfikujących zaliczają się ambasadorowie, komisarze sądowi, lokalni sędziowie itp.",
        content164: "3.2.4 Wymóg identyfikacji ostatecznego beneficjenta i kontroli rachunku polega na ustaleniu, które osoby fizyczne są ostatecznie właścicielami bezpośredniego klienta lub które go kontrolują, i/lub ustaleniu, czy trwające transakcje są realizowane w imieniu innych osób. Jeżeli jest to firma, należy zweryfikować tożsamość głównych akcjonariuszy (np. posiadających co najmniej 10% udziałów w głosach). Ogólnie rzecz biorąc, posiadanie 25% akcji będzie uznane za objęte normalnym ryzykiem i tożsamość akcjonariusza będzie musiała zostać zweryfikowana; posiadanie 10% akcji lub posiadanie większej liczby praw głosu lub akcji będzie uważane za sytuację wysokiego ryzyka, a tożsamość akcjonariusza wymaga weryfikacji.",
        content165: "4. Monitoruj transakcje",
        content166: "4.1 Od czasu do czasu ustalamy i dostosowujemy dzienne maksymalne limity transakcji i wypłat walut w oparciu o bezpieczeństwo i rzeczywiste warunki transakcji;",
        content167: "4.2 Jeśli u zarejestrowanego użytkownika transakcje występują często lub zachodzą nieuzasadnione okoliczności, nasz profesjonalny zespół oceni i zadecyduje, czy są one podejrzane;",
        content168: "4.3 Jeżeli na podstawie własnego osądu stwierdzimy, że transakcja jest podejrzana, możemy podjąć środki ograniczające, takie jak zawieszenie transakcji, odrzucenie transakcji, a nawet jak najszybciej ją cofnąć, jeśli to możliwe, i zgłosić to właściwemu organowi, ale nie powiadomi Cię;",
        content169: "4.4 Zastrzegamy sobie prawo do odrzucenia wniosków rejestracyjnych od osób z jurysdykcji, które nie spełniają międzynarodowych standardów przeciwdziałania praniu pieniędzy lub osób, które można uznać za osoby na eksponowanym stanowisku politycznym. Zastrzegamy sobie prawo do zawieszenia lub zakończenia transakcji, które są podejrzane według naszego wyłącznego uznania na w dowolnym momencie, ale tego nie robimy.Naruszenie jakichkolwiek obowiązków i odpowiedzialności wobec Ciebie.",
    },
    register: {
        formTitle1: "tworzyć",
        formTitle2: "Zarejestruj się, korzystając ze swojego adresu e-mail lub numeru telefonu komórkowego",
        formTitle3: "Konto",
        field1: "Kod weryfikacyjny",
        field2: "Uzyskać",
        field3: "Potwierdź hasło",
        field4: "Kod zaproszenia",
        field41: "（Opcjonalny）",
        field5: "Przeczytałem i zgadzam się",
        field6: "Warunki usługi",
        placeholder1: "proszę wpisać kod weryfikacyjny",
        content7: "proszę wpisać kod weryfikacyjny",
        content12: "Proszę podać numer telefonu",
        content13: "Proszę wpisać hasło",
        content14: "proszę podać swój adres e-mail",
        content18: "Wprowadź hasło ponownie",
        content19: "Proszę zaakceptować warunki korzystania z usługi",
        content20: "Proszę wprowadzić poprawny kod weryfikacyjny",
        content23: "Nadal nie otrzymałeś kodu weryfikacyjnego？",
        content24: "Wpisz poniżej kod weryfikacyjny",
        registerSuccess: "Rejestracja zakończona",
        content25: "Hasła są niespójne",
        content26: "Proszę wpisać poprawny adres e-mail",
        content27: "Resetowanie hasła powiodło się"
    },
    download: {
        content1: "Handluj w dowolnym miejscu i czasie",
        content2: "Idealnie kompatybilny z wieloma terminalami, spełniając potrzeby transakcyjne różnych scenariuszy w dowolnym momencie",
        content3: "W każdej chwili możesz sprawdzić warunki rynkowe w czasie rzeczywistym",
        content4: "Z łatwością kupuj i sprzedawaj BTC, ETH, XRP i inne aktywa cyfrowe",
        content5: "Otrzymuj wczesne ostrzeżenia o zmianach cen kryptowalut, na których Ci zależy",
        content6: "Zobacz pożyczkę spot Bitcoin, pożyczkę futures i ceny opcji",
        content7: "Porównaj ceny kryptowalut na różnych giełdach",
        title: "pobierać",
        install: "Uwagi dotyczące instalacji",
        one: "1、Zezwalaj na pobieranie plików opisu",
        two: "2、Pobrano plik z opisem",
        three: "3、Przejdź do [Ustawienia] na swoim telefonie i znajdź [Ogólne]",
        four: "4、Profile i Menedżer urządzeń",
        five: "5、Kliknij profil",
    },
    identity: {
        noAuthentication: {
            title: "Certyfikacja Juniora",
            pleaseSelectYourCountry: "Proszę wybrać swój kraj",
            name: "Nazwa",
            enterName: "proszę wpisać swoje imię",
            city: "miasto",
            enterCity: "Proszę wpisać swoje miasto",
            IDNumber: "numer identyfikacyjny",
            enterIDNumber: "Proszę podać swój numer identyfikacyjny",
        },
        noAdvancedAuthentication: {
            title: "Zaawansowana certyfikacja",
            infoCountry: "kraj/region",
            infoName: "Nazwa",
            infoNumber: "numer identyfikacyjny",
            infoAddress: "adres",
        },
        text: "nie certyfikowane",
        recertification: "recertyfikacja",
        certification: "Orzecznictwo",
        authenticationInProgress: "zweryfikowane",
        inAuthentication: "Certyfikacja w toku",
        uploadTheFrontOfTheDocument: "Prześlij przód identyfikatora",
        uploadTheReverseSideOfTheDocument: "Prześlij drugą stronę certyfikatu",
        uploadAPhotoOfAHandHeldID: "Prześlij zdjęcie swojego dowodu osobistego, który trzymasz w ręku",
        pleaseUpload: "Proszę ",
        pleaseCompleteOrdinaryCertificationFirst: "Należy poczekać na sukces podstawowego przeglądu certyfikacji",
        refused: "odrzucony",
        reason: "powód",
        succeeded: "udało się",
        uploading: "przesyłanie",
        newTitle: "Wybierz kraj, w którym wydano Twój dokument tożsamości",
        pleaseOrdinaryCertificationFirst: "Najpierw wykonaj certyfikację podstawową",
        text1: "Zmodyfikuj awatar",
        text2: "Przezwisko",
        text3: "konto",
        text4: "Modyfikacja pseudonimu",
        text5: "nowy nick",
        text6: "Wpisz swój nowy pseudonim",
        text7: "Stare hasło",
        text8: "Wpisz swoje stare hasło",
        text9: "nowe hasło",
        text10: "Wpisz swoje nowe hasło",
        text11: "Potwierdź hasło",

        text12: "Wprowadź ponownie nowe hasło",
        text13: "nowa skrzynka pocztowa",
        text14: "Kod weryfikacyjny",
        text15: "proszę wpisać kod weryfikacyjny",
        text16: "Twój numer telefonu",
        text17: "Nowy numer telefonu komórkowego",
        text18: "proszę podać swój numer telefonu",
        text19: "Kod weryfikacyjny telefonu komórkowego",
        text20: "Certyfikacja Juniora",
        text21: "Proszę zweryfikować swoje dane identyfikacyjne",
        text22: "Kraj",
        text23: "Miasto",
        text24: "Wpisz swoje miasto",
        text25: "Wpisz swoje imię",
        text26: "Twój numer identyfikacyjny",
        text27: "Wpisz swój numer identyfikacyjny",
        text28: "Zdjęcie z przodu dowodu osobistego",
        text29: "Kliknij, aby przesłać dokumenty",
        text30: "prześlij ponownie",
        text31: "wgrywać",
        text32: "Trzyma zdjęcie identyfikacyjne",
        text33: "Kliknij, aby przesłać zdjęcie identyfikacyjne gospodarstwa",
        text34: "Powiązałeś aplikację Google Authenticator",
        text35: "klucz",
        text36: "Wprowadź kod weryfikacyjny Google",
        text37: "wysłać",
        text38: "Nie może być pusty",
        text39: "Format wiadomości e-mail jest nieprawidłowy",
        text40: "Dwa hasła są niespójne",
        text41: "Skopiowano pomyślnie",
        text42: "Kopiowanie nie powiodło się",
        text43: "Modyfikacja awatara",
        text44: "Zmień hasło",
        text45: "Wiązanie e-mailem",
        text46: "Zmodyfikuj adres e-mail",
        text47: "Wiązanie telefonu komórkowego",
        text48: "Zmodyfikuj telefon komórkowy",
        text49: "Weryfikacja Google",
        text50: "Powiąż Google Authenticator",
        text51: "Wpisz kod weryfikacyjny Google",
        text52: "ratować",
        text53: "Potwierdź przesłanie",
        text54: "Poprawiać",
        text55: "wiążący",
        text56: "Wyłącz weryfikację Google",
        text57: "Następny krok",
        text58: "Skończyć",
        text59: "Weryfikacja Google jest ograniczona",
        text60: "Weryfikacja Google została rozdzielona",
        text61: "Złożono podstawowy certyfikat",
        text62: "Przesłano zaawansowany certyfikat",
        text63: "Brak przesłanego obrazu",
        text64: "Wysłać ponownie",
        text65: "Proszę wpisać pseudonim",
        text66: "Pseudonim został pomyślnie zapisany",
        text67: "Nie przesłano jeszcze żadnych zdjęć",
        text68: "Resetowanie hasła zakończone",
        text69: "Adres e-mail został pomyślnie zmodyfikowany",
        text70: "Awatar został pomyślnie zapisany",
        text71: "Załaduj obrazek",
        text72: "swoją skrzynkę pocztową",
        text73: "Wpisz swój nowy adres e-mail",
        text74: "Twoje imię",
        text75: "Przejdź do uwierzytelniania",
        text76: "Sprawdź swoje zdjęcie identyfikacyjne"
    },
    retrievePassword: {
        formTitle1: "Zresetuj hasło",
        formTitle2: "Ze względu na bezpieczeństwo Twoich aktywów transakcje są zabronione w ciągu 24 godzin od zmiany hasła.",
        formTitle3: "Odzyskaj hasło",
        formTitle4: "Potwierdź zmiany",
        formTitle5: "Proszę wprowadzić stare hasło",
        formTitle6: "Proszę wprowadzić nowe hasło"
    },
    home: {
        title1: "Najpopularniejsza platforma handlu kryptowalutami",
        title3: "dlaczego jest ",
        title4: " Jest to wysokowydajna platforma typu blockchain typu open source, zaprojektowana do obsługi i obsługi bezpiecznej, zgodnej i przewidywalnej infrastruktury cyfrowej.",
        field1: "Ustalona wymiana",
        field2: "Usługi finansowe dotyczące aktywów cyfrowych",
        field3: "Dziesiątki milionów użytkowników",
        field4: "Utworzenie wielu funduszy ochrony inwestorów",
        field5: "Szybki handel",
        field6: "CME Grupop jest w stanie dopasowywać zamówienia z bardzo niskimi opóźnieniami, zapewniając najwyższą jakość handlu.",
        title5: "trend rynkowy",
        title6: "Zobacz więcej",
        table1: "Waluta",
        table2: "Najnowsza cena",
        table3: "24-godzinne wzloty i upadki",
        table4: "Wolumen obrotu 24 godziny na dobę",
        table5: "Wolumen transakcji 24H",
        table6: "cytaty",
        title7: "o ",
        title8: " Zależy nam na zapewnieniu użytkownikom najbardziej zaawansowanych narzędzi handlowych, innowacyjnych technologii i niezrównanej jakości obsługi klienta.",
        title9: "Pole zasobów cyfrowych zmienia się z każdym dniem i tylko zachowując dalekowzroczność i szybki postęp, możemy nadążać za tempem innowacji technologicznych.",
        title901: " Dostarczanie najbardziej zaawansowanych usług handlu aktywami cyfrowymi naszym użytkownikom i globalnym dostawcom płynności.",
        title10: "Jesteśmy głęboko przekonani, że każdy użytkownik zasługuje na najlepsze doświadczenia handlowe.",
        title11: "Bezpieczeństwo, któremu możesz zaufać",
        title12: "Nasze wyrafinowane środki bezpieczeństwa i fundusze SAFU chronią Twoje zasoby cyfrowe przed wszelkimi zagrożeniami. ",
        title13: "Najlepsze stawki transakcyjne",
        title14: "Preferencyjne stawki transakcyjne, konkurencyjne prawa VIP i ciesz się usługami najwyższej jakości.",
        title15: "Całodobowa obsługa klienta",
        title16: "Całodobowy tryb pracy w pełnym wymiarze godzin, w każdych warunkach pogodowych, odpowiemy na Twoje pytania tak szybko, jak to możliwe.",
        title17: "Handel bez opłat",
        title18: "Korzystaj z wielu metod płatności, aby handlować walutami cyfrowymi bez opłat manipulacyjnych, bezpiecznie i szybko.",
        title19: "Handluj w dowolnym miejscu i czasie",
        title20: "Idealnie kompatybilny z wieloma terminalami, spełniając potrzeby transakcyjne różnych scenariuszy w dowolnym momencie",
        title21: "W każdej chwili możesz sprawdzić warunki rynkowe w czasie rzeczywistym",
        title22: "Z łatwością kupuj i sprzedawaj BTC, ETH, XRP i inne aktywa cyfrowe",
        title23: "Otrzymuj wczesne ostrzeżenia o zmianach cen kryptowalut, na których Ci zależy",
        title24: "Zobacz pożyczkę spot Bitcoin, pożyczkę futures i ceny opcji",
        title25: "Porównaj ceny kryptowalut na różnych giełdach",
        title26: "Zapisz się teraz",
        title27: "Dolar",
        title28: "Całodobowy wolumen obrotu",
        title29: "Zintegrowany dostawca płynności",
        title30: "użytkownik",
        title31: "Więcej",
        title32: "Binance Loan dodaje CTSI i KSM jako aktywa zabezpieczające i wprowadza zniżki na obniżki stóp procentowych BUSD i USDT!",
        title33: "Nasza przewaga",
        title34: "bezpieczny i stabilny",
        title35: "Najlepszy zespół techniczny, kompleksowa ochrona bezpieczeństwa, niezależne badania i rozwój szybkiego silnika syntezy transakcji, mogą nadal działać stabilnie i niezawodnie w przypadku masowych jednoczesnych transakcji.",
        title36: "Profesjonalna zgodność",
        title37: "Profesjonalny zespół operacyjny, z wieloletnim doświadczeniem w blockchain i finansach, posiada zgodną z przepisami licencję na handel aktywami cyfrowymi i ma gwarancję 100% rezerwy.",
        title38: "Najpierw użytkownik",
        title39: "Zmierzenie się z rynkiem globalnym, obsługa wielu języków, działanie 24 godziny na dobę, 7 dni w tygodniu, silne wsparcie społeczności i profesjonalna obsługa klienta.",
        title40: "wysoka wydajność",
        title41: "300 000 transakcji na sekundę i czas reakcji na zamówienie poniżej 1 milisekundy.",
        title42: "o nas",
        title43: "Handluj w dowolnym miejscu i czasie",
        title44: "Idealnie kompatybilny z wieloma terminalami, spełniając potrzeby transakcyjne różnych scenariuszy w dowolnym momencie",
        title45: "（Zeskanuj kod, aby pobrać）",
        title46: "Wiadomości o walutach cyfrowych",
        title47: "naszym partnerem biznesowym",
        title48: "Lista przegranych",
        title49: "Obrót 24h",
        title50: "24-godzinne zwiększanie i zmniejszanie"
    },
    quotation: {
        marketTrading: "cytaty",
        turnover: "Tom",
        optional: "Opcjonalny",
        contractMarket: "rynek kontraktowy",
        currencyPair: "para walutowa",
        latestPrice: "Cena zamykająca",
        upsAndDowns: "Zmiana cytatu",
        highest: "Najwyższy",
        lowest: "najniższy",
        transactionAmount: "Wolumen transakcji",
        operate: "działać",
        buy: "handel",
        topTurnovers: "Lista transakcji",
        topGainers: "Lista zdobywców",
        tradingPair: "para handlowa",
        buyOrder: "Kupić",
        sellOrder: "zlecenie sprzedaży"
    },
    accountChange: {
        demo: "symulacja",
        realAccount: "Rzeczywisty",
        demoAccount: "konto demo",
        accountRole: "Rola konta",
        pleaseSelect: "proszę wybrać",
        AccountChangeType: "Typ zmiany konta",
        AccountType: "typ konta",
        currencyType: "Rodzaj waluty",
        allType: "Wszystkie typy",
        allAccount: "Wszystkie konta",
        allCoin: "Wszystkie waluty",
        coin: "Waluta",
        amount: "Zmień kwotę",
        balance: "Bilans konta",
        time: "czas",
        title: "Zapisy zmian konta",
        filtrar: "filtr",
        check: "Przełącz na",
        showOnly: "Wyświetlaj tylko zasoby walutowe",
        holdAssets: "Bilans walutowy",
        accountList: "Lista kont"
    },
    contractTransaction: {
        chart: "wykres",
        basicEdition: "Wersja podstawowa",
        depthMap: "Mapa głębokości",
        second: "Drugi",
        minute: "punkt",
        day: "niebo",
        week: "tydzień",
        month: "księżyc",
        open: "otwarty",
        trade: "handel",
        buyLong: "długi zakup",
        buyShort: "krótki zakup",
        cannotBuy: "Ta waluta nie obsługuje transakcji na podstawie umów dostawy",
        cannotBuy1: "Ta waluta nie obsługuje handlu wieczystego",
        tradingAssets: "aktywa handlowe",
        amountAvailable: "dostępna ilość",
        timeStr: "czas",
        transactionPeriod: "okres handlowy",
        quantity: "ilość",
        enterQuantity: "Proszę wpisać ilość",
        buyIn: "Idź długo",
        latestTransaction: "ostatnia transakcja",
        time: "czas",
        direction: "kierunek",
        price: "cena",
        sell: "krótki",
        positionOrder: "Zamówienie dostawy",
        historicalOrders: "Zamówienia historyczne",
        product: "produkt",
        realAccount: "(prawdziwe konto)",
        demoAccount: "(konto demo)",
        openingTime: "czas realizacji",
        openingPrice: "Cena otwarcia",
        curretPrice: "Aktualna cena",
        liquidationPrice: "Cena zamykająca",
        profitOrLoss: "Zysk i strata",
        endTime: "Czas zamknięcia",
        areYouSureToBuy: "Czy na pewno chcesz kupić?",
        areYouSureToLongBuy: "Czy na pewno chcesz iść długo?",
        areYouSureToShortBuy: "Czy na pewno chcesz kupić krótko?",
        successfullyBought: "Kup pomyślnie",
        minBuy: "Minimalne wpisowe",
        assets: "aktywa",
        withdraw: "wycofać",
        entrustedOrder: "Zamówienie",
        total: "całkowity",
        continueToPlaceOrder: "Kontynuuj składanie zamówienia",
        orderConfirmation: "Potwierdzenie zamówienia",
        buyRu: "kupić",
        sellChu: "sprzedać",
        areYouSureToSell: "Czy na pewno chcesz sprzedać?",
        operation: "działać",
        buyCover: "pozycja osłony",
        commitSuccess: "Transakcja udana",
        onlyEnterIntegers: "Można wprowadzać tylko liczby całkowite",
        trend: "Tendencja",
        lotteryTime: "Termin składania zamówień",
        countdown: "Odliczanie",
        period: "Wydanie",
        cycle: "Okres czasu",
        unlimited: "Nieograniczony",
        put: "Put",
        call: "Call",
        pending: "Na prowizji",
        unopened: "Jeszcze nie narysowane",
        opened: "Nagroda wylosowana",
        settlementPrice: "Cena rozliczeniowa",
        rateOfReturn: "stopa zwrotu",
        currentPositions: "Aktualna pozycja",
        positionQuantity: "Ilość pozycji",
        guarantee: "margines",
        text1: "Call",
        text2: "Put",
        text3: "Czekam na zakup",
        text4: "Ilość zamówienia",
        text5: "Liczba transakcji",
        text6: "Anulować",
        text7: "czas dostawy",
        text8: "Przypomnienie o anulowaniu",
        text9: "Czy na pewno chcesz anulować zamówienie?",
        text10: "Nielegalna ilość",
        text11: "Przypomnienie o zamówieniu",
        text12: "Czy na pewno kupujesz byczo?",
        text13: "Jesteś pewien, że kupisz wkład?",
        text14: "Anulowanie powiodło się"
    },
    mining: {
        title1: "Wiodąca na świecie platforma do eksploracji zasobów cyfrowych typu blockchain",
        title2: "Otwarte i przejrzyste · bezpieczne i stabilne",
        title3: "Proszę wybrać walutę",
        title4: "Wydobycie w ujęciu rocznym",
        title5: "niebo",
        title6: "Okres górniczy",
        title7: "Szczegóły Produktu",
        btn: "Mój teraz",
        btn2: "zamknięcie",
        tabs1: "w trakcie",
        tabs2: "odkupiony",
        table1: "Nazwa produktu",
        table2: "Okres górniczy",
        table3: "Czas wydobycia",
        table4: "Ilość wydobycia",
        table5: "Data ważności",
        table6: "Przechodzi do dojrzałości",
        table7: "Status zamówienia",
        egularMining: "Regularnie wydobywam",
        inProgress: "w trakcie",
        redeemed: "odkupiony",
        redemption: "odkupienie",
        regularMining: "Regularnie wydobywam",
        earlyRedemption: "wczesne odkupienie",
        need: "potrzebować",
        handlingFee: "opłata manipulacyjna",
        redemptionConfirmationMessage: "Potwierdź, czy chcesz skorzystać z wyprzedzeniem?",
        form1: "Wydobycie w ujęciu rocznym",
        form2: "Okres górniczy",
        form3: "Waluta górnicza",
        form4: "Kwota abonamentu (minimum/maksimum)",
        form5: "Opłata za wcześniejszy wykup: ",
        form6: "Cena zakupu",
        form7: "Dostępne fundusze",
        form8: "Cena zakupu",
        form9: "Dostępne fundusze",
        form10: "Proszę wpisać kwotę zakupu",
        form11: "Wszystko",
        form12: "Szacunkowe przychody",
        form13: "Niewystarczające dostępne środki",
        form14: "Proszę wpisać kwotę zakupu",
        form15: "Moje zamówienie",
        form16: "dochód",
        form17: "Szczegóły zamówienia",
        form18: "Instrukcje dotyczące wcześniejszego wykupu:",
        form19: "Kwota abonamentu (minimalna)",
        form20: "Kwota abonamentu (maksymalna)",
        form21: "Posiadana ilość",
        form22: "Kupowanie czasu",
        form23: "czas odkupienia",
        form24: "nad",
        form25: "Subskrypcja powiodła się",
        form26: "Wykup udany",
        form27: "Łatwe zarządzanie finansami z ponad 25% zwrotami, przewyższające rynek, a także możesz otrzymać nagrody po zakupie",
        form28: "Zaproś znajomych, aby otrzymać nagrodę w wysokości 50 USDT, a bony kontraktowe mogą również zwrócić 20% prowizji."
    },
    assets: {
        content1: "Aktywa konta",
        content2: "Dzisiejsze zarobki",
        content3: "rachunek bieżący",
        content4: "Rzeczywiste konto to rzeczywiste aktywa konta, które można wykorzystać do transakcji i wypłat.",
        content5: "Wycofaj monety",
        content6: "Wpłacaj monety",
        content7: "giełda",
        content8: "przenosić ",
        content9: "proszę wybrać",
        content10: "konto demo",
        content11: "Rzeczywisty",
        content12: "nabyć aktywa",
        content13: "Czy na pewno chcesz przejść na konto rzeczywiste?",
        content14: "Czy na pewno chcesz przejść na konto demo?",
        content15: "odnieść sukces",
        table1: "Waluta",
        table2: "ilość",
        table3: "zamrażać",
        table4: "Dostępny",
        dialog1: "Szczegóły Produktu",
        dialog2: "1、Tego zasobu można używać wyłącznie w trybie konta demonstracyjnego",
        dialog3: "2、Aby zapobiec nadużyciom, w ciągu miesiąca dozwolona jest tylko jedna wymiana.",
        dialog4: "3、Otrzymanie aktywów spowoduje dodanie kwoty otrzymanych aktywów na podstawie pierwotnej i nie zmieni statusu pozycji.",
        dialog5: "4、Jeśli masz inne pytania, skontaktuj się z obsługą klienta",
        dialog6: "zamknięcie",
        dialog7: "Pobierz teraz zasoby demonstracyjne",
        dialog8: "przeniesienie w",
        dialog9: "przenieść",
        dialog10: "przełącznik",
        dialog11: "Wycena",
        dialog12: "Zasoby demonstracyjne",
        dialog13: "Moje konto",
        dialog14: "Łączne aktywa konta",
        dialog15: "Przegląd zasobów",
        dialog16: "Całkowita wycena aktywów",
        dialog17: "Wycena aktywów",
        dialog18: "Dzisiejsze zarobki",
        dialog19: "zamrozić fundusze",
        dialog20: "Możesz otrzymywać symulowany limit zasobów co miesiąc",
        dialog21: "odbierać",
        dialog22: "Otrzymałeś pomyślnie",
        dialog23: 'Skumulowany dochód z 30 dni',
        dialog24: "Produkt finansowy",
        dialog25: "Moje zarządzanie finansami",
        dialog26: "Historyczne zarządzanie finansami",
        dialog27: "rekord historii",
        dialog28: "Zapisy dotyczące finansowania"
    },
    validator: {
        dialog14: "walidator",
        dialog15: "Walidator powiązania",
        dialog16: "przełącznik walidacyjny",
        dialog17: "Kod weryfikacyjny",
        dialog18: "Kod QR walidatora",
        dialog19: "klucz walidacyjny",
        dialog20: "ratować",
        dialog21: "Wybierz walidator",
        dialog22: "skopiuj klucz",
        dialog23: "Zapisano pomyślnie",
        dialog24: "proszę wpisać kod weryfikacyjny",
        dialog25: "Przyjazne przypomnienie: Przed potwierdzeniem zapisu otwórz odpowiednią aplikację i zeskanuj poniższy kod QR lub dodaj klucz ręcznie!",
        dialog26: "Włączenie modułu uwierzytelniającego zapewnia maksymalne bezpieczeństwo Twojego konta.",
        dialog27: "Przy każdym logowaniu lub wypłacie środków konieczne będzie wprowadzenie unikalnego kodu weryfikacyjnego, do którego dostęp można uzyskać za pośrednictwem telefonu komórkowego.",
        dialog28: "Skonfiguruj walidator",
        dialog29: "pomoc",
        dialog30: "Nie powiązałeś kodu uwierzytelniającego. Uzyskaj silniejszą ochronę dzięki modułowi uwierzytelniającemu.",
        dialog31: "Walidator nie został jeszcze skonfigurowany, proszę go najpierw skonfigurować",
        dialog32: "Przejdź do ustawień"
    },
    recharge: {
        title1: "Nie wiesz, jak kupować za pomocą cyfrowej waluty?",
        title2: "Tylko trzy proste kroki!",
        title3: "Z menu rozwijanego wybierz walutę cyfrową, którą chcesz kupić i wprowadź wielkość lub kwotę zakupu.",
        title4: "Wybierz preferowaną metodę płatności",
        title5: "Po potwierdzeniu płatności zakupiona waluta cyfrowa zostanie automatycznie przelana na Twoje konto.",
        title6: "Wybierz walutę, którą chcesz doładować",
        title7: "Zapis depozytu",
        table1: "Waluta",
        table2: "ilość",
        table3: "Nazwa łańcucha",
        table4: "państwo",
        table5: "powód",
        table6: "czas",
        table7: "Zobacz kupon",
        dialog1: "Wpisz poniżej kod weryfikacyjny",
        dialog2: "kod płatności",
        dialog3: "Kopiuj",
        dialog4: "adres",
        dialog5: "Kwota depozytu",
        dialog6: "Zrzut ekranu transakcji",
        dialog7: "ważna uwaga",
        dialog8: "Prosimy o uzupełnienie kwoty depozytu",
        dialog9: "Załaduj obrazek",
        dialog10: "Proszę wpisać treść",
        dialog11: "Prześlij zrzuty ekranu z ładowania",
        dialog12: "Bez recenzji",
        dialog13: "odrzucony",
        dialog14: "Zdeponowane",
        tip1: "Przesłano pomyślnie",
        tip2: "Skopiowano pomyślnie",
        tip3: "Kopiowanie nie powiodło się",
        tip4: "Wybierz walutę, którą chcesz doładować",
        tip5: "1.Powyższy adres wpłaty jest oficjalnym adresem płatności platformy. Poszukaj oficjalnego adresu wpłaty platformy. Będziesz odpowiedzialny za utratę środków spowodowaną błędną wpłatą;",
        tip6: "2.Pamiętaj, aby potwierdzić bezpieczeństwo swojego komputera i przeglądarki, aby zapobiec fałszowaniu lub wyciekowi informacji;",
        tip7: "3.Po doładowaniu powyższego adresu cały węzeł sieci musi potwierdzić, zanim będzie można zasilić konto;",
        tip8: "4.Proszę wybrać powyższy system tokenów i walutę i przelać odpowiednią kwotę do depozytu. Proszę nie przekazywać żadnych innych niepowiązanych aktywów, w przeciwnym razie nie zostaną one odzyskane.",
        tip9: "Zapisz kod QR",
        tip10: "uzupełniać",
        tip11: "ważna uwaga",
        tip12: "Linki poza witryną",
        tip13: "Doładuj kod QR",
        tip14: "Następny krok",
        tip15: "Rekord doładowania",
        tip16: "Szczegóły doładowania",
        tip17: "Adres depozytu",
        tip18: "Szybka płatność",
        tip20: "Niewystarczająca ilość środków",
        tip22: "Przesłano, czekam na potwierdzenie",
        tip23: "czekać na wypłatę",
        tip24: "Kwota",
        tip25: "Płatność została przesłana, oczekuje na potwierdzenie bloku",
        tip26: "Płatność odrzucona",
        tip27: "Niepowodzenie płatności",
        tip28: "Płatność pomyślna",
        bankChannel: "Kanał kart bankowych",
        tip29: "Poszukiwania historyczne",
        tip30: "Lista walut",
        tip31: "Wybierz sieć",
        tip32: "Doładowując tę ​​walutę, ładuj wyłącznie za pośrednictwem obsługiwanych sieci wymienionych poniżej. Jeśli doładowujesz za pośrednictwem innych sieci, środki zostaną utracone.",
        tip33: "Wypłacając tę ​​walutę, należy wypłacać wyłącznie za pośrednictwem poniższych sieci. Jeśli wypłacasz za pośrednictwem innych sieci, środki zostaną utracone.",
        tip34: "Szybkie ładowanie",
        tip35: "Zeskanuj kod, aby doładować",
        tip36: "Ręczne ładowanie",
        tip37: "Naładuj sieć",

        tip38: "Adres doładowania",
        tip39: "Kwota doładowania",
        tip40: "Przesyłanie potwierdzenia transakcji",
        tip41: "Załaduj obrazek",
        tip42: "potwierdź przesłanie",
        tip43: "Potwierdź płatność",
        tip44: "Uwaga: potwierdź adres, na który chcesz doładować konto. Nie ponosimy odpowiedzialności za utratę pieniędzy w przypadku nieprawidłowego jego wypełnienia.",
        tip45: "przypomnieć",
        tip46: "Adres skopiowany pomyślnie!",
        tip47: "Kopiowanie adresu nie powiodło się!",
        tip48: "Niewystarczające dostępne saldo",
        tip49: "Doładowanie zostało pomyślnie przesłane i oczekuje na potwierdzenie w łańcuchu.",
        tip50: "Doładowanie nie powiodło się",
        tip51: "Kwota doładowania jest nieprawidłowa",
        tip52: "Połączenie nieudane",
        tip53: "Portfel niezalogowany",
        tip54: "Połączenie odrzucone",
        tip55: "Przesłano pomyślnie, oczekuje na sprawdzenie i zatwierdzenie przez obsługę klienta",
        tip56: "Prześlij zrzut ekranu potwierdzenia transakcji",
        tip57: "Przesyłanie nie powiodło się",
        tip58: "Rekord doładowania",
        tip59: "Rekord wypłat",
        tip60: "Doładuj walutę",
        tip61: "Waluta wypłaty",
        tip62: "Adres doładowania",
        tip63: "Pokazuje zrzut ekranu",
        tip64: "Kliknij, aby powiększyć obraz",
        tip65: "Czas ładowania",
        tip66: "Powód odmowy",
        tip67: "Kwota wypłaty",
        tip68: "Ilość otrzymana",
        tip69: "Sieć wypłat gotówki",
        tip70: "Adres do wypłaty",
        tip71: "Czas wypłaty",
        tip72: "zamknięcie",
        tip73: "Zrzut ekranu transakcji",
        tip74: "W oczekiwaniu na opinię",
        tip75: "odrzucony",
        tip76: "zakończony",
        tip77: "odrzucony",
        tip78: "Sprawdzone",
        tip79: "Wypłacone",
        tip80: "wyciąg",
        tip81: "sieć transferowa",
        tip82: "Adres/faktura",
        tip83: "Wycofaj wszystko",
        tip84: "Proszę nie wypłacać pieniędzy bezpośrednio na adres crowdfundingu lub ICO, w przeciwnym razie nie będziesz mógł otrzymać tokenów wydanych przez crowdfunding lub ICO.",
        tip85: "Ilość przybycia",
        tip86: "Potwierdź wypłatę",
        tip87: "Zły adres wypłaty",
        tip88: "Błędna kwota wypłaty",
        tip89: "Kwota do wypłaty wynosi co najmniej",
        tip90: "Maksymalna kwota wypłaty wynosi",
        tip91: "Złożono wniosek o wypłatę",
        tip92: "lista adresowa",
        tip93: "Nie dodałeś jeszcze adresu",
        tip94: "Dodaj to teraz",
        tip95: "Dodaj adres",
        tip96: "Dodaj nowy adres",
        tip97: "proszę wybrać",
        tip98: "adres",
        tip99: "Wybierz sieć transferu",
        tip100: "Proszę wpisać adres",
        tip101: "Adres dodano pomyślnie",
        tip102: "w transakcji",
    },
    fundExchange: {
        title1: "Jak wymieniać środki?",
        title2: "Wybierz odpowiednią walutę do przeliczenia.",
        title3: "Wybierz odpowiednią walutę do przeliczenia.",
        title4: "Wprowadź kwotę do wymiany i kliknij przycisk Prześlij, aby zakończyć wymianę środków.",
        title5: "z",
        title6: "przyjechać",
        title7: "Ilość wymiany",
        title8: "Kurs wymiany",
        title9: "Dostępny",
        title10: "Proszę wprowadzić ilość wykupu",
        title11: "Nie ma odpowiedniej pary handlowej do wymiany.",
        title12: "Proszę wpisać ilość do wykorzystania",
        title13: "Wykup udany",
        title14: "Zapis wymiany",
        table1: "Użyj waluty",
        table2: "ilość",
        table3: "Kurs wymiany",
        table4: "Kurs wymiany walut",
        table5: "Paragon",
        table6: "czas",
        title15: "z",
        title16: "Obecnie nie ma dostępnych żadnych zasobów. Wybierz inny"
    },
    fundTransfer: {
        title1: "Jak przelać środki?",
        title2: "Wybierz bieżący rachunek, który chcesz przenieść.",
        title3: "Wybierz konto, które chcesz przenieść.",
        title4: "Wprowadź kwotę przelewu i kliknij przycisk Wyślij, aby dokończyć przelew.",
        title5: "Waluta",
        title6: "Ilość transferu",
        title7: "Proszę wpisać kwotę do przelewu",
        title8: "zapis transferowy",
        title9: "Proszę wybrać typ portfela",
        title10: "Proszę wybrać walutę",
        title11: "Proszę wybrać typ portfela",
        title12: "Proszę wybrać walutę",
        title13: "Proszę wpisać kwotę do przelewu",
        title14: "Przesłano pomyślnie",
        table1: "Przenieś konto",
        table2: "walutę transferową",
        table3: "Ilość transferu",
        table4: "konto transferowe",
        table5: "czas",
        table6: "przenosić ",
        table7: "Transfer środków",
        table8: "Przenieść do",
        table9: "zapis transferowy",
        table10: "Szczegóły przelewu",
        table11: "Transfer udany",
        table12: "Transfer nie powiódł się",
        table13: "Konta bieżącego nie można przenieść"
    },
    withdrawal: {
        title1: "ważna uwaga",
        title2: "1.Aby zapobiec arbitrażowi, możesz złożyć wniosek o wypłatę waluty, gdy wolumen transakcji osiągnie limit.",
        title3: "2.Wniosek o wypłatę zostanie zaksięgowany w ciągu 24 godzin od złożenia. Jeśli wypłata nie zostanie otrzymana po szacowanym czasie wypłaty, skontaktuj się z obsługą klienta online.",
        title4: "3.Po złożeniu wniosku o wypłatę środki są w stanie zamrożonym, ponieważ wypłata jest w toku i środki są tymczasowo zatrzymane w systemie.Nie oznacza to, że utraciłeś aktywa lub że wystąpiła nieprawidłowości w aktywach.",
        title5: "Waluta",
        title6: "adres portfela",
        title7: "Ilość wycofanych monet",
        title8: "Rekord wypłaty monet",
        title9: "Proszę wybrać walutę",
        title10: "Proszę wybrać typ łańcucha",
        title101: "",
        title11: "Podaj",
        title12: "adres portfela",
        title13: "Przesłano pomyślnie",
        title14: "w trakcie przeglądu",
        title15: "Recenzja pomyślna",
        title16: "Błąd audytu",
        title17: "Wypłacone",
        title18: "Wprowadź lub wklej adres swojego portfela",
        title19: "Proszę wpisać kwotę wypłaty",
        title20: "opłata manipulacyjna",
        title21: "Rzeczywisty przyjazd",
        table1: "Waluta",
        table2: "Ilość wycofanych monet",
        table3: "Nazwa łańcucha",
        table4: "adres portfela",
        table5: "państwo",
        table6: "powód",
        table7: "czas aplikacji",
        table8: "Wycofaj monety",
        table81: "Wycofaj monety",
        table9: "Adres do wypłaty",
        table10: "Wybierz walutę, którą chcesz wypłacić",
        table11: "Upewnij się, że wejdziesz",
        table12: "Adres (zwykle zaczyna się od „0x”)",
        table13: "Monety do wypłaty (potrącona opłata manipulacyjna)：",
        table14: "Wybierz walutę",
        table15: "Napotkane problemy? Natychmiastowe informacje zwrotne",
        table16: "balansować",
        table17: "Szczegóły wypłaty",
        password: "hasło",
        passwordTips: "Proszę wpisać hasło",
        passwordAg: "Potwierdź hasło",
        passwordAgTips: "Wprowadź hasło ponownie",
        oldPassword: "Stare hasło",
        oldPasswordTips: "Proszę wprowadzić stare hasło",
        newPassword: "nowe hasło",
        newPasswordTips: "Proszę wprowadzić nowe hasło",
        passwordNotSame: "Hasła są niespójne",
        withdrawPasswordSet: "Ustaw hasło wypłaty",
        withdrawPasswordModify: "Zmień hasło wypłaty",
        withdrawPassword: "Wycofaj hasło",
        withdrawPasswordTips: "Proszę podać hasło do wypłaty",
        setWithdrawPasswordFirst: "Najpierw ustaw hasło do wypłaty",
        withdrawPasswordSetSuccess: "Konfiguracja powiodła się",
        withdrawPasswordModifySuccess: "Pomyślnie zmodyfikowano",
        submit: "Submit",
    },
    customer: {
        content1: "serwis internetowy",
        content2: "obsługa klienta",
        content3: "Proszę podać swoje dane kontaktowe",
        content4: "Proszę wpisać treść",
        content5: "wysłać",
        content6: "Wysyłanie nie powiodło się, połączenie sieciowe nie powiodło się",
        content7: "zdjęcie",
        content8: "wiadomość jest pusta",
        content9: "moja część",
        content10: "Udostępnij link",
        content11: "zapisz zdjęcie",
        content12: "Moje zarobki",
        content13: "Opis poziomu",
        content14: "Wielkość drużyny",
        content15: "Dochód z zaproszeń",
        content16: "Opis reguły",
        content17: "poziom członkostwa",
        content18: "Kwota doładowania jest większa niż",
        content19: "Poziom zespołu",
        content20: "Poziom zespołu i dochód",
        content21: "Aby spełnić warunki",
        content22: "Dochód z dystrybucji",
        content23: "zespół na poziomie",
        content24: "poziom, zaproszenie",
        content25: "ważnych członków",
        content26: "dochód pierwotny",
        content27: "dochód wtórny",
        content28: "Dochód na poziomie 3",
    },
    standardContract: {
        increaseMargin: "Increase margin",
        increaseAmount: "Increase amount",
        Pleaseenterthemargin: "Please enter the margin amount to be increased",
        Pleaseenterthecorrect: "Please enter the correct deposit amount",
        Increasemarginsuccessfully: "Increase margin successfully",
        takeProfitPriceWrong: "Źle potraktuj cenę zysku",
        stoppriceerror: "Zatrzymaj błąd ceny",
        thetakeprofit1: "Cena take-profit musi być wyższa od ceny rynkowej",
        thetakeprofit2: "Cena stop loss musi być niższa niż cena rynkowa",
        thetakeprofit3: "Cena take zysku musi być niższa od ceny rynkowej",
        thetakeprofit4: "Cena stop loss musi być wyższa niż cena rynkowa",
        thetakeprofit5: "Zła ilość",
        estimatedprofitloss: "Szacowany zysk i strata",
        positionAmount: "Liczba pozycji",
        closeAmount: "Ilość zamykająca",
        whenthelatest: "Kiedy osiągnięta zostanie ostatnia cena",
        whenthelatest2: "zostanie uruchomione zlecenie take-profit po cenie rynkowej w celu zamknięcia bieżącej pozycji. Oczekiwany zysk i strata",
        whenthelatest3: ", zostanie uruchomione rynkowe zlecenie stop loss w celu zamknięcia bieżącej pozycji. Oczekiwany zysk i strata",
        contract: "kontrakt",
        ordercanceledsuccessfully: "Zamówienie anulowane pomyślnie",
        successfullyordered: "pomyślnie zamówione",
        positionopeningreminder: "Przypomnienie o otwarciu pozycji",
        wrongquantity: "Zła ilość",
        quantitycannot: "Ilość nie może być większa niż",
        modificationstoploss: "Modyfikacja Stop Profit i Stop Loss zakończyła się pomyślnie",
        positionclosedsuccessfully: "Pozycja została pomyślnie zamknięta",
        orderCancellation: "Anulowanie zamówienia",
        orderCancellation2: "Czy na pewno chcesz anulować zamówienie?",
        tobeexecuted: "Do wykonania",
        expired: "wygasły",
        failed: "przegrany",
        contractTransaction: "Umowa wieczysta",
        increase: "Zwiększyć",
        openPosition: "Pełny magazyn",
        isolatedPosition: "Odosobnione stanowisko",
        limitOrder: "limit cenowy",
        marketOrder: "Cena rynkowa",
        pleaseSelectLeverage: "Proszę wybrać dźwignię",
        pleaseEnterQuantity: "Proszę wpisać ilość",
        canOpenMore: "Maksymalna pozycja, którą można otworzyć",
        contractAmount: "Kwota kontraktu",
        openingFee: "opłata manipulacyjna",
        currentPosition: "Pozycja",
        historicalPositions: "Transakcje historyczne",
        currentCommission: "Aktualne zamówienie",
        historicalCommission: "komisja historyczna",
        buyPrice: "Cena zakupu",
        income: "zysk",
        quantity: "ilość",
        marginRate: "stopa marży",
        initialMargin: "Margines początkowy",
        estimatedPriceParity: "Cena przymusowej likwidacji",
        operate: "działać",
        finishQuantity: "Wolumen transakcji",
        averagePrice: "średnia cena transakcyjna",
        pleaseEnterLeverage: "Proszę wprowadzić dźwignię",
        takeProfitAndStopLoss: "Zrealizuj zysk i zatrzymaj stratę",
        takeProfit: "Mieć zysk",
        stopLoss: "zatrzymać stratę",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Zamknięta pozycja",
        marketLquidation: "Cena rynkowa jest płaska",
        cancel: "Anuluj zamówienie",
        enterBuyPrice: "Proszę wpisać cenę zakupu",
        areYouSureLquidation: "Czy na pewno chcesz zamknąć pozycję?",
        confirm: "Jasne",
        enterStopWinPrice: "Proszę podać cenę realizacji zysku",
        enterStopLossPrice: "Proszę wprowadzić cenę stop-loss",
        commissionTime: "Czas prowizji",
        commissionPrice: "Cena prowizyjna",
        commissionQuantity: "Łączna kwota prowizji",
        volumeAlreadyTraded: "Kwota ukończona",
        areYouSureToWinOrLoss: "Czy na pewno chcesz zatrzymać zysk i zatrzymać stratę?",
        maxQuantity: "Maksymalna pozycja",
        saleQuantity: "Pozycja zamknięta",
        salePrice: "średnia cena zamknięcia",
        saleTime: "czas",
        succeeded: "udało się",
        cancelled: "Odwołany",
        piece: "otwarty",
        triggerPrice: "cena wywoławcza",
        setWin: "Ustaw zysk",
        setLoss: "Ustaw stop-loss",
        enterWinQuantity: "Proszę wprowadzić kwotę zrealizowanego zysku",
        enterLossQuantity: "Proszę wprowadzić kwotę stop-loss",
        planCommission: "Prowizja planu",
        enterWinTriggerPrice: "Proszę wprowadzić cenę wyzwalającą realizację zysku",
        enterLossTriggerPrice: "Proszę wprowadzić cenę wyzwalającą stop-loss",
        insufficientQuantity: "Niewystarczająca ilość",
        enterTriggerPrice: "Proszę wprowadzić cenę wywoławczą",
        tips1: "Cena Twojego zamówienia jest wyższa niż cena aktualna i zostanie ono zrealizowane bezpośrednio po aktualnej cenie rynkowej. Czy na pewno chcesz kupić?",
        tips2: "Cena Twojego zamówienia jest niższa od aktualnej ceny i zostanie ono zrealizowane bezpośrednio po aktualnej cenie rynkowej. Czy na pewno kupujesz?",
        type: "typ",
        increaseQuantity: "Zwiększ pozycję",
        reduceQuantity: "Zmniejsz pozycje",
        limitCommission: "zamówienie limitowane",
        tips3: "Cena stop loss musi być niższa niż cena rynkowa",
        tips4: "Cena take-profit musi być wyższa od ceny rynkowej",
        tips5: "Cena stop loss musi być wyższa niż cena rynkowa",
        tips6: "Cena take zysku musi być niższa od ceny rynkowej",
        tips7: "Cena stop loss powinna być niższa od ceny rynkowej i niższa od ceny zlecenia",
        tips8: "Cena realizacji zysku powinna być większa niż cena zlecenia (może być mniejsza niż cena rynkowa)",
        tips9: "Cena stop loss powinna być wyższa od ceny rynkowej i wyższa od ceny zlecenia",
        tips10: "Cena realizacji zysku powinna być mniejsza niż cena zlecenia (może być większa niż cena rynkowa)",
        accountEquity: "Bilans konta",
        unrealizedPL: "niezrealizowany zysk lub strata",
        tips11: "Czy na pewno masz rację",
        tips12: "Cena rynkowa",
        tips13: "Zamknij wszystkie pozycje",
        tips14: "Nie przypominaj znowu",
        profitRate: "Zamknięta stopa zwrotu",
        openTime: "Czas otwarcia",
        unwindTime: "Czas zamknięcia",
        dealTime: "Czas transakcji",
        amountClose: "Kwota zamkniętej pozycji",
        long: "długo",
        short: 'krótki',
        orderQuantity: "Ilość zamówienia",
        entrustedMargin: "powierzoną marżę",
    },
    spotGoods: {
        spotGoods: "giełda",
        sellPrice: "Cena sprzedaży",
        orderRecord: "Rekord wykupu Flash",
        transactionTime: "Czas transakcji",
        maxBuy: "dostępne do kupienia",
        maxSell: "Dostępny na sprzedaż",
        purchaseQuantity: "Zakupiona ilość",
        slippage: "Poślizg",
        convertTips: "Zamkniemy transakcję po najkorzystniejszej cenie z poślizgiem 0-5%.",
        changePrice: "Aktualny kurs wymiany",
        consume: "konsumować",
        get: "Dostawać",
        changeTips: "Czy na pewno chcesz skorzystać?",
        confirm: "Potwierdź wykup"
    },
    news: {
        title: "Centrum Wiadomości",
        redMore: "Czytaj więcej",
    },
    credit: {
        credit: "Ocena kredytowa",
        creditScore: "całkowity wynik kredytowy",
        latestEntry: "najnowszy rekord",
        liveUpdate: "Uaktualnienia na bieżąco",
        systemEvaluation: "Ocena systemu",
        time: "data",
        name: "nazwa",
        reason: "powód",
        scoreStory: "Rekord punktacji kredytowej"
    },
    borrowing: {
        borrowing: "pożyczka",
        diversifiedMarket: "Zdywersyfikowane rynki",
        totalSupply: "Całkowita podaż",
        totalBorrowings: "całości pożyczek",
        tips1: "Jest to całkowita kwota, jaką możesz pożyczyć pod zabezpieczenie, aż do limitu pożyczki.",
        tips2: "Twoja stabilna stopa procentowa pozostanie taka sama przez cały okres trwania pożyczki. Zalecane w przypadku dostaw długoterminowych i użytkowników lubiących przewidywalność.",
        totalSupplys: "całkowita podaż",
        supplyAPR: "stopa podaży",
        totalBorrowings: "Całkowite zadłużenie",
        borrowAPR: "stopa kredytu",
        check: "Sprawdzać",
        market: "rynek",
        availableLiquidity: "Dostępna płynność",
        currencyPrice: "aktualny kurs waluty",
        supplyAssets: "aktywa dostawcze",
        tips3: "Uwaga: Poniższe oprocentowanie jest oprocentowaniem stałym. Po upływie okresu dostawy kwota główna wraz z zarobkami zostanie zwrócona na Twoje konto.",
        supplyCycle: "cykl dostaw",
        minimumSupply: "minimalna podaż",
        supply: "dostarczać",
        borrowedAssets: "pożyczone aktywa",
        tips4: "Ważne, aby wiedzieć: aby pożyczyć pieniądze, musisz przedstawić wszelkie zabezpieczenia lub dowód,",
        tips5: "Proszę zapoznać się z opisem aktywów hipotecznych.",
        repaymentCycle: "cykl spłaty",
        BorrowableAmount: "Dostępna kwota kredytu",
        borrow: "pożyczka",
        accountInformation: "informacje o koncie",
        walletBalance: "saldo portfela",
        canSupply: "Dostępny",
        AmountCanBeBorrowed: "Całkowita dostępna kwota kredytu",
        borrowedAmount: "Pożyczona kwota",
        remainingAmount: "Pozostałe saldo",
        getBorrowednAmount: "Uzyskaj limit kredytu",
        supplyOrder: "zamówienie na dostawę",
        borrowedOrder: "zlecenie pożyczki",
        supplyAmount: "ilość dostawy",
        expectedProfits: "Spodziewany powrót",
        supplyTime: "Czas dostawy",
        expirationTime: "Data ważności",
        borrowingAmount: "Kwota kredytu",
        dueRepayment: "Kwota spłaty",
        borrowingTime: "Czas zwolnienia",
        repaymentTime: "Czas spłaty",
        repay: "spłata",
        supplyCurrency: "waluta podaży",
        buyNow: "Kup Teraz",
        supplyRecords: "zapisy dostaw",
        borrowRecords: "Zapisy kredytów",
        completed: "zakończony",
        borrowNow: "Pożycz teraz",
        borrowCurrency: "Pożyczanie waluty",
        borrowMin: "najmniejszy",
        borrowMax: "maksymalny",
        borrowRange: "Kwota pożyczki (minimalna/maksymalna)",
        tips6: "Prosimy o terminową spłatę.W przypadku opóźnienia zostanie naliczona dodatkowa dzienna kwota (kapitał + odsetki).",
        tips7: "Zaległa opłata.",
        pleaseEnterBorrowAmount: "Proszę wpisać kwotę pożyczki",
        interest: "Odsetki",
        tips8: "Czy na pewno chcesz spłacić z góry?",
        borrowChannel: "Kanał pożyczkowy",
        tips9: "Zacznijmy od poznania Ciebie",
        name: "nazwa",
        lastName: "nazwisko",
        birthDate: "Data urodzenia",
        tips10: "Trochę więcej informacji. Jaki jest Twój adres?",
        familyAddress: "Adres domowy (wraz z numerem jednostki)",
        city: "Miasto",
        country: "naród",
        postalCode: "kod pocztowy",
        pleaseEnterYourHomeAddress: "Proszę podać swój adres domowy",
        tips11: "Opowiedz nam o swojej sytuacji finansowej",
        tips12: "Być może będziemy musieli później potwierdzić te informacje, dlatego prosimy o podanie jak największej dokładności",
        yourMonthlyIncome: "swój miesięczny dochód",
        enterYourMonthlyIncome: "Proszę podać swój miesięczny dochód",
        extraIncomeOptional: "Dodatkowy dochód (opcjonalnie)",
        enterExtraIncome: "Proszę podać swój dodatkowy dochód",
        monthlyRentOrMortgagePayment: "Miesięczny czynsz lub spłata kredytu hipotecznego",
        enterMonthlyRent: "Proszę podać miesięczną ratę czynszu lub ratę kredytu hipotecznego",
        tip13: "Powiedz nam, czy masz kredyt hipoteczny",
        tip14: "W przypadku braku wyboru gwarancji hipotecznej prawdopodobieństwo pozytywnego rozpatrzenia jest niskie.",
        isThereMortgage: "Czy jest kredyt hipoteczny?",
        selectIsThereMortgage: "Proszę zaznaczyć, czy istnieje kredyt hipoteczny",
        selectMortgageType: "Proszę wybrać rodzaj kredytu hipotecznego",
        yes: "Tak",
        no: "NIE",
        uploadScreenshotOfTransaction: "Prześlij zrzuty ekranu transakcji",
        tip15: "Bądźmy w kontakcie",
        tip16: "Możemy skontaktować się z Tobą pod tym numerem, jeśli będziemy potrzebować dalszych informacji na temat Twojego wniosku o pożyczkę",
        tip17: "Przeczytałem i podpisałem elektronicznie moją zgodę",
        tip18: "Warunki korzystania i zgoda elektroniczna",
        tip19: "，Obejmuje to Twoją zgodę na przesyłanie Ci informacji o naszych",
        tip20: "Warunki korzystania z produktów i usług",
        tip21: "Po uzupełnieniu informacji należy poczekać na ocenę i recenzję systemu.",
        phoneNumber: "numer telefonu",
        enterPhoneNumber: "Proszę podać swój numer telefonu",
        tip22: "Proszę zaznaczyć pole, aby wyrazić zgodę na warunki korzystania i elektroniczny formularz zgody",
        inReview: "w trakcie przeglądu",
        applicationAmount: "Ilość aplikacji",
        reasonForRejection: "Powód odmowy",
        reapply: "Zastosuj ponownie",
        applyStatus: "status aplikacji",
        currentOrder: "aktualne zamówienie",
        interestRate: "oprocentowanie"
    },
    yuebao: {
        filed1: "inwestować",
        filed2: "Na wynos",
        filed3: "Dzienny dochód",
        filed4: "przyspieszenie zespołu",
        filed5: "Zdeponowane",
        filed6: "Wczorajsze zarobki",
        filed7: "Całkowity dochód",
        filed8: "Dostępne fundusze",
        filed9: "Proszę wpisać kwotę",
        filed10: "Wydobywanie płynności",
        filed11: "Wstęp",
        field12: "Minimalna cena zakupu",
        field13: "Najwięcej, jakie możesz kupić",
        filed14: "Subskrypcja",
        filed15: 'regularny',
        filed16: 'roczna stopa procentowa',
    },
    tipsPopover: {
        title: "Zainstaluj tę aplikację",
        press: "według",
        and: "I",
        scrren: "Dodaj do ekranu głównego",
        hasInstall: "(Jeśli jest zainstalowany, zamknij okno)",
    },
    follow: {
        title1: "Plan dalszych działań",
        follow_text1: "Zainicjuj kolejne zamówienie",
        follow_text2: "Historia wydań",
        follow_text3: "tytuł",
        follow_text4: 'para handlowa',
        follow_text5: 'Czas trwania zakupu',
        follow_text6: 'czas realizacji',
        follow_text7: 'czas zwolnienia',
        follow_text8: 'Liczba obserwujących',
        follow_text9: 'Kwota zamówienia',
        follow_text10: 'działać',
        follow_text11: 'zapraszać',
        follow_text12: 'Postępuj zgodnie z kierunkiem zamówienia',
        follow_text13: 'Ustal warunki zamówienia',
        follow_text14: 'Warunki realizacji zamówień',
        follow_text15: 'stała kwota',
        follow_text16: 'odsetek',
        follow_text17: 'Zaproszeni ludzie',
        follow_text18: 'Identyfikator drugiej strony',
        follow_text19: "Potwierdź, że chcesz zastosować się do polecenia",
        follow_text20: "Kwota zamówienia (opcjonalnie)",
        follow_text21: "Proszę wpisać tytuł",
        follow_text22: "Wybierz parę handlową",
        follow_text23: "Proszę wybrać kierunek zamówienia",
        follow_text24: "Proszę wybrać czas trwania zakupu",
        follow_text25: "Proszę wybrać termin zamówienia",
        follow_text26: "Proszę wybrać ilość zakupu",
        follow_text27: "Maksymalna ilość zakupu nie może być mniejsza niż minimalna ilość zakupu",
        follow_text28: "Minimalne wpisowe",
        follow_text29: "Większość kupuje",
        follow_text30: "Pomyślnie utworzono zamówienie kopii",
        follow_text31: "Lista par handlowych",
        follow_text32: "Już wykonałem polecenie",
        follow_text33: "Wprowadź informacje o zaproszonych osobach",
        follow_text34: "Zaproszony",
        follow_text35: "zaprosił mnie",
        follow_text36: "Kopiowanie historii",
        follow_text37: "Zakres kwoty zamówienia"
    },
    followHistory: {
        title1: 'Historia wydań',
        title2: "Kopiowanie historii",
        follow_h_text1: "Całkowita kwota zamówienia",
        follow_h_text2: "Łączna liczba osób wykonujących polecenia",
        follow_h_text3: "Liczba wydań",
        follow_h_text4: "zwycięska stawka",
        follow_h_text5: "Liczba wykonań poleceń",
        follow_h_text6: "Zyskaj na kolejnych zamówieniach",
        follow_h_text7: "kierunek",
        follow_h_text8: "wygrać",
        follow_h_text9: "deficyt",
    },
    "asset.index.1579E8B41F8D5134": "moje aktywa",
    "asset.index.B3F6F05Dfbe431Cd": "symulacja",
    "asset.index.B9Aeab4Ee8F33746": "Wycena aktywów",
    "asset.index.583Df8Bd541Eb7E8": "trendy zarobkowe",
    "asset.index.B1254815D133Cc26": "7 dni",
    "asset.index.2F615F6C029F01B7": "30 dni",
    "asset.index.29439Fb58E4Bbe22": "wycofać",
    "asset.index.831Ab31568A78285": "uzupełniać",
    "asset.index.39F7A0D48D12E8C9": "przenosić ",
    "asset.index.B99063C5689Beaec": "Wymiana flashowa",
    "asset.index.E1Dc620F2F038B2A": "Otrzymuj aktywa",
    "asset.index.972A0B4C2B4393D0": "Moje konto",
    "asset.index.9Dfb0Fb334620733": "Dostępne saldo",
    "asset.index.Cdc5Fbd26790Cb47": "zamrożona ilość",
    "asset.index.3D8132D72Ae965Cd": "Wycena",
    "asset.receive.825503Cc430559Cb": "nabyć aktywa",
    "asset.receive.54154A0553A535B7": "Zasoby demonstracyjne",
    "asset.receive.F2E57B00A9C5D129": "1.Tego zasobu można używać wyłącznie w trybie konta demonstracyjnego",
    "asset.receive.Ea0D1D52989299Bd": "2.Aby zapobiec nadużyciom, w ciągu miesiąca dozwolona jest tylko jedna wymiana.",
    "asset.receive.090E0037Aa235D3D": "3.Otrzymanie aktywów spowoduje dodanie kwoty otrzymanych aktywów na podstawie pierwotnej i nie zmieni statusu pozycji.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Jeśli masz inne pytania, skontaktuj się z obsługą klienta",
    "asset.receive.Df5B3A511D986575": "Pobierz teraz zasoby demonstracyjne",
    "asset.records.1Fa02E8187A39592": "ostatnie trzy miesiące",
    "asset.records.7B291C37333Fe34D": "z bliska do daleka",
    "asset.records.A476Cd1Ec868Fc5F": "ostatnie trzy miesiące,",
    "asset.records.9D5755A842E55Bb7": "z bliska do daleka,",
    "asset.records.E3Fa05E607203Cdc": "uzupełniać",
    "asset.records.B4F206C6Bec08Dea": "wycofać",
    "asset.index.Df5B3A511D986576": "Pomyślnie otrzymałeś zasoby demonstracyjne",
    "deposit.depositSpeedy.Efe81D0E0174239B": "metoda płatności",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Waluta Fiata",
    "deposit.depositSpeedy.16658A855F07D171": "Kwota",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Jasne",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Nie może być pusty",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Minimalne doładowanie",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Maksymalne doładowanie",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Wpłać USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Doładuj walutę",
    "deposit.depositWallet.B404C543712B71D5": "Naładuj sieć",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Adres depozytu",
    "deposit.depositWallet.D3980D54Acf242Db": "Zapisz kod QR",
    "deposit.depositWallet.A06E84801D6582Be": "Kwota doładowania",
    "deposit.depositWallet.Daaaeb1B7B22B126": "wskazówka",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Doładowanie powiodło się",
    "deposit.depositWallet.4F48Abbafce532Bb": "Przesłano pomyślnie. Czekam na opinię obsługi klienta",
    "deposit.depositWallet.A88C481228811696": "Odmówiłeś zmiany sieci",
    "deposit.depositWallet.C6Ebf27895C21932": "Proszę ręcznie otworzyć wtyczkę portfela TRON i zalogować się",
    "deposit.index.B5491C8Dd09D8183": "Wybierz walutę, którą chcesz doładować",
    "deposit.index.2F784B2298D39987": "Szybkie ładowanie",
    "deposit.index.0Daa03Dcdcabd837": "Linki poza witryną",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Potwierdzenie wypłaty",
    "withdraw.bottomSheet.A086Fa20082C849D": "Upewnij się, że poniższe informacje są prawidłowe",
    "withdraw.bottomSheet.Bf8B184D32971501": "Sieć wypłat",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "adres",
    "withdraw.bottomSheet.F9Db93B87E08763B": "ilość",
    "withdraw.bottomSheet.4Ad26475002878C0": "opłata manipulacyjna",
    "withdraw.index.A96454041169Af6D": "Wybierz walutęWybierz walutę",
    "withdraw.index.2A563B7081Dd5B83": "Adres do wypłaty",
    "withdraw.index.B189C2Ef713Cfff4": "Ilość wycofanych monet",
    "withdraw.index.558F35140E84B562": "Dostępny：",
    "withdraw.index.F4E759087E12Cb77": "Wycofaj wszystko",
    "withdraw.index.5Cbd9A6B284D25Ae": "Hasło funduszu",
    "withdraw.index.B89Cbe4Bfc33Bb41": "opłata manipulacyjna:",
    "withdraw.index.C886De8D8A27026E": "ważna uwaga",
    "withdraw.index.Eac7C5A4095F5606": "1.Aby zapobiec arbitrażowi, możesz złożyć wniosek o wypłatę waluty, gdy wolumen transakcji osiągnie limit.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.Wniosek o wypłatę zostanie zaksięgowany w ciągu 24 godzin od złożenia. Jeśli wypłata nie zostanie otrzymana po szacowanym czasie wypłaty, skontaktuj się z obsługą klienta online.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Po złożeniu wniosku o wypłatę środki są w stanie zamrożonym, ponieważ wypłata jest w toku i środki są tymczasowo zatrzymane w systemie.Nie oznacza to, że utraciłeś aktywa lub że wystąpiła nieprawidłowości w aktywach.",
    "withdraw.index.4639F3A6E07C00B3": "Następny krok",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Wprowadź lub wklej adres swojego portfela",
    "withdraw.index.25768141C8Bdea91": "Proszę wpisać kwotę wypłaty",
    "withdraw.index.97A85734Cd1B7Bf0": "Proszę podać hasło funduszu",
    "withdraw.index.39C7D74B33241C78": "Kwota wypłaty jest błędna",
    "withdraw.index.86A5D17908197525": "Potwierdzenie wypłaty, oczekiwanie na sprawdzenie w celu uwolnienia monet",
    "withdraw.index.48Fb603C6638C7F6": "Proszę ustawić hasło funduszu",
    "withdraw.index.873Ade9A3Bacc2F7": "wskazówka",
    "bill.index.5Bbb2A6Ac68D6B2E": "Rzeczywisty",
    "bill.index.75751E0Eb8261C40": "konto demo",
    "bill.index.Cbc853B37Ecc2E97": "Typ zmiany konta",
    "bill.index.4A0Cacddeb2E38B1": "typ konta",
    "bill.index.F16217028E098B2F": "Waluta",
    "bill.index.A620Dbe6F06B78Ba": "Wszystkie typy",
    "bill.index.2C3C148Bb94D9B3F": "Wszystkie konta",
    "c2C.index.Ab45695E4Bf1E382": "Transakcja C2C",
    "c2C.index.2702Dc911Dfea762": "chcę kupić",
    "c2C.index.D737D5B8367A53C4": "chcę sprzedać",
    "c2C.index.C0C540A3Ba9Abe01": "Tom",
    "c2C.index.D8644E8D60C8Bd8A": "Cena jednostkowa",
    "c2C.index.Ebb8670B203D3604": "limit",
    "c2C.index.F8C6245A2Ebf1D27": "Wszystkie karty bankowe",
    "c2C.index.F8C6245A2Ebf1D28": "kolekcja walut",
    "c2C.index.F8C6245A2Ebf1D29": "karta bankowa",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Kupić",
    "c2C.index.Cc3Aa8141Da9Ec9F": "sprzedać",
    "c2C.index.67A39834582Ad99A": "Brak danych",
    "c2C.order.04C23810B47F77F7": "według kwoty",
    "c2C.order.6905365902E0A73D": "według ilości",
    "c2C.order.3F0Ecebbd9D2F23B": "Dostępny",
    "c2C.order.16853Bda54120Bf1": "Wszystko",
    "c2C.order.Ad3335796F2C95F7": "kwota ryczałtowa",
    "c2C.order.61092E27A6879318": "Aby chronić bezpieczeństwo mienia, zwiększ swoją świadomość w zakresie zapobiegania!",
    "c2C.order.0F51881F0Fb920B2": "Informacje handlowe",
    "c2C.order.Dd74Ceca85E5Bc65": "Termin płatności",
    "c2C.order.De3341388A59073F": "30 minut",
    "c2C.order.D334B597A352167F": "Pseudonim sprzedawcy",
    "c2C.order.D334B597A352167G": "Pseudonim kupującego",
    "c2C.order.9D5F89F99Ced890D": "środek transakcji",
    "c2C.order.8Adf755B14Bc6B7C": "Najpierw przeczytaj poniższe informacje",
    "c2C.order.686B16F454A7338B": "Podczas dokonywania przelewów kartą bankową nie rób żadnych uwag, w przeciwnym razie pieniądze nie zostaną zwolnione, a konto zostanie bezpośrednio zablokowane. Po dokonaniu płatności należy podać nowy diagram szczegółów transakcji po dokonaniu płatności (w przypadku zgłoszenia do platformy diagramu P lub ukrytych szczegółów transakcji w celu zamrożenia konta)",
    "c2C.order.9Bec8Fba867B739B": "Wybierz Metodę Płatności",
    "c2C.order.Cf2D892C419Fea36": "minimalny zakup",
    "c2C.order.7290A6B7456A03E0": "największy zakup",
    "c2C.orderConfirm.F7E99B8C203E2B07": "Szczegóły zamówienia",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Zamówienie zostało wygenerowane",
    "c2C.orderConfirm.0F5433503F3845Ce": "Proszę zapłacić sprzedającemu w czasie odliczania。",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Oczekiwanie na wpłatę kupującego ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "skontaktuj się ze sprzedawcą",
    "c2C.orderConfirm.8E8140D7Ab607B25": "skontaktuj się z kupującym",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "Cyfrowa waluta sprzedającego została zdeponowana na rachunku depozytowym. Prosimy o dokonanie płatności  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Prawdziwe imię i nazwisko kupującego：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "Obsługa klienta 7x24 godziny ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "numer zamówienia",
    "c2C.orderConfirm.726C51702F70C486": "czas utworzenia",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Biuro obsługi klienta",
    "c2C.orderConfirm.103408Fbbe30287F": "Anuluj zamówienie",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Potwierdziłem płatność",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Już zapłacone",
    "c2C.orderConfirm.387Ca3625B260E4E": "Twoje opłacone zamówienie ",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "Anuluj transakcję",
    "c2C.orderConfirm.76C86566Cc3751C0": "Zamówienie anulowane",
    "c2C.orderConfirm.205B349751D176B4": "płatność pomyślna",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "metoda płatności",
    "c2C.paymentAdd.C87E865Df6800304": "Konfiguracja metody płatności",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Typ metody płatności",
    "c2C.paymentAdd.06910Bddf002695F": "rzeczywiste imię",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Konto paypal",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Numer rachunku bankowego/numer karty",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Nazwa waluty",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Zapłać kod QR",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Kliknij, aby przesłać",
    "c2C.paymentAdd.6Daae340F2E9718A": "Nazwa banku",
    "c2C.paymentAdd.347751415C3Da489": "Nazwa łańcucha",
    "c2C.paymentAdd.15B3627Faddccb1D": "Uwaga",
    "c2C.paymentAdd.86Da96Cbd274058F": "Ciepłe przypomnienie: gdy sprzedajesz walutę cyfrową, wybrana przez Ciebie metoda płatności zostanie wyświetlona kupującemu. Potwierdź, że informacje zostały wprowadzone prawidłowo.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "ratować",
    "c2C.paymentAdd.90D2A3A5C07179C0": "proszę wpisać swoje prawdziwe imię i nazwisko",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Proszę wpisać swoje",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Proszę podać nazwę swojego banku",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Proszę podać nazwę swojego łańcucha",
    "c2C.paymentAdd.94F034F0E01439E0": "Proszę wpisać swój adres",
    "c2C.paymentAdd.B12E035663A42B32": "Wszystkie karty bankowe",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "kolekcja walut",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Prześlij kod płatności",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Dodano pomyślnie",
    "c2C.paymentDetail.86Da96Cbd274058F": "Ciepłe przypomnienie: gdy sprzedajesz walutę cyfrową, wybrana przez Ciebie metoda płatności zostanie wyświetlona kupującemu. Potwierdź, że informacje zostały wprowadzone prawidłowo.",
    "c2C.paymentDetail.C4C613Da60226De4": "Pomyślnie zmodyfikowano",
    "c2C.paymentMethod.7609F7C8337C7A17": "Metoda płatności C2C",
    "c2C.records.9E36893D5736Abb4": "Lista zamówień",
    "c2C.records.4B53F9A5B13B3235": "Oczekująca płatność",
    "c2C.records.4B53F9A5B13B3236": "Anuluj transakcję",
    "c2C.records.4B53F9A5B13B3237": "Już zapłacone",
    "contract.index.9706Df877458F0Be": "Pełny magazyn",
    "contract.index.623Fc7D3D40Aa479": "Margines początkowy",
    "contract.index.F92Dce1169330387": "Cena rynkowa jest płaska",
    "contract.index.3598B97Dd28Dbf20": "Proszę podać ilość (szt.)",
    "contract.index.6E1Fae821Dc0B797": "Zrównoważony",
    "contract.index.Eb07Eb0C37C14575": "Dostawa",
    "contract.index.Eb6492F19B1C1Fb6": "Historyczne szczegóły transakcji",
    "contract.index.873Ade9A3Bacc2F7": "wskazówka",
    "contract.index.Ff63B0F93D39F50B": "Czy na pewno masz rację",
    "contract.index.Ff63B0F93D39F50D": "Idź długo",
    "contract.index.Ff63B0F93D39F50E": "krótki",
    "contract.index.Ff63B0F93D39F50F": "prowadzić",
    "contract.index.Ff63B0F93D39F50G": "Zamknij wszystkie pozycje？",
    "customer.dialog.3D410C88E8A24Fe8": "Potwierdź pseudonim",
    "customer.dialog.4B3340F7872B411F": "Anulować",
    "customer.dialog.549B1456Bb5F98B3": "Proszę wpisać pseudonim",
    "customer.dialog.B773B3E45Ccfad00": "Pseudonim jest pusty",
    "customer.index.7308C6B849D159E9": "Szczegóły obrazu",
    "delivery.callDialog.C74F562C3Fe91Fe3": "pomyślnie zamówione",
    "delivery.callDialog.B8C98F42Caa15Ca5": "kierunek",
    "delivery.callDialog.B79A581Dae543974": "długi zakup",
    "delivery.callDialog.B79A581Dae543975": "krótki zakup",
    "delivery.callDialog.3B26F0F7E90773F3": "Cena otwarcia",
    "delivery.callDialog.65Fb252Bd94E1039": "Szacowane przychody",
    "delivery.index.B483Ba4Fce633539": "Dostawa",
    "delivery.index.224Dd6Cb58D58249": "okres handlowy",
    "delivery.index.28158F517E902788": "Minimalne wpisowe",
    "delivery.index.A1232162B14Bec76": "Zamówienie dostawy",
    "delivery.index.445B1D3176D9C69D": "Zamówienia historyczne",
    "delivery.index.Ab6Cb86E28653C5C": "Dostawa",
    "delivery.index.20A7A6F14D3698C0": "Czas otwarcia",
    "delivery.index.Ec6821A49395Ddc2": "Czas zamknięcia",
    "delivery.index.254Fdc665C391743": "Cena zamykająca",
    "delivery.index.2A0C5E68B44A472A": "Zysk i strata",
    "delivery.index.E05B33309F6D236A": "Proszę wpisać ilość",
    "delivery.index.B2899E073Cb355D9": "Minimalny zakup",
    "download.description.869467A93C7Dd4A1": "Uwagi dotyczące instalacji",
    "download.description.C80C1Edd6Bee12Bc": "1.Zezwalaj na pobieranie plików opisu",
    "download.description.2F5Bc180F92642B4": "2.Pobrano plik z opisem",
    "download.description.3C3B849F6Ccf712F": "3.Wejdź w [Ustawienia] w swoim telefonie, a pobrany plik opisu zostanie wyświetlony poniżej informacji o koncie.",
    "download.description.86Affd862985E045": "4.Kliknij, aby pobrać plik opisu",
    "download.description.32832492Bfc1D95E": "5.Kliknij by zainstalować",
    "download.index.245883C80F181C4A": "pobierać",
    "download.index.Ef3De8Cb2722937A": "Rozpocznij handel w dowolnym miejscu i czasie",
    "download.index.F4A87Eaeed691638": "Bądź na bieżąco z najnowszymi wiadomościami za pośrednictwem naszej aplikacji",
    "download.index.Ad3Aba52D0D39D01": "Pobierz pakiet instalacyjny",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Miłe wskazówki",
    "download.safariGuide.2Cc68Aaadf623477": "Zapraszamy do odwiedzenia w Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Odwiedzasz zdecentralizowaną stronę internetową, uruchom ją w aplikacji Safari",
    "download.safariGuide.06Da016F3E2F79Bd": "skopiuj link",
    "finance.index.B034B367545D5236": "Globalne projekty wysokiej jakości",
    "finance.index.6Ebfac2C0A9228B0": "Ponad 1,6 miliona aktywnych użytkowników, 122,4 miliarda dolarów",
    "finance.index.52Bdcaf19659363D": "Wydobywanie płynności",
    "finance.index.D66924272B031Bf5": "Bardzo wysoki roczny dochód",
    "finance.index.34789D88Afcefa64": "Zobacz przedmioty",
    "finance.index.E12676B95654D21E": "pożyczka kredytowa",
    "finance.index.4C924Cfd89A11Ac7": "Odkryj więcej możliwości handlowych",
    "finance.index.C251D4B594Da2848": "Kwantyfikacja AI",
    "finance.index.4E53Cf2E5F173Dd3": "Leasing jednym kliknięciem, inteligentna transakcja AI",
    "finance.records.Cce168301447D1Ba": "w trakcie",
    "finance.records.4587B6Bd7Cfa4454": "odkupiony",
    "finance.records.18685418E3A50147": "Górnictwo",
    "finance.records.Df9Fd438F97Cc908": "niebo",
    "finance.records.E23Cb03C5Ad359E5": "termin",
    "finance.records.Bd87Dac6Cba7Eba8": "Zakupiona ilość",
    "finance.records.2D0Fff99265E8B1A": "Opłacalny",
    "finance.records.2D0Fff99265E8B1B": "Zysk w terminie zapadalności",
    "finance.records.024F5F0B851782De": "Czas wydobycia：",
    "finance.records.3143A26D3F983896": "czas odkupienia：",
    "finance.records.96574Aaf8Db85Ead": "wczesne odkupienie",
    "finance.records.531E1829203181Ac": "Czy na pewno chcesz skorzystać wcześniej?;",
    "finance.records.9205843B8923Dac3": "Wykup udany;",
    "loan.applyCallback.B9258Ec8746A74D8": "Kanał pożyczkowy",
    "loan.applyForm.7B8Ed340400A6E2B": "nazwa",
    "loan.applyForm.549A44Bae725F4A2": "nazwisko",
    "loan.applyForm.2A27E61375E7Fea8": "Data urodzenia",
    "loan.applyForm.89925B019D537738": "adres e-mail",
    "loan.applyForm.Afaeb32Cb4D62E28": "Adres domowy (wraz z numerem jednostki)",
    "loan.applyForm.E05B5D049B64B040": "Miasto",
    "loan.applyForm.B166E4E8Fe9513Fa": "naród",
    "loan.applyForm.83Aaede109E395F8": "kod pocztowy",
    "loan.applyForm.647Ed21332A4968B": "swój miesięczny dochód",
    "loan.applyForm.Cf0A9B845D34D4B4": "Dodatkowy dochód (opcjonalnie)",
    "loan.applyForm.7048F618Acc6A54C": "Miesięczny czynsz lub spłata kredytu hipotecznego",
    "loan.applyForm.15C1Bba18Ae18545": "Powiedz nam, czy masz kredyt hipoteczny",
    "loan.applyForm.3921E90625A84E36": "W przypadku braku wyboru gwarancji hipotecznej prawdopodobieństwo pozytywnego rozpatrzenia jest niskie.",
    "loan.applyForm.262Eec4F8906B0F6": "Czy jest kredyt hipoteczny?",
    "loan.applyForm.8634Eb21B6910F21": "Proszę wybrać rodzaj kredytu hipotecznego",
    "loan.applyForm.A4897B3A331B5E61": "Kupon transakcyjny",
    "loan.applyForm.6623384C683De457": "Bądźmy w kontakcie",
    "loan.applyForm.A8360Bdba86834Cb": "Możemy skontaktować się z Tobą pod tym numerem, jeśli będziemy potrzebować dalszych informacji na temat Twojego wniosku o pożyczkę",
    "loan.applyForm.C1C373F385A4C7B3": "numer telefonu",
    "loan.applyForm.1B9785Badb91191F": "Po uzupełnieniu informacji należy poczekać na ocenę i recenzję systemu.",
    "loan.applyForm.8755B9C761D4080C": "Przeczytałem i podpisałem elektronicznie moją zgodę",
    "loan.applyForm.3E987B787468148C": "Warunki korzystania i zgoda elektroniczna",
    "loan.applyForm.Bc54E76B61C50Fb9": "Przeczytaj i zaakceptuj umowę",
    "loan.applyForm.89E158384F634Eb8": "Wróć do poprzedniego kroku",
    "loan.applyForm.Efa5A4548E2F3510": "Adres domowy",
    "loan.applyForm.4Cadf1787541B35D": "Tak,",
    "loan.applyForm.Fab408D2F4100447": "NIE,",
    "loan.applyForm.3Bad7A307480Cc56": "Format wiadomości e-mail jest nieprawidłowy;",
    "loan.applyForm.306322F49B36855D": "Zgłoszenie zostało przesłane, proszę czekać na rozpatrzenie przez obsługę klienta",
    "loan.applyForm.3C6E0E858C995Ff9": "Proszę przesłać kupon transakcyjny",
    "loan.applyForm.2Bf443476B271376": "Można przesłać maksymalnie 4 zdjęcia",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Kanał pożyczkowy",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Uzyskaj limit kredytu",
    "loan.applyQuota.9E0780F9352C7273": "saldo portfela",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Całkowita dostępna kwota kredytu",
    "loan.applyQuota.033A05462195B080": "Pożyczona kwota",
    "loan.applyQuota.9Aa89B036Ef4821D": "Pozostałe saldo",
    "loan.applyQuota.5656Fb52Ef17A557": "Uzyskaj limit kredytu",
    "loan.applyQuota.6Ff6702B4C917D40": "w trakcie przeglądu",
    "loan.applyQuota.6Ff6702B4C917D41": "odrzucony",
    "loan.applyQuota.6Ff6702B4C917D42": "udało się",
    "loan.applyQuota.0Bad0E857Ea4A952": "czas aplikacji",
    "loan.applyQuota.3E7E56E2De2Cc546": "Ilość aplikacji",
    "loan.applyQuota.8F1D170D9E54Af5B": "Postęp aplikacji",
    "loan.applyQuota.6167Ca035B93Caf3": "powód",
    "loan.applyQuota.410C7954F9A3981D": "Zastosuj ponownie",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Proszę wybrać walutę",
    "loan.index.4C10307B22E885C3": "Odkryj więcej możliwości handlowych i twórz bogactwo",
    "loan.index.Ae8D37Dea2B3717B": "oprocentowanie",
    "loan.index.1Bb54625682E85Db": "Wprowadzenie do zasady",
    "loan.index.5Bd31Fab681C6E9D": "Zobacz więcej",
    "loan.index.111F95F404814327": "Waluta kredytu",
    "loan.index.6Bc2723B3Bec106D": "pożyczka",
    "loan.index.3200106280E6E370": "oprocentowanie",
    "loan.index.049A6Ccb6C81A635": "Kwota kredytu (min./maks.)",
    "loan.productDetails.5037Ad86Ab4B7E02": "pożyczka",
    "loan.productDetails.37181C74559Db182": "Zamówienie ",
    "loan.productDetails.5802Aaf06A1E4755": "Płynność",
    "loan.productDetails.89Aea9Cf9337C40F": "Dostępna ilość do wypożyczenia",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Ilość do spłaty",
    "loan.productDetails.C65F329Ef14C4A3D": "Ilość pożyczonych monet",
    "loan.productDetails.3A74667815Adc8Bf": "maksymalny",
    "loan.productDetails.Ab851D8351573227": "niebo",
    "loan.productDetails.Ddf82D8A2C8F46A0": "cykl spłaty",
    "loan.productDetails.C105E5562005Aeb1": "niebo",
    "loan.productDetails.3F346Be2137F0756": "Kwota kredytu (min./maks.)",
    "loan.productDetails.2707F78Df864E194": "Odsetki",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Prosimy o terminową spłatę.W przypadku opóźnienia zostanie naliczona dodatkowa dzienna kwota (kapitał + odsetki).",
    "loan.productDetails.B9Dc9B81E726Bdf2": "%za spóźnione opłaty",
    "loan.productDetails.4D94Faa033Be7356": "Zgodziłem się",
    "loan.productDetails.62429A957A22780A": "Umowa o świadczenie usług wypożyczania monet",
    "loan.productDetails.Bc54E76B61C50Fb9": "Przeczytaj i zaakceptuj umowę",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Pożycz teraz",
    "loan.productDetails.59246A0Bf081D398": "najmniej",
    "loan.productDetails.Ec3F1172B71Cd36C": "stawka minimalna",
    "loan.productDetails.7Ccc4Cf1D81930B3": "największa ilość",
    "loan.productDetails.071488598990Aa68": "Przeczytaj i zaakceptuj umowę;",
    "loan.productDetails.579Dd216D3339119": "Pożyczka udana;",
    "loan.records.4951B36Fd0D2A831": "Historia kredytów i pożyczek",
    "loan.records.Bb0Aaa30514B7Cba": "Aktualny zapis",
    "loan.records.0F8B0A7D80769051": "Historia spłat",
    "loan.records.D4B42E43D88De6F1": "rekord historii",
    "loan.records.Cb1847D6281897E6": "Kwota kredytu",
    "loan.records.C7Bfcacfc2Adea58": "Kwota spłaty",
    "loan.records.8B369164D5Fea340": "Czas pożyczki：",
    "loan.records.Ab2402Ad3Ffd35E7": "提前还贷",
    "loan.records.B7A0Ca0C195D0Cd1": "Czas zwolnienia：",
    "loan.records.42014Bdcfd9C411B": "Czas spłaty：",
    "loan.records.A0Acfaee54F06458": "zakończony",
    "loan.records.67A39834582Ad99A": "Brak danych",
    "loan.records.6A687Ff5B8F1598F": "Czy na pewno chcesz spłacić z góry?",
    "loan.records.7C20D7008D0Eae86": "Spłata pomyślna",
    "mining.bottomSheet.Ba91637D32Ccba04": "Wydobywanie płynności",
    "mining.bottomSheet.7De3D636A165E008": "stopa zwrotu",
    "mining.bottomSheet.F3003345B189D683": "Okres górniczy",
    "mining.bottomSheet.3E4C0A8C533F5848": "typ monety",
    "mining.bottomSheet.Adcba368993Ae65E": "Kwota abonamentu (minimum/maksimum)",
    "mining.bottomSheet.94743B24E4Ed4166": "Nieograniczony",
    "mining.bottomSheet.286944Fedd211257": "Dostępne fundusze",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Szacowane przychody:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Mój teraz",
    "mining.bottomSheet.Bb1493C567C114Dd": "Miłe wskazówki：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Opłata za wcześniejszy wykup",
    "mining.index.8B2Bba1422Eed6F0": "Twórz dziesiątki miliardów bogactwa, 100% dochodu netto",
    "mining.index.42997E300086Ee89": "najwyższy zwrot",
    "mining.index.510F74F42052Ecce": "Produkty górnicze",
    "mining.index.B853F96626106912": "Idź na górnictwo",
    "mining.productList.37181C74559Db182": "Zamówienie",
    "mining.productList.Aac5B810004C46C8": "regularny",
    "mining.productList.D2Fb4F78C387D888": "Udało Ci się wydobyć",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Potwierdzić zamówienie",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Kwota wynajmu",
    "quantify.bottomSheet.Fbd931286Be387Aa": "cykl",
    "quantify.bottomSheet.9Ad84Db481807844": "w sposób nieokreślony",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Dzienna stopa zwrotu",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "data waluty",
    "quantify.bottomSheet.88008603Ba7Bde64": "Zakup udany",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Od teraz uzyskaj udział w przychodach！",
    "quantify.bottomSheet.C778A674C4A054F9": "sprawdź zamówienie",
    "quantify.bottomSheet.685Dad322B7029B0": "Zwrot transakcji",
    "quantify.buyForm.Acf8560A04334E95": "Cena zakupu",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Przegląd",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "Termin wypłaty dywidendy",
    "quantify.buyForm.Fcda03Adbc5Fca97": "codziennie",
    "quantify.buyForm.Afb89504Fc399189": "Proszę podać zakupioną ilość",
    "quantify.buyForm.1435Afdfb4Ec0596": "Minimalna ilość zakupu",
    "quantify.buyForm.1B97F1730Bacd839": "Maksymalna ilość zakupu",
    "quantify.index.32F56C9B7D0E250A": "Okres: nieokreślony",
    "quantify.index.E34F028D00904630": "cena",
    "quantify.index.E34F028D00904631": ",Dzienna stopa zwrotu",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Kwota depozytu",
    "quantify.productDetail.53635B53Bca31Dfa": "Szacowany dzienny dochód",
    "quantify.productDetail.F9E46449A6269E09": "Skumulowany dochód",
    "quantify.productDetail.98Fc46B1B8C993B2": "Zamów hosting",
    "quantify.productDetail.93624Bb045830966": "Wynajmij teraz",
    "quantify.records.0C16A18C32B9Cf4A": "Zapis zamówienia",
    "quantify.records.B191F5614B0E2312": "Historia zakupów",
    "quantify.records.6A06033B86A73F7C": "zapis wykupu",
    "quantify.records.45E0F7E71611618E": "Skumulowany dochód",
    "quantify.records.45E0F7E71611618F": "Zysk w terminie zapadalności",
    "quantify.records.Aae1201E38C4Baaa": "Działanie",
    "flash.index.C41Fd4F2Fdfa594B": "konsumować",
    "flash.index.1Ac59Fa2218E3B00": "Dostępny:",
    "flash.index.Bf7233A660821549": "Dostawać",
    "flash.index.548E775Dd67C7472": "Potwierdź wymianę",
    "flash.index.066A2449Bd616F03": "Proszę wprowadzić ilość wykupu",
    "flash.index.Fca26Fcf9C06563F": "Odkupienie Flash powiodło się",
    "message.index.40031524233F4564": "Centrum wiadomości",
    "message.index.8E5586210Fd44D69": "Ciągłe przypomnienie o pozycji",
    "message.index.Bcd3F470E88C197D": "Nie ma jeszcze powiadomienia",
    "message.index.23A557Fbce545879": "Zawiadomienie o trwałej likwidacji",
    "message.index.B5E19B36B5125550": "Przypomnienie o wypłacie",
    "message.index.716A981465138334": "Przypomnienie o doładowaniu",
    "message.index.884474Ebff2977Ba": "informacje o systemie",
    "message.index.08Cc7D24752Ec42C": "serwis internetowy",
    "message.index.1D820A6A19Ab025D": "Ręczna obsługa online 7 x 24 godziny",
    "more.index.29881E0B5Df58Bf7": "Więcej funkcji",
    "more.index.C77Ee35911D10D9D": "Wspólne funkcje",
    "more.index.3558F69B0260Be61": "Funkcja handlowa",
    "more.index.7243Fe610Ed98Cec": "prawdziwe konto",
    "more.index.7243Fe610Ed98Ced": "konto demo",
    "more.index.693C7B35Bdf3E3A3": "finanse",
    "more.index.F855997Fac114554": "Centrum pomocy",
    "more.index.Ae9A1252278Da742": "pomoc",
    "more.index.Ace012504Bc529E0": "Informacja",
    "more.index.05682Dbef5Eeb0A8": "obsługa klienta",
    "more.index.C7Af227A5D5C7Bb9": "Przełączyłeś się na konto rzeczywiste",
    "more.index.D818A65592694786": "Przełączyłeś się na konto demo",
    "notice.details.B360807Dbe727E6E": "o nas",
    "notice.details.92F2552F695E70E3": "o nas",
    "notice.details.7Aaceaf69B67F3D1": "Wprowadzenie do zasady",
    "notice.index.6128438Be34Bcb9F": "Wieczyste powiadomienie",
    "notice.index.71627F5Eb7128261": "Powiadomienie o odstąpieniu",
    "notice.index.2Dce570Fda841705": "Powiadomienie o doładowaniu",
    "notice.index.228A7C9C6448A9B1": "powiadomienie systemowe",
    "setting.dialog.8B4F5B74660Deabe": "Konfiguracja kolorów",
    "setting.dialog.Bfc7D25486Ebc1A9": "Zielony rośnie, a czerwony opada",
    "setting.dialog.796Ea0E1A82C320B": "Czerwień wznosi się, a zieleń opada",
    "setting.index.8Ddc5864E1739466": "Preferencje",
    "setting.index.F5205801D57D6727": "język",
    "setting.index.2E2C12Dd0Cc1B63B": "Waluta notowań",
    "setting.index.322D8496Aec3D918": "numer wersji",
    "setting.index.Aa7E6C204C751Cfa": "Wyloguj się",
    "share.index.75Bc38Bbf7D2E766": "Wyloguj się",
    "share.index.45575Eb5D42213E6": "mój kod zaproszenia",
    "share.index.29F67F0644A78Bf3": "Kopiuj",
    "share.index.19Cc65Bb6Bb98Fe7": "Mój link z zaproszeniem",
    "share.index.5372712F162Fb78B": "Liczba bezpośrednich podwładnych",
    "share.index.C88D6B95E3Ab7D6F": "Całkowita liczba podwładnych",
    "share.index.Bb5Fa5471Aeecc62": "Rabat prowizyjny dla bezpośrednich podwładnych",
    "share.index.426B6Bac9A5F3D4F": "całkowity rabat",
    "share.index.197E4A428F0401C9": "Wyświetl rekordy rabatów",
    "share.records.88A6B2E2B74B13C4": "Protokół Komisji",
    "share.records.B671A35Ffdc93B89": "Przylga",
    "transfer.index.B17617A869C03Ca5": "portfel",
    "transfer.index.E4116Dcc6F6C7270": "Transfer udany",
    "transfer.index.48Fb603C6638C7F6": "Proszę ustawić hasło funduszu",
    "transfer.records.Eb0E39Eb5911D418": "Dostawać",
    "transfer.records.977554Be0Aa65052": "Czas realizacji Flash：",
    "transfer.records.9Aa33884Aa400B7C": "czas transferu：",
    "form1.forget.76636Db8Fae5B124": "swoją skrzynkę pocztową",
    "form1.forget.35354B9Cca1Bb0E6": "Kod weryfikacyjny poczty elektronicznej",
    "form1.forget.604A0B11E916F387": "hasło",
    "form1.forget.9Cb1C9C51E7503D6": "Potwierdź hasło",
    "form1.forget.3A4F7E04B008453C": "Twój numer telefonu",
    "form1.forget.Cf11Dce5B4713B5A": "Kod weryfikacyjny telefonu komórkowego",
    "form1.forget.3012Edba57D4F297": "Proszę podać swój adres e-mail",
    "form1.forget.F1Ee1D97E869E447": "proszę wpisać kod weryfikacyjny",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Wprowadź hasło",
    "form1.forget.C6Cb174Da411D5F7": "Proszę podać swój telefon komórkowy",
    "form1.forget.947B3B21Ee45E582": "wysłać",
    "form1.forget.3E0580F1E8630Df6": "Dwa hasła są niespójne",
    "form1.forget.C5A61836B8Cc74C6": "Zmiana hasła została pomyślnie przekierowana...",
    "form1.forget.B96D072Bc8962565": "Wysłać ponownie",
    "form1.login.2B72C27Ff828Cfc4": "Twoje hasło",
    "form1.login.D7C6B872Af9Cb17F": "Pamiętaj moje hasło",
    "form1.login.0Fff44C827A4F3B6": "Zaloguj sie",
    "form1.login.1B7F2C1Ede3990Cc": "Nie masz jeszcze konta? Zapisz się teraz",
    "form1.login.F657F7Be1Cc33Bd2": "zapomnij hasła",
    "form1.login.2074A1Eec4B088B0": "Logowanie do portfela",
    "form1.login.1B8938Fbcc3C9B56": "Logowanie powiodło się i następuje przekierowanie...",
    "form1.login.07162A7Bb9310555": "Zainstaluj portfel ERC20",
    "form1.login.766969966Ae151Dc": "Zainstaluj portfel TRC20",
    "form1.login.B88171E1Ad4C50Bf": "Odmówiłeś zmiany sieci",
    "form1.register.Aaef0710Cb4Db554": "Wprowadź hasło ponownie",
    "form1.register.54Dfea0415C708Ff": "Kod zaproszenia",
    "form1.register.6839940Dac08C794": "rejestr",
    "form1.register.9901A3B5550Aa58E": "Posiadasz już konto? Zaloguj sie",
    "form1.register.2548Ca53785252Ea": "Proszę wpisać kod zaproszenia",
    "form1.register.Dd71Fd65900Dd03E": "Prosimy o zapoznanie się z Umową o świadczenie usług rejestracyjnych i wyrażenie zgody,",
    "form1.register.7975613909490B77": "Rejestracja powiodła się i przekierowanie...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Wybierz portfel",
    "userc.certification.2Af9511640153D2E": "Certyfikacja Juniora",
    "userc.certification.177B191D8E0Fa84D": "nie certyfikowane",
    "userc.certification.601218D4D572E98A": "zweryfikowane",
    "userc.certification.6E37C51Ba9012Fc8": "Nie przeszedł",
    "userc.certification.1B9B563E05C96474": "Zaawansowana certyfikacja",
    "userc.certification.1418B83803Ab56B4": "Najpierw wypełnij podstawowy certyfikat",
    "userc.creditScore.0B149112601Acc4D": "Ocena kredytowa",
    "userc.creditScore.88Ac7F84E106C5F6": "całkowity wynik kredytowy",
    "userc.creditScore.3E1D6E0E7F1E6155": "Ocena systemu",
    "userc.index.62Eac58D04Bbca0F": "Centrum osobiste",
    "userc.index.F4Cac53F93Cb2Aff": "symulowany handel",
    "userc.index.42F409Fdf3559773": "Centrum Bezpieczeństwa",
    "userc.index.1B8E211009807Db8": "Uwierzytelnianie",
    "userc.index.Bdfcf21Eb8F2Eb70": "Zapisy zmian konta",
    "userc.index.6C18D67F6D19586B": "Wprowadzenie do platformy",
    "userc.index.807A814Ed58A93Bc": "Wyczyść pamięć podręczną",
    "userc.index.E43Daad59759611C": "nie certyfikowane",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Zmień hasło logowania",
    "userc.secureCenter.1F37Ba427Debf24A": "Powiązanie konta",
    "userc.secureCenter.F9A5Ffa750664004": "Powiąż Google Authenticator",
    "userb.certificationForm.7685Af7D30043Cd6": "Certyfikacja Juniora",
    "userb.certificationForm.7685Af7D30043Cd7": "Zaawansowana certyfikacja",
    "userb.certificationForm.70E37C35Abc3F462": "Kraj",
    "userb.certificationForm.F92C25A68E4D08C5": "Miasto",
    "userb.certificationForm.0Ccc6387B38E0318": "Twoje imię",
    "userb.certificationForm.94C67Bba370Fdd46": "numer licencji",
    "userb.certificationForm.B2D4C7Fffe79258E": "Poczta",
    "userb.certificationForm.57554268761Fb47A": "Prześlij przód swojego dokumentu tożsamości",
    "userb.certificationForm.D79E90201F5319Bd": "Prześlij tył swojego dokumentu tożsamości",
    "userb.certificationForm.9C6Bad08Af29E20E": "Prześlij zdjęcie swojego dowodu osobistego",
    "userb.certificationForm.F96B62Dfa31Cde45": "Potwierdź przesłanie",
    "userb.certificationForm.2125D78Ea8C6D287": "Wymagania dotyczące przesyłania",
    "userb.certificationForm.0D64864D3076A824": "Zobacz przykład",
    "userb.certificationForm.35287Deb72281E87": "1.Musi być w stanie wyraźnie odczytać informacje z dowodu osobistego.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.Treść zdjęcia jest prawdziwa i aktualna i nie jest dozwolona żadna modyfikacja.",
    "userb.certificationForm.25A90660554319F9": "3.Numer identyfikacyjny i nazwa muszą być wyraźnie widoczne, obsługują JPG / JPEG / PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "Podaj",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Przesyłanie zostało pomyślnie przesłane. Proszę czekać na sprawdzenie",
    "userb.certificationForm.744Bd07D8Abd2513": "Prześlij przód swojego dokumentu tożsamości",
    "userb.certificationForm.6De1C319130F7Fef": "Prześlij tył swojego dokumentu tożsamości",
    "userb.certificationForm.79F8E8B172E30C8A": "Prosimy o przesłanie zdjęcia dowodu osobistego",
    "userb.certificationForm.46412E16D28A4753": "Przesyłanie zdjęcia nie powiodło się",
    "userb.changeDialog.676B0Adca1F8Eea4": "Zmień hasło",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "numer telefonu",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "przełącznik walidacyjny",
    "userb.modifyInfoDialog.62149E566814154C": "Wybierz walidator",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Otwórz aplikację Uwierzytelnianie i dotknij +",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "Wybierz „Zeskanuj kod QR” lub „Wprowadź klucz”",
    "userb.modifyInfoDialog.93F094E2B4369472": "Zeskanuj kod QR lub wprowadź klucz",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "zamknięte otwarte",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Stare hasło do funduszu",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Nowe hasło funduszu",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Potwierdź nowe hasło do funduszu",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Potwierdź hasło funduszu",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Proszę podać swoje stare hasło do funduszu",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Proszę podać nowe hasło do funduszu",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Proszę podać hasło do funduszu",
    "userb.modifyInfoDialog.258A534498Fe984F": "Proszę potwierdzić hasło do funduszu",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Powiązanie konta",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Hasło funduszu",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "potwierdzać",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Walidator jest zamknięty",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Weryfikacja Google została pomyślnie włączona",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Hasło funduszu zostało pomyślnie zmodyfikowane",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Ustaw hasło funduszu pomyślnie",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Wiązanie powiodło się",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Błąd kodu weryfikacyjnego",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Przykład przesyłania certyfikatu",
    "userb.veBottomSheet.6F38A0D5858633Ee": "ciemne, czyste tło",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Wystarczające oświetlenie",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Wyrównanie ramy pomocniczej",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Zdjęcia są wyraźne",
    "userb.veBottomSheet.50B741382E71F103": "Możliwe przyczyny niepowodzeń",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "Osłona zabezpieczająca dowód osobisty nie została usunięta",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "Tło jest dotknięte bałaganem",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "Dowód osobisty jest odblaskowy",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "Światło jest zbyt ciemne/przesłonięte przez cień telefonu komórkowego",
    "userb.veBottomSheet.1A75D2Fc2614592B": "trzyma dowód osobisty",
    "home.home.C3525Eb110Db58Aa": "Więcej",
    "home.home.29761532Fc8Ebecc": "handel",
    "home.home.F15Ea99Bfa6E1848": "Certyfikat bezpieczeństwa, kupuj bez obaw",
    "home.home.46E1Ab39Bae7182A": "Szybki",
    "home.home.Fde2Eb7F6F3Dd800": "Szybkie transakcje i prosta obsługa",
    "home.home.Ea4756Bc1642E0F1": "nazwa",
    "home.home.B6B1127Ede1C97B4": "ostatnia cena",
    "home.home.3587Ad870Eb0Ab86": "24H wzrost i spadek",
    "home.home.Cb1A4E14Ec2Cd2B5": "Odkryć",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "Metal",
    "home.home.5Fc1474Dbf74Ac1A": "Agro",
    "home.home.135318F4664F7773": "energia",
    "kline.kLine.C96Ff250Cf6B8B84": "Najwyższa cena 24h",
    "kline.kLine.6E8Be64F19D74565": "Wolumen obrotu 24h",
    "kline.kLine.08A4Bfbe26A0F262": "Najniższa cena 24h",
    "kline.kLine.8701Deb075066331": "Wolumen obrotu 24h (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 punkt",
    "kline.kLine.B49Cca240E013124": "5 punktów",
    "kline.kLine.F27A90Be97Ba3D6C": "15 marek",
    "kline.kLine.Ff3E62395B694384": "30 punktów",
    "kline.kLine.C1993F9C6Db1A674": "1 godzina",
    "kline.kLine.Fd2Cbbae405E284C": "1 dzień",
    "kline.kLine.C9Cd71904395042C": "Styczeń",
    "kline.kLine.7D8127Ec18C7B8C7": "Kupić",
    "kline.kLine.E3D3Ffdb9B3E5636": "Ilość (sztuki)",
    "kline.kLine.6834Fec1838D8029": "Cena (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "zlecenie sprzedaży",
    "kline.kLine.B9Bea1A9A88D1419": "Idź długo",
    "kline.kLine.D36Cfa96Ee2B343B": "Idź krótki",
    "other.languageRateSelect.6897784800834C1B": "ustawienie języka",
    "other.languageRateSelect.6897784800834C1C": "Proszę wybrać walutę cenową",
    "other.navigationBar.F6E62F9642018Cbb": "pierwsza strona",
    "other.navigationBar.0Ec227161A47B49D": "cytaty",
    "other.navigationBar.29761532Fc8Ebecc": "handel",
    "other.navigationBar.693C7B35Bdf3E3A3": "finanse",
    "other.navigationBar.9734C54620Eb09Af": "aktywa",

    "home.index.25Aa4Fc745150030": "The King of Cryptocurrency",
    "home.index.2D6Ecf8Faaa8B124": "Faster, better, stronger",
    "home.index.1Db26267D1041223": "Easily trade over 1700 cryptocurrencies",
    "home.index.F8E18C90C3E35618": "Register now",
    "home.index.29761532Fc8Ebecc": "Transaction",
    "home.index.D8A95E4506487452": "Popular Contracts",
    "home.index.5Bd31Fab681C6E9D": "View more",
    "home.index.010Ae07A09Caf221": "Trading pair",
    "home.index.F4D875118E6D6Dbb": "Latest transaction price",
    "home.index.3Dfdfb3D6599100E": "24-hour increase or decrease",
    "home.index.28Fb39Bd41E54242": "Trend Chart",
    "home.index.B47A3911Bacd94C6": "Growth List",
    "home.index.712910D574F43D95": "Stable income",
    "home.index.5507Fd830D7Bf51F": "Top strategy, easy to copy",
    "home.index.7De3D636A165E008": "yield",
    "home.index.B853F96626106912": "Go mining",
    "home.index.213B5C96Be7Cd1F8": "Days, annualized interest rate",
    "home.index.213B5C96Be7Cd1F9": "Maximum purchase",
    "home.index.Dc710Cffe6313Bb5": "View details",
    "home.index.B92Ffc837Ecaa1Dd": "News",
    "home.index.6C18D67F6D19586B": "Platform introduction",
    "home.index.6342E3C33Cb48Cd5": "Digital currency is a medium of exchange that uses cryptographic principles to ensure transaction security. Unlike fiat currencies such as the U.S. dollar, digital currencies do not have a physical form. Bitcoin (BTC), Ethereum (ETH) and Polkadot Popular digital currencies such as (DOT) use blockchain as the underlying technology and act as a decentralized digital ledger. All digital currency transactions are recorded in the blockchain and cannot be changed once confirmed and verified. Unlike traditional currencies, which are maintained by banks Unlike centralized ledgers, digital currency transactions occur on a public blockchain and can be accessed by anyone.",
    "home.index.198Efc4254683Eef": "In addition, according to the consensus mechanism, everyone can verify digital currency transactions and add them to the blockchain, thereby achieving decentralization. With Samsung, BlackRock, Morgan Stanley Leading global companies such as Alphabet and Alphabet have invested in it, and people believe that blockchain technology will completely change the global financial system. Digital currency opens the door to the world of decentralized finance for you, allowing you to explore the infinite possibilities brought by cutting-edge technology .",
    "home.index.E710339D9576292E": "Our advantages",
    "home.index.F9Ce8B2C63885B26": "Safe and stable",
    "home.index.C4D0A2C9Ff0305Ce": "Top technical team, comprehensive security protection, independent research and development of high-speed transaction synthesis engine, which can still operate stably and reliably under massive concurrent transactions.",
    "home.index.2E8708Ee3F11Cc1A": "Professional and reliable",
    "home.index.Bb12Cbcf89C56C5C": "Professional operation team, many years of experience in blockchain and financial industry, holding a compliant digital asset trading license, and 100% reserve guarantee.",
    "home.index.Aa4723Bfbf6808F4": "Service with heart",
    "home.index.1Bf0204B7D3Ee230": "Facing the global market, supporting multiple languages, running 24/7, strong community support, and professional customer service.",
    "home.index.2E7Dfd53D26F7224": "High performance",
    "home.index.1C65453103B5Fcc3": "300,000 transactions per second, order response time is less than 1 millisecond.",
    "home.index.85Ac49Ece9Dcc80B": "Trade anytime, anywhere!",
    "home.index.2799E18842E4Ebe9": "Instant position opening, supported by OKEX App and web page!",
    "home.index.2Bf52Ce75E29Fc88": "Scan the code to download now",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple",
    "home.index.04Ee893775563F22": "Products and Services",
    "home.index.04Ee893775563F23": "Financial Management",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "Kup Bitcoina, Ethereum oraz",
	"homepage.text1_2": "ponad 1000 innych kryptowalut",
	"homepage.text2": "Twoja podróż przez świat kryptowalut",
	"homepage.text4": "Wysokooprocentowane zarządzanie finansowe",
	"homepage.text5": "Stabilne zarządzanie finansowe, wysokie roczne zwroty",
	"homepage.text6": "Dzielenie się nagrodami",
	"homepage.text7": "Szybkie transakcje, proste operacje",
	"homepage.text8": "Zobacz więcej",
	"homepage.text9": "Natychmiastowa wymiana",
	"homepage.text10": "Bezpieczna autoryzacja, bez obaw o zakupy",
	"homepage.text11": "Przywództwo w technologii, kompleksowe bezpieczeństwo",
	"homepage.text12": "Ochrona, niezależność",
	"homepage.text13": "Profesjonalne i niezawodne",
	"homepage.text14": "Profesjonalny zespół zarządzający, wieloletnie doświadczenie w blockchain i finansach",
	"homepage.text15": "Dedykowana obsługa",
	"homepage.text16": "Globalnie zorientowany rynek, wielojęzyczne wsparcie, działanie przez całą dobę",
	"homepage.text17": "Wysoka wydajność",
	"homepage.text18": "Przetwarzanie 300 000 transakcji na sekundę, czas odpowiedzi zamówienia poniżej 1 milisekundy",
}
