<template>
  <div class="header-page ">
    <div class="pc-page">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center header-left">
          <img
            :src="getConfigData.pcLogo"
            @click="$router.replace('/')"
            height="40"
            alt=""
          />
          <!-- <img
            src="../../public/image/home/logo.png"
            @click="$router.replace('/')"
            height="40"
            alt=""
          /> -->
          <div class="menu-list d-flex">
            <div
              class="item"
              :class="routePath == '/' && 'active'"
              @click="$router.replace('/')"
            >
              {{ $vuetify.lang.t("$vuetify.public.home") }}
            </div>
            <div
              class="item"
              :class="routePath == '/delivery' && 'active'"
              @click="$router.replace('/delivery')"
            >
              {{ $vuetify.lang.t("$vuetify.setting.dreivatives") }}
            </div>
            <div
              class="item"
              :class="routePath == '/contract' && 'active'"
              @click="$router.replace('/contract')"
            >
              {{ $vuetify.lang.t("$vuetify.standardContract.contractTransaction") }}
            </div>
            <div
              class="item"
              :class="routePath == '/assets' && 'active'"
              @click="$router.replace('/assets')"
            >
              {{ $vuetify.lang.t("$vuetify.setting.assets") }}
            </div>
            <div
              class="item"
              v-if="openMining"
              :class="routePath == '/invest' && 'active'"
              @click="$router.replace('/invest')"
            >
              {{ $vuetify.lang.t("$vuetify.setting.liquidity") }}
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="user-box is-not-login" v-if="!getIsLogin" @click="$router.push('/login')">
            {{ $vuetify.lang.t("$vuetify.login.login") }}
            <!-- <div class="avatar-box d-flex align-center justify-center">
              <v-icon color="#555A64" large>mdi-account</v-icon>
            </div>
            <div class="d-flex ml-4">
              <div
                class="font-primary-14 cursor-pointer"
                @click="$router.push('/login')"
              >
                {{ $vuetify.lang.t("$vuetify.login.login") }}
              </div>
              <div class="font-primary-14 ml-2 mr-2">/</div>
              <div
                class="font-primary-14 cursor-pointer"
                @click="$router.push('/register')"
              >
                {{ $vuetify.lang.t("$vuetify.login.register") }}
              </div>
            </div> -->
          </div>
          <div
            class="user-box d-flex align-center cursor-pointer"
            @click="$router.push('/user')"
            v-else
          >
            <v-avatar color="#0a66ff" size="40">
              <img v-if="avatar.type == 1" :src="avatar.content" alt="" />
              <span v-else>{{ avatar.content }}</span>
            </v-avatar>

            <div class="d-flex align-center ml-4">
              <p class="font-primary-14 mb-0">
                {{ avatar.userName.slice(0, 6) + "..." }}
              </p>
              <v-icon>mdi-menu-down</v-icon>
            </div>
          </div>
          <v-select
            style="width: 140px"
            background-color="rgba(10, 102, 255, 0.1)"
            class="ml-4"
            light
            :items="getLanguageList"
            v-model="selectLang"
            @change="changeLang"
            item-text="text"
            item-value="key"
            :height="50"
            hide-details
            solo
          ></v-select>
        </div>
      </div>
    </div>
    <div class="mobile-page">
      <div class="d-flex justify-space-between align-center">
        <v-icon @click="showSidebar = true">mdi-menu</v-icon>
        <div class="user-box is-not-login d-flex align-center" v-if="!getIsLogin" @click="$router.push('/login')">
          {{ $vuetify.lang.t("$vuetify.login.login") }}
          <!-- <div class="avatar-box d-flex align-center justify-center">
            <v-icon color="#555A64" large>mdi-account</v-icon>
          </div>
          <div class="d-flex ml-4">
            <div
              class="font-primary-14 cursor-pointer"
              @click="$router.push('/login')"
            >
              {{ $vuetify.lang.t("$vuetify.login.login") }}
            </div>
            <div class="font-primary-14 ml-2 mr-2">/</div>
            <div
              class="font-primary-14 cursor-pointer"
              @click="$router.push('/register')"
            >
              {{ $vuetify.lang.t("$vuetify.login.register") }}
            </div>
          </div> -->
        </div>
        <div
          class="user-box d-flex align-center cursor-pointer"
          @click="$router.push('/user')"
          v-else
        >
          <v-avatar color="#0a66ff" size="30">
            <img v-if="avatar.type == 1" :src="avatar.content" alt="" />
            <span v-else>{{ avatar.content }}</span>
          </v-avatar>

          <div class="d-flex align-center ml-4">
            <p class="font-primary-14 mb-0">
              {{ avatar.userName.slice(0, 6) + "..." }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <Sidebar :value.sync="showSidebar" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { setCookie } from "@/utils/cookie";
import Sidebar from "./Sidebar.vue"
export default {
  data() {
    return {
      languageList: ["EN"],
      languageObj: {
        SIMPLIFIED_CHINESE: "简体中文",
        ENGLISH: "ENGLISH",
        TRADITIONAL_CHINESE: "繁體中文",
      },
      selectLang: "",
      showSidebar:false,
    };
  },
  components:{
    Sidebar
  },
  computed: {
    ...mapGetters([
      "getIsLogin",
      "getUserInfo",
      "getLanguageList",
      "getConfigData",
    ]),
    openMining() {
      return this.getConfigData.openMining;
    },
    avatar() {
      if (Object.keys(this.getUserInfo).length == 0) {
        return;
      }
      let nickName =
        this.getUserInfo.nickName ||
        this.getUserInfo.email ||
        this.getUserInfo.phone ||
        this.getUserInfo.address;
      let userName =
        this.getUserInfo.email ||
        this.getUserInfo.phone ||
        this.getUserInfo.address;
      if (this.getUserInfo.headerImg) {
        return {
          type: 1,
          content: this.getUserInfo.headerImg,
          userName,
        };
      }

      return {
        type: 2,
        content: nickName.slice(0, 1),
        userName,
      };
    },
    routePath() {
      return this.$route.path;
    },
  },
  created() {
    this.selectLang = localStorage.getItem("lang") || "ENGLISH";
  },
  methods: {
    ...mapMutations(["setLanguage"]),
    changeLang() {
      setCookie("lang", this.selectLang);
      this.setLanguage(this.selectLang);
      localStorage.setItem("lang", this.selectLang);
      this.reloadLangs();
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__title {
  font-size: 14px !important;
}
::v-deep .v-input__slot {
  box-shadow: none !important;
}
@media (max-width:1000px) {
  .pc-page {
    display: none;
  }
  .mobile-page {
    display: block;
  }
}
@media (min-width:1000px) {
  .pc-page {
    display: block;
  }
  .mobile-page {
    display: none;
  }
}
.header-page {
  //   height: 60px;
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;

  background-color: #fff;
  ::v-deep .v-input__slot {
    box-shadow: none;
    background: transparent !important;
    border-radius: 50px;
    border: 1px solid #23252c;
    padding: 0 18px !important;
  }
  .menu-list {
    // width: 30vw;
    // margin-left: 120px;
    .item {
      //   flex: 1;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;

      &.active {
        color: #0A66FF;;
        font-weight: 600;
      }
    }
  }
  .user-box {
    min-width: 114px;
    font-size: 14px;
    line-height: 48px;
    text-align: center;
    height: 48px;
    
    border-radius: 24px 24px 24px 24px;
    color: #fff;
    cursor: pointer;
    &.is-not-login {
      background: #0A66FF;
    }
    // .avatar-box {
    //   width: 50px;
    //   height: 50px;
    //   //   background: #999999;
    //   border-radius: 25px 25px 25px 25px;
    //   border: 1px solid #23252c;
    // }
  }
}

@media (max-width: 1500px) {
  .header-page {
    padding: 25px 20px;
  }
  .header-left {
    img {
      margin-right: 30px;
    }
  }
  .user-box {
    // margin-right: 22px;
    text-align:center;
    justify-content: center;
  }
  .menu-list {
    .item {
      margin-right: 70px;
    }
  }
}
@media (min-width: 1500px) {
  .header-page {
    padding: 25px 60px;
  }
  .header-left {
    img {
      margin-right: 5vw;
    }
  }
  .user-box {
    margin-right: 48px;
  }
  .menu-list {
    .item {
      margin-right: 70px;
    }
  }
}
</style>