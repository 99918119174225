import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Chinois simplifié",
        dialogDetermine: "Bien sûr",
        dreivatives: "Livraison",
        markets: "Citations",
        assets: "actifs",
        liquidity: "Extraction de liquidité",
        helpCentre: "Centre d'aide",
        announcementCenter: "Centre d'annonces",
        about: "à propos",
        bulletinCentre: "Centre d'annonces",
        serviceAgreement: "Contrat de service",
        support: "soutien",
        onlineSupportCustomerService: "un service en ligne",
        suggestionsAndFeedback: "Suggestions et commentaires",
        service: "Servir",
        download: "télécharger",
        coinApplication: "Application de cotation de pièces",
        privacyStatement: "Déclaration de confidentialité",
        trade: "négociation de contrats",
        standardConteact: "Durable",
        signOut: "Durable",
        assetWallets: "Portefeuille d'actifs",
        financeRecords: "Enregistrements de modifications de compte",
        identification: "Authentification",
        personalCenter: "Centre personnel",
        chooseLanguage: "Choisissez une langue",
        tips: "indice",
        dark: "mode sombre",
        light: "mode jour",
        languageTitle: "langue",
        convertCurrency: "Convertir la devise",
        cancel: "Annuler",
        aboutUs: "à propos de nous",
        standard: "Continuité de la norme",
        share: "partager",
        introduce: "Présentation de la plateforme",
        set: "installation",
        changePassword: "Changer le mot de passe de connexion",
        denomination: "Devise de cotation",
        color: "Configuration des couleurs",
        code: "numéro de version",
        color1: "Le rouge monte et le vert descend",
        color2: "Le vert monte et le rouge descend",
        setLangue: "Paramètres de langue",
        vip: "Avantages VIP",
        vip2: "Niveau VIP actuel",
        safeMenu: "Centre de sécurité",
        conventional: "conventionnel",
        other: "autre",
        quickTips: "Ajoutez jusqu'à 3 boutons de fonction de raccourci",
        quickTransaction: "Transaction rapide",
        safeConvenient: "Sûr et pratique",
        timeSharing: "partage de temps",
        currentLevel: "niveau actuel",
        levelBenefits: "Revenu de niveau %{level}",
        teamLevel: "Niveau d'équipe",
        levelLower: "%{level}subordonné",
        rules: "Description de la règle",
        bindWithdrawalAddress: "Lier l'adresse de retrait",
        tipsBindWithdrawalAddress: "Vous n'avez pas encore lié votre adresse de retrait, veuillez d'abord la lier",
        walletAddressFormatIncorrect: "Le format de l'adresse du portefeuille est incorrect",
        tradeRules1: "Prédisez la hausse et la baisse dans la période future. Le prix au moment de la commande est utilisé comme prix de départ, et le prix à la dernière seconde de la période de prévision est utilisé comme prix de règlement. Si le prix de règlement est supérieur au prix de départ, cela signifie une augmentation, et si le prix de règlement est supérieur au prix de départ, cela signifie une augmentation, et si le prix de règlement est supérieur au prix de départ, cela signifie une augmentation, et si le le prix de règlement est inférieur au prix de départ, cela signifie une diminution.",
        tradeRules2: "1.qu'est-ce qu'un contrat",
        tradeRules3: "Cette plate-forme propose des contrats perpétuels basés sur l'USDT. Les utilisateurs peuvent juger de la hausse ou de la baisse et choisir d'acheter des contrats longs ou de vendre des contrats courts pour tirer profit de la hausse/baisse des prix des actifs numériques. Les contrats perpétuels n'ont pas de date de livraison et les utilisateurs peuvent les conserver pour toujours.",
        tradeRules4: "2.Qu'est-ce que la marge",
        tradeRules5: "Sur le marché des contrats virtuels, les utilisateurs n'ont qu'à payer une petite somme d'argent dans une certaine proportion en fonction du prix du contrat comme garantie financière pour l'exécution du contrat, puis ils peuvent participer à l'achat et à la vente du contrat. Ce type de fonds est le dépôt contractuel virtuel. \nLes contrats sur cette plateforme adoptent le modèle de marge séparée des comptes séparés. Chaque devise correspond à un compte de contrats. Les actifs et positions des comptes des différentes devises du contrat sont indépendants les uns des autres, et les transferts et transactions entre différents comptes de contrats ne s'influencent pas mutuellement. Après l'ouverture du compte contractuel d'un utilisateur, les risques et les avantages de toutes les positions détenues dans le compte contractuel seront calculés ensemble.",
        tradeRules6: "3.Comment calculer la marge",
        tradeRules7: "Marge de position = valeur nominale du contrat * nombre de contrats ouverts / multiplicateur de levier\nmarge gelée = la somme des marges de tous les ordres passés avec succès et des ordres d'ouverture dans la commande en cours\nmarge disponible = solde du compte - marge de position - marge gelée",
        tradeRules8: "4.Comment calculer le taux de marge",
        tradeRules9: "Le taux de marge est une mesure du risque lié aux actifs des utilisateurs. \nTaux de marge = (Profits et pertes latents + Marge de position + Marge disponible + Marge de gel - Commission de liquidation) / Marge de position \nPlus le taux de marge est faible, plus le risque du compte est élevé. Lorsque le taux de marge est inférieur ou égal à 0%, une liquidation forcée sera déclenchée.",
        tradeRules10: "5.Quel est le prix de parité forcée estimé ?",
        tradeRules11: "Le prix de liquidation forcée estimé, c'est-à-dire le prix calculé par le système qui déclenche théoriquement la liquidation forcée, est donné à titre indicatif uniquement et peut s'écarter légèrement de la valeur réelle.",
        tradeRules12: "6.Qu’est-ce que le total des capitaux propres du compte et les profits et pertes non réalisés ?",
        tradeRules13: "Total des capitaux propres du compte = solde du compte + profits et pertes non réalisés.\nLes profits et pertes non réalisés correspondent aux profits et pertes de la position actuellement détenue par l'utilisateur dans le contrat de change. Les profits et pertes non réalisés changeront en fonction des dernières modifications du prix de transaction. \nProfits et pertes non réalisés pour les positions longues = (1/prix moyen de la position - 1/dernier prix de transaction) * nombre de contrats pour les positions longues * valeur nominale du contrat * dernier prix de transaction \n profits et pertes non réalisés pour les positions courtes = (1/ dernier prix de transaction - 1/ Prix moyen de la position) * Nombre de contrats courts * Valeur nominale du contrat * Dernier prix de transaction",
        text1: "Sécurité du compte",
        text2: "mode jour et nuit",
        text3: "vider le cache",
        text4: "Êtes-vous sûr de vouloir vous déconnecter?",
        text5: "Êtes-vous sûr de vouloir vider le cache ?",
        text6: "multilingue",
        text7: "Pour protéger votre sécurité, nous vous recommandons d'activer au moins une authentification à deux facteurs"
    },
    login: {
        login: "Se connecter",
        register: "registre",
        title1: "Négociez à tout moment, n'importe où et consultez les conditions du marché en temps réel",
        title2: "Négociez à tout moment, n'importe où et consultez les conditions du marché en temps réel",
        title3: "La plateforme de trading de crypto-monnaie la plus fiable",
        formTitle1: "Connexion au compte",
        formTitle2: "Content de te revoir! Connectez-vous avec votre email, votre numéro de téléphone",
        btn1: "téléphone",
        btn2: "Mail",
        btn3: "Se connecter",
        btn4: "registre",
        btn5: "oublié le mot de passe ?",
        field1: "Numéro de téléphone",
        field2: "mot de passe",
        field3: "Mail",
        placeholder1: "nation",
        placeholder2: "Veuillez entrer le numéro de téléphone",
        placeholder3: "Veuillez entrer le mot de passe",
        placeholder4: "veuillez saisir votre email",
        content12: "Veuillez entrer le numéro de téléphone",
        content13: "Veuillez entrer le mot de passe",
        content14: "veuillez saisir votre email",
        content16: "Services financiers sur actifs numériques",
        loginSuccess: "connexion réussie ",
        chooseArea: "Veuillez sélectionner l'indicatif régional",
        placeholder5: "Veuillez sélectionner le code du pays",
        placeholder6: "mémoriser mon mot de passe",
        placeholder7: "Télécharger l'application",
        placeholder8: "Contactez le service à la clientèle",
        placeholder9: "Sélectionnez un pays ou une région",
        placeholder10: "indicatif régional",
        placeholder11: "Connexion au portefeuille",
        placeholder12: "L'opération a échoué, veuillez passer à",
        placeholder13: "portefeuille"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "à propos",
        aboutCoinbeadCenterDetails: "Elle dispose de la première plateforme mondiale de négociation d'actifs blockchain et exploite l'ensemble de l'écosystème.",
        footDetails1: "volume de transactions quotidien",
        footDetails2: "transactions/seconde",
        footDetails3: "Centre d'aide",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Il s'agit d'un site Web international de renommée mondiale pour les actifs numériques, fournissant principalement des pièces et des dérivés d'actifs numériques tels que Bitcoin (BTC), Litecoin (LTC) et Ethereum (ETH) aux utilisateurs du monde entier.",
        coinbeadCenterDetailsm: "En adoptant des technologies avancées telles que GSLB, les serveurs réseau, les transactions réseau, les réseaux, les moteurs d'échange de mémoire haute vitesse multi-machines, les portefeuilles froids, les portefeuilles chauds hors ligne intelligents, etc., nous servons nos clients avec plusieurs terminaux tels que les téléphones mobiles Web et les PC. .Fournir des services de transactions tarifaires numériques sûrs, stables et fiables. Dans le même temps, nous améliorons et mettons à niveau les produits et services en fonction des suggestions et des besoins des utilisateurs afin de fournir de meilleurs services à chaque client, et l'amélioration de l'expérience utilisateur innovante est sans fin.",
        infrastructureCenterTitle: "Infrastructure",
        infrastructureCenterDetails: "Notre mission est de fournir des services d'infrastructure pour l'ensemble de l'écosystème blockchain",
        freeCenterTitle: "gratuit",
        freeCenterDetails: "Notre vision est de faire circuler librement l’argent dans le monde entier. Nous pensons que diffuser cette liberté peut rendre le monde meilleur",
        futureCenterTitle: "Pionnier de l’avenir de la technologie",
        futureCenterDetails: "Ouvrez l’avenir de la technologie",
    },
    applicationForListing: {
        title: "Application de cotation de pièces",
        projectName: "nom du projet",
        tokenName: "Nom du jeton",
        projectDescription: "Description du projet",
        contractAddress: "Adresse du contrat",
        officialWebsite: "site officiel",
        ContactAndEmail: "Email du contact",
        ContactAndName: "Nom du contact",
        file: "Télécharger la pièce jointe",
        pleaseEnter: "Entrez s'il vous plait",
        enterFile: "Veuillez télécharger des fichiers",
        upload1: "Actuellement limité à 1 sélection de fichiers,",
        upload2: "Cette fois j'ai choisi",
        upload3: "des dossiers,",
        upload4: "Total sélectionné",
        upload5: "des dossiers",
    },
    public: {
        submit: "soumettre",
        copySuccessfully: "Copié avec succès",
        copyFailed: "Échec de la copie",
        noDate: "Pas de données",
        submitSuccessfully: "Soumis avec succès",
        goBack: "revenir à la dernière page",
        cancel: "Annuler",
        home: "Fig début",
        loading: "chargement...",
        confirm: "confirmer",
        loadingFailed: "anomalie du réseau",
        nomore: "pas plus",
        tryAgain: "Veuillez actualiser la page/quitter le programme et télécharger à nouveau !",
        hot: "Zones populaires",
    },
    inviteFriends: {
        inviteFriends: "Partager avec des amis",
        freedomOfWealth: "Que la richesse soit libre ensemble",
        myInvitationCode: "mon code d'invitation",
        clickCopy: "Cliquez pour copier",
        myInvitationLink: "Mon lien d'invitation",
        clicktoSaveTheQRCode: "Cliquez pour enregistrer le code QR",
        saveQRCode: "Inviter le code QR",
        numberOfSubordinates: "Nombre de subordonnés",
        rebateIncome: "Revenu de remise",
        people: "personnes",
        myPromotionRevenue: "Mes revenus promotionnels",
        numberOfDirectSubordinates: "Nombre de subordonnés directs",
        totalNumberOfSubordinates: "Nombre total de subordonnés",
        directSubordinateReturnsCommission: "Remise de commission pour les subordonnés directs",
        theTotalCommission: "remise totale",
        checkHistory: "Afficher dossiers remise",
        history: "Dossier de commission",
        theLowerAccount: "Compte subordonné",
        type: "taper：",
        serviceCharge: "frais de gestion：",
        percentageofCommissionReturned: "Taux de remise：",
        commissionrebateAmount: "Montant du rabais：",
        time: "temps：",
        typeName1: "Recharge et commission",
        typeName2: "Frais de traitement des commandes MT, distribution à trois niveaux",
        typeName3: "Distribution rentable à trois niveaux MT",
        typeName4: "Récompenses de parrainage",
        typeName30: "Recharge et commission",
        typeName31: "Remise périodique sur les bénéfices commerciaux",
        typeName32: "Rabais sur les bénéfices miniers en liquidité",
        typeName45: "Rabais sur les frais de transaction",
        typeName46: "Remise de commission sur les frais de traitement propre",
        typeName50: "Remise sur les frais de traitement du contrat de livraison",
        typeName51: "Remise sur les frais de traitement du contrat perpétuel",
        typeName52: "Remise de commission supplémentaire pour les frais de traitement du contrat de livraison",
        typeName53: "Remise de commission supplémentaire pour les frais de traitement de contrats perpétuels",
        typeName54: "Remises supplémentaires sur les frais de traitement des contrats perpétuels",
        shareRules1: "Instructions pour les invitations",
        shareRules2: "Entrez dans le centre d'invitation, copiez le lien ou le code d'invitation et partagez-le avec vos amis. Les amis peuvent devenir vos subordonnés en vous inscrivant avec votre code d'invitation.",
        shareRules3: "Gagnez des rabais",
        shareRules4: "Lorsque les subordonnés effectuent des transactions, vous pouvez obtenir les commissions correspondantes, jusqu'à trois niveaux de subordonnés sont pris en charge. Par exemple, si vous invitez un ami A, A invite B et B invite C, alors A, B et C effectuent des contrats et d'autres transactions sur la plateforme, et vous pouvez tous obtenir les commissions correspondantes.",
        shareRules5: "Niveau d'équipe",
        shareRules6: "Plus vous promouvez de subordonnés de premier niveau, plus le niveau de l'équipe est élevé et plus la remise dont vous pouvez bénéficier est élevée. Les niveaux d'équipe sont divisés en LV1 à LV6. Les règles de mise à niveau sont présentées dans le tableau ci-dessous, où « N » est le nombre de subordonnés de premier niveau qui ont rechargé et terminé l'authentification par leur nom réel.",
        shareRules7: "Lorsque des subordonnés parient sur des contrats de livraison, vous pouvez recevoir des commissions proportionnelles à leurs paris.",
        upgradeConditions: "Exiger",
        recommendedNumberOfPeople: "Nombre de personnes recommandé"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Suggestions et commentaires",
        text: "Si vous rencontrez des bugs ou avez des suggestions de produits lors de l'utilisation, n'hésitez pas à nous faire part de vos commentaires.",
        email: "adresse e-mail",
        enterEmail: "Veuillez entrer l'adresse e-mail",
        enterCorrectEmail: "Veuillez saisir la bonne adresse e-mail",
        title: "titre",
        enterTitle: "Veuillez entrer un titre de question",
        describeTheProblem: "Décris le problème",
        enterDescribeTheProblem: "Veuillez entrer le problème que vous décrivez",
        submit: "soumettre",
    },
    help: {
        enterSearch: "recherche",
        title: "Centre d'aide",
        search: "Veuillez saisir votre question",
        text1: "Comprendre vos besoins et continuer à vous fournir des services plus pratiques"
    },
    announcement: {
        enterSearch: "recherche",
        title: "Centre d'annonces",
        search: "Veuillez saisir votre question"
    },
    privacy: {
        title: "Déclaration de confidentialité",
        content1: "1. Quelles informations personnelles sur les utilisateurs sont collectées ?",
        content2: "Les informations personnelles sont collectées pour fournir des produits et services et pour les améliorer continuellement.",
        content3: "Voici les types d’informations personnelles que nous collectons：",
        content4: "Informations que vous nous fournissez : Nous recevons et stockons toutes les informations que vous fournissez en relation avec la Plateforme. Vous pouvez choisir de ne pas fournir certaines informations, mais vous ne pourrez donc peut-être pas utiliser certains « services de cette plateforme ». Des exemples d'informations que vous nous fournissez incluent：",
        content5: "Nom",
        content6: "adresse",
        content7: "Pays de citoyenneté",
        content8: "Photo d'identité",
        content9: "Numéro de téléphone portable/numéro de courrier électronique",
        content10: "Autres informations qui nous aident à vous identifier",
        content11: "Informations collectées automatiquement : nous collectons et stockons automatiquement certains types d'informations sur votre utilisation des services de la plateforme. Comme de nombreux sites Web, nous utilisons des cookies et d'autres identifiants uniques. Nous obtenons certains types d'informations lorsque votre navigateur Web ou votre appareil accède à la Plateforme. Voici des exemples d'informations que nous collectons et analysons :",
        content12: "L'adresse IP (Internet Protocol) qui connecte un ordinateur personnel à Internet ;",
        content13: "Informations de connexion, adresse e-mail, mot de passe et emplacement d'un appareil personnel ou d'un ordinateur ;",
        content14: "Paramètres de version et de fuseau horaire ;",
        content15: "Historique des transactions.",
        content16: "Informations provenant d'autres sources : Nous pouvons collecter des informations vous concernant à partir d'autres sources, telles que vos antécédents de crédit auprès des agences d'évaluation du crédit. Nous utiliserons ces informations pour prévenir et détecter la fraude.",
        content17: "2. Les enfants peuvent-ils l'utiliser ?",
        content18: "Cette plateforme ne permet à aucune personne âgée de moins de 18 ans d'utiliser le « Service ».",
        content19: "3. A quoi servent les cookies et autres identifiants ?",
        content20: "Nous utilisons des cookies et des outils similaires pour optimiser l'expérience utilisateur, fournir nos services et comprendre comment les clients utilisent nos services afin que nous puissions apporter des améliorations ciblées. Nous utilisons des cookies pour garantir que notre système peut reconnaître votre navigateur ou appareil et vous fournir des services.",
        content21: "Nous utilisons des cookies opérationnels et des outils similaires (collectivement « Cookies ») pour fournir des services, tels que：",
        content22: "Pour vous identifier lorsque vous vous connectez et utilisez nos services.",
        content23: "Fournir des fonctionnalités et des services personnalisés.",
        content24: "Protégez-vous contre les activités frauduleuses.",
        content25: "Améliorer la sécurité.",
        content26: "Suivez vos préférences (telles que la devise et la langue).",
        content27: "Nous utilisons également des cookies pour comprendre comment les utilisateurs utilisent nos services afin que nous puissions apporter des améliorations ciblées.",
        content28: "4. Mes informations personnelles seront-elles partagées ?",
        content29: "Les informations utilisateur constituent une partie importante de notre activité et nous ne vendrons pas les informations personnelles des utilisateurs à des tiers. Cette plateforme ne partagera les informations personnelles des utilisateurs avec ses filiales ou sociétés affiliées que dans les circonstances suivantes. Ces filiales ou sociétés affiliées sont tenues de respecter cette déclaration de confidentialité ou au moins de suivre des pratiques aussi protectrices que les mesures de protection décrites dans cette déclaration de confidentialité.",
        content30: "Fournisseurs de services tiers : nous employons d'autres sociétés et individus pour exécuter des fonctions en notre nom. Des exemples de telles fonctions incluent : l'analyse des données, la fourniture d'une assistance marketing, le traitement des paiements, la transmission de contenu, ainsi que l'évaluation et la gestion du risque de crédit. Ces prestataires de services tiers ont accès aux informations personnelles nécessaires à l'exercice de leurs fonctions, mais ne peuvent les utiliser à d'autres fins. De plus, ils doivent traiter les informations personnelles conformément à cette déclaration de confidentialité et aux lois applicables en matière de protection des données.",
        content31: "Transferts d'entreprises : à mesure que notre entreprise continue de croître, nous pouvons vendre ou acheter d'autres entreprises ou services. Dans de telles transactions, les informations de l'utilisateur font généralement partie des actifs commerciaux transférés, mais restent soumises aux promesses contenues dans toute déclaration de confidentialité préexistante (à moins, bien sûr, que l'utilisateur n'en accepte autrement). De plus, si notre société ou la quasi-totalité de ses actifs était acquise, les informations des utilisateurs seraient également transférées. Protection de notre entreprise et des autres : lorsque nous estimons que la divulgation du compte et d'autres informations personnelles est appropriée pour se conformer à la loi ou à nos obligations réglementaires, faire respecter ou appliquer nos conditions d'utilisation et autres accords, ou les droits, la propriété ou la sécurité de notre utilisateurs ou autres Quand, nous divulguerons le compte et d’autres informations personnelles. Cela inclut l'échange d'informations avec d'autres entreprises et organisations pour se protéger contre la fraude et réduire le risque de crédit.",
        content32: "5. Transferts internationaux de données personnelles",
        content33: "Nous pouvons transférer vos données en dehors de l'Espace économique européen (« EEE »). Nous mettrons en place des garanties appropriées pour garantir que ces transferts sont conformes aux règles applicables en matière de protection des données, à moins que la Commission européenne n'ait confirmé que le pays vers lequel les données sont transférées offre un niveau de protection adéquat.",
        content34: "6. Mes informations personnelles sont-elles bien protégées?",
        content35: "Nous avons conçu nos systèmes en pensant à votre sécurité et à votre confidentialité. Nous utilisons des protocoles et des logiciels de cryptage pour nous efforcer de protéger la sécurité des informations personnelles lors de la transmission.",
        content36: "Nous adoptons toujours des mesures de protection physiques, électroniques et procédurales lors de la collecte, du stockage et de la divulgation d'informations personnelles. Nos procédures de sécurité signifient que nous devrons peut-être vérifier votre identité avant de vous divulguer des informations personnelles.",
        content37: "La chose la plus importante que vous puissiez faire est de protéger les mots de passe de votre compte personnel contre tout accès non autorisé. Nous vous recommandons de définir un mot de passe unique pour votre compte, distinct des autres comptes en ligne. Et assurez-vous de vous déconnecter après avoir utilisé un ordinateur partagé.",
        content38: "7. Comment dois-je protéger les informations personnelles ?",
        content39: "Si vous avez des questions ou des objections sur la manière dont nous collectons et traitons les informations personnelles, veuillez contacter le service client.",
        content40: "Après avoir accepté que nous traitions vos informations personnelles dans un but spécifique, vous pouvez retirer votre intention à tout moment et nous cesserons de traiter vos données à cette fin.",
        content41: "En outre, vous avez le droit de demander l'accès, la rectification et la suppression des données personnelles et de demander la portabilité des données, sous réserve des lois applicables. Vous pouvez également vous opposer au traitement de vos données personnelles ou demander que notre traitement de vos données personnelles soit limité dans certaines circonstances.",
        content42: "8. RGPD de l'UE et loi britannique sur la protection des données – Base juridique",
        content43: "Le RGPD de l'UE et la loi britannique sur la protection des données nous obligent à respecter la base juridique lors de l'utilisation des informations personnelles. Notre base dépend des finalités spécifiques pour lesquelles les informations personnelles sont utilisées. Ces bases comprennent :",
        content44: "Exécution d'un contrat - pour vous fournir ou communiquer avec vous au sujet de produits ou de services, y compris notre utilisation de vos informations personnelles pour accepter et traiter les commandes et traiter les paiements.",
        content45: "Nos intérêts commerciaux légitimes et intérêts des utilisateurs - Nous détectons et prévenons les fraudes et les abus afin de protéger la sécurité de nos utilisateurs, de nous-mêmes ou des autres.",
        content46: "Votre consentement – ​​Nous avons besoin de votre consentement pour traiter vos informations personnelles aux fins spécifiques qui vous sont communiquées. Une fois que vous nous avez donné votre consentement pour traiter vos informations personnelles dans un but spécifique, vous pouvez retirer votre consentement à tout moment et nous cesserons de traiter vos données à cette fin.",
        content47: "Respecter les obligations légales - Nous utilisons vos informations personnelles comme l'exige la loi. Par exemple, nous collectons des informations de compte bancaire à des fins de vérification d'identité.",
        content48: "Les bases juridiques ci-dessus et d’autres dépendent des finalités spécifiques pour lesquelles nous utilisons les informations personnelles.",
        content49: "9. Conditions d'utilisation, avis et modifications",
        content50: "Votre utilisation et tout litige concernant la confidentialité sont soumis à cette déclaration et à nos conditions d'utilisation. Si vous avez des inquiétudes concernant la confidentialité de cette plateforme, veuillez nous contacter avec une explication détaillée et nous serons heureux de résoudre le problème pour vous. Vous avez également le droit de contacter votre autorité locale de protection des données.",
        content51: "Notre activité évolue constamment, tout comme notre déclaration de confidentialité. Vous devriez consulter fréquemment notre site Web pour voir les dernières modifications. Si vous n'êtes pas d'accord avec le contenu révisé, vous devez immédiatement cesser d'y accéder. Une fois la version mise à jour de la politique de confidentialité publiée, votre accès continu signifie que vous acceptez le contenu mis à jour et acceptez de respecter la politique de confidentialité mise à jour. Sauf indication contraire, la déclaration de confidentialité actuelle s'applique à toutes les informations que nous détenons sur vous et votre compte.",
    },
    serviceAgreement: {
        title: "Contrat de service",
        content1: "Ce site Internet est une plateforme permettant spécifiquement aux utilisateurs d'effectuer des transactions sur des actifs numériques et de fournir des services associés (ci-après dénommé « le service » ou « le service »). Pour faciliter la description de cet accord, ce site Web utilise collectivement « nous » ou d'autres pronoms à la première personne dans cet accord. Tant que la personne physique ou autre sujet qui se connecte au site Web est un utilisateur de ce site Web, pour faciliter l'expression de cet accord, « vous » ou une autre deuxième personne sera utilisé ci-dessous. Pour faciliter l'expression du présent Accord, nous et vous sommes collectivement appelés les « Parties » dans le présent Accord, et nous ou vous êtes individuellement appelés « Partie ».",
        content2: "indice important：",
        content3: "Nous tenons à vous le rappeler ici：",
        content4: "1 Les actifs numériques eux-mêmes ne sont émis par aucune institution ou entreprise financière ou par ce site Web.",
        content5: "2 Les marchés des actifs numériques sont nouveaux, non confirmés et pourraient ne pas croître；",
        content6: "3 Les actifs numériques sont principalement utilisés de manière intensive par les spéculateurs, avec relativement peu d'utilisation sur les marchés de détail et commerciaux. Les transactions sur actifs numériques sont extrêmement risquées. Elles sont négociées en continu tout au long de la journée, sans limite de hausse et de baisse, et les prix sont facilement affectés par le marché. décideurs et politiques gouvernementales mondiales.；",
        content7: "4  La Société se réserve le droit de suspendre ou de résilier votre compte à tout moment si la Société détermine, à sa seule discrétion, que vous avez violé le présent Accord, ou que les services fournis par ce site Web ou votre utilisation des services fournis par ce site Web sont illégal en vertu des lois de votre juridiction. , ou suspendre ou mettre fin à votre utilisation des services ou des transactions d'actifs numériques fournis par ce site Web. Il est interdit à toute personne de [Chine continentale, Taiwan, Israël, Irak, Bangladesh, Bolivie, Équateur, Kirghizistan, Sébastopol et Royaume-Uni (utilisateurs particuliers)] d'utiliser les services de transaction contractuelle fournis par ce site Web. La liste de pays ou de régions susmentionnée changera en fonction des politiques et des types de produits des différents pays ou régions. Nous ne pouvons pas vous en informer spécifiquement à ce moment-là, veuillez donc prêter attention aux mises à jour de cet accord en temps opportun.",
        content8: "Le trading d’actifs numériques comporte des risques extrêmement élevés et ne convient pas à la plupart des gens. Vous comprenez et comprenez que cette transaction peut entraîner une perte partielle ou totale, vous devez donc déterminer le montant de la transaction en fonction du degré de perte que vous pouvez vous permettre. Vous savez et comprenez que les actifs numériques généreront des risques dérivés, donc si vous avez des questions, il est recommandé de demander d'abord l'aide de conseillers professionnels. De plus, outre les risques mentionnés ci-dessus, il existe également des risques imprévisibles. Vous devez soigneusement réfléchir et faire preuve d'un jugement clair pour évaluer votre situation financière et les risques mentionnés ci-dessus avant de prendre toute décision d'achat ou de vente d'actifs numériques, et supporter toutes les pertes qui en résultent. Nous n'assumons aucune responsabilité à ce sujet.",
        content9: "Attention à vous：",
        content10: "1 Vous comprenez que ce site Web est uniquement utilisé pour vous permettre d'obtenir des informations sur les actifs numériques, de trouver des parties à des transactions, de négocier et d'effectuer des transactions sur des actifs numériques. Ce site Web ne participe à aucune de vos transactions, vous devez donc faire preuve de prudence. pour déterminer les actifs numériques et/ou les transactions concernés ou l'authenticité, la légalité et la validité des informations, et assumera seul les responsabilités et les pertes qui en résultent.",
        content11: "2 Les opinions, actualités, discussions, analyses, prix, recommandations et autres informations figurant sur ce site Web sont des commentaires généraux sur le marché et ne constituent pas des conseils d'investissement. Nous ne sommes pas responsables des pertes résultant directement ou indirectement de la confiance accordée à ces informations, y compris, mais sans s'y limiter, toute perte de bénéfices.",
        content12: "3 Ce site Web se réserve le droit de prendre sa propre décision, de modifier ou de changer le contenu à tout moment. Nous avons pris des mesures raisonnables pour garantir l'exactitude des informations sur le site Web, mais nous ne pouvons garantir leur exactitude et nous ne serons pas responsables de toute perte ou dommage causé par les informations contenues dans ce site Web ou le manque d'informations. Pertes directes ou indirectes résultant de retards ou d'échecs dans la connexion à Internet, la transmission ou la réception de notifications et d'informations.",
        content13: "4 Il existe également des risques liés à l'utilisation de systèmes de trading basés sur Internet, notamment, mais sans s'y limiter, les pannes de logiciels, de matériel et de connexion Internet. Comme nous n'avons aucun contrôle sur la fiabilité et la disponibilité d'Internet, nous déclinons toute responsabilité en cas de distorsion, de retards et d'échecs de connexion.",
        content14: "5 Il est interdit d'utiliser ce site Web pour se livrer à toutes activités commerciales illégales ou à des activités illégales telles que le blanchiment d'argent, la contrebande, la corruption commerciale, etc. Si des transactions illégales ou des activités illégales suspectées sont détectées, ce site Web utilisera divers moyens disponibles, y compris, mais sans se limiter au gel des comptes et à la notification des parties concernées. Autorités, etc., nous n'assumons pas toutes les responsabilités qui en découlent et nous nous réservons le droit de poursuivre la responsabilité des personnes concernées.",
        content15: "6 Il est interdit d'utiliser ce site Web pour manipuler de manière malveillante le marché, des transactions inappropriées et d'autres activités commerciales contraires à l'éthique. Si de tels incidents sont découverts, ce site Web avertira, restreindra les transactions et mettra fin à tous les comportements contraires à l'éthique tels que la manipulation malveillante des prix et l'influence malveillante sur le marché. Nous n'assumons pas toute responsabilité découlant de mesures de protection préventives telles que la suspension du compte et nous nous réservons le droit de poursuivre la responsabilité des personnes concernées.",
        content16: "1. Principes généraux",
        content17: "1.1 « Contrat d'utilisation » (ci-après dénommé « cet Contrat » ou « Ces conditions générales ») comprend le texte principal, « Conditions de confidentialité », « Connaissez votre client et politique de lutte contre le blanchiment d'argent » et diverses autres politiques qui ont ont été publiés ou pourraient être publiés à l'avenir sur ce site Web.Règles de classe, déclarations, descriptions, etc.",
        content18: "1.2 Vous devez lire attentivement cet accord avant d'utiliser les services fournis par ce site Web. Si vous ne comprenez rien ou si cela est nécessaire, veuillez consulter un avocat professionnel. Si vous n'acceptez pas cet accord et/ou sa modification à tout moment, veuillez cesser immédiatement d'utiliser les services fournis par ce site Web ou ne plus vous connecter à ce site Web. Une fois que vous vous connectez à ce site Web, utilisez l'un des services de ce site Web ou tout autre comportement similaire, cela signifie que vous avez compris et pleinement accepté le contenu de cet accord, y compris toute modification apportée par ce site Web à cet accord à tout moment.",
        content19: `1.3 Vous pouvez devenir membre de ce site Web (ci-après dénommé « membre ») en remplissant les informations pertinentes conformément aux exigences de ce site Web et en vous inscrivant avec succès après d'autres procédures pertinentes. En cliquant sur le bouton « Accepter » lors de l'inscription processus, vous signez électroniquement. Forme d'accord avec la société ; ou lorsque vous cliquez sur un bouton marqué "J'accepte" ou une signification similaire lors de l'utilisation de ce site Web, ou utilisez réellement les services fournis par ce site Web d'autres manières autorisées par ce site Web. , cela signifie que vous comprenez, acceptez et acceptez pleinement tous les termes du présent accord. L'absence de votre signature manuscrite n'affectera pas la force juridiquement contraignante du présent accord sur vous.`,
        content20: "1.4 Après être devenu membre de ce site Web, vous recevrez un compte de membre et le mot de passe correspondant. Vous êtes responsable de la conservation du compte de membre et du mot de passe ; vous serez légalement responsable de toutes les activités et événements menés sous votre compte.",
        content21: "1.5 Seuls les membres qui deviennent membres de ce site Web peuvent utiliser la plateforme de négociation d'actifs numériques fournie par ce site Web pour effectuer des transactions et profiter d'autres services stipulés par ce site Web qui sont uniquement disponibles pour les membres ; les non-membres peuvent uniquement se connecter au site Web, parcourir le site Web. et d'autres services stipulés par ce site Web.",
        content22: "1.6 En vous inscrivant et en utilisant les services et fonctions fournis par ce site Web, vous serez réputé avoir lu, compris et :",
        content23: "1.6.1 Acceptez d'être lié par tous les termes et conditions du présent accord.",
        content24: "1.6.2 Vous confirmez que vous avez plus de 18 ans ou que vous avez l'âge légal pour conclure des contrats selon les différentes lois applicables. Votre inscription sur ce site, vos ventes ou achats, la publication d'informations, etc. et l'acceptation des services de ce site doivent être conformes. avec vos exigences. Avoir juridiction sur les lois et réglementations pertinentes d'un pays ou d'une région souveraine, et avoir la pleine capacité d'accepter ces conditions, de conclure des transactions et d'utiliser ce site Web pour effectuer des transactions d'actifs numériques.",
        content25: "1.6.3 Vous garantissez que tous les actifs numériques qui vous appartiennent et impliqués dans la transaction sont légalement obtenus et en sont la propriété.",
        content26: "1.6.4 Vous acceptez que vous êtes seul responsable de vos propres activités commerciales ou non commerciales et de tout gain ou perte.",
        content27: "1.6.5 Vous confirmez que les informations que vous fournissez lors de votre inscription sont vraies et exactes.",
        content28: "1.6.6 Vous acceptez de vous conformer aux exigences de toute loi pertinente, y compris la déclaration de tout bénéfice commercial à des fins fiscales.",
        content29: "1.6.7 Vous acceptez de ne pas vous engager ou participer à des conduites ou à des activités qui nuisent aux intérêts de ce site Web ou de la société, à aucun moment, qu'elles soient liées ou non aux services fournis par ce site Web.",
        content30: "1.6.8 Cet accord régit uniquement les droits et obligations entre vous et nous, et n'implique pas les relations juridiques et les litiges juridiques découlant des transactions d'actifs numériques entre les utilisateurs de ce site Web et d'autres sites Web et vous.",
        content31: "2. Modifications de l'accord",
        content32: "Nous nous réservons le droit de réviser cet accord de temps en temps et de l'annoncer sur le site Web sans vous en informer séparément. Après la révision, l'heure de révision sera marquée sur l'[Heure de la dernière mise à jour] affichée dans cet accord, et cela prendra automatiquement effet dès sa publication sur le site Internet. Vous devez parcourir et prêter attention à l'heure de mise à jour et au contenu mis à jour de cet accord de temps en temps. Si vous n'êtes pas d'accord avec les modifications pertinentes, vous devez immédiatement cesser d'utiliser les services de ce site Web ; votre utilisation continue des services de ce site Web site Web signifie que vous acceptez et acceptez d'être lié par l'accord révisé. .",
        content33: "3. Inscription",
        content34: "3.1 Conditions d'inscription",
        content35: "Vous confirmez et promettez que lorsque vous terminez le processus d'inscription ou utilisez réellement les services fournis par ce site Web d'une autre manière autorisée par ce site Web, vous devriez avoir la possibilité de signer cet accord et d'utiliser les services de ce site Web comme stipulé par les lois applicables. personne physique, personne morale ou autre organisation. Une fois que vous avez cliqué sur le bouton Accepter de vous inscrire, cela signifie que vous ou votre agent autorisé avez accepté le contenu de l'accord et que vous avez été enregistré et utilisé les services de ce site Web par son agent. Si vous ne possédez pas les qualifications susmentionnées, vous et votre agent autorisé supporterez toutes les conséquences qui en découlent, et la société se réserve le droit d'annuler ou de geler définitivement votre compte, et de vous tenir responsables, vous et votre agent autorisé.",
        content36: "3.2 Objectif de l'inscription",
        content37: "Vous confirmez et promettez que votre inscription sur ce site Web n'a pas pour but de violer les lois et réglementations ou de perturber l'ordre des transactions d'actifs numériques sur ce site Web.",
        content38: "3.3 Processus d'inscription",
        content39: "3.3.1 Vous acceptez de fournir une adresse e-mail valide, un numéro de téléphone mobile et d'autres informations requises sur la page d'enregistrement des utilisateurs de ce site Web. Vous pouvez utiliser l'adresse e-mail, le numéro de téléphone mobile que vous avez fourni ou confirmé ou d'autres méthodes autorisées par ce site Web comme identifiant. méthode pour accéder à ce site Web. Si nécessaire, conformément aux lois et réglementations en vigueur dans les différentes juridictions, vous devez fournir votre vrai nom, votre pièce d'identité et d'autres informations pertinentes stipulées dans les lois et réglementations, les clauses de confidentialité et les clauses anti-blanchiment d'argent, et mettre constamment à jour les informations d'enregistrement dans de manière opportune, détaillée et précise. Toutes les informations saisies à l’origine seront référencées comme informations d’inscription. Vous êtes responsable de l’authenticité, de l’exhaustivité et de l’exactitude de ces informations et supporterez toutes les pertes directes ou indirectes et les conséquences néfastes qui en découlent.",
        content40: "3.3.2 Si les lois, réglementations, règles, ordonnances et autres réglementations du pays ou de la région souveraine dans laquelle vous vous trouvez imposent un nom réel pour les numéros de téléphone mobile, vous acceptez que le numéro de téléphone mobile enregistré fourni ait été enregistré sous votre nom réel. vous ne le fournissez pas conformément à la réglementation, les conséquences qui vous seront causées seront des pertes directes ou indirectes et les conséquences néfastes seront à votre charge.",
        content41: "3.3.3 Si vous fournissez les informations requises pour l'inscription de manière légale, complète et effective et que vous les avez vérifiées, vous avez le droit d'obtenir le numéro de compte et le mot de passe de ce site Web. Lorsque vous obtenez le numéro de compte et le mot de passe de ce site Web, votre inscription est considérée comme réussi et vous pouvez vous connecter en tant que membre sur ce site Web.",
        content42: "3.3.4 Vous acceptez de recevoir des e-mails et/ou des messages courts envoyés par ce site Web liés à la gestion et au fonctionnement de ce site Web.",
        content43: "4. Prestations",
        content44: "Ce site Web fournit uniquement des services de plateforme de trading en ligne pour vos activités de trading d'actifs numériques (y compris, mais sans s'y limiter, le trading d'actifs numériques et d'autres services) via ce site Web.",
        content45: "4.1 Contenu des services",
        content46: "4.1.1 Vous avez le droit de parcourir les conditions du marché en temps réel et les informations sur les transactions de divers produits d'actifs numériques sur ce site Web, et vous avez le droit de soumettre des instructions de transaction d'actifs numériques et d'effectuer des transactions d'actifs numériques via ce site Web.",
        content47: "4.1.2 Vous avez le droit de consulter les informations de votre compte de membre sur ce site Web et d'utiliser les fonctions fournies par ce site Web pour fonctionner.",
        content48: "4.1.3 Vous avez le droit de participer aux activités du site Web organisées par ce site Web conformément aux règles d'activité publiées sur ce site Web.",
        content49: "4.1.4 Ce site Web promet de vous fournir d'autres services.",
        content50: "4.2 Règles de service Vous vous engagez à respecter les règles de service suivantes de ce site Web：",
        content51: "4.2.1 Vous devez respecter les lois, réglementations et exigences politiques, garantir la légalité des sources de tous les actifs numériques de votre compte et ne pas vous engager dans des activités illégales ou autres qui nuisent aux droits et intérêts de ce site Web ou de tiers sur ce site Web. ou en utilisant ses services, y compris, mais sans s'y limiter : Vous n'êtes pas limité à l'envoi ou à la réception d'informations illégales, illégales ou portant atteinte aux droits d'autrui, à l'envoi ou à la réception de systèmes pyramidaux ou d'autres informations ou remarques préjudiciables, à l'utilisation ou à la falsification du informations d'en-tête de courrier électronique de ce site Web sans autorisation de ce site Web, etc.",
        content52: "4.2.2 Vous devez respecter les lois et réglementations et utiliser et conserver correctement votre numéro de compte, votre mot de passe de connexion, votre mot de passe de fonds, le numéro de téléphone mobile qui vous est lié lors de votre inscription et le code de vérification de téléphone mobile reçu par votre téléphone mobile. Vous êtes entièrement responsable de toutes les opérations et conséquences liées à l’utilisation de votre compte et de votre mot de passe de connexion, de votre mot de passe de fonds et du code de vérification de votre téléphone mobile. Lorsque vous constatez que le numéro de compte, le mot de passe de connexion, le mot de passe du fonds ou le code de vérification de ce site Web sont utilisés par un tiers sans votre autorisation, ou qu'il existe d'autres problèmes de sécurité de compte, vous devez immédiatement et efficacement informer ce site Web et demander à ce site Web de suspendre. le service du compte de ce site Web. . Ce site Web a le droit de donner suite à votre demande dans un délai raisonnable, mais ce site Web n'assume aucune responsabilité pour les conséquences (y compris, mais sans s'y limiter, les pertes que vous subissez) qui se sont produites avant d'agir. Vous ne pouvez pas donner, emprunter, louer, transférer ou autrement disposer de votre compte sur ce site Web à des tiers sans le consentement de ce site Web.",
        content53: "4.2.3 Vous acceptez d'être responsable de toutes les activités qui se produisent sous votre compte et votre mot de passe sur ce site Web (y compris, mais sans s'y limiter, la divulgation d'informations, la publication d'informations, les clics en ligne pour accepter ou la soumission de diverses règles et accords, le renouvellement en ligne d'accords ou l'achat de services, etc.).",
        content54: "4.2.4 Lorsque vous effectuez des transactions d'actifs numériques sur ce site Web, vous ne devez pas interférer de manière malveillante avec le déroulement normal des transactions d'actifs numériques ni perturber l'ordre des transactions ; vous ne devez pas interférer avec le fonctionnement normal de ce site Web ni interférer avec l'utilisation par d'autres utilisateurs de ce site Web. services par tout moyen technique ou autre ; vous ne devez pas utiliser de fictifs ; diffamer de manière malveillante la bonne volonté de ce site Web par des faits et d'autres moyens.",
        content55: "4.2.5 Si vous avez un litige avec d'autres utilisateurs en raison de transactions en ligne, vous ne pouvez pas demander à ce site Web de fournir des informations pertinentes par des voies non judiciaires ou administratives.",
        content56: "4.2.6 Toute taxe à payer, ainsi que tous les matériels, logiciels, services et autres dépenses encourus lors de votre utilisation des services fournis par ce site Web seront uniquement jugés et supportés par vous.",
        content57: "4.2.7 Vous devez respecter cet accord ainsi que les autres conditions de service et règles de fonctionnement publiées et mises à jour par ce site Web de temps à autre, et vous avez le droit de mettre fin à l'utilisation des services fournis par ce site Web à tout moment.",
        content58: "4.3 règles du produit",
        content59: "4.3.1 Parcourir les informations sur les transactions",
        content60: "Lorsque vous parcourez les informations de transaction sur ce site Web, vous devez lire attentivement tout le contenu contenu dans les informations de transaction, y compris, mais sans s'y limiter, le prix, le volume des commissions, les frais de traitement, la direction d'achat ou de vente, et seulement après avoir pleinement accepté tout le contenu contenu dans les informations sur la transaction Les transactions peuvent être effectuées en cliquant sur un bouton.",
        content61: "4.3.2 Afficher les détails de la transaction",
        content62: "Vous pouvez consulter les enregistrements de transactions correspondants via votre compte.",
        content63: "5. Droits et obligations de ce site Internet",
        content64: "5.1 Si vous ne possédez pas les qualifications d'inscription stipulées dans le présent accord, ce site Web a le droit de refuser votre inscription. Pour ceux qui sont déjà inscrits, ce site Web a le droit d'annuler votre compte de membre. Ce site Web se réserve le droit de vous retenir ou votre agent autorisé responsable. Dans le même temps, ce site Web se réserve le droit de décider d'accepter ou non votre inscription dans d'autres circonstances.",
        content65: "5.2 Sur la base du propre jugement de ce site Web, si ce site Web estime que vous ou les utilisateurs de votre compte associés n'êtes pas adapté aux investissements à haut risque, nous avons le droit de suspendre ou de résilier votre compte et l'utilisation de tous les comptes associés.",
        content66: "5.3 Lorsque ce site Web découvre que l'utilisateur du compte n'est pas le titulaire initial du compte, il a le droit de suspendre ou de mettre fin à l'utilisation du compte.",
        content67: "5.4 Lorsque ce site Web soupçonne raisonnablement que les informations que vous avez fournies sont erronées, fausses, invalides ou incomplètes grâce à des tests techniques, un échantillonnage manuel et d'autres méthodes de test, il a le droit de vous informer de corriger ou de mettre à jour les informations ou de suspendre ou de mettre fin à la fourniture de ce site Web vous propose des services.",
        content68: "5.5 Ce site Web se réserve le droit de corriger toute information affichée sur ce site Web s'il s'avère qu'il existe une erreur évidente.",
        content69: "5.6 Ce site Web se réserve le droit de modifier, suspendre ou mettre fin aux services de ce site Web à tout moment. Ce site Web n'a pas besoin de vous en informer au préalable pour exercer le droit de modifier ou de résilier les services. Si ce site Web met fin à un ou plusieurs services de ce site Internet, la résiliation s'effectuera depuis ce site sur le site Internet, à compter de la date de publication de l'annonce de résiliation.",
        content70: "5.7 Ce site Web prendra les moyens techniques et les mesures de gestion nécessaires pour assurer le fonctionnement normal de ce site Web, fournir un environnement commercial et des services commerciaux nécessaires et fiables et maintenir l'ordre des transactions sur les actifs numériques.",
        content71: "5.8 Si vous ne vous connectez pas à ce site Web en utilisant votre compte de membre et votre mot de passe pendant une année consécutive, ce site Web a le droit d'annuler votre compte. Une fois le compte annulé, ce site Web a le droit d'ouvrir le nom de membre correspondant à d'autres utilisateurs pour enregistrement et utilisation.",
        content72: "5.9 Ce site Web assure la sécurité de vos actifs numériques en renforçant les investissements technologiques, en améliorant les précautions de sécurité et d'autres mesures, et vous avertira à l'avance lorsque des risques de sécurité prévisibles surviennent dans votre compte.",
        content73: "5.10 Ce site Web a le droit de supprimer à tout moment toutes sortes d'informations de contenu sur ce site Web qui ne sont pas conformes aux lois et règlements ou aux réglementations de ce site Web. Ce site Web n'a pas besoin de vous en informer à l'avance pour exercer ces droits.",
        content74: "5.11 Ce site Web a le droit de vous demander de fournir plus d'informations ou de documents conformément aux lois, réglementations, règles, ordonnances et autres exigences réglementaires de votre pays ou région souverain, et de prendre des mesures raisonnables pour se conformer aux exigences réglementaires locales. sont tenus de coopérer ; ce site Web a le droit de suspendre ou d'arrêter définitivement de vous ouvrir tout ou partie des services de ce site Web conformément aux exigences des lois, réglementations, règles, ordonnances et autres spécifications de votre pays ou région souverain.",
        content75: "5.12 Ce site Web se réserve le droit de fermer votre compte avec un préavis de sept jours ouvrables, à sa seule discrétion. Par conséquent, vous disposez de sept jours ouvrables pour annuler des commandes et clôturer des positions. Si vous n'avez pas annulé la commande et clôturé la position à l'expiration du délai, nous annulerons de force la commande et clôturerons la position, et vous restituerons les actifs numériques restants de votre compte.",
        content76: "5.13 Afin de protéger les droits et les intérêts des commerçants, ce site Web a le droit d'apporter des modifications aux produits en ligne dans des circonstances particulières (telles qu'une panne du système, une panne du réseau, des conditions de marché extrêmes, etc.), telles que : des contrats de livraison anticipée et règlement, types de contrats et contrats de livraison et de règlement anticipés.La période ainsi que le prix de règlement et de livraison sont soumis à l'annonce sur ce site Web.",
        content77: "6. Rémunération",
        content78: "6.1 En aucun cas, notre responsabilité envers vous pour les dommages directs ne dépassera le total des frais de service que nous vous avons facturés pour votre utilisation de ce site Web pendant une période de trois (3) mois.",
        content79: "6.2 Si vous violez le présent Accord ou d'autres lois et réglementations, vous devez nous indemniser d'au moins 2 millions de dollars américains et supporter tous les frais encourus (y compris les honoraires d'avocat, etc.). Si cela ne suffit pas à compenser les pertes réelles, vous devez faire prêt pour ça.",
        content80: "7. Droit de demander une injonction",
        content81: "Nous et vous reconnaissons tous deux que les recours de droit commun en cas de rupture de contrat ou de rupture éventuelle de contrat peuvent ne pas être suffisants pour compenser toutes les pertes que nous subissons. Nous avons donc le droit de demander une injonction comme le permettent la loi commune ou l'équité dans le pays. en cas de rupture de contrat de votre part ou de rupture possible de contrat, tous les autres recours.",
        content82: "8. Limitation de responsabilité et clause de non-responsabilité",
        content83: "8.1 Vous comprenez et acceptez qu'en aucun cas nous ne serons responsables des questions suivantes：",
        content84: "8.1.1 perte de revenu；",
        content85: "8.1.2 Bénéfices commerciaux ou pertes contractuelles；",
        content86: "8.1.3 Pertes causées par une interruption d’activité；",
        content87: "8.1.4 Perte des économies monétaires attendues；",
        content88: "8.1.5 Pertes causées par des problèmes d’information；",
        content89: "8.1.6 Perte d’opportunité, de clientèle ou de réputation；",
        content90: "8.1.7 Corruption ou perte de données；",
        content91: "8.1.8 Coût d'achat de produits ou de services de substitution；",
        content92: "8.1.9 Toute perte ou dommage indirect, spécial ou accidentel résultant d'un délit (y compris la négligence), d'une rupture de contrat ou de toute autre raison, que cette perte ou ce dommage soit raisonnablement prévisible ou non par nous ; que nous soyons ou non informés de leur existence dans possibilité avancée de perte ou de dommage.",
        content93: "8.1.1 Les articles du 8.1.9 sont indépendants les uns des autres.",
        content94: "8.2 Vous comprenez et acceptez que nous ne serons pas responsables de tout dommage qui vous serait causé par l'une des circonstances suivantes :：",
        content95: "8.2.1 Vos transactions spécifiques peuvent impliquer des violations majeures des lois ou des ruptures de contrat.",
        content96: "8.2.2 Votre comportement sur ce site Web peut être illégal ou contraire à l'éthique.",
        content97: "8.2.3 Coûts et pertes encourus lors de l'achat ou de l'obtention de données, d'informations ou de la réalisation de transactions via les services de ce site Web ou par des actions de substitution.",
        content98: "8.2.4 Votre incompréhension concernant les services de ce site Web.",
        content99: "8.2.5 Toute autre perte liée aux services fournis par ce site Web qui ne sont pas causés par nous.",
        content100: "8.3 Nous sommes responsables de la maintenance des équipements du réseau d'information, des pannes de connexion au réseau d'information, des pannes informatiques, de communication ou autres systèmes, des pannes de courant, des conditions météorologiques, des accidents, des grèves, des conflits du travail, des émeutes, des soulèvements, des émeutes, de la productivité ou des matériaux de production insuffisants, des incendies, des inondations. , tempêtes, explosions, guerres, raisons bancaires ou autres raisons partenaires, effondrement du marché des actifs numériques, actions gouvernementales, ordres d'organismes judiciaires ou administratifs, autres actions indépendantes de notre volonté ou que nous n'avons aucune capacité de contrôler, ou raisons de tiers. n'assumons aucune responsabilité pour l'incapacité ou le retard du service, ou pour vos pertes.",
        content101: "8.4 Nous ne pouvons pas garantir que toutes les informations, programmes, textes, etc. contenus dans ce site Web sont totalement sûrs et exempts d'interférences et de dommages causés par des virus, chevaux de Troie et autres programmes malveillants. Par conséquent, si vous vous connectez et utilisez des services sur ce site Web ou télécharger et utiliser des programmes téléchargés, des informations, des données, etc. sont toutes vos propres décisions et vous supportez les risques et les pertes possibles à vos propres risques.",
        content102: "8.5 Nous ne donnons aucune garantie ni engagement concernant les informations, produits et services de sites Web tiers liés à ce site Web ou toute autre forme de contenu qui ne nous appartient pas. Si vous utilisez des services, informations et services fournis par des tiers -les sites Web des parties, les produits, etc. relèvent de vos propres décisions et vous assumez toutes les responsabilités qui en découlent.",
        content103: "8.6 Nous n'offrons aucune garantie expresse ou implicite pour votre utilisation des services de ce site Web, y compris, mais sans s'y limiter, l'adéquation des services fournis par ce site Web, l'absence d'erreurs ou d'omissions, la continuité, l'exactitude, la fiabilité et l'adéquation à un usage particulier. but. En même temps, nous ne prenons aucun engagement ou garantie quant à la validité, l'exactitude, l'exactitude, la fiabilité, la qualité, la stabilité, l'exhaustivité et l'actualité de la technologie et des informations impliquées dans les services fournis par ce site Web. Que ce soit pour vous connecter ou utiliser les services fournis par ce site Web est votre décision personnelle et vous supportez vos propres risques et pertes éventuelles. Nous ne donnons aucune garantie expresse ou implicite concernant le marché, la valeur et le prix des actifs numériques. Vous comprenez et comprenez que le marché des actifs numériques est instable. Les prix et les valeurs peuvent fluctuer ou s'effondrer de manière significative à tout moment. Le trading d'actifs numériques est votre liberté personnelle. Choisissez et décidez à vos propres risques et pertes éventuelles.",
        content104: "8.7 Nos garanties et engagements énoncés dans le présent Contrat sont les seules garanties et représentations faites par nous en relation avec le présent Contrat et les services fournis sur ce site Web et remplacent toutes les garanties et engagements découlant de toute autre manière, qu'ils soient écrits ou oraux, expresses. ou implicite. Toutes ces garanties et représentations représentent uniquement nos propres engagements et garanties et ne garantissent pas qu'un tiers se conformera aux garanties et engagements du présent Accord.",
        content105: "8.8 Nous ne renonçons à aucun droit non mentionné dans le présent Accord pour limiter, exclure ou compenser notre responsabilité en cas de dommages dans toute la mesure de la loi applicable.",
        content106: "8.9 Après votre inscription, vous acceptez toutes les opérations que nous effectuons conformément aux règles stipulées dans le présent accord, et tous les risques encourus sont à votre charge.",
        content107: "9. Résiliation du Contrat",
        content108: "9.1 Ce site Web a le droit de mettre fin à tous les services de ce site Web conformément au présent accord. Cet accord sera résilié à la date à laquelle tous les services de ce site Web seront terminés.",
        content109: "9.2 Après la résiliation de cet accord, vous n'avez pas le droit d'exiger que ce site Web continue à lui fournir des services ou à remplir toute autre obligation, y compris, mais sans s'y limiter, d'exiger que ce site Web conserve ou vous divulgue toute information contenue dans son compte de site Web d'origine. à vous ou à un tiers, qui transmet toute information qu'il n'a pas lue ou envoyée.",
        content110: "9.3 La résiliation de cet accord n'affectera pas les autres responsabilités de la partie non-contrevenante à l'égard de la partie contrevenante.",
        content111: "10. Droits de propriété intellectuelle",
        content112: "10.1 Toutes les réalisations intellectuelles contenues dans ce site Web incluent, sans s'y limiter, les logos de sites Web, les bases de données, les conceptions de sites Web, les textes et graphiques, les logiciels, les photos, les vidéos, la musique, les sons et leurs combinaisons, les compilations de logiciels, les codes sources et logiciels associés (y compris les petites applications). ) Les droits de propriété intellectuelle des programmes et scripts) appartiennent à ce site Web. Vous ne pouvez pas reproduire, modifier, copier, envoyer ou utiliser l’un des éléments ou contenus ci-dessus à des fins commerciales.",
        content113: "10.2 Tous les droits contenus dans le nom de ce site Web (y compris, mais sans s'y limiter, le fonds de commerce et les marques, logos) appartiennent à la société.",
        content114: "10.3 Votre acceptation de cet accord sera considérée comme votre initiative de transférer les droits d'auteur de toute forme d'information que vous publiez sur ce site Web, y compris, mais sans s'y limiter : les droits de reproduction, les droits de distribution, les droits de location, les droits d'exposition, les droits de représentation, les droits de projection, les droits de diffusion, les droits de transmission d'informations sur Internet, les droits de tournage, les droits d'adaptation, les droits de traduction, les droits de compilation et autres droits transférables dont devrait jouir le titulaire du droit d'auteur sont exclusivement transférés gratuitement sur ce site Web. Ce site Web a le droit de déposer une déclaration indépendante. intenter une action en justice contre tout sujet pour contrefaçon et obtenir une indemnisation complète. Cet accord est valable pour tout contenu d'œuvre protégé par la loi sur le droit d'auteur que vous publiez sur ce site Web, que le contenu soit formé avant la signature de cet accord ou après la signature de cet accord.",
        content115: "10.4 Vous ne pouvez pas utiliser ou disposer illégalement des droits de propriété intellectuelle de ce site Web ou d’autres lorsque vous utilisez les services de ce site Web. Vous ne pouvez pas publier ou autoriser d’autres sites Web (et médias) à utiliser les informations publiées sur ce site Web sous quelque forme que ce soit.",
        content116: "11. Protection des informations",
        content117: "Les dispositions des « Conditions de confidentialité » publiées séparément sur ce site Web prévaudront.",
        content118: "12. Calcul",
        content119: "Tous les résultats de calcul des transactions ont été vérifiés par nos soins et toutes les méthodes de calcul ont été publiées sur le site Internet, mais nous ne pouvons garantir que l'utilisation du site Internet ne sera pas interrompue ou sans erreur.",
        content120: "13. Contrôle des exportations",
        content121: "Vous comprenez et reconnaissez que, conformément aux lois en vigueur, vous n'êtes pas autorisé à exporter, réexporter, importer ou transférer tout matériel (y compris les logiciels) sur ce site Web, vous garantissez donc que vous ne mettrez pas en œuvre, n'assisterez ou ne participerez pas activement à l'un des les exportations ou activités connexes mentionnées ci-dessus qui violent les lois et réglementations. Transfert ou autres violations des lois et réglementations applicables ; si une telle situation est découverte, vous devez nous la signaler activement en temps opportun et nous aider à la gérer.",
        content122: "14. Transfert",
        content123: "Les droits et obligations stipulés dans le présent Contrat lient également les cessionnaires, héritiers, exécuteurs testamentaires et administrateurs des parties qui bénéficient des droits et obligations. Vous ne pouvez pas le transférer à un tiers sans notre consentement, mais nous pouvons transférer nos droits et obligations en vertu du présent Accord à un tiers à tout moment et vous en informer.",
        content124: "15. Divisibilité",
        content125: "Si une disposition du présent Contrat est jugée par un tribunal compétent comme inapplicable, invalide ou illégale, cela n’affectera pas la validité des autres dispositions du présent Contrat.",
        content126: "16. Relation hors agence",
        content127: "Rien dans le présent Contrat ne sera réputé créer, impliquer ou autrement nous constituer en tant que votre agent, fiduciaire ou autre représentant, sauf disposition contraire du présent Contrat.",
        content128: "17. Abstention",
        content129: "La renonciation par nous ou par vous à la responsabilité d'une partie en cas de rupture de contrat ou autre responsabilité stipulée dans le présent Accord ne sera pas considérée ou interprétée comme une renonciation à toute autre responsabilité en cas de rupture de contrat ; le défaut d'exercer un droit ou un recours ne sera en aucun cas ne peut en aucun cas être interprété comme une renonciation à ces droits ou recours.",
        content130: "18. Titre",
        content131: "Tous les titres sont uniquement destinés à faciliter l'expression de l'accord et ne sont pas utilisés pour élargir ou limiter le contenu ou la portée des termes de l'accord.",
        content132: "19. Loi applicable",
        content133: "19.1 Avant de soulever un litige ou une réclamation, vous reconnaissez et acceptez de contacter d'abord ce site Web par e-mail pour résoudre le litige de manière informelle. Nous essaierons de résoudre votre litige en interne dès que possible ; les deux parties conviennent de résoudre le litige par le biais de négociations de bonne foi (discussions doit être confidentiel et protégé par les règles applicables et ne doit pas être utilisé comme preuve dans une procédure judiciaire).",
        content134: "19.2 Vous reconnaissez et acceptez qu'en cas de litige, controverse, désaccord ou réclamation, y compris l'existence, la validité, l'interprétation, l'exécution, la violation ou la résiliation des présentes Conditions ou tout litige découlant de ou en relation avec les présentes Conditions (« Litige » ), les parties susmentionnées soumettront le différend à la Cour internationale d'arbitrage de la Chambre de commerce internationale (« Cour internationale d'arbitrage de la CCI ») conformément au « Règlement d'arbitrage de la CCI » alors en vigueur. Le lieu de l'arbitrage sera Belize et la loi applicable à l'arbitrage sera déterminée conformément aux règles d'arbitrage de la Chambre de commerce internationale. Sauf accord contraire des parties, un seul arbitre sera nommé conformément au règlement d'arbitrage de la CCI. L'arbitre aura le pouvoir exclusif de statuer sur sa propre compétence, y compris, mais sans s'y limiter, toute objection à l'existence, à la portée ou à la validité de la convention d'arbitrage ou à l'arbitrabilité de toute réclamation ou demande reconventionnelle. Tout arbitrage se déroulera en anglais. Quelle que soit la manière dont l'arbitrage est mené, l'arbitre doit rendre une sentence écrite raisonnable et suffisante pour expliquer la sentence et les conclusions essentielles, le cas échéant, sur lesquelles la sentence est fondée. La sentence arbitrale sera définitive et contraignante pour les parties et pourra être exécutée devant tout tribunal compétent.",
        content135: "20. Efficacité et interprétation de l'accord",
        content136: "20.1 Cet accord prend effet lorsque vous cliquez sur la page d'inscription de ce site Web pour accepter de vous inscrire, terminer le processus d'inscription et obtenir un compte et un mot de passe pour ce site Web. Il lie à la fois ce site Web et vous.",
        content137: "20.2 Le droit d'interprétation finale de cet accord appartient à ce site Web.",
        content138: "Connaissez votre politique client et anti-blanchiment d’argent",
        content139: "1. Introduction",
        content140: "1.1 Nous nous engageons à respecter scrupuleusement les lois et réglementations relatives à la « Connaissance de votre client » et à la lutte contre le blanchiment d'argent et ne violerons pas intentionnellement la « Politique de connaissance de votre client et de lutte contre le blanchiment d'argent ». Dans le cadre de notre contrôle raisonnable, nous prendrons les mesures et technologies nécessaires pour vous fournir des services sûrs et vous protéger autant que possible des pertes causées par le comportement de blanchiment d'argent de suspects criminels.",
        content141: "1.2 Notre politique de connaissance de votre client et de lutte contre le blanchiment d'argent est un système de politique internationale complet qui comprend des politiques de connaissance de votre client et de lutte contre le blanchiment d'argent pour les différentes juridictions juridiques auxquelles vous êtes affilié.",
        content142: "2. Connaissez vos clients et les politiques anti-blanchiment d’argent sont les suivantes :",
        content143: "2.1 Promulguer des politiques de connaissance de la clientèle et de lutte contre le blanchiment d'argent et les mettre à jour de temps à autre pour répondre aux normes fixées par les lois et réglementations correspondantes ;",
        content144: "2.2 Promulguer et mettre à jour certains principes directeurs et règles d'exploitation de ce site Web, et nos employés fourniront des services conformément aux directives de ces principes et règles ;",
        content145: "2.3 Concevoir et mettre en œuvre des procédures de surveillance et de contrôle internes des transactions, telles que la vérification des identités par des moyens stricts et la constitution d'une équipe professionnelle responsable du travail de lutte contre le blanchiment d'argent ;",
        content146: "2.4 Adopter une approche de prévention des risques pour effectuer une diligence raisonnable et une supervision continue des clients；",
        content147: "2.5 Examiner et vérifier régulièrement les transactions effectuées；",
        content148: "2.6 Signaler les transactions suspectes aux autorités compétentes；",
        content149: "2.7 Les preuves d'identité, d'adresse et les enregistrements de transactions seront conservés pendant au moins six ans et pourront être soumis aux autorités réglementaires sans autre préavis.",
        content150: "3. Informations d'identité et vérification",
        content151: "3.1 Informations d'identité",
        content152: "3.1.1 Selon différentes réglementations en vigueur dans différentes juridictions et différents types d'entités, le contenu de vos informations que nous collectons peut être incohérent. En principe, les informations suivantes seront collectées auprès de personnes enregistrées.：",
        content153: "Informations personnelles de base : votre nom, votre adresse (et votre adresse permanente, si différente), votre date de naissance et votre nationalité et autres informations disponibles. La vérification de l'identité doit être basée sur des documents délivrés par des autorités officielles ou d'autres autorités similaires, tels que des passeports, des cartes d'identité ou d'autres documents d'identification requis et déclenchés par différentes juridictions. L'adresse que vous fournissez sera vérifiée à l'aide de méthodes appropriées, telles que la vérification du titre de transport de passagers ou de la note relative au taux d'intérêt ou la vérification de la liste électorale.",
        content154: "Photo valide : Avant de vous inscrire, vous devez fournir une photo de vous tenant votre pièce d'identité contre votre poitrine ;",
        content155: "Coordonnées : Numéro de téléphone/mobile et/ou adresse email valide.",
        content156: "3.1.2 Si vous êtes une société ou une autre entité juridique, nous collecterons les informations suivantes pour vous identifier ou identifier le bénéficiaire ultime de votre compte en fiducie.",
        content157: "Enregistrement et certificat d'enregistrement de la société ; copies des statuts et du mémorandum de la société ; documents de certification détaillés de la structure du capital de la société et de la description de la propriété, prouvant la résolution du conseil d'administration du mandant autorisé qui détermine l'ouverture et l'exécution du compte sur ce site ; société administrateurs qui doivent être fournis, le cas échéant, les pièces d'identité de l'actionnaire majoritaire et de la personne autorisée à signer le compte sur ce site Internet ; l'adresse professionnelle principale de la société, si elle est différente de l'adresse postale de la société, indiquer l'adresse postale. Si l'adresse locale d'une entreprise ne correspond pas à son adresse professionnelle principale, elle est considérée comme un client à risque plus élevé et devra soumettre des documents supplémentaires supplémentaires.",
        content158: "En fonction des différentes réglementations en vigueur dans différentes juridictions et différents types d'entités, nous exigeons d'autres certifications et documents délivrés par les autorités ainsi que les documents que nous jugeons nécessaires.",
        content159: "3.1.3 Nous n'acceptons que les versions anglaises ou chinoises des informations d'identité. Dans le cas contraire, veuillez traduire vos informations d'identité en anglais et les faire légaliser.",
        content160: "3.2 Confirmer la vérification",
        content161: "3.2.1 Nous vous demandons de fournir le contenu complet de la page de votre document d’identification.",
        content162: "3.2.2 Nous demandons une photo de vous tenant votre pièce d’identité contre votre poitrine.",
        content163: "3.2.3 Les copies des pièces justificatives doivent généralement être vérifiées par rapport aux pièces justificatives originales. Toutefois, une copie est acceptable si un certificateur approprié et digne de confiance peut certifier que la copie est une reproduction exacte et complète du document original. Ces certificateurs comprennent les ambassadeurs, les commissaires judiciaires, les magistrats locaux, etc.",
        content164: "3.2.4 L'obligation d'identifier le bénéficiaire final et le contrôle du compte consiste à déterminer quelles personnes possèdent ou contrôlent en fin de compte le client direct et/ou à établir que les transactions en cours sont exécutées pour le compte de tiers. S'il s'agit d'une entreprise, l'identité des principaux actionnaires (tels que ceux détenant 10 % ou plus des droits de vote) doit être vérifiée. Généralement, la détention de 25 % des actions sera considérée comme étant dans le cadre d'un risque normal et l'identité de l'actionnaire doit être vérifiée ; la détention de 10 % des actions ou la détention de plus de droits de vote ou d'actions est considérée comme une situation à haut risque, et l'identité de l’actionnaire doit être vérifiée.",
        content165: "4. Surveiller les transactions",
        content166: "4.1 Nous fixons et ajustons les limites maximales quotidiennes des transactions et des retraits de devises de temps à autre en fonction de la sécurité et des conditions réelles des transactions.;",
        content167: "4.2 Si des transactions se produisent fréquemment chez un utilisateur enregistré ou s'il existe des circonstances déraisonnables, notre équipe de professionnels évaluera et décidera si elles sont suspectes ;",
        content168: "4.3 Si nous déterminons qu'une transaction est suspecte sur la base de notre propre jugement, nous pouvons prendre des mesures restrictives telles que suspendre la transaction, rejeter la transaction, voire annuler la transaction dès que possible si possible, et la signaler à l'autorité compétente, mais ne vous en informera pas.;",
        content169: "4.4 Nous nous réservons le droit de rejeter les demandes d'enregistrement de personnes provenant de juridictions qui ne respectent pas les normes internationales de lutte contre le blanchiment d'argent ou de personnes pouvant être considérées comme des personnes politiquement exposées. Nous nous réservons le droit de suspendre ou de mettre fin aux transactions suspectes, à notre seul jugement. à tout moment, mais nous ne le faisons pas. Violer les devoirs ou responsabilités qui vous sont dus.",
    },
    register: {
        formTitle1: "créer",
        formTitle2: "Inscrivez-vous en utilisant votre e-mail ou votre numéro de téléphone portable",
        formTitle3: "Compte",
        field1: "Le code de vérification",
        field2: "Obtenir",
        field3: "Confirmez le mot de passe",
        field4: "Code d'invitation",
        field41: "（Facultatif）",
        field5: "J'ai lu et accepté",
        field6: "Conditions d'utilisation",
        placeholder1: "veuillez entrer le code de vérification",
        content7: "veuillez entrer le code de vérification",
        content12: "Veuillez entrer le numéro de téléphone",
        content13: "Veuillez entrer le mot de passe",
        content14: "veuillez saisir votre email",
        content18: "Veuillez saisir à nouveau le mot de passe",
        content19: "Veuillez accepter les conditions d'utilisation",
        content20: "Veuillez entrer le code de vérification correct",
        content23: "Vous n'avez toujours pas reçu votre code de vérification ?",
        content24: "Veuillez entrer le code de vérification ci-dessous",
        registerSuccess: "succès de l'inscription",
        content25: "Les mots de passe sont incohérents",
        content26: "Veuillez saisir la bonne adresse e-mail",
        content27: "Réinitialisation du mot de passe réussie"
    },
    download: {
        content1: "Négociez à tout moment et en tout lieu",
        content2: "Parfaitement compatible avec plusieurs terminaux, répondant aux besoins de transaction de divers scénarios à tout moment",
        content3: "Vérifiez à tout moment les conditions du marché en temps réel",
        content4: "Achetez et vendez facilement du BTC, ETH, XRP et d'autres actifs numériques",
        content5: "Recevez des alertes précoces sur les changements de prix des crypto-monnaies qui vous intéressent",
        content6: "Afficher les prix des prêts au comptant Bitcoin, des prêts à terme et des options",
        content7: "Comparez les prix des cryptomonnaies sur différentes bourses",
        title: "télécharger",
        install: "Notes d'installation",
        one: "1、Autoriser le téléchargement des fichiers de description",
        two: "2、Fichier de description téléchargé",
        three: "3、Accédez à [Paramètres] sur votre téléphone et recherchez [Général]",
        four: "4、Profils et gestionnaire de périphériques",
        five: "5、Cliquez sur le profil",
    },
    identity: {
        noAuthentication: {
            title: "Certification junior",
            pleaseSelectYourCountry: "S'il vous plaît sélectionnez votre pays",
            name: "Nom",
            enterName: "S'il vous plaît entrez votre nom",
            city: "ville",
            enterCity: "Veuillez entrer votre ville",
            IDNumber: "Numéro d'identification",
            enterIDNumber: "Veuillez entrer votre numéro d'identification",
        },
        noAdvancedAuthentication: {
            title: "Certification avancée",
            infoCountry: "pays/région",
            infoName: "Nom",
            infoNumber: "Numéro d'identification",
            infoAddress: "adresse",
        },
        text: "non certifié",
        recertification: "recertification",
        certification: "Attestation",
        authenticationInProgress: "vérifié",
        inAuthentication: "Certification en cours",
        uploadTheFrontOfTheDocument: "Téléchargez le recto de la pièce d'identité",
        uploadTheReverseSideOfTheDocument: "Téléchargez le verso du certificat",
        uploadAPhotoOfAHandHeldID: "Téléchargez une photo de votre carte d'identité en main",
        pleaseUpload: "s'il te plaît ",
        pleaseCompleteOrdinaryCertificationFirst: "Il faut attendre le succès de l'examen de certification primaire",
        refused: "rejeté",
        reason: "raison",
        succeeded: "réussi",
        uploading: "Téléchargement",
        newTitle: "Sélectionnez le pays où votre pièce d'identité a été délivrée",
        pleaseOrdinaryCertificationFirst: "Veuillez d'abord effectuer une certification primaire",
        text1: "Modifier l'avatar",
        text2: "Surnom",
        text3: "compte",
        text4: "Modification du pseudo",
        text5: "nouveau surnom",
        text6: "Entrez votre nouveau pseudo",
        text7: "ancien mot de passe",
        text8: "Entrez votre ancien mot de passe",
        text9: "nouveau mot de passe",
        text10: "nouveau mot de passe",
        text11: "Confirmez le mot de passe",

        text12: "Saisissez à nouveau votre nouveau mot de passe",
        text13: "nouvelle boîte aux lettres",
        text14: "Le code de vérification",
        text15: "veuillez entrer le code de vérification",
        text16: "votre numéro de téléphone portable",
        text17: "Nouveau numéro de téléphone portable",
        text18: "Veuillez entrer votre numéro de téléphone",
        text19: "Code de vérification du téléphone portable",
        text20: "Certification junior",
        text21: "Veuillez vérifier vos informations d'identité",
        text22: "Pays",
        text23: "Ville",
        text24: "Entrez votre ville",
        text25: "Entrez votre nom",
        text26: "Votre numéro d'identification",
        text27: "Entrez votre numéro d'identification",
        text28: "Photo recto de la carte d'identité",
        text29: "Cliquez pour télécharger vos documents",
        text30: "ré-télécharger",
        text31: "télécharger",
        text32: "Tenir une photo d'identité",
        text33: "Cliquez pour télécharger votre photo d'identité",
        text34: "Vous avez lié Google Authenticator",
        text35: "clé",
        text36: "Veuillez saisir le code de vérification Google",
        text37: "envoyer",
        text38: "Ne peux pas être vide",
        text39: "Le format de l'e-mail est incorrect",
        text40: "Deux mots de passe sont incohérents",
        text41: "Copié avec succès",
        text42: "Échec de la copie",
        text43: "Modification d'avatar",
        text44: "changer le mot de passe",
        text45: "Liaison par e-mail",
        text46: "Modifier l'e-mail",
        text47: "Reliure de téléphone portable",
        text48: "Modifier un téléphone portable",
        text49: "Vérification Google",
        text50: "Lier l'authentificateur Google",
        text51: "Entrez le code de vérification Google",
        text52: "sauvegarder",
        text53: "Confirmer la soumission",
        text54: "Réviser",
        text55: "obligatoire",
        text56: "Désactivez la vérification Google",
        text57: "L'étape suivante",
        text58: "Finition",
        text59: "La vérification Google est liée",
        text60: "La vérification Google a été dégroupée",
        text61: "La vérification Google a été dégroupée",
        text62: "Certification avancée soumise",
        text63: "Aucune image téléchargée",
        text64: "Renvoyer",
        text65: "Veuillez entrer un pseudo",
        text66: "Pseudonyme enregistré avec succès",
        text67: "Aucune image téléchargée pour l'instant",
        text68: "Réinitialisation du mot de passe terminée",
        text69: "E-mail modifié avec succès",
        text70: "Avatar enregistré avec succès",
        text71: "Télécharger une image",
        text72: "votre boîte aux lettres",
        text73: "Entrez votre nouvel email",
        text74: "votre nom",
        text75: "Aller à l'authentification",
        text76: "Veuillez vérifier votre photo d'identité"
    },
    retrievePassword: {
        formTitle1: "réinitialiser le mot de passe",
        formTitle2: "Pour la sécurité de vos actifs, les transactions sont interdites dans les 24 heures suivant le changement du mot de passe.",
        formTitle3: "Récupérer mot de passe",
        formTitle4: "Confirmez les modifications",
        formTitle5: "Veuillez saisir l'ancien mot de passe",
        formTitle6: "Veuillez entrer un nouveau mot de passe"
    },
    home: {
        title1: "Plateforme de trading de crypto-monnaie la plus populaire",
        title3: "pourquoi est-ce ",
        title4: " Il s'agit d'une plate-forme blockchain open source haute performance conçue pour prendre en charge et exploiter une infrastructure numérique sécurisée, conforme et prévisible.",
        field1: "Échange établi",
        field2: "Services financiers sur actifs numériques",
        field3: "Des dizaines de millions d'utilisateurs",
        field4: "Créer plusieurs fonds de protection des investisseurs",
        field5: "Trading rapide",
        field6: "CME Gruop est capable de faire correspondre les ordres avec une latence ultra-faible pour une expérience de trading ultime.",
        title5: "tendance du marché",
        title6: "voir plus",
        table1: "Devise",
        table2: "Dernier prix",
        table3: "Des hauts et des bas sur 24 heures",
        table4: "24H Volume",
        table5: "Volume de transactions sur 24H",
        table6: "Citations",
        title7: "à propos ",
        title8: " Nous nous engageons à fournir aux utilisateurs les outils de trading les plus avancés, une technologie innovante et un service client de qualité inégalée.",
        title9: "Le domaine des actifs numériques évolue chaque jour et ce n’est qu’en étant clairvoyant et en progressant rapidement que nous pourrons suivre le rythme de l’innovation technologique.",
        title901: " Fournir les services de trading d'actifs numériques les plus avancés à nos utilisateurs et fournisseurs de liquidités mondiaux.",
        title10: "Nous croyons fermement que chaque utilisateur mérite la meilleure expérience de trading.",
        title11: "Une sécurité à laquelle vous pouvez faire confiance",
        title12: "Nos mesures de sécurité sophistiquées et nos fonds SAFU protègent vos actifs numériques de tous les risques. ",
        title13: "Meilleurs taux de transactions",
        title14: "Tarifs de transaction préférentiels, droits VIP compétitifs et profitez de services de la meilleure qualité.",
        title15: "Service client 24h/24 et 7j/7",
        title16: "Mode de fonctionnement à temps plein, 24h/24 et 7j/7, nous répondrons à vos demandes pertinentes dans les plus brefs délais.",
        title17: "0 frais de négociation",
        title18: "Utilisez plusieurs méthodes de paiement pour échanger des devises numériques sans frais de traitement, de manière sûre et rapide.",
        title19: "Négociez à tout moment et en tout lieu",
        title20: "Parfaitement compatible avec plusieurs terminaux, répondant aux besoins de transaction de divers scénarios à tout moment",
        title21: "Vérifiez à tout moment les conditions du marché en temps réel",
        title22: "Achetez et vendez facilement du BTC, ETH, XRP et d'autres actifs numériques",
        title23: "Recevez des alertes précoces sur les changements de prix des crypto-monnaies qui vous intéressent",
        title24: "Afficher les prix des prêts au comptant Bitcoin, des prêts à terme et des options",
        title25: "Comparez les prix des cryptomonnaies sur différentes bourses",
        title26: "S'inscrire maintenant",
        title27: "Dollar",
        title28: "24H Volume",
        title29: "Fournisseur de liquidité intégré",
        title30: "utilisateur",
        title31: "Plus",
        title32: "Binance Loan ajoute CTSI et KSM comme actifs de garantie et lance des réductions de taux d'intérêt BUSD et USDT !",
        title33: "Notre avantage",
        title34: "sûr et stable",
        title35: "Équipe technique de premier plan, protection de sécurité complète, recherche et développement indépendants de transactions à haut débit, toujours stables et utilisables lors de transactions massives.",
        title36: "Conformité professionnelle",
        title37: "Une équipe d'exploitation professionnelle, avec de nombreuses années d'expérience dans la blockchain et la finance, est titulaire d'une licence de négociation d'actifs numériques conforme et dispose d'une garantie de réserve à 100 %.",
        title38: "L'utilisateur d'abord",
        title39: "Face au marché mondial, prise en charge de plusieurs langues, fonctionnement 24h/24 et 7j/7, support communautaire solide et service client professionnel.",
        title40: "haute performance",
        title41: "300 000 transactions par seconde et temps de réponse aux commandes inférieur à 1 milliseconde.",
        title42: "à propos de nous",
        title43: "Négociez à tout moment et en tout lieu",
        title44: "Parfaitement compatible avec plusieurs terminaux, répondant aux besoins de transaction de divers scénarios à tout moment",
        title45: "（Scannez le code pour télécharger）",
        title46: "Actualités sur la monnaie numérique",
        title47: "notre partenaire commercial",
        title48: "Liste des perdants",
        title49: "Chiffre d'affaires 24h",
        title50: "Augmentation et diminution sur 24h"
    },
    quotation: {
        marketTrading: "Citations",
        turnover: "Volume",
        optional: "Facultatif",
        contractMarket: "marché des contrats",
        currencyPair: "paire de devises",
        latestPrice: "Le dernier prix",
        upsAndDowns: "Changement de devis",
        highest: "Le plus élevé",
        lowest: "le plus bas",
        transactionAmount: "Volume des transactions",
        operate: "fonctionner",
        buy: "commerce",
        topTurnovers: "Liste des transactions",
        topGainers: "Liste des gagnants",
        tradingPair: "paire de trading",
        buyOrder: "Acheter",
        sellOrder: "ordre de vente"
    },
    accountChange: {
        demo: "simulation",
        realAccount: "compte réel",
        demoAccount: "Compte démo",
        accountRole: "Rôle du compte",
        pleaseSelect: "Choisissez s'il vous plaît",
        AccountChangeType: "Type de changement de compte",
        AccountType: "Type de compte",
        currencyType: "Type de devise",
        allType: "Tous les types",
        allAccount: "Tous les comptes",
        allCoin: "Toutes les devises",
        coin: "Devise",
        amount: "Modifier le montant",
        balance: "Solde du compte",
        time: "temps",
        title: "Enregistrements de modifications de compte",
        filtrar: "filtre",
        check: "basculer vers",
        showOnly: "Afficher uniquement les avoirs en devises",
        holdAssets: "Solde en devises",
        accountList: "Liste des comptes"
    },
    contractTransaction: {
        chart: "graphique",
        basicEdition: "Version de base",
        depthMap: "Carte de profondeur",
        second: "Deuxième",
        minute: "indiquer",
        day: "ciel",
        week: "semaine",
        month: "lune",
        open: "ouvrir",
        trade: "commerce",
        buyLong: "long achat",
        buyShort: "achat à découvert",
        cannotBuy: "Cette devise ne prend pas en charge les transactions de contrat de livraison",
        cannotBuy1: "Cette devise ne prend pas en charge le trading perpétuel",
        tradingAssets: "actifs commerciaux",
        amountAvailable: "Quantité disponible",
        timeStr: "temps",
        transactionPeriod: "période de négociation",
        quantity: "quantité",
        enterQuantity: "Veuillez entrer la quantité",
        buyIn: "Allez-y longtemps",
        latestTransaction: "dernière transaction",
        time: "temps",
        direction: "direction",
        price: "prix",
        sell: "court",
        positionOrder: "Bon de livraison",
        historicalOrders: "Commandes historiques",
        product: "produit",
        realAccount: "(vrai compte)",
        demoAccount: "(Compte démo)",
        openingTime: "temps de commande",
        openingPrice: "Prix ​​d'ouverture",
        curretPrice: "Prix ​​actuel",
        liquidationPrice: "Le dernier prix",
        profitOrLoss: "Profit et perte",
        endTime: "Heure de fermeture",
        areYouSureToBuy: "Êtes-vous sûr de vouloir acheter ?",
        areYouSureToLongBuy: "Etes-vous sûr de vouloir y aller longtemps ?",
        areYouSureToShortBuy: "Etes-vous sûr de vouloir acheter à découvert ?",
        successfullyBought: "Achetez avec succès",
        minBuy: "Achat minimum",
        assets: "actifs",
        withdraw: "retirer",
        entrustedOrder: "Commande",
        total: "total",
        continueToPlaceOrder: "Continuer à passer une commande",
        orderConfirmation: "Confirmation de commande",
        buyRu: "acheter",
        sellChu: "vendre",
        areYouSureToSell: "Etes-vous sûr de vouloir vendre ?",
        operation: "fonctionner",
        buyCover: "position du couvercle",
        commitSuccess: "Transaction réussie",
        onlyEnterIntegers: "Seuls des nombres entiers peuvent être saisis",
        trend: "S'orienter",
        lotteryTime: "Date limite de commande",
        countdown: "Compte à rebours",
        period: "Problème",
        cycle: "Période de temps",
        unlimited: "Illimité",
        put: "Put",
        call: "Call",
        pending: "À la commission",
        unopened: "Pas encore dessiné",
        opened: "Tirage au sort",
        settlementPrice: "Prix ​​de règlement",
        rateOfReturn: "taux de retour",
        currentPositions: "Position actuelle",
        positionQuantity: "Quantité de position",
        guarantee: "marge",
        text1: "Call",
        text2: "Put",
        text3: "En attendant d'acheter",
        text4: "Quantité commandée",
        text5: "Le nombre de transactions",
        text6: "Annuler",
        text7: "Délai de livraison",
        text8: "Rappel d'annulation",
        text9: "Etes-vous sûr de vouloir annuler la commande ?",
        text10: "Quantité illégale",
        text11: "Rappel de commande",
        text12: "Êtes-vous sûr d’acheter de façon haussière ?",
        text13: "Etes-vous sûr d'acheter le put ?",
        text14: "Annulation réussie"
    },
    mining: {
        title1: "La première plateforme mondiale d'extraction d'actifs numériques blockchain",
        title2: "Ouvert et transparent · sûr et stable",
        title3: "Veuillez sélectionner la devise",
        title4: "Rendement annualisé minier",
        title5: "ciel",
        title6: "Période minière",
        title7: "détails du produit",
        btn: "Le mien maintenant",
        btn2: "fermeture",
        tabs1: "en cours",
        tabs2: "racheté",
        table1: "nom du produit",
        table2: "Période minière",
        table3: "Temps d'exploitation minière",
        table4: "Montant minier",
        table5: "Date d'expiration",
        table6: "Produit jusqu'à l'échéance",
        table7: "Statut de la commande",
        egularMining: "Miner régulièrement",
        inProgress: "en cours",
        redeemed: "racheté",
        redemption: "rachat",
        regularMining: "Miner régulièrement",
        earlyRedemption: "rachat anticipé",
        need: "besoin",
        handlingFee: "frais de gestion",
        redemptionConfirmationMessage: "Veuillez confirmer s'il faut procéder à un rachat à l'avance ?",
        form1: "Rendement annualisé minier",
        form2: "Période minière",
        form3: "Monnaie minière",
        form4: "Montant de l'abonnement (minimum/maximum)",
        form5: "Frais de rachat anticipé: ",
        form6: "prix d'achat",
        form7: "Fonds disponibles",
        form8: "prix d'achat",
        form9: "Fonds disponibles",
        form10: "Veuillez saisir le montant de l'achat",
        form11: "tous",
        form12: "Revenu estimé",
        form13: "Fonds disponibles insuffisants",
        form14: "Veuillez saisir le montant de l'achat",
        form15: "Ma commande",
        form16: "revenu",
        form17: "détails de la commande",
        form18: "Instructions pour le remboursement anticipé:",
        form19: "Montant de l'abonnement (minimum)",
        form20: "Montant de l'abonnement (maximum)",
        form21: "Quantité détenue",
        form22: "Gagner du temps",
        form23: "heure de rachat",
        form24: "sur",
        form25: "Abonnement réussi",
        form26: "Rédemption réussie",
        form27: "Gestion financière facile avec plus de 25 % de rendement, surperformant le marché, et vous pouvez également recevoir des récompenses après l'achat",
        form28: "Invitez des amis à recevoir une récompense de 50 USDT, et les bons de contrat peuvent également rapporter une commission de 20 %."
    },
    assets: {
        content1: "Actifs du compte",
        content2: "Les gains d'aujourd'hui",
        content3: "compte courant",
        content4: "Le compte réel correspond aux actifs réels de votre compte, qui peuvent être utilisés pour les transactions et les retraits.",
        content5: "Retirer des pièces",
        content6: "Déposer des pièces",
        content7: "échange",
        content8: "transfert",
        content9: "Choisissez s'il vous plaît",
        content10: "Compte démo",
        content11: "compte réel",
        content12: "acquérir des actifs",
        content13: "Êtes-vous sûr de vouloir passer à un compte réel ?",
        content14: "Êtes-vous sûr de vouloir passer à un compte démo ?",
        content15: "obtenir du succès",
        table1: "Devise",
        table2: "quantité",
        table3: "geler",
        table4: "Disponible",
        dialog1: "détails du produit",
        dialog2: "1、Cet actif ne peut être utilisé qu'en mode compte démo",
        dialog3: "2、Pour éviter les abus, un seul rachat est autorisé par mois.",
        dialog4: "3、La réception des actifs ajoutera le montant des actifs reçus sur la base initiale et ne modifiera pas le statut de la position.",
        dialog5: "4、Si vous avez d'autres questions, veuillez contacter le service client",
        dialog6: "fermeture",
        dialog7: "Obtenez des ressources de démonstration maintenant",
        dialog8: "transférer dans",
        dialog9: "transfert",
        dialog10: "changer",
        dialog11: "Évaluation",
        dialog12: "Actifs de démonstration",
        dialog13: "Mon compte",
        dialog14: "Actif total du compte",
        dialog15: "Aperçu des actifs",
        dialog16: "Valorisation totale des actifs",
        dialog17: "Évaluation des actifs",
        dialog18: "Les gains d'aujourd'hui",
        dialog19: "geler les fonds",
        dialog20: "Vous pouvez recevoir un quota d'actifs simulé chaque mois",
        dialog21: "recevoir",
        dialog22: "Vous avez reçu avec succès",
        dialog23: '30 jours de revenus cumulés',
        dialog24: "Produit financier",
        dialog25: "Ma gestion financière",
        dialog26: "Gestion financière historique",
        dialog27: "enregistrement de l'histoire",
        dialog28: "Dossiers de financement"
    },
    validator: {
        dialog14: "validateur",
        dialog15: "Lier le validateur",
        dialog16: "commutateur de validateur",
        dialog17: "Le code de vérification",
        dialog18: "Code QR du validateur",
        dialog19: "clé du validateur",
        dialog20: "sauvegarder",
        dialog21: "Veuillez sélectionner un validateur",
        dialog22: "copier la clé",
        dialog23: "Enregistré avec succès",
        dialog24: "veuillez entrer le code de vérification",
        dialog25: "Rappel amical : avant de confirmer la sauvegarde, veuillez ouvrir l'application correspondante et scanner le code QR ci-dessous ou ajouter la clé manuellement !",
        dialog26: "L'activation de votre authentificateur offre une sécurité maximale pour votre compte.",
        dialog27: "Vous devrez saisir un code de vérification unique accessible via votre téléphone mobile chaque fois que vous vous connecterez ou retirerez des fonds.",
        dialog28: "Configurer le validateur",
        dialog29: "aide",
        dialog30: "Vous n'avez pas lié le code d'authentification. Bénéficiez d’une protection renforcée avec un authentificateur.",
        dialog31: "Le validateur n'a pas encore été configuré, veuillez d'abord le configurer",
        dialog32: "Aller aux paramètres"
    },
    recharge: {
        title1: "Vous ne savez pas comment acheter en utilisant la monnaie numérique ?",
        title2: "Seulement trois étapes faciles!",
        title3: "Sélectionnez la devise numérique que vous souhaitez acheter dans le menu déroulant et entrez la quantité ou le montant de l'achat.",
        title4: "Choisissez votre mode de paiement préféré",
        title5: "Une fois le paiement confirmé, la monnaie numérique que vous avez achetée sera automatiquement transférée sur votre compte.",
        title6: "Veuillez sélectionner la devise que vous souhaitez recharger",
        title7: "Dossier de dépôt",
        table1: "Devise",
        table2: "quantité",
        table3: "Nom de la chaîne",
        table4: "État",
        table5: "raison",
        table6: "temps",
        table7: "Voir le bon",
        dialog1: "Veuillez entrer le code de vérification ci-dessous",
        dialog2: "code de paiement",
        dialog3: "copie",
        dialog4: "adresse",
        dialog5: "Montant du dépôt",
        dialog6: "Capture d'écran de la transaction",
        dialog7: "avis important",
        dialog8: "Merci de renseigner le montant du dépôt",
        dialog9: "télécharger une image",
        dialog10: "Veuillez saisir le contenu",
        dialog11: "Veuillez télécharger des captures d'écran de la recharge",
        dialog12: "Pas révisé",
        dialog13: "rejeté",
        dialog14: "Déposé",
        tip1: "Soumis avec succès",
        tip2: "Copié avec succès",
        tip3: "Échec de la copie",
        tip4: "Veuillez sélectionner la devise que vous souhaitez recharger",
        tip5: "1.L'adresse de dépôt ci-dessus est l'adresse de paiement officielle de la plateforme. Veuillez rechercher l'adresse de dépôt officielle de la plateforme. Toute perte de fonds causée par un dépôt erroné sera à votre charge.；",
        tip6: "2.Veuillez vous assurer que votre ordinateur et votre navigateur sont sécurisés pour éviter que les informations ne soient falsifiées ou divulguées.；",
        tip7: "3.Après avoir rechargé l'adresse ci-dessus, l'ensemble du nœud du réseau doit être confirmé avant que le compte puisse être reçu.；",
        tip8: "4.Veuillez sélectionner le système de jetons et la devise ci-dessus et transférer le montant correspondant pour le dépôt. Veuillez ne transférer aucun autre actif non lié, sinon il ne sera pas récupéré.",
        tip9: "Enregistrer le code QR",
        tip10: "recharger",
        tip11: "avis important",
        tip12: "Liens hors site",
        tip13: "Recharger le code QR",
        tip14: "L'étape suivante",
        tip15: "Recharger l'enregistrement",
        tip16: "Détails de la recharge",
        tip17: "Adresse de dépôt",
        tip18: "Paiement rapide",
        tip20: "Solde insuffisant",
        tip22: "Soumis, en attente de confirmation",
        tip23: "en attente de paiement",
        tip24: "Montant",
        tip25: "Le paiement a été soumis, en attente de confirmation de blocage",
        tip26: "Paiement refusé",
        tip27: "Échec du paiement",
        tip28: "Paiement réussi",
        bankChannel: "Canal carte bancaire",
        tip29: "Recherches historiques",
        tip30: "Liste des devises",
        tip31: "Sélectionnez réseau",
        tip32: "Lorsque vous rechargez cette devise, veuillez recharger uniquement via les réseaux pris en charge ci-dessous. Si vous rechargez via d'autres réseaux, les fonds seront perdus.",
        tip33: "Lorsque vous retirez cette devise, veuillez effectuer votre retrait uniquement via les réseaux pris en charge ci-dessous. Si vous retirez via d'autres réseaux, les fonds seront perdus.",
        tip34: "Recharge rapide",
        tip35: "Scannez le code pour recharger",
        tip36: "Recharge manuelle",
        tip37: "Réseau de recharge",

        tip38: "Adresse de recharge",
        tip39: "Montant de la recharge",
        tip40: "Téléchargement du reçu de transaction",
        tip41: "télécharger une image",
        tip42: "confirmer la soumission",
        tip43: "confirmer le paiement",
        tip44: "Remarque : veuillez confirmer l'adresse dont vous avez besoin pour recharger. Nous ne sommes pas responsables de la perte d'argent si vous la remplissez incorrectement.",
        tip45: "rappeler",
        tip46: "Adresse copiée avec succès!",
        tip47: "Échec de la copie de l'adresse!",
        tip48: "Solde disponible insuffisant",
        tip49: "La recharge a été soumise avec succès et est en attente de confirmation sur la chaîne.",
        tip50: "La recharge a échoué",
        tip51: "Le montant de la recharge est erroné",
        tip52: "La connexion a échoué",
        tip53: "Portefeuille non connecté",
        tip54: "Connexion rejetée",
        tip55: "Soumis avec succès, en attente d'examen et d'approbation du service client",
        tip56: "Veuillez télécharger une capture d'écran du reçu de transaction",
        tip57: "Échec de la soumission",
        tip58: "Recharger l'enregistrement",
        tip59: "Registre des retraits",
        tip60: "Recharger la devise",
        tip61: "Devise de retrait",
        tip62: "Adresse de recharge",
        tip63: "Les captures d'écran montrent",
        tip64: "Cliquez pour agrandir l'image",
        tip65: "Temps de recharge",
        tip66: "Raison du rejet",
        tip67: "Montant de retrait",
        tip68: "Montant reçu",
        tip69: "Réseau de retrait d'espèces",
        tip70: "Adresse de retrait",
        tip71: "Délai de retrait",
        tip72: "fermeture",
        tip73: "Capture d'écran de la transaction",
        tip74: "à l'étude",
        tip75: "rejeté",
        tip76: "complété",
        tip77: "rejeté",
        tip78: "Vérifié",
        tip79: "Décaissé",
        tip80: "extrait",
        tip81: "réseau de transfert",
        tip82: "Adresse/Facture",
        tip83: "Tout retirer",
        tip84: "Veuillez ne pas retirer d'argent directement à l'adresse du financement participatif ou de l'ICO, sinon vous ne pourrez pas recevoir les jetons émis par le financement participatif ou l'ICO.",
        tip85: "Quantité d'arrivée",
        tip86: "Confirmer le retrait",
        tip87: "Mauvaise adresse de retrait",
        tip88: "Montant de retrait erroné",
        tip89: "Le montant à retirer est d'au moins",
        tip90: "Le montant maximum de retrait est de",
        tip91: "La demande de retrait a été soumise",
        tip92: "liste d'adresses",
        tip93: "Vous n'avez pas encore ajouté d'adresse",
        tip94: "Ajoutez-le maintenant",
        tip95: "Ajoutez l'adresse",
        tip96: "Ajouter une nouvelle adresse",
        tip97: "Choisissez s'il vous plaît",
        tip98: "adresse",
        tip99: "Veuillez sélectionner un réseau de transfert",
        tip100: "Veuillez entrer l'adresse",
        tip101: "Adresse ajoutée avec succès",
        tip102: "en transaction",
    },
    fundExchange: {
        title1: "Comment échanger des fonds ?",
        title2: "Sélectionnez la devise appropriée à convertir.",
        title3: "Sélectionnez la devise appropriée à convertir.",
        title4: "Entrez le montant à échanger et cliquez sur Soumettre pour terminer l'échange de fonds.",
        title5: "depuis",
        title6: "arriver",
        title7: "Quantité d'échange",
        title8: "taux de change",
        title9: "Disponible",
        title10: "Veuillez saisir la quantité à échanger",
        title11: "Il n’existe pas de paire de trading correspondante pour l’échange.",
        title12: "Veuillez entrer la quantité à échanger",
        title13: "Rédemption réussie",
        title14: "Dossier d'échange",
        table1: "Utiliser la devise",
        table2: "quantité",
        table3: "taux de change",
        table4: "Monnaie d'échange",
        table5: "Reçu",
        table6: "temps",
        title15: "depuis",
        title16: "Il n'y a actuellement aucun actif disponible, veuillez en sélectionner un autre"
    },
    fundTransfer: {
        title1: "Comment transférer des fonds ?",
        title2: "Sélectionnez le compte courant qui doit être transféré.",
        title3: "Sélectionnez le compte que vous souhaitez transférer.",
        title4: "Entrez le montant du transfert et cliquez sur Soumettre pour terminer le transfert.",
        title5: "Devise",
        title6: "Quantité transférée",
        title7: "Veuillez saisir le montant à transférer",
        title8: "dossier de transfert",
        title9: "Veuillez sélectionner le type de portefeuille",
        title10: "Veuillez sélectionner la devise",
        title11: "Veuillez sélectionner le type de portefeuille",
        title12: "Veuillez sélectionner la devise",
        title13: "Veuillez saisir le montant à transférer",
        title14: "Soumis avec succès",
        table1: "Transférer le compte",
        table2: "devise de transfert",
        table3: "Quantité transférée",
        table4: "compte de transfert",
        table5: "temps",
        table6: "transfert",
        table7: "Transfert de fonds",
        table8: "Transférer à",
        table9: "dossier de transfert",
        table10: "Détails du transfert",
        table11: "Transfert réussi",
        table12: "Le transfert a échoué",
        table13: "Le compte courant ne peut pas être transféré"
    },
    withdrawal: {
        title1: "avis important",
        title2: "1.Afin d'éviter les arbitrages, vous pouvez demander un retrait de devises lorsque le volume des transactions atteint le quota.",
        title3: "2.La demande de retrait sera créditée dans les 24 heures suivant sa soumission. Si le retrait n'est pas reçu après le délai de retrait estimé, veuillez consulter le service client en ligne.",
        title4: "3.Après avoir soumis la demande de retrait, les fonds sont gelés car le retrait est en cours et les fonds sont temporairement détenus par le système. Cela ne signifie pas que vous avez perdu l'actif ou qu'il y a une anomalie avec l'actif.",
        title5: "Devise",
        title6: "adresse du portefeuille",
        title7: "Quantité de pièces retirées",
        title8: "Registre de retrait de pièces",
        title9: "Veuillez sélectionner la devise",
        title10: "Veuillez sélectionner le type de chaîne",
        title101: "",
        title11: "Entrez s'il vous plait",
        title12: "adresse du portefeuille",
        title13: "Soumis avec succès",
        title14: "à l'étude",
        title15: "Examen réussi",
        title16: "Échec de l'audit",
        title17: "Décaissé",
        title18: "Veuillez saisir ou coller l'adresse de votre portefeuille",
        title19: "Veuillez saisir le montant du retrait",
        title20: "frais de gestion",
        title21: "Arrivée réelle",
        table1: "Devise",
        table2: "Quantité de pièces retirées",
        table3: "Nom de la chaîne",
        table4: "adresse du portefeuille",
        table5: "État",
        table6: "raison",
        table7: "temps d'application",
        table8: "Retirer des pièces",
        table81: "Retirer des pièces",
        table9: "Adresse de retrait",
        table10: "Veuillez sélectionner la devise que vous souhaitez retirer",
        table11: "Veuillez vous assurer d'entrer",
        table12: 'Adresse (commence généralement par "0x")',
        table13: "Pièces retirables (frais de traitement déduits)：",
        table14: "Sélectionnez la devise",
        table15: "Rencontrer des problèmes? Impressions immédiates",
        table16: "équilibre",
        table17: "Détails du retrait",
        password: "mot de passe",
        passwordTips: "Veuillez entrer le mot de passe",
        passwordAg: "Confirmez le mot de passe",
        passwordAgTips: "Veuillez saisir à nouveau le mot de passe",
        oldPassword: "ancien mot de passe",
        oldPasswordTips: "Veuillez saisir l'ancien mot de passe",
        newPassword: "nouveau mot de passe",
        newPasswordTips: "Veuillez entrer un nouveau mot de passe",
        passwordNotSame: "Les mots de passe sont incohérents",
        withdrawPasswordSet: "Définir le mot de passe de retrait",
        withdrawPasswordModify: "Changer le mot de passe de retrait",
        withdrawPassword: "Retirer le mot de passe",
        withdrawPasswordTips: "Veuillez saisir le mot de passe de retrait",
        setWithdrawPasswordFirst: "Veuillez d'abord définir un mot de passe de retrait",
        withdrawPasswordSetSuccess: "Installation réussie",
        withdrawPasswordModifySuccess: "Modification réussie",
        submit: "soumettre",
    },
    customer: {
        content1: "un service en ligne",
        content2: "service client",
        content3: "Veuillez entrer vos coordonnées",
        content4: "Veuillez saisir le contenu",
        content5: "envoyer",
        content6: "Échec de l'envoi, échec de la connexion réseau",
        content7: "image",
        content8: "le message est vide",
        content9: "mon partage",
        content10: "lien de partage",
        content11: "enregistrer l'image",
        content12: "mes gains",
        content13: "Description du niveau",
        content14: "Taille de l'équipe",
        content15: "Revenus d'invitation",
        content16: "Description de la règle",
        content17: "niveau d'adhésion",
        content18: "Le montant de la recharge est supérieur à",
        content19: "Niveau d'équipe",
        content20: "Niveau d'équipe et revenus",
        content21: "Pour remplir les conditions",
        content22: "Revenus de distribution",
        content23: "équipe de niveau",
        content24: "niveau, invitation",
        content25: "membres valides",
        content26: "revenu primaire",
        content27: "revenu secondaire",
        content28: "Revenu de niveau 3",
    },
    standardContract: {
        increaseMargin: "accroître la marge",
        increaseAmount: "augmenter le montant",
        Pleaseenterthemargin: "Veuillez saisir le montant de la marge à augmenter",
        Pleaseenterthecorrect: "Veuillez saisir le montant correct du dépôt",
        Increasemarginsuccessfully: "Augmenter la marge avec succès",
        takeProfitPriceWrong: "Prix ​​​​de profit erroné",
        stoppriceerror: "Arrêter l'erreur de prix",
        thetakeprofit1: "Le prix du take profit doit être supérieur au prix du marché",
        thetakeprofit2: "Le prix stop loss doit être inférieur au prix du marché",
        thetakeprofit3: "Le prix du take profit doit être inférieur au prix du marché",
        thetakeprofit4: "Le prix stop loss doit être supérieur au prix du marché",
        thetakeprofit5: "Mauvaise quantité",
        estimatedprofitloss: "Profits et pertes estimés",
        positionAmount: "Nombre de postes",
        closeAmount: "Quantité de clôture",
        whenthelatest: "Lorsque le dernier prix est atteint",
        whenthelatest2: ", l'ordre de prise de bénéfices au prix du marché sera déclenché pour clôturer la position actuelle. Profits et pertes attendus",
        whenthelatest3: ", l'ordre stop loss du marché sera déclenché pour clôturer la position actuelle. Profits et pertes attendus",
        contract: "contracter",
        ordercanceledsuccessfully: "Commande annulée avec succès",
        successfullyordered: "commandé avec succès",
        positionopeningreminder: "Rappel d'ouverture de poste",
        wrongquantity: "Mauvaise quantité",
        quantitycannot: "La quantité ne peut pas être supérieure à",
        modificationstoploss: "Modification du stop profit et du stop loss réussie",
        positionclosedsuccessfully: "Position clôturée avec succès",
        orderCancellation: "Annulation de commande",
        orderCancellation2: "Etes-vous sûr de vouloir annuler la commande ?",
        tobeexecuted: "Être éxecuté",
        expired: "expiré",
        failed: "échoué",
        contractTransaction: "Contrat perpétuel",
        increase: "Augmenter",
        openPosition: "Entrepôt complet",
        isolatedPosition: "Position isolée",
        limitOrder: "Limite",
        marketOrder: "Marché",
        pleaseSelectLeverage: "Veuillez sélectionner l'effet de levier",
        pleaseEnterQuantity: "Veuillez entrer la quantité",
        canOpenMore: "Position d'ouverture maximale",
        contractAmount: "Montant du contrat",
        openingFee: "frais de gestion",
        currentPosition: "Position",
        historicalPositions: "Opérations historiques",
        currentCommission: "Commande en cours",
        historicalCommission: "commande historique",
        buyPrice: "prix d'achat",
        income: "profit",
        quantity: "quantité",
        marginRate: "taux de marge",
        initialMargin: "Marge initiale",
        estimatedPriceParity: "Prix ​​de liquidation forcée",
        operate: "fonctionner",
        finishQuantity: "Volume des transactions",
        averagePrice: "prix moyen des transactions",
        pleaseEnterLeverage: "Veuillez saisir l'effet de levier",
        takeProfitAndStopLoss: "TP&SL",
        takeProfit: "TP",
        stopLoss: "SL",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Position fermée",
        marketLquidation: "Prix marché global",
        cancel: "Annuler la commande",
        enterBuyPrice: "Veuillez entrer le prix d'achat",
        areYouSureLquidation: "Êtes-vous sûr de clôturer la position ?",
        confirm: "Bien sûr",
        enterStopWinPrice: "Veuillez saisir le prix du take profit",
        enterStopLossPrice: "Veuillez entrer le prix stop loss",
        commissionTime: "Temps de commission",
        commissionPrice: "Prix ​​de commission",
        commissionQuantity: "Montant total des commissions",
        volumeAlreadyTraded: "Montant complété",
        areYouSureToWinOrLoss: "Êtes-vous sûr de vouloir arrêter les profits et arrêter les pertes ?",
        maxQuantity: "Position maximale",
        saleQuantity: "Poste fermé",
        salePrice: "cours de clôture moyen",
        saleTime: "temps",
        succeeded: "réussi",
        cancelled: "Annulé",
        piece: "ouvrir",
        triggerPrice: "prix de déclenchement",
        setWin: "Définir le profit",
        setLoss: "Définir le stop loss",
        enterWinQuantity: "Veuillez saisir le montant du take profit",
        enterLossQuantity: "Veuillez saisir le montant du stop loss",
        planCommission: "Commission de Planification",
        enterWinTriggerPrice: "Veuillez saisir le prix de déclenchement du take profit",
        enterLossTriggerPrice: "Veuillez saisir le prix de déclenchement du stop loss",
        insufficientQuantity: "Quantité insuffisante",
        enterTriggerPrice: "Veuillez saisir le prix de déclenchement",
        tips1: "Le prix de votre ordre est supérieur au prix actuel et il sera exécuté directement au prix actuel du marché. Êtes-vous sûr de vouloir acheter ?",
        tips2: "Le prix de votre ordre est inférieur au prix actuel et il sera exécuté directement au prix actuel du marché. Êtes-vous sûr d'acheter ?",
        type: "taper",
        increaseQuantity: "Augmenter le poste",
        reduceQuantity: "Réduire les postes",
        limitCommission: "ordre limité",
        tips3: "Le prix stop loss doit être inférieur au prix du marché",
        tips4: "Le prix du take profit doit être supérieur au prix du marché",
        tips5: "Le prix stop loss doit être supérieur au prix du marché",
        tips6: "Le prix du take profit doit être inférieur au prix du marché",
        tips7: "Le prix stop loss doit être inférieur au prix du marché et inférieur au prix de l'ordre",
        tips8: "Le prix du take profit doit être supérieur au prix de la commande (peut être inférieur au prix du marché)",
        tips9: "Le prix stop loss doit être supérieur au prix du marché et supérieur au prix de l'ordre",
        tips10: "Le prix du take profit doit être inférieur au prix de la commande (peut être supérieur au prix du marché)",
        accountEquity: "Solde du compte",
        unrealizedPL: "Gains et pertes latents",
        tips11: "Êtes-vous sûr d'avoir raison",
        tips12: "Prix ​​du marché",
        tips13: "Fermer tous les postes",
        tips14: "Ne rappelle plus",
        profitRate: "Taux de rendement de clôture",
        openTime: "Horaire d'ouverture",
        unwindTime: "Heure de fermeture",
        dealTime: "Temps de transaction",
        amountClose: "Montant de la position clôturée",
        long: "Duoduo",
        short: 'nul',
        orderQuantity: "Quantité commandée",
        entrustedMargin: "marge confiée",
    },
    spotGoods: {
        spotGoods: "Flash",
        sellPrice: "prix de vente",
        orderRecord: "Enregistrement d'échange Flash",
        transactionTime: "Temps de transaction",
        maxBuy: "disponible à l'achat",
        maxSell: "Disponible à la vente",
        purchaseQuantity: "Quantité d'achat",
        slippage: "Glissement",
        convertTips: "Nous clôturerons la transaction au prix le plus avantageux avec un dérapage de 0 à 5 %.",
        changePrice: "Prix ​​de change actuel",
        consume: "consommer",
        get: "obtenir",
        changeTips: "Etes-vous sûr de vouloir échanger ?",
        confirm: "Confirmer l'échange"
    },
    news: {
        title: "Centre d'Information",
        redMore: "En savoir plus",
    },
    credit: {
        credit: "pointage de crédit",
        creditScore: "cote de crédit totale",
        latestEntry: "dernier enregistrement",
        liveUpdate: "Mise à jour en direct",
        systemEvaluation: "Évaluation du système",
        time: "date",
        name: "nom",
        reason: "raison",
        scoreStory: "Dossier de crédit"
    },
    borrowing: {
        borrowing: "prêt",
        diversifiedMarket: "Des marchés diversifiés",
        totalSupply: "Offre totale",
        totalBorrowings: "emprunts totaux",
        tips1: "Il s’agit du montant total que vous pouvez emprunter contre votre garantie, jusqu’à la limite d’emprunt.",
        tips2: "Votre taux d’intérêt stable restera le même pendant toute la durée de votre prêt. Recommandé pour un approvisionnement à long terme et pour les utilisateurs qui aiment la prévisibilité.",
        totalSupplys: "approvisionnement total",
        supplyAPR: "taux d'approvisionnement",
        totalBorrowings: "Emprunt total",
        borrowAPR: "taux d'emprunt",
        check: "Vérifier",
        market: "marché",
        availableLiquidity: "Liquidité disponible",
        currencyPrice: "prix actuel de la devise",
        supplyAssets: "actifs d'approvisionnement",
        tips3: "Remarque : Le taux d'intérêt ci-dessous est un taux d'intérêt fixe. Une fois la période d'approvisionnement terminée, le principal et les revenus seront restitués sur votre compte.",
        supplyCycle: "cycle d'approvisionnement",
        minimumSupply: "approvisionnement minimum",
        supply: "fournir",
        borrowedAssets: "actifs empruntés",
        tips4: "Important à savoir : Pour emprunter de l’argent, vous devrez fournir des garanties ou des justificatifs,",
        tips5: "Veuillez consulter la description de l'actif hypothécaire.",
        repaymentCycle: "cycle de remboursement",
        BorrowableAmount: "Montant du prêt disponible",
        borrow: "prêt",
        accountInformation: "Information sur le compte",
        walletBalance: "solde du portefeuille",
        canSupply: "Disponible",
        AmountCanBeBorrowed: "Montant total du prêt disponible",
        borrowedAmount: "Montant emprunté",
        remainingAmount: "solde restant",
        getBorrowednAmount: "Obtenir la limite de prêt",
        supplyOrder: "commande de fournitures",
        borrowedOrder: "ordre de prêt",
        supplyAmount: "montant de la fourniture",
        expectedProfits: "Retour attendu",
        supplyTime: "Délai d'approvisionnement",
        expirationTime: "Date d'expiration",
        borrowingAmount: "Montant du prêt",
        dueRepayment: "Montant du remboursement",
        borrowingTime: "Temps de libération",
        repaymentTime: "Délai de remboursement",
        repay: "remboursement",
        supplyCurrency: "fournir de la monnaie",
        buyNow: "Acheter maintenant",
        supplyRecords: "registres d'approvisionnement",
        borrowRecords: "Dossiers de prêt",
        completed: "complété",
        borrowNow: "Emprunter maintenant",
        borrowCurrency: "Emprunter de la monnaie",
        borrowMin: "le plus petit",
        borrowMax: "maximum",
        borrowRange: "Montant de l'emprunt (minimum/maximum)",
        tips6: "Veuillez rembourser à temps. En cas de retard, un montant quotidien supplémentaire de (capital + intérêts) sera facturé.",
        tips7: "Frais de retard.",
        pleaseEnterBorrowAmount: "Veuillez saisir le montant du prêt",
        interest: "Intérêt",
        tips8: "Etes-vous sûr de vouloir rembourser par avance ?",
        borrowChannel: "Canal d'emprunt",
        tips9: "Commençons par apprendre à vous connaître",
        name: "nom",
        lastName: "nom de famille",
        birthDate: "date de naissance",
        tips10: "Un peu plus d'informations, quelle est votre adresse ?",
        familyAddress: "Adresse du domicile (y compris le numéro d'unité)",
        city: "Ville",
        country: "nation",
        postalCode: "Code Postal",
        pleaseEnterYourHomeAddress: "Veuillez entrer votre adresse personnelle",
        tips11: "Parlez-nous de votre situation financière",
        tips12: "Nous devrons peut-être confirmer ces informations ultérieurement, alors essayez d'être aussi précis que possible.",
        yourMonthlyIncome: "votre revenu mensuel",
        enterYourMonthlyIncome: "Veuillez entrer votre revenu mensuel",
        extraIncomeOptional: "Revenu supplémentaire (facultatif)",
        enterExtraIncome: "Veuillez saisir votre revenu supplémentaire",
        monthlyRentOrMortgagePayment: "Loyer mensuel ou versement hypothécaire",
        enterMonthlyRent: "Veuillez saisir votre loyer mensuel ou votre versement hypothécaire",
        tip13: "Dites-nous si vous avez un prêt hypothécaire",
        tip14: "Si aucune garantie hypothécaire n’est sélectionnée, la probabilité de réussite à l’examen est faible.",
        isThereMortgage: "Y a-t-il une hypothèque ?",
        selectIsThereMortgage: "Veuillez indiquer s'il existe une hypothèque",
        selectMortgageType: "Veuillez sélectionner le type d'hypothèque",
        yes: "Oui",
        no: "Non",
        uploadScreenshotOfTransaction: "Veuillez télécharger des captures d'écran de la transaction",
        tip15: "Restons en contact",
        tip16: "Nous pouvons vous contacter à ce numéro si nous avons besoin de plus amples informations sur votre demande de prêt.",
        tip17: "J'ai lu et signé électroniquement mon consentement",
        tip18: "Conditions d'utilisation et consentement électronique",
        tip19: ", qui inclut votre consentement à ce que nous vous envoyions des informations sur nos",
        tip20: "Conditions d'utilisation des produits et services",
        tip21: "Après avoir rempli les informations, veuillez attendre l'évaluation et la révision du système.",
        phoneNumber: "numéro de téléphone",
        enterPhoneNumber: "Veuillez entrer votre numéro de téléphone",
        tip22: "Veuillez cocher la case pour accepter les conditions d'utilisation et le formulaire de consentement électronique.",
        inReview: "à l'étude",
        applicationAmount: "Montant de la demande",
        reasonForRejection: "Raison du rejet",
        reapply: "Réappliquer",
        applyStatus: "état de la candidature",
        currentOrder: "commande en cours",
        interestRate: "taux d'intérêt"
    },
    yuebao: {
        filed1: "investir",
        filed2: "sortir",
        filed3: "Revenu quotidien",
        filed4: "accélération de l'équipe",
        filed5: "Déposé",
        filed6: "Les gains d'hier",
        filed7: "Revenu total",
        filed8: "Fonds disponibles",
        filed9: "Veuillez saisir le montant",
        filed10: "Extraction de liquidité",
        filed11: "Introduction",
        field12: "Prix ​​d'achat minimum",
        field13: "Le maximum que vous puissiez acheter",
        filed14: "Abonnement",
        filed15: 'régulier',
        filed16: "taux d'intérêt annualisé",
    },
    tipsPopover: {
        title: "Installez cette application",
        press: "selon",
        and: "et",
        scrren: "Ajouter à l'écran d'accueil",
        hasInstall: "(Si installé, veuillez fermer la fenêtre)",
    },
    follow: {
        title1: "Plan de suivi",
        follow_text1: "Suivre les commandes",
        follow_text2: "Historique des versions",
        follow_text3: "titre",
        follow_text4: 'paire de trading',
        follow_text5: "Durée d'achat",
        follow_text6: 'temps de commande',
        follow_text7: 'temps de libération',
        follow_text8: "Nombre d'abonnés",
        follow_text9: 'Montant de la commande',
        follow_text10: 'fonctionner',
        follow_text11: 'inviter',
        follow_text12: 'Suivez le sens de la commande',
        follow_text13: 'Définir les conditions de commande',
        follow_text14: 'Conditions pour suivre les commandes',
        follow_text15: 'Montant fixé',
        follow_text16: 'pourcentage',
        follow_text17: 'Personnes invitées',
        follow_text18: "L'identité de l'autre partie",
        follow_text19: "Confirmez pour suivre la commande",
        follow_text20: "Montant de votre commande (facultatif)",
        follow_text21: "Veuillez entrer un titre",
        follow_text22: "Veuillez sélectionner une paire de trading",
        follow_text23: "Veuillez sélectionner le sens de la commande",
        follow_text24: "Veuillez sélectionner la durée d'achat",
        follow_text25: "Veuillez sélectionner l'heure de la commande",
        follow_text26: "Veuillez sélectionner la quantité d'achat",
        follow_text27: "La quantité maximale d'achat ne peut pas être inférieure à la quantité minimale d'achat",
        follow_text28: "Achat minimum",
        follow_text29: "La plupart achètent",
        follow_text30: "Créer une commande de copie avec succès",
        follow_text31: "Liste des paires de trading",
        follow_text32: "Déjà suivi la commande",
        follow_text33: "Veuillez saisir les informations de l'invité",
        follow_text34: "Invité",
        follow_text35: "m'a invité",
        follow_text36: "Copie de l'historique",
        follow_text37: "Fourchette de montant de la commande"
    },
    followHistory: {
        title1: 'Historique des versions',
        title2: "Copie de l'historique",
        follow_h_text1: "Le montant total de la commande",
        follow_h_text2: "Nombre total de personnes suivant les commandes",
        follow_h_text3: "Nombre de versions",
        follow_h_text4: "taux de réussite",
        follow_h_text5: "Nombre de fois pour suivre les commandes",
        follow_h_text6: "Bénéficiez du suivi des commandes",
        follow_h_text7: "direction",
        follow_h_text8: "gagner",
        follow_h_text9: "déficit",
    },
    "asset.index.1579E8B41F8D5134": "mes atouts",
    "asset.index.B3F6F05Dfbe431Cd": "simulation",
    "asset.index.B9Aeab4Ee8F33746": "Évaluation des actifs",
    "asset.index.583Df8Bd541Eb7E8": "Tendances revenus",
    "asset.index.B1254815D133Cc26": "7 jours",
    "asset.index.2F615F6C029F01B7": "30 jours",
    "asset.index.29439Fb58E4Bbe22": "retirer",
    "asset.index.831Ab31568A78285": "recharger",
    "asset.index.39F7A0D48D12E8C9": "transfert",
    "asset.index.B99063C5689Beaec": "Flash",
    "asset.index.E1Dc620F2F038B2A": "Recevoir fonds",
    "asset.index.972A0B4C2B4393D0": "Mon compte",
    "asset.index.9Dfb0Fb334620733": "Solde disponible",
    "asset.index.Cdc5Fbd26790Cb47": "montant gelé",
    "asset.index.3D8132D72Ae965Cd": "Évaluation",
    "asset.receive.825503Cc430559Cb": "acquérir des actifs",
    "asset.receive.54154A0553A535B7": "Actifs de démonstration",
    "asset.receive.F2E57B00A9C5D129": "1.Actifs de démonstration",
    "asset.receive.Ea0D1D52989299Bd": "2.Pour éviter les abus, un seul rachat est autorisé par mois.",
    "asset.receive.090E0037Aa235D3D": "3.La réception des actifs ajoutera le montant des actifs reçus sur la base initiale et ne modifiera pas le statut de la position.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Si vous avez d'autres questions, veuillez contacter le service client",
    "asset.receive.Df5B3A511D986575": "Obtenez de l'argent pour une démo",
    "asset.records.1Fa02E8187A39592": "les trois derniers mois",
    "asset.records.7B291C37333Fe34D": "de près à loin",
    "asset.records.A476Cd1Ec868Fc5F": "les trois derniers mois,",
    "asset.records.9D5755A842E55Bb7": "de près à loin,",
    "asset.records.E3Fa05E607203Cdc": "recharger",
    "asset.records.B4F206C6Bec08Dea": "retirer",
    "asset.index.Df5B3A511D986576": "Vous avez reçu avec succès les ressources de démonstration",
    "deposit.depositSpeedy.Efe81D0E0174239B": "mode de paiement",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Monnaie Fiat",
    "deposit.depositSpeedy.16658A855F07D171": "Montant",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Bien sûr",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Ne peux pas être vide",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Recharge minimale",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Recharge maximale",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Dépôt USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Recharger la devise",
    "deposit.depositWallet.B404C543712B71D5": "Réseau de recharge",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Adresse de dépôt",
    "deposit.depositWallet.D3980D54Acf242Db": "Enregistrer le code QR",
    "deposit.depositWallet.A06E84801D6582Be": "Montant de la recharge",
    "deposit.depositWallet.Daaaeb1B7B22B126": "indice",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Recharge réussie",
    "deposit.depositWallet.4F48Abbafce532Bb": "Soumis avec succès, en attente d'examen par le service client",
    "deposit.depositWallet.A88C481228811696": "Vous avez refusé de changer de réseau",
    "deposit.depositWallet.C6Ebf27895C21932": "Veuillez ouvrir manuellement le plug-in du portefeuille TRON et vous connecter",
    "deposit.index.B5491C8Dd09D8183": "Sélectionnez la devise que vous souhaitez recharger",
    "deposit.index.2F784B2298D39987": "Recharge rapide",
    "deposit.index.0Daa03Dcdcabd837": "Liens hors site",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Confirmation de retrait",
    "withdraw.bottomSheet.A086Fa20082C849D": "Veuillez vous assurer que les informations ci-dessous sont correctes",
    "withdraw.bottomSheet.Bf8B184D32971501": "Réseau de retrait",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "adresse",
    "withdraw.bottomSheet.F9Db93B87E08763B": "quantité",
    "withdraw.bottomSheet.4Ad26475002878C0": "frais de gestion",
    "withdraw.index.A96454041169Af6D": "Sélectionnez la devise",
    "withdraw.index.2A563B7081Dd5B83": "Adresse de retrait",
    "withdraw.index.B189C2Ef713Cfff4": "Quantité de pièces retirées",
    "withdraw.index.558F35140E84B562": "Disponible：",
    "withdraw.index.F4E759087E12Cb77": "Tout retirer",
    "withdraw.index.5Cbd9A6B284D25Ae": "Mot de passe du fonds",
    "withdraw.index.B89Cbe4Bfc33Bb41": "frais de gestion:",
    "withdraw.index.C886De8D8A27026E": "avis important",
    "withdraw.index.Eac7C5A4095F5606": "1.Afin d'éviter les arbitrages, vous pouvez demander un retrait de devises lorsque le volume des transactions atteint le quota.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.La demande de retrait sera créditée dans les 24 heures suivant sa soumission. Si le retrait n'est pas reçu après le délai de retrait prévu, veuillez contacter le service client en ligne.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Après avoir soumis la demande de retrait, les fonds sont gelés car le retrait est en cours et les fonds sont temporairement détenus par le système. Cela ne signifie pas que vous avez perdu l'actif ou qu'il y a une anomalie avec l'actif.",
    "withdraw.index.4639F3A6E07C00B3": "L'étape suivante",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Veuillez saisir ou coller l'adresse de votre portefeuille",
    "withdraw.index.25768141C8Bdea91": "Veuillez saisir le montant du retrait",
    "withdraw.index.97A85734Cd1B7Bf0": "Veuillez saisir le mot de passe du fonds",
    "withdraw.index.39C7D74B33241C78": "Le montant du retrait est erroné",
    "withdraw.index.86A5D17908197525": "Confirmation de retrait, en attente d'examen pour libérer les pièces)",
    "withdraw.index.48Fb603C6638C7F6": "Veuillez définir un mot de passe pour le fonds",
    "withdraw.index.873Ade9A3Bacc2F7": "indice",
    "bill.index.5Bbb2A6Ac68D6B2E": "compte réel",
    "bill.index.75751E0Eb8261C40": "Compte démo",
    "bill.index.Cbc853B37Ecc2E97": "Type de changement de compte",
    "bill.index.4A0Cacddeb2E38B1": "Type de compte",
    "bill.index.F16217028E098B2F": "Devise",
    "bill.index.A620Dbe6F06B78Ba": "Tous les types",
    "bill.index.2C3C148Bb94D9B3F": "Tous les comptes",
    "c2C.index.Ab45695E4Bf1E382": "Transaction C2C",
    "c2C.index.2702Dc911Dfea762": "je veux acheter",
    "c2C.index.D737D5B8367A53C4": "je veux vendre",
    "c2C.index.C0C540A3Ba9Abe01": "Volume",
    "c2C.index.D8644E8D60C8Bd8A": "prix unitaire",
    "c2C.index.Ebb8670B203D3604": "limite",
    "c2C.index.F8C6245A2Ebf1D27": "Toutes les cartes bancaires",
    "c2C.index.F8C6245A2Ebf1D28": "collecte de devises",
    "c2C.index.F8C6245A2Ebf1D29": "carte bancaire",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Acheter",
    "c2C.index.Cc3Aa8141Da9Ec9F": "vendre",
    "c2C.index.67A39834582Ad99A": "Pas de données",
    "c2C.order.04C23810B47F77F7": "par montant",
    "c2C.order.6905365902E0A73D": "par quantité",
    "c2C.order.3F0Ecebbd9D2F23B": "Disponible",
    "c2C.order.16853Bda54120Bf1": "tous",
    "c2C.order.Ad3335796F2C95F7": "montant forfaitaire",
    "c2C.order.61092E27A6879318": "Pour protéger la sécurité des actifs, sensibilisez-vous à la prévention !",
    "c2C.order.0F51881F0Fb920B2": "Informations commerciales",
    "c2C.order.Dd74Ceca85E5Bc65": "Délai de paiement",
    "c2C.order.De3341388A59073F": "Délai de paiement",
    "c2C.order.D334B597A352167F": "Pseudonyme du vendeur",
    "c2C.order.D334B597A352167G": "Pseudo de l'acheteur",
    "c2C.order.9D5F89F99Ced890D": "moyen de transaction",
    "c2C.order.8Adf755B14Bc6B7C": "Veuillez d'abord lire ce qui suit",
    "c2C.order.686B16F454A7338B": "Ne faites aucune remarque lors des virements par carte bancaire, sinon l'argent ne sera pas débloqué et le compte sera directement bloqué. Après le paiement, vous devez fournir un nouveau diagramme des détails de la transaction après le paiement (si le diagramme P ou les détails cachés de la transaction sont signalés à la plateforme pour geler le compte)",
    "c2C.order.9Bec8Fba867B739B": "Sélectionnez le mode de paiement",
    "c2C.order.Cf2D892C419Fea36": "achat minimum",
    "c2C.order.7290A6B7456A03E0": "le plus gros achat",
    "c2C.orderConfirm.F7E99B8C203E2B07": "détails de la commande",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "La commande a été générée",
    "c2C.orderConfirm.0F5433503F3845Ce": "Veuillez payer le vendeur dans le délai du compte à rebours.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " En attente du paiement des acheteurs ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "contacter le vendeur",
    "c2C.orderConfirm.8E8140D7Ab607B25": "contacter l'acheteur",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "La monnaie numérique du vendeur est désormais déposée sur le compte séquestre, n'hésitez pas à payer  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Le vrai nom de l'acheteur：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "Service client 7x24 heures ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "numéro de commande",
    "c2C.orderConfirm.726C51702F70C486": "temps de creation",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Contactez le service à la clientèle",
    "c2C.orderConfirm.103408Fbbe30287F": "annuler la commande",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "J'ai confirmé le paiement",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Déjà payé",
    "c2C.orderConfirm.387Ca3625B260E4E": "Votre commande payée",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "annuler la transaction",
    "c2C.orderConfirm.76C86566Cc3751C0": "Commande annulée",
    "c2C.orderConfirm.205B349751D176B4": "paiement réussi",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "mode de paiement",
    "c2C.paymentAdd.C87E865Df6800304": "Paramétrage du mode de paiement",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Type de mode de paiement",
    "c2C.paymentAdd.06910Bddf002695F": "nom réel",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Compte Paypal",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Numéro de compte bancaire/numéro de carte",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Nom de la devise",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Payer le code QR",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Cliquez pour télécharger",
    "c2C.paymentAdd.6Daae340F2E9718A": "Nom de banque",
    "c2C.paymentAdd.347751415C3Da489": "Nom de la chaîne",
    "c2C.paymentAdd.15B3627Faddccb1D": "Remarque",
    "c2C.paymentAdd.86Da96Cbd274058F": "Rappel chaleureux : lorsque vous vendez de la monnaie numérique, le mode de paiement que vous choisissez sera affiché à l'acheteur. Veuillez confirmer que les informations sont remplies avec précision.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "sauvegarder",
    "c2C.paymentAdd.90D2A3A5C07179C0": "veuillez entrer votre vrai nom",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "S'il vous plait entrez votre",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Veuillez entrer le nom de votre banque",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Veuillez entrer le nom de votre chaîne",
    "c2C.paymentAdd.94F034F0E01439E0": "Veuillez entrer votre adresse",
    "c2C.paymentAdd.B12E035663A42B32": "Toutes les cartes bancaires",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "collecte de devises",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Veuillez télécharger le code de paiement",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Ajouté avec succès",
    "c2C.paymentDetail.86Da96Cbd274058F": "Rappel chaleureux : lorsque vous vendez de la monnaie numérique, le mode de paiement que vous choisissez sera affiché à l'acheteur. Veuillez confirmer que les informations sont remplies avec précision.",
    "c2C.paymentDetail.C4C613Da60226De4": "Modification réussie",
    "c2C.paymentMethod.7609F7C8337C7A17": "Mode de paiement C2C",
    "c2C.records.9E36893D5736Abb4": "Liste de commande",
    "c2C.records.4B53F9A5B13B3235": "En attente de paiement",
    "c2C.records.4B53F9A5B13B3236": "annuler la transaction",
    "c2C.records.4B53F9A5B13B3237": "Déjà payé",
    "contract.index.9706Df877458F0Be": "Entrepôt complet",
    "contract.index.623Fc7D3D40Aa479": "Marge initiale",
    "contract.index.F92Dce1169330387": "Prix marché global",
    "contract.index.3598B97Dd28Dbf20": "Veuillez entrer la quantité (pièces)",
    "contract.index.6E1Fae821Dc0B797": "Durable",
    "contract.index.Eb07Eb0C37C14575": "Livraison",
    "contract.index.Eb6492F19B1C1Fb6": "Détails historiques des transactions",
    "contract.index.873Ade9A3Bacc2F7": "indice",
    "contract.index.Ff63B0F93D39F50B": "Êtes-vous sûr d'avoir raison",
    "contract.index.Ff63B0F93D39F50D": "Allez-y longtemps",
    "contract.index.Ff63B0F93D39F50E": "court",
    "contract.index.Ff63B0F93D39F50F": "conduire",
    "contract.index.Ff63B0F93D39F50G": "Fermer toutes les positions ?",
    "customer.dialog.3D410C88E8A24Fe8": "Confirmer le pseudo",
    "customer.dialog.4B3340F7872B411F": "Annuler",
    "customer.dialog.549B1456Bb5F98B3": "s'il vous plait, entrez ton surnom",
    "customer.dialog.B773B3E45Ccfad00": "Le pseudo est vide",
    "customer.index.7308C6B849D159E9": "Détails de l'image",
    "delivery.callDialog.C74F562C3Fe91Fe3": "commandé avec succès",
    "delivery.callDialog.B8C98F42Caa15Ca5": "direction",
    "delivery.callDialog.B79A581Dae543974": "long achat",
    "delivery.callDialog.B79A581Dae543975": "achat à découvert",
    "delivery.callDialog.3B26F0F7E90773F3": "Prix ​​d'ouverture",
    "delivery.callDialog.65Fb252Bd94E1039": "Revenu estimé",
    "delivery.index.B483Ba4Fce633539": "Livraison",
    "delivery.index.224Dd6Cb58D58249": "période de négociation",
    "delivery.index.28158F517E902788": "Achat minimum",
    "delivery.index.A1232162B14Bec76": "Bon de livraison",
    "delivery.index.445B1D3176D9C69D": "Commandes historiques",
    "delivery.index.Ab6Cb86E28653C5C": "Livraison",
    "delivery.index.20A7A6F14D3698C0": "Horaire d'ouverture",
    "delivery.index.Ec6821A49395Ddc2": "Heure de fermeture",
    "delivery.index.254Fdc665C391743": "Le dernier prix",
    "delivery.index.2A0C5E68B44A472A": "Profit et perte",
    "delivery.index.E05B33309F6D236A": "Veuillez entrer la quantité",
    "delivery.index.B2899E073Cb355D9": "Achat minimum",
    "download.description.869467A93C7Dd4A1": "Notes d'installation",
    "download.description.C80C1Edd6Bee12Bc": "1.Autoriser le téléchargement des fichiers de description",
    "download.description.2F5Bc180F92642B4": "2.Fichier de description téléchargé",
    "download.description.3C3B849F6Ccf712F": "3.Entrez [Paramètres] sur votre téléphone et le fichier de description téléchargé s'affichera sous les informations du compte.",
    "download.description.86Affd862985E045": "4.Cliquez pour télécharger le fichier de description",
    "download.description.32832492Bfc1D95E": "5.Cliquer pour installer",
    "download.index.245883C80F181C4A": "télécharger",
    "download.index.Ef3De8Cb2722937A": "Commencez à trader à tout moment et en tout lieu",
    "download.index.F4A87Eaeed691638": "Tenez-vous au courant des dernières nouvelles grâce à notre application",
    "download.index.Ad3Aba52D0D39D01": "Téléchargez le package d'installation",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Conseils aimables",
    "download.safariGuide.2Cc68Aaadf623477": "Veuillez visiter dans Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Vous visitez un site Web décentralisé, veuillez l'exécuter dans l'application Safari",
    "download.safariGuide.06Da016F3E2F79Bd": "Copier le lien",
    "finance.index.B034B367545D5236": "Des projets mondiaux de qualité",
    "finance.index.6Ebfac2C0A9228B0": "Plus de 1,6 million d'utilisateurs actifs, 122,4 milliards de dollars",
    "finance.index.52Bdcaf19659363D": "Extraction de liquidité",
    "finance.index.D66924272B031Bf5": "Revenu annualisé très élevé",
    "finance.index.34789D88Afcefa64": "Afficher les articles",
    "finance.index.E12676B95654D21E": "prêt de crédit",
    "finance.index.4C924Cfd89A11Ac7": "Découvrez plus d'opportunités de trading",
    "finance.index.C251D4B594Da2848": "Quantification de l'IA",
    "finance.index.4E53Cf2E5F173Dd3": "Location en un clic, transaction intelligente IA",
    "finance.records.Cce168301447D1Ba": "en cours",
    "finance.records.4587B6Bd7Cfa4454": "racheté",
    "finance.records.18685418E3A50147": "Exploitation minière",
    "finance.records.Df9Fd438F97Cc908": "ciel",
    "finance.records.E23Cb03C5Ad359E5": "le terme",
    "finance.records.Bd87Dac6Cba7Eba8": "Quantité d'achat",
    "finance.records.2D0Fff99265E8B1A": "Rentable",
    "finance.records.2D0Fff99265E8B1B": "Bénéfice à l'échéance",
    "finance.records.024F5F0B851782De": "Temps d'exploitation minière：",
    "finance.records.3143A26D3F983896": "heure de rachat：",
    "finance.records.96574Aaf8Db85Ead": "rachat anticipé",
    "finance.records.531E1829203181Ac": "Êtes-vous sûr de vouloir échanger plus tôt ?",
    "finance.records.9205843B8923Dac3": "Rédemption réussie ;",
    "loan.applyCallback.B9258Ec8746A74D8": "Canal d'emprunt",
    "loan.applyForm.7B8Ed340400A6E2B": "nom",
    "loan.applyForm.549A44Bae725F4A2": "nom de famille",
    "loan.applyForm.2A27E61375E7Fea8": "date de naissance",
    "loan.applyForm.89925B019D537738": "adresse e-mail",
    "loan.applyForm.Afaeb32Cb4D62E28": "Adresse du domicile (y compris le numéro d'unité)",
    "loan.applyForm.E05B5D049B64B040": "Ville",
    "loan.applyForm.B166E4E8Fe9513Fa": "nation",
    "loan.applyForm.83Aaede109E395F8": "Code Postal",
    "loan.applyForm.647Ed21332A4968B": "votre revenu mensuel",
    "loan.applyForm.Cf0A9B845D34D4B4": "Revenu supplémentaire (facultatif)",
    "loan.applyForm.7048F618Acc6A54C": "Loyer mensuel ou versement hypothécaire",
    "loan.applyForm.15C1Bba18Ae18545": "Dites-nous si vous avez un prêt hypothécaire",
    "loan.applyForm.3921E90625A84E36": "Si aucune garantie hypothécaire n’est sélectionnée, la probabilité de réussite à l’examen est faible.",
    "loan.applyForm.262Eec4F8906B0F6": "Y a-t-il une hypothèque ?",
    "loan.applyForm.8634Eb21B6910F21": "Veuillez sélectionner le type d'hypothèque",
    "loan.applyForm.A4897B3A331B5E61": "Bon de transaction",
    "loan.applyForm.6623384C683De457": "Restons en contact",
    "loan.applyForm.A8360Bdba86834Cb": "Nous pouvons vous contacter à ce numéro si nous avons besoin de plus amples informations sur votre demande de prêt.",
    "loan.applyForm.C1C373F385A4C7B3": "numéro de téléphone",
    "loan.applyForm.1B9785Badb91191F": "Après avoir rempli les informations, veuillez attendre l'évaluation et la révision du système.",
    "loan.applyForm.8755B9C761D4080C": "J'ai lu et signé électroniquement mon consentement",
    "loan.applyForm.3E987B787468148C": "Conditions d'utilisation et consentement électronique",
    "loan.applyForm.Bc54E76B61C50Fb9": "Veuillez lire et accepter l'accord",
    "loan.applyForm.89E158384F634Eb8": "Revenir à l'étape précédente",
    "loan.applyForm.Efa5A4548E2F3510": "Adresse du domicile",
    "loan.applyForm.4Cadf1787541B35D": "Oui,",
    "loan.applyForm.Fab408D2F4100447": "Non,",
    "loan.applyForm.3Bad7A307480Cc56": "Le format de l'e-mail est incorrect ;",
    "loan.applyForm.306322F49B36855D": "La demande a été soumise, veuillez attendre l'examen du service client",
    "loan.applyForm.3C6E0E858C995Ff9": "Veuillez télécharger le bon de transaction",
    "loan.applyForm.2Bf443476B271376": "Jusqu'à 4 photos peuvent être téléchargées",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Canal d'emprunt",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Obtenir la limite de prêt",
    "loan.applyQuota.9E0780F9352C7273": "solde du portefeuille",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Montant total du prêt disponible",
    "loan.applyQuota.033A05462195B080": "Montant emprunté",
    "loan.applyQuota.9Aa89B036Ef4821D": "solde restant",
    "loan.applyQuota.5656Fb52Ef17A557": "Obtenir la limite de prêt",
    "loan.applyQuota.6Ff6702B4C917D40": "à l'étude",
    "loan.applyQuota.6Ff6702B4C917D41": "rejeté",
    "loan.applyQuota.6Ff6702B4C917D42": "réussi",
    "loan.applyQuota.0Bad0E857Ea4A952": "temps d'application",
    "loan.applyQuota.3E7E56E2De2Cc546": "Montant de la demande",
    "loan.applyQuota.8F1D170D9E54Af5B": "Progression de la candidature",
    "loan.applyQuota.6167Ca035B93Caf3": "raison",
    "loan.applyQuota.410C7954F9A3981D": "Réappliquer",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Veuillez sélectionner la devise",
    "loan.index.4C10307B22E885C3": "Découvrez plus d'opportunités de trading et créez de la richesse",
    "loan.index.Ae8D37Dea2B3717B": "taux d'intérêt",
    "loan.index.1Bb54625682E85Db": "Introduction aux règles",
    "loan.index.5Bd31Fab681C6E9D": "voir plus",
    "loan.index.111F95F404814327": "Devise du prêt",
    "loan.index.6Bc2723B3Bec106D": "prêt",
    "loan.index.3200106280E6E370": "taux d'intérêt",
    "loan.index.049A6Ccb6C81A635": "Montant du prêt (min/max)",
    "loan.productDetails.5037Ad86Ab4B7E02": "prêt",
    "loan.productDetails.37181C74559Db182": "Commande ",
    "loan.productDetails.5802Aaf06A1E4755": "Liquidité",
    "loan.productDetails.89Aea9Cf9337C40F": "Quantité disponible à emprunter",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Quantité à rembourser",
    "loan.productDetails.C65F329Ef14C4A3D": "Quantité de pièces empruntées",
    "loan.productDetails.3A74667815Adc8Bf": "maximum",
    "loan.productDetails.Ab851D8351573227": "ciel",
    "loan.productDetails.Ddf82D8A2C8F46A0": "cycle de remboursement",
    "loan.productDetails.C105E5562005Aeb1": "ciel",
    "loan.productDetails.3F346Be2137F0756": "Montant du prêt (min/max)",
    "loan.productDetails.2707F78Df864E194": "Intérêt",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Veuillez rembourser à temps. En cas de retard, un montant quotidien supplémentaire de (capital + intérêts) sera facturé.",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% correspond aux frais de retard",
    "loan.productDetails.4D94Faa033Be7356": "j'ai accepté",
    "loan.productDetails.62429A957A22780A": "Contrat de service de prêt de pièces",
    "loan.productDetails.Bc54E76B61C50Fb9": "Veuillez lire et accepter l'accord",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Emprunter maintenant",
    "loan.productDetails.59246A0Bf081D398": "moins",
    "loan.productDetails.Ec3F1172B71Cd36C": "montant minimal",
    "loan.productDetails.7Ccc4Cf1D81930B3": "la plus grande quantité",
    "loan.productDetails.071488598990Aa68": "Veuillez lire et accepter l'accord;",
    "loan.productDetails.579Dd216D3339119": "Prêt réussi;",
    "loan.records.4951B36Fd0D2A831": "Historique de prêt et de crédit",
    "loan.records.Bb0Aaa30514B7Cba": "record actuel",
    "loan.records.0F8B0A7D80769051": "Historique de remboursement",
    "loan.records.D4B42E43D88De6F1": "enregistrement de l'histoire",
    "loan.records.Cb1847D6281897E6": "Montant du prêt",
    "loan.records.C7Bfcacfc2Adea58": "Montant du remboursement",
    "loan.records.8B369164D5Fea340": "Durée du prêt：",
    "loan.records.Ab2402Ad3Ffd35E7": "Retour le prêt",
    "loan.records.B7A0Ca0C195D0Cd1": "Temps de libération：",
    "loan.records.42014Bdcfd9C411B": "Délai de remboursement：",
    "loan.records.A0Acfaee54F06458": "complété",
    "loan.records.67A39834582Ad99A": "Pas de données",
    "loan.records.6A687Ff5B8F1598F": "Etes-vous sûr de vouloir rembourser par avance ?",
    "loan.records.7C20D7008D0Eae86": "Remboursement réussi",
    "mining.bottomSheet.Ba91637D32Ccba04": "Extraction de liquidité",
    "mining.bottomSheet.7De3D636A165E008": "taux de retour",
    "mining.bottomSheet.F3003345B189D683": "Période minière",
    "mining.bottomSheet.3E4C0A8C533F5848": "type de pièce de monnaie",
    "mining.bottomSheet.Adcba368993Ae65E": "Montant de l'abonnement (minimum/maximum)",
    "mining.bottomSheet.94743B24E4Ed4166": "Illimité",
    "mining.bottomSheet.286944Fedd211257": "Fonds disponibles",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Revenu estimé:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Le mien maintenant",
    "mining.bottomSheet.Bb1493C567C114Dd": "Conseils aimables：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Frais de rachat anticipé",
    "mining.index.8B2Bba1422Eed6F0": "Créer des dizaines de milliards de richesse, 100% de revenu net",
    "mining.index.42997E300086Ee89": "rendement le plus élevé",
    "mining.index.510F74F42052Ecce": "Produits miniers",
    "mining.index.B853F96626106912": "Allez miner",
    "mining.productList.37181C74559Db182": "Commande",
    "mining.productList.Aac5B810004C46C8": "régulier",
    "mining.productList.D2Fb4F78C387D888": "Vous avez miné avec succès",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Confirmer la commande",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Montant du loyer",
    "quantify.bottomSheet.Fbd931286Be387Aa": "faire du vélo",
    "quantify.bottomSheet.9Ad84Db481807844": "indéfiniment",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Taux de rendement journalier",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "date de valeur",
    "quantify.bottomSheet.88008603Ba7Bde64": "Achat réussi",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Obtenez désormais une part des revenus！",
    "quantify.bottomSheet.C778A674C4A054F9": "vérifier la commande",
    "quantify.bottomSheet.685Dad322B7029B0": "Opération de retour",
    "quantify.buyForm.Acf8560A04334E95": "prix d'achat",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Aperçu",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "Délai de versement des dividendes",
    "quantify.buyForm.Fcda03Adbc5Fca97": "tous les jours",
    "quantify.buyForm.Afb89504Fc399189": "Veuillez saisir la quantité achetée",
    "quantify.buyForm.1435Afdfb4Ec0596": "Quantité minimale d'achat",
    "quantify.buyForm.1B97F1730Bacd839": "Quantité maximale d'achat",
    "quantify.index.32F56C9B7D0E250A": "Période : indéterminée",
    "quantify.index.E34F028D00904630": "prix",
    "quantify.index.E34F028D00904631": ",Taux de rendement journalier",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Montant du séquestre",
    "quantify.productDetail.53635B53Bca31Dfa": "Revenu journalier estimé",
    "quantify.productDetail.F9E46449A6269E09": "Revenu cumulé",
    "quantify.productDetail.98Fc46B1B8C993B2": "Commander un hébergement",
    "quantify.productDetail.93624Bb045830966": "Louer maintenant",
    "quantify.records.0C16A18C32B9Cf4A": "Dossier de commande",
    "quantify.records.B191F5614B0E2312": "Historique d'achat",
    "quantify.records.6A06033B86A73F7C": "dossier de rachat",
    "quantify.records.45E0F7E71611618E": "Revenu cumulé",
    "quantify.records.45E0F7E71611618F": "Bénéfice à l'échéance",
    "quantify.records.Aae1201E38C4Baaa": "En cours d'exécution",
    "flash.index.C41Fd4F2Fdfa594B": "consommer",
    "flash.index.1Ac59Fa2218E3B00": "Disponible:",
    "flash.index.Bf7233A660821549": "obtenir",
    "flash.index.548E775Dd67C7472": "Confirmer l'échange",
    "flash.index.066A2449Bd616F03": "Veuillez saisir la quantité à échanger",
    "flash.index.Fca26Fcf9C06563F": "Récupération Flash réussie",
    "message.index.40031524233F4564": "Centre de messagerie",
    "message.index.8E5586210Fd44D69": "Rappel perpétuel de position",
    "message.index.Bcd3F470E88C197D": "Aucune notification pour l'instant",
    "message.index.23A557Fbce545879": "Avis de liquidation définitive",
    "message.index.B5E19B36B5125550": "Rappel de retrait",
    "message.index.716A981465138334": "Rappel de recharge",
    "message.index.884474Ebff2977Ba": "informations système",
    "message.index.08Cc7D24752Ec42C": "un service en ligne",
    "message.index.1D820A6A19Ab025D": "Service manuel en ligne 7 X 24 heures",
    "more.index.29881E0B5Df58Bf7": "Plus de fonctionnalités",
    "more.index.C77Ee35911D10D9D": "Fonctions communes",
    "more.index.3558F69B0260Be61": "Fonction de trading",
    "more.index.7243Fe610Ed98Cec": "vrai compte",
    "more.index.7243Fe610Ed98Ced": "Compte démo",
    "more.index.693C7B35Bdf3E3A3": "finance",
    "more.index.F855997Fac114554": "Centre d'aide",
    "more.index.Ae9A1252278Da742": "aide",
    "more.index.Ace012504Bc529E0": "information",
    "more.index.05682Dbef5Eeb0A8": "service client",
    "more.index.C7Af227A5D5C7Bb9": "Vous êtes passé à un compte réel",
    "more.index.D818A65592694786": "Vous êtes passé à un compte démo",
    "notice.details.B360807Dbe727E6E": "à propos de nous",
    "notice.details.92F2552F695E70E3": "à propos de nous",
    "notice.details.7Aaceaf69B67F3D1": "Introduction aux règles",
    "notice.index.6128438Be34Bcb9F": "Avis perpétuel",
    "notice.index.71627F5Eb7128261": "Notification de retrait",
    "notice.index.2Dce570Fda841705": "Notification de recharge",
    "notice.index.228A7C9C6448A9B1": "notification du système",
    "setting.dialog.8B4F5B74660Deabe": "Configuration des couleurs",
    "setting.dialog.Bfc7D25486Ebc1A9": "Le vert monte et le rouge descend",
    "setting.dialog.796Ea0E1A82C320B": "Le rouge monte et le vert descend",
    "setting.index.8Ddc5864E1739466": "Préférences",
    "setting.index.F5205801D57D6727": "langue",
    "setting.index.2E2C12Dd0Cc1B63B": "Devise de cotation",
    "setting.index.322D8496Aec3D918": "numéro de version",
    "setting.index.Aa7E6C204C751Cfa": "se déconnecter",
    "share.index.75Bc38Bbf7D2E766": "Inviter des amis",
    "share.index.45575Eb5D42213E6": "mon code d'invitation",
    "share.index.29F67F0644A78Bf3": "copie",
    "share.index.19Cc65Bb6Bb98Fe7": "Mon lien d'invitation",
    "share.index.5372712F162Fb78B": "Nombre de subordonnés directs",
    "share.index.C88D6B95E3Ab7D6F": "Nombre total de subordonnés",
    "share.index.Bb5Fa5471Aeecc62": "Remise de commission pour les subordonnés directs",
    "share.index.426B6Bac9A5F3D4F": "remise totale",
    "share.index.197E4A428F0401C9": "Afficher les enregistrements de remise",
    "share.records.88A6B2E2B74B13C4": "Dossier de commission",
    "share.records.B671A35Ffdc93B89": "Rabais",
    "transfer.index.B17617A869C03Ca5": "portefeuille",
    "transfer.index.E4116Dcc6F6C7270": "Transfert réussi",
    "transfer.index.48Fb603C6638C7F6": "Veuillez définir un mot de passe pour le fonds",
    "transfer.records.Eb0E39Eb5911D418": "obtenir",
    "transfer.records.977554Be0Aa65052": "Temps d'échange Flash：",
    "transfer.records.9Aa33884Aa400B7C": "temps de transfert：",
    "form1.forget.76636Db8Fae5B124": "votre boîte aux lettres",
    "form1.forget.35354B9Cca1Bb0E6": "Code de vérification de l'e-mail",
    "form1.forget.604A0B11E916F387": "mot de passe",
    "form1.forget.9Cb1C9C51E7503D6": "Confirmez le mot de passe",
    "form1.forget.3A4F7E04B008453C": "Votre numéro de téléphone",
    "form1.forget.Cf11Dce5B4713B5A": "Code de vérification du téléphone portable",
    "form1.forget.3012Edba57D4F297": "Veuillez entrer votre email",
    "form1.forget.F1Ee1D97E869E447": "veuillez entrer le code de vérification",
    "form1.forget.3A5C4C9Ae68Fa0F6": "s'il vous plait entrez votre mot de passe",
    "form1.forget.C6Cb174Da411D5F7": "Veuillez entrer votre téléphone portable",
    "form1.forget.947B3B21Ee45E582": "envoyer",
    "form1.forget.3E0580F1E8630Df6": "Deux mots de passe sont incohérents",
    "form1.forget.C5A61836B8Cc74C6": "Le changement de mot de passe a été redirigé avec succès...",
    "form1.forget.B96D072Bc8962565": "Renvoyer",
    "form1.login.2B72C27Ff828Cfc4": "Renvoyer",
    "form1.login.D7C6B872Af9Cb17F": "mémoriser mon mot de passe",
    "form1.login.0Fff44C827A4F3B6": "Se connecter",
    "form1.login.1B7F2C1Ede3990Cc": "Pas compte? S'inscrire maintenant",
    "form1.login.F657F7Be1Cc33Bd2": "oublier le mot de passe",
    "form1.login.2074A1Eec4B088B0": "Connexion au portefeuille",
    "form1.login.1B8938Fbcc3C9B56": "Connexion réussie et redirection...",
    "form1.login.07162A7Bb9310555": "Veuillez installer le portefeuille ERC20",
    "form1.login.766969966Ae151Dc": "Veuillez installer le portefeuille TRC20",
    "form1.login.B88171E1Ad4C50Bf": "Vous avez refusé de changer de réseau",
    "form1.register.Aaef0710Cb4Db554": "Entrez à nouveau le mot de passe",
    "form1.register.54Dfea0415C708Ff": "Code d'invitation",
    "form1.register.6839940Dac08C794": "registre",
    "form1.register.9901A3B5550Aa58E": "Vous avez déjà un compte? Se connecter",
    "form1.register.2548Ca53785252Ea": "Veuillez entrer votre code d'invitation",
    "form1.register.Dd71Fd65900Dd03E": "Veuillez lire le contrat de service d'inscription et accepter,",
    "form1.register.7975613909490B77": "Inscription réussie et redirection...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Choisir un portefeuille",
    "userc.certification.2Af9511640153D2E": "Certification junior",
    "userc.certification.177B191D8E0Fa84D": "non certifié",
    "userc.certification.601218D4D572E98A": "vérifié",
    "userc.certification.6E37C51Ba9012Fc8": "N'a pas passé",
    "userc.certification.1B9B563E05C96474": "Certification avancée",
    "userc.certification.1418B83803Ab56B4": "Veuillez d'abord remplir la certification principale",
    "userc.creditScore.0B149112601Acc4D": "pointage de crédit",
    "userc.creditScore.88Ac7F84E106C5F6": "cote de crédit totale",
    "userc.creditScore.3E1D6E0E7F1E6155": "Évaluation du système",
    "userc.index.62Eac58D04Bbca0F": "Centre personnel",
    "userc.index.F4Cac53F93Cb2Aff": "trading simulé",
    "userc.index.42F409Fdf3559773": "Centre de sécurité",
    "userc.index.1B8E211009807Db8": "Authentification",
    "userc.index.Bdfcf21Eb8F2Eb70": "Enregistrements modifications compte",
    "userc.index.6C18D67F6D19586B": "Présentation de la plateforme",
    "userc.index.807A814Ed58A93Bc": "vider le cache",
    "userc.index.E43Daad59759611C": "non certifié",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Changer le mot de passe de connexion",
    "userc.secureCenter.1F37Ba427Debf24A": "Liaison de compte",
    "userc.secureCenter.F9A5Ffa750664004": "Lier l'authentificateur Google",
    "userb.certificationForm.7685Af7D30043Cd6": "Certification junior",
    "userb.certificationForm.7685Af7D30043Cd7": "Certification avancée",
    "userb.certificationForm.70E37C35Abc3F462": "Pays",
    "userb.certificationForm.F92C25A68E4D08C5": "Ville",
    "userb.certificationForm.0Ccc6387B38E0318": "votre nom",
    "userb.certificationForm.94C67Bba370Fdd46": "numéro de licence",
    "userb.certificationForm.B2D4C7Fffe79258E": "Mail",
    "userb.certificationForm.57554268761Fb47A": "Téléchargez le recto de votre pièce d'identité",
    "userb.certificationForm.D79E90201F5319Bd": "Téléchargez le dos de votre pièce d'identité",
    "userb.certificationForm.9C6Bad08Af29E20E": "Téléchargez une photo de votre carte d'identité",
    "userb.certificationForm.F96B62Dfa31Cde45": "Confirmer la soumission",
    "userb.certificationForm.2125D78Ea8C6D287": "Exigences de téléchargement",
    "userb.certificationForm.0D64864D3076A824": "Voir l'exemple",
    "userb.certificationForm.35287Deb72281E87": "1.Doit être capable de lire clairement les informations de la carte d’identité.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.Le contenu de la photo est vrai et valide et aucune modification n'est autorisée.",
    "userb.certificationForm.25A90660554319F9": "3.Le numéro d'identification et le nom doivent être clairement visibles, prendre en charge JPG/JPEG/PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "Entrez s'il vous plait",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Soumission réussie, veuillez attendre l'examen",
    "userb.certificationForm.744Bd07D8Abd2513": "Veuillez télécharger le recto de votre pièce d'identité",
    "userb.certificationForm.6De1C319130F7Fef": "Veuillez télécharger le dos de votre pièce d'identité",
    "userb.certificationForm.79F8E8B172E30C8A": "Veuillez télécharger une photo de votre carte d'identité",
    "userb.certificationForm.46412E16D28A4753": "Échec du téléchargement des photos",
    "userb.changeDialog.676B0Adca1F8Eea4": "changer le mot de passe",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "numéro de téléphone",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "commutateur de validateur",
    "userb.modifyInfoDialog.62149E566814154C": "Sélectionnez le validateur",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Ouvrez l'application d'authentification et appuyez sur +",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "Sélectionnez « Scanner le code QR » ou « Touche Entrée »",
    "userb.modifyInfoDialog.93F094E2B4369472": "Scannez le code QR ou entrez la clé",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "fermé ouvert",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Mot de passe de l'ancien fonds",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Nouveau mot de passe du fonds",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Confirmer le nouveau mot de passe du fonds",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Confirmer le mot de passe du fonds",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Veuillez saisir votre ancien mot de passe du fonds",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Veuillez saisir votre nouveau mot de passe pour le fonds",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Veuillez saisir le mot de passe de votre fonds",
    "userb.modifyInfoDialog.258A534498Fe984F": "Veuillez confirmer le mot de passe de votre fonds",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Liaison de compte",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Mot de passe du fonds",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "confirmer",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Le validateur est fermé",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "La vérification Google a été activée avec succès",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Mot de passe du fonds modifié avec succès",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Définir le mot de passe du fonds avec succès",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Reliure réussie",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Erreur de code de vérification",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Exemple de téléchargement de certificat",
    "userb.veBottomSheet.6F38A0D5858633Ee": "fond sombre et propre",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Éclairage suffisant",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Cadre auxiliaire d'alignement",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Les photos sont claires",
    "userb.veBottomSheet.50B741382E71F103": "Raisons possibles de l'échec",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "Le cache de protection de la carte d'identité n'a pas été retiré",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "L'arrière-plan est affecté par le désordre",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "La carte d'identité est réfléchissante",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "La lumière est trop sombre/obstruée par l'ombre du téléphone portable",
    "userb.veBottomSheet.1A75D2Fc2614592B": "titulaire d'une carte d'identité",
    "home.home.C3525Eb110Db58Aa": "Plus",
    "home.home.29761532Fc8Ebecc": "commerce",
    "home.home.F15Ea99Bfa6E1848": "Certification de sécurité, achetez en toute confiance",
    "home.home.46E1Ab39Bae7182A": "Rapide",
    "home.home.Fde2Eb7F6F3Dd800": "Transactions rapides et opération simple",
    "home.home.Ea4756Bc1642E0F1": "nom",
    "home.home.B6B1127Ede1C97B4": "dernier prix",
    "home.home.3587Ad870Eb0Ab86": "24H haut et bas",
    "home.home.Cb1A4E14Ec2Cd2B5": "Découvrir",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "Métal",
    "home.home.5Fc1474Dbf74Ac1A": "Agricole",
    "home.home.135318F4664F7773": "énergie",
    "kline.kLine.C96Ff250Cf6B8B84": "24H plus élevé",
    "kline.kLine.6E8Be64F19D74565": "24H Volume",
    "kline.kLine.08A4Bfbe26A0F262": "24H plus bas",
    "kline.kLine.8701Deb075066331": "24H Volume(USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 point",
    "kline.kLine.B49Cca240E013124": "5 points",
    "kline.kLine.F27A90Be97Ba3D6C": "15 points",
    "kline.kLine.Ff3E62395B694384": "30 points",
    "kline.kLine.C1993F9C6Db1A674": "1 heure",
    "kline.kLine.Fd2Cbbae405E284C": "Un jour",
    "kline.kLine.C9Cd71904395042C": "Janvier",
    "kline.kLine.7D8127Ec18C7B8C7": "Acheter",
    "kline.kLine.E3D3Ffdb9B3E5636": "Quantité (pièces)",
    "kline.kLine.6834Fec1838D8029": "Prix ​​(USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "ordre de vente",
    "kline.kLine.B9Bea1A9A88D1419": "Ouvert longtemps",
    "kline.kLine.D36Cfa96Ee2B343B": "Ouvert court",
    "other.languageRateSelect.6897784800834C1B": "Paramètres de langue",
    "other.languageRateSelect.6897784800834C1C": "Veuillez sélectionner la devise de tarification",
    "other.navigationBar.F6E62F9642018Cbb": "Fig début",
    "other.navigationBar.0Ec227161A47B49D": "Citations",
    "other.navigationBar.29761532Fc8Ebecc": "commerce",
    "other.navigationBar.693C7B35Bdf3E3A3": "finance",
    "other.navigationBar.9734C54620Eb09Af": "actifs",

    "home.index.25Aa4Fc745150030": "roi des crypto-monnaies",
    "home.index.2D6Ecf8Faaa8B124": "Plus rapide, meilleur, plus fort",
    "home.index.1Db26267D1041223": "échangez facilement plus de 1?700 crypto-monnaies",
    "home.index.F8E18C90C3E35618": "S'inscrire maintenant",
    "home.index.29761532Fc8Ebecc": "commerce",
    "home.index.D8A95E4506487452": "Contrats populaires",
    "home.index.5Bd31Fab681C6E9D": "voir plus",
    "home.index.010Ae07A09Caf221": "paire de trading",
    "home.index.F4D875118E6D6Dbb": "Dernier prix de transaction",
    "home.index.3Dfdfb3D6599100E": "24H de montée et de descente",
    "home.index.28Fb39Bd41E54242": "s'orienter",
    "home.index.B47A3911Bacd94C6": "Liste des gagnants",
    "home.index.712910D574F43D95": "Revenu stable",
    "home.index.5507Fd830D7Bf51F": "Meilleures stratégies, faciles à copier",
    "home.index.7De3D636A165E008": "taux de retour",
    "home.index.B853F96626106912": "Allez miner",
    "home.index.213B5C96Be7Cd1F8": "jours, taux d'intérêt annualisé",
    "home.index.213B5C96Be7Cd1F9": "Maximum achetable",
    "home.index.Dc710Cffe6313Bb5": "vérifier les détails",
    "home.index.B92Ffc837Ecaa1Dd": "nouvelles",
    "home.index.6C18D67F6D19586B": "Présentation de la plateforme",
    "home.index.6342E3C33Cb48Cd5": "La monnaie numérique est un moyen d'échange qui utilise des principes cryptographiques pour garantir la sécurité des transactions. Contrairement aux monnaies fiduciaires telles que le dollar américain, les monnaies numériques n’ont pas de forme physique. Les monnaies numériques populaires telles que Bitcoin (BTC), Ethereum (ETH) et Polkadot (DOT) utilisent la blockchain comme technologie sous-jacente pour agir comme un registre numérique décentralisé. Toutes les transactions en monnaie numérique sont enregistrées dans la blockchain et ne peuvent être modifiées une fois confirmées et vérifiées. Contrairement aux monnaies traditionnelles, où les banques tiennent des registres centralisés, les transactions en monnaie numérique se déroulent sur des blockchains publiques accessibles à tous.",
    "home.index.198Efc4254683Eef": "De plus, selon le mécanisme de consensus, chacun peut vérifier les transactions en monnaie numérique et les ajouter à la blockchain, réalisant ainsi la décentralisation. Alors que des sociétés mondiales de premier plan telles que Samsung, BlackRock, Morgan Stanley et Alphabet ont investi, les gens pensent que la technologie blockchain va complètement changer le système financier mondial. La monnaie numérique vous ouvre la porte du monde de la finance décentralisée, vous permettant d’explorer les possibilités infinies offertes par la technologie de pointe.",
    "home.index.E710339D9576292E": "Notre avantage",
    "home.index.F9Ce8B2C63885B26": "s?r et stable",
    "home.index.C4D0A2C9Ff0305Ce": "Équipe technique de premier plan, protection de sécurité complète, recherche et développement indépendants de transactions à haut débit, toujours stables et utilisables lors de transactions massives.",
    "home.index.2E8708Ee3F11Cc1A": "Professionnel et fiable",
    "home.index.Bb12Cbcf89C56C5C": "Une équipe d'exploitation professionnelle, avec de nombreuses années d'expérience dans la blockchain et la finance, est titulaire d'une licence de négociation d'actifs numériques conforme et dispose d'une garantie de réserve à 100 %.",
    "home.index.Aa4723Bfbf6808F4": "Servir avec c?ur",
    "home.index.1Bf0204B7D3Ee230": "Face au marché mondial, prise en charge de plusieurs langues, fonctionnement 24h/24 et 7j/7, support communautaire solide et service client professionnel.",
    "home.index.2E7Dfd53D26F7224": "haute performance",
    "home.index.1C65453103B5Fcc3": "300 000 transactions par seconde et temps de réponse aux commandes inférieur à 1 milliseconde.",
    "home.index.85Ac49Ece9Dcc80B": "Négociez à tout moment et en tout lieu?!",
    "home.index.2799E18842E4Ebe9": "Ouvrez une position instantanément, pris en charge à la fois par l'application OKEX et la page Web?!",
    "home.index.2Bf52Ce75E29Fc88": "Scannez le code pour télécharger maintenant",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "IOS",
    "home.index.04Ee893775563F22": "service produit",
    "home.index.04Ee893775563F23": "direction financière",
    "home.index.04Ee893775563F24": "retour",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "Achetez Bitcoin, Ethereum et",
    "homepage.text1_2": "1000+ cryptomonnaies",
    "homepage.text2": "Votre parcours dans l'univers des cryptomonnaies",
    "homepage.text4": "Gestion financière à taux élevé",
    "homepage.text5": "Gestion financière stable avec des rendements annuels élevés",
    "homepage.text6": "Récompenses de partage",
    "homepage.text7": "Transactions rapides, utilisation simple",
    "homepage.text8": "Voir plus",
    "homepage.text9": "Échange instantané",
    "homepage.text10": "Certification de sécurité, achetez en toute confiance",
    "homepage.text11": "Équipe technique de pointe, sécurité complète",
    "homepage.text12": "Protection indépendante",
    "homepage.text13": "Professionnel et fiable",
    "homepage.text14": "Équipe d'exploitation professionnelle, avec de nombreuses années d'expérience en blockchain et finance",
    "homepage.text15": "Service avec cœur",
    "homepage.text16": "Axé sur le marché mondial, supporte plusieurs langues, fonctionnement 24/7",
    "homepage.text17": "Haute performance",
    "homepage.text18": "300 000 transactions par seconde et temps de réponse des ordres inférieur à 1 milliseconde",
}
