<template>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      color="#fff"
      temporary
    >
      <v-list-item class="pt-4 pb-4">
        <v-list-item-avatar color="#f3f3f3">
          <img v-if="avatar && avatar.type == 1" :src="avatar && avatar.content" alt="" />
          <div style="width: 100%;color: #111;" v-else>{{ avatar && avatar.content }}</div>
          <!-- <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img> -->
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ avatar && avatar.userName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          link
          @click="$router.replace('/')"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $vuetify.lang.t("$vuetify.public.home") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          @click="$router.replace('/delivery')"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.setting.dreivatives") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          @click="$router.replace('/contract')"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.standardContract.contractTransaction") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          @click="$router.replace('/assets')"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.setting.assets") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="openMining"
          link
          @click="$router.replace('/invest')"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.setting.liquidity") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!isApp"
          link
          @click="$router.replace('/download')"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.login.placeholder7") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="langSelect"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $vuetify.lang.t("$vuetify.setting.languageTitle") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
      <Dialog
        :value.sync="showConfirmBox"
        :title="confirmTitle"
        :sub="confirmSub"
        :type="confirmType"
        :list="confirmList"
        @confirm-click="confirmClick"
      />
    </v-navigation-drawer>
</template>

<script>
import {mapActions,mapGetters} from "vuex"
import Dialog from "@/views/personal/components/Dialog.vue";
export default {
  components: {
    Dialog,
  },
  data () {
    return {
      drawer:false,
      isApp:false,
      showConfirmBox: false,
      confirmTitle: "",
      confirmSub: "",
      confirmType: "",
      confirmList: [],
      languages:[]
     
    }
  },
  created() {
    if (window.location.href.indexOf("ios.html") != -1) {
      this.isApp = true;
    }
  },
  computed:{
    ...mapGetters(["getLanguage","getUserInfo","getConfigData"]),
    openMining() {
      return this.getConfigData.openMining;
    },
    avatar() {
      if (Object.keys(this.getUserInfo).length == 0) {
        return;
      }
      let nickName =
        this.getUserInfo.nickName ||
        this.getUserInfo.email ||
        this.getUserInfo.phone ||
        this.getUserInfo.address;
      let userName =
        this.getUserInfo.email ||
        this.getUserInfo.phone ||
        this.getUserInfo.address;
      if (this.getUserInfo.headerImg) {
        return {
          type: 1,
          content: this.getUserInfo.headerImg,
          userName,
        };
      }

      return {
        type: 2,
        content: nickName.slice(0, 1),
        userName,
      };
    },
  },
  props:{
    value:{
      type:Boolean,
      default:() => false
    }
  },
  methods:{
    ...mapActions([
      "languageFetch",
    ]),
    langSelect() {
      this.confirmList = [];
      this.getLanguageListFuc();
      this.showConfirmBox = true;
      this.confirmType = "lang";
      this.confirmTitle = this.$vuetify.lang.t("$vuetify.setting.text6");
    },
    async getLanguageListFuc() {
      let res = await this.languageFetch();
      if (res.errCode == 0) {
        this.languages = res.data;
        this.confirmList = res.data;
      }
    },
    confirmClick() {
      this.showConfirmBox = false;
    },
    close() {
      this.$emit('update:value',false)
    }
  },
  watch:{
    value(v) {
      this.drawer = v
    },
    drawer(v) {
      if(!v) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-list-item {
  min-height: 50px;
}
</style>