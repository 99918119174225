import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Vereinfachtes Chinesisch",
        dialogDetermine: "Sicher",
        dreivatives: "Lieferung",
        markets: "Zitate",
        assets: "Aktiva",
        liquidity: "Mining",
        helpCentre: "Hilfezentrum",
        announcementCenter: "Ankündigung",
        about: "um",
        bulletinCentre: "Ankündigung",
        serviceAgreement: "Dienstleistungsvertrag",
        support: "Unterstützung",
        onlineSupportCustomerService: "Onlineservice",
        suggestionsAndFeedback: "Vorschläge und Feedback",
        service: "Aufschlag",
        download: "herunterladen",
        coinApplication: "Antrag auf Münzauflistung",
        privacyStatement: "Datenschutzerklärung",
        trade: "Vertragshandel",
        standardConteact: "Nachhaltig",
        signOut: "Abmelden",
        assetWallets: "Vermögenswert-Wallet",
        financeRecords: "Kontoänderungsaufzeichnungen",
        identification: "Authentifizierung",
        personalCenter: "Persönliches Zentrum",
        chooseLanguage: "Wählen Sie eine Sprache",
        tips: "Hinweis",
        dark: "Dunkelmodus",
        light: "Tagmodus",
        languageTitle: "Sprache",
        convertCurrency: "Geld umtauschen",
        cancel: "Stornieren",
        aboutUs: "über uns",
        standard: "Kontinuität des Standards",
        share: "Aktie",
        introduce: "Plattformeinführung",
        set: "aufstellen",
        changePassword: "Login-Passwort ändern",
        denomination: "Notierungswährung",
        color: "Farbkonfiguration",
        code: "Versionsnummer",
        color1: "Rot steigt und Grün fällt",
        color2: "Grün steigt und Rot fällt",
        setLangue: "Spracheinstellungen",
        vip: "VIP-Vorteile",
        vip2: "Aktuelles VIP-Level",
        safeMenu: "Sicherheitscenter",
        conventional: "konventionell",
        other: "andere",
        quickTips: "Fügen Sie bis zu 3 Shortcut-Funktionstasten hinzu",
        quickTransaction: "Schnelle Transaktion",
        safeConvenient: "Sicher und bequem",
        timeSharing: "Zeit teilen",
        currentLevel: "Aktuelles Level",
        levelBenefits: "Einkommen auf %{level}-Niveau",
        teamLevel: "Teamebene",
        levelLower: "%{level}untergeordnet",
        rules: "Regelbeschreibung",
        bindWithdrawalAddress: "Binden Sie die Auszahlungsadresse ein",
        tipsBindWithdrawalAddress: "Sie haben Ihre Auszahlungsadresse noch nicht festgelegt. Bitte binden Sie diese zuerst ein",
        walletAddressFormatIncorrect: "Das Format der Wallet-Adresse ist falsch",
        tradeRules1: "Prognostizieren Sie den Anstieg und Rückgang in der zukünftigen Periode. Der Preis zum Zeitpunkt der Bestellung wird als Startpreis verwendet, und der Preis in der letzten Sekunde des Prognosezeitraums wird als Abrechnungspreis verwendet. Wenn der Abrechnungspreis größer als der Startpreis ist, bedeutet dies eine Erhöhung. Wenn die Abrechnung Liegt der Preis unter dem Startpreis, bedeutet dies einen Rückgang.",
        tradeRules2: "1.Was ist ein Vertrag?",
        tradeRules3: "Diese Plattform bietet USDT-basierte unbefristete Verträge. Benutzer können den Anstieg oder Rückgang beurteilen und sich für den Kauf von Long- oder Short-Kontrakten entscheiden, um Gewinne aus dem Anstieg/Fall der Preise für digitale Vermögenswerte zu erzielen. Unbefristete Verträge haben kein Lieferdatum und Benutzer können sie für immer behalten.",
        tradeRules4: "2.Was ist Marge?",
        tradeRules5: "Auf dem virtuellen Vertragsmarkt müssen Benutzer nur einen kleinen Betrag in einem bestimmten Verhältnis zum Vertragspreis als finanzielle Garantie für die Vertragserfüllung zahlen und können dann am Kauf und Verkauf des Vertrags teilnehmen. Bei dieser Art von Geldern handelt es sich um die virtuelle Vertragseinlage. \nDie Verträge auf dieser Plattform nutzen das separate Margin-Modell separater Konten. Jede Währung entspricht einem Vertragskonto. Die Kontowerte und Positionen verschiedener Vertragswährungen sind unabhängig voneinander und Überweisungen und Transaktionen zwischen verschiedenen Vertragskonten beeinflussen sich nicht gegenseitig. Nach der Eröffnung eines Vertragskontos eines Nutzers werden die Risiken und Vorteile aller auf dem Vertragskonto gehaltenen Positionen zusammengerechnet.",
        tradeRules6: "3.So berechnen Sie die Marge",
        tradeRules7: "Positionsmarge = Kontraktnennwert * Anzahl offener Kontrakte / Leverage-Multiplikator\nEingefrorene Marge = die Summe der Margen aller erfolgreich platzierten Orders und Eröffnungsorders in der aktuellen Order\nverfügbare Marge = Kontostand – Positionsmarge – eingefrorene Marge",
        tradeRules8: "4.So berechnen Sie die Margin-Rate",
        tradeRules9: "Die Margin-Rate ist ein Maß für das Vermögensrisiko des Benutzers. \nMarginsatz = (Nicht realisierter Gewinn und Verlust + Positionsmarge + Verfügbare Margin + Eingefrorene Marge – Liquidationsgebühr) / Positionsmarge \nJe kleiner der Marginsatz, desto höher das Risiko des Kontos. Wenn der Margensatz kleiner oder gleich 0 % ist, wird eine Zwangsliquidation ausgelöst.",
        tradeRules10: "5.Wie hoch ist der geschätzte erzwungene Paritätspreis?",
        tradeRules11: "Der geschätzte Zwangsliquidationspreis, also der vom System berechnete Preis, der theoretisch eine Zwangsliquidation auslöst, dient nur als Referenz und kann geringfügig vom tatsächlichen Wert abweichen.",
        tradeRules12: "6.Was ist das Gesamteigenkapital und der nicht realisierte Gewinn und Verlust?",
        tradeRules13: "Gesamtkontokapital = Kontostand + nicht realisierter Gewinn und Verlust\nDer nicht realisierte Gewinn und Verlust ist der Gewinn und Verlust der Position, die der Benutzer derzeit im Währungsvertrag hält. Nicht realisierter Gewinn und Verlust ändert sich mit den letzten Änderungen des Transaktionspreises. \nNicht realisierter Gewinn und Verlust für Long-Positionen = (1/durchschnittlicher Positionspreis - 1/letzter Transaktionspreis) * Anzahl der Kontrakte für Long-Positionen * Kontraktnennwert * aktueller Transaktionspreis \n Nicht realisierter Gewinn und Verlust für Short-Positionen = (1/ Letzter Transaktionspreis - 1/ Durchschnittlicher Positionspreis) * Anzahl der Short-Kontrakte * Kontraktnennwert * Letzter Transaktionspreis",
        text1: "Konto Sicherheit",
        text2: "Tag- und Nachtmodus",
        text3: "Cache leeren",
        text4: "Möchten Sie sich wirklich abmelden?",
        text5: "Sind Sie sicher, dass Sie den Cache leeren möchten?",
        text6: "mehrsprachig",
        text7: "Um Ihre Sicherheit zu gewährleisten, empfehlen wir, mindestens eine Zwei-Faktor-Authentifizierung zu aktivieren"
    },
    login: {
        login: "Anmeldung",
        register: "registrieren",
        title1: "Handeln Sie jederzeit und überall und sehen Sie sich die Marktbedingungen in Echtzeit an",
        title2: "Handeln Sie jederzeit und überall und sehen Sie sich die Marktbedingungen in Echtzeit an",
        title3: "Die vertrauenswürdigste Handelsplattform für Kryptowährungen",
        formTitle1: "Account Login",
        formTitle2: "Willkommen zurück! Melden Sie sich mit Ihrer E-Mail-Adresse und Telefonnummer an",
        btn1: "Handy",
        btn2: "Post",
        btn3: "Anmeldung",
        btn4: "registrieren",
        btn5: "vergessen Sie das Passwort？",
        field1: "Telefonnummer",
        field2: "Passwort",
        field3: "Post",
        placeholder1: "Enter verification code",
        placeholder2: "Bitte geben Sie die Telefonnummer ein",
        placeholder3: "Bitte Passwort eingeben",
        placeholder4: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        content12: "Bitte geben Sie die Telefonnummer ein",
        content13: "Bitte Passwort eingeben",
        content14: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        content16: "Finanzdienstleistungen für digitale Vermögenswerte",
        loginSuccess: "Anmeldung erfolgreich ",
        chooseArea: "Bitte wählen Sie die Vorwahl aus",
        placeholder5: "Bitte wählen Sie den Ländercode aus",
        placeholder6: "Passwort merken",
        placeholder7: "Lade App herunter",
        placeholder8: "Kontaktieren Sie den Kundendienst",
        placeholder9: "Wählen Sie Land oder Region aus",
        placeholder10: "Vorwahl",
        placeholder11: "Wallet-Login",
        placeholder12: "Der Vorgang ist fehlgeschlagen. Bitte wechseln Sie zu",
        placeholder13: "Geldbörse"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "um",
        aboutCoinbeadCenterDetails: "Es verfügt über die weltweit führende Blockchain-Asset-Handelsplattform und betreibt das gesamte Ökosystem.",
        footDetails1: "tägliches Handelsvolumen",
        footDetails2: "Transaktionen/Sekunde",
        footDetails3: "Hilfezentrum",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Es handelt sich um eine weltbekannte internationale Website für digitale Vermögenswerte, die hauptsächlich Münzen und Derivate digitaler Vermögenswerte wie Bitcoin (BTC), Litecoin (LTC) und Ethereum (ETH) für globale Benutzer bereitstellt.",
        coinbeadCenterDetailsm: "Durch den Einsatz fortschrittlicher Technologien wie GSLB, Netzwerkserver, Netzwerktransaktionen, Netzwerke, Multi-Machine-High-Speed-Memory-Trading-Engines, Cold Wallets, intelligente Offline-Hot Wallets usw. bedienen wir unsere Kunden mit mehreren Terminals wie Web-Mobiltelefonen und PCs . Bereitstellung sicherer, stabiler und zuverlässiger digitaler Tariftransaktionsdienste. Gleichzeitig verbessern und aktualisieren wir Produkte und Dienstleistungen auf der Grundlage von Benutzervorschlägen und -bedürfnissen, um jedem Kunden bessere Dienstleistungen zu bieten, und die Verbesserung der innovativen Benutzererfahrung nimmt kein Ende.",
        infrastructureCenterTitle: "Infrastruktur",
        infrastructureCenterDetails: "Unsere Mission ist es, Infrastrukturdienste für das gesamte Blockchain-Ökosystem bereitzustellen",
        freeCenterTitle: "frei",
        freeCenterDetails: "Unsere Vision ist es, den freien Geldfluss auf der ganzen Welt zu ermöglichen. Wir glauben, dass die Verbreitung dieser Freiheit die Welt zu einem besseren Ort machen kann",
        futureCenterTitle: "Pionierarbeit für die Zukunft der Technologie",
        futureCenterDetails: "Eröffnen Sie die Zukunft der Technologie",
    },
    applicationForListing: {
        title: "Antrag auf Münzauflistung",
        projectName: "Projektname",
        tokenName: "Tokenname",
        projectDescription: "Projektbeschreibung",
        contractAddress: "Vertragsadresse",
        officialWebsite: "offizielle Website",
        ContactAndEmail: "Kontakt E-mail",
        ContactAndName: "Kontaktname",
        file: "Anhang hochladen",
        pleaseEnter: "Bitte eingeben",
        enterFile: "Bitte laden Sie Dateien hoch",
        upload1: "Derzeit auf 1 Dateiauswahl beschränkt,",
        upload2: "Dieses Mal habe ich mich entschieden",
        upload3: "Dateien,",
        upload4: "Insgesamt ausgewählt",
        upload5: "Dateien",
    },
    public: {
        submit: "einreichen",
        copySuccessfully: "Erfolgreich kopiert",
        copyFailed: "Das Kopieren ist fehlgeschlagen",
        noDate: "Keine Daten",
        submitSuccessfully: "erfolgreich eingereicht",
        goBack: "zurück zur letzten Seite",
        cancel: "Stornieren",
        home: "Titelseite",
        loading: "Wird geladen...",
        confirm: "bestätigen",
        loadingFailed: "Netzwerkanomalie",
        nomore: "nicht mehr",
        tryAgain: "Bitte aktualisieren Sie die Seite/beenden Sie das Programm und laden Sie es erneut hoch!",
        hot: "Beliebte Gebiete",
    },
    inviteFriends: {
        inviteFriends: "Mit Freunden teilen",
        freedomOfWealth: "Lassen Sie den Reichtum gemeinsam frei sein",
        myInvitationCode: "mein Einladungscode",
        clickCopy: "Klicken Sie zum Kopieren",
        myInvitationLink: "Mein Einladungslink",
        clicktoSaveTheQRCode: "Klicken Sie hier, um den QR-Code zu speichern",
        saveQRCode: "QR-Code einladen",
        numberOfSubordinates: "Anzahl der Untergebenen",
        rebateIncome: "Rabatteinkommen",
        people: "Menschen",
        myPromotionRevenue: "Mein Werbeeinkommen",
        numberOfDirectSubordinates: "Anzahl der direkten Untergebenen",
        totalNumberOfSubordinates: "Gesamtzahl der Untergebenen",
        directSubordinateReturnsCommission: "Provisionsrabatt für direkte Untergebene",
        theTotalCommission: "Gesamtrabatt",
        checkHistory: "Rückvergütungsdatensätze anzeigen",
        history: "Provisionsprotokoll",
        theLowerAccount: "Untergeordnetes Konto",
        type: "Typ:",
        serviceCharge: "Bearbeitungsgebühr:",
        percentageofCommissionReturned: "Rabattverhältnis：",
        commissionrebateAmount: "Rabattbetrag：",
        time: "Zeit：",
        typeName1: "Aufladen und Kommissionieren",
        typeName2: "MT-Auftragsbearbeitungsgebühr, dreistufige Verteilung",
        typeName3: "MT Profitable Drei-Ebenen-Verteilung",
        typeName4: "Empfehlungsprämien",
        typeName30: "Aufladen und Kommissionieren",
        typeName31: "Regelmäßige Handelsgewinnrückerstattung",
        typeName32: "Gewinnrückerstattung für Liquidity Mining",
        typeName45: "Rückerstattung der Transaktionsgebühr",
        typeName46: "Provisionsrabatt auf die eigene Bearbeitungsgebühr",
        typeName50: "Rückerstattung der Bearbeitungsgebühr für Lieferverträge",
        typeName51: "Rückerstattung der Bearbeitungsgebühr für unbefristete Verträge",
        typeName52: "Zusätzlicher Provisionsrabatt für die Bearbeitungsgebühren des Liefervertrags",
        typeName53: "Zusätzlicher Provisionsrabatt für unbefristete Vertragsabwicklungsgebühren",
        typeName54: "Zusätzliche Rabatte auf die Bearbeitungsgebühren für unbefristete Verträge",
        shareRules1: "Anweisungen zur Einladung",
        shareRules2: "Betreten Sie das Einladungscenter, kopieren Sie den Einladungslink oder Code und teilen Sie ihn mit Ihren Freunden. Freunde können Ihre Untergebenen werden, indem Sie sich mit Ihrem Einladungscode registrieren.",
        shareRules3: "Verdienen Sie Rabatte",
        shareRules4: "Wenn Untergebene Transaktionen durchführen, können Sie entsprechende Provisionen erhalten, es werden bis zu drei Ebenen von Untergebenen unterstützt. Wenn Sie beispielsweise Freund A einladen, A B einlädt und B C einlädt, führen A, B und C Verträge und andere Transaktionen auf der Plattform durch und Sie können alle entsprechende Provisionen erhalten.",
        shareRules5: "Teamebene",
        shareRules6: "Je mehr Untergebene der ersten Ebene Sie befördern, desto höher ist die Teamebene und desto höher ist der Rabatt, den Sie genießen können. Die Teamebenen sind in LV1-LV6 unterteilt. Die Upgrade-Regeln sind in der folgenden Tabelle aufgeführt, wobei „N“ die Anzahl der Untergebenen der ersten Ebene ist, die aufgeladen und die Authentifizierung mit echtem Namen abgeschlossen haben.",
        shareRules7: "Wenn Untergebene Wetten auf Lieferverträge abschließen, können Sie Provisionen im Verhältnis zu ihren Wetten erhalten.",
        upgradeConditions: "Erfordern",
        recommendedNumberOfPeople: "Empfohlene Personenanzahl"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Vorschläge und Feedback",
        text: "Wenn Sie während der Nutzung auf Fehler stoßen oder Produktvorschläge haben, geben Sie uns bitte Feedback.",
        email: "E-Mail-Adresse",
        enterEmail: "Bitte geben Sie die E-Mail-Adresse ein",
        enterCorrectEmail: "Bitte geben Sie die richtige E-Mail-Adresse ein",
        title: "Titel",
        enterTitle: "Bitte geben Sie einen Fragetitel ein",
        describeTheProblem: "Beschreibe das Problem",
        enterDescribeTheProblem: "Bitte geben Sie das von Ihnen beschriebene Problem ein",
        submit: "einreichen",
    },
    help: {
        enterSearch: "suchen",
        title: "Hilfezentrum",
        search: "Bitte geben Sie Ihre Frage ein",
        text1: "Verstehen Sie Ihre Bedürfnisse und bieten Sie Ihnen weiterhin komfortablere Dienste"
    },
    announcement: {
        enterSearch: "suchen",
        title: "Ankündigung",
        search: "Bitte geben Sie Ihre Frage ein"
    },
    privacy: {
        title: "Datenschutzerklärung",
        content1: "1. Welche personenbezogenen Daten der Nutzer werden erfasst?",
        content2: "Personenbezogene Daten werden erhoben, um Produkte und Dienstleistungen bereitzustellen und diese kontinuierlich zu verbessern.",
        content3: "Die folgenden Arten personenbezogener Daten erfassen wir:",
        content4: "Informationen, die Sie uns zur Verfügung stellen: Wir erhalten und speichern alle Informationen, die Sie im Zusammenhang mit der Plattform bereitstellen. Sie haben die Möglichkeit, bestimmte Informationen nicht bereitzustellen, können dadurch aber möglicherweise bestimmte „Dienste dieser Plattform“ nicht nutzen. Beispiele für Informationen, die Sie uns zur Verfügung stellen, sind:",
        content5: "Name",
        content6: "Adresse",
        content7: "Land der Staatsbürgerschaft",
        content8: "Ausweisfoto",
        content9: "Mobiltelefonnummer/E-Mail-Nummer",
        content10: "Weitere Informationen, die uns helfen, Sie zu identifizieren",
        content11: "Automatisch erfasste Informationen: Wir erfassen und speichern automatisch bestimmte Arten von Informationen über Ihre Nutzung der Plattformdienste. Wie viele Websites verwenden wir Cookies und andere eindeutige Kennungen. Wir erhalten bestimmte Arten von Informationen, wenn Ihr Webbrowser oder Ihr Gerät auf die Plattform zugreift. Beispiele für Informationen, die wir sammeln und analysieren, sind:",
        content12: "Die Internet Protocol (IP)-Adresse, die einen PC mit dem Internet verbindet;",
        content13: "Anmeldeinformationen, E-Mail-Adresse, Passwort und Standort eines persönlichen Geräts oder Computers;",
        content14: "Versions- und Zeitzoneneinstellungen;",
        content15: "Verlauf der Transaktionen.",
        content16: "Informationen aus anderen Quellen: Wir erfassen möglicherweise Informationen über Sie aus anderen Quellen, beispielsweise aus der Bonitätshistorie von Kreditauskunfteien. Wir werden diese Informationen verwenden, um Betrug zu verhindern und aufzudecken.",
        content17: "2. Können Kinder es benutzen?",
        content18: "Auf dieser Plattform ist es Personen unter 18 Jahren nicht gestattet, den „Dienst“ zu nutzen.",
        content19: "3. Wofür werden Cookies und andere Identifikatoren verwendet?",
        content20: "Wir verwenden Cookies und ähnliche Tools, um das Benutzererlebnis zu optimieren, unsere Dienste bereitzustellen und zu verstehen, wie Kunden unsere Dienste nutzen, damit wir gezielte Verbesserungen vornehmen können. Wir verwenden Cookies, um sicherzustellen, dass unser System Ihren Browser oder Ihr Gerät erkennen und Ihnen Dienste anbieten kann.",
        content21: "Wir verwenden betriebliche Cookies und ähnliche Tools (zusammen „Cookies“), um Dienste bereitzustellen, wie zum Beispiel:",
        content22: "Um Sie zu identifizieren, wenn Sie sich anmelden und unsere Dienste nutzen.",
        content23: "Stellen Sie maßgeschneiderte Funktionen und Dienste bereit.",
        content24: "Schützen Sie sich vor betrügerischen Aktivitäten.",
        content25: "Verbessern Sie die Sicherheit.",
        content26: "Verfolgen Sie Ihre Präferenzen (z. B. Währung und Sprache).",
        content27: "Wir verwenden Cookies auch, um zu verstehen, wie Benutzer unsere Dienste nutzen, damit wir gezielte Verbesserungen vornehmen können.",
        content28: "4. Werden meine persönlichen Daten weitergegeben?",
        content29: "Benutzerinformationen sind ein wichtiger Teil unseres Geschäfts und wir werden die persönlichen Daten der Benutzer nicht an andere verkaufen. Diese Plattform gibt die personenbezogenen Daten der Benutzer nur unter den folgenden Umständen an ihre Tochtergesellschaften oder verbundenen Unternehmen weiter. Diese Tochtergesellschaften oder verbundenen Unternehmen sind verpflichtet, diese Datenschutzerklärung einzuhalten oder zumindest Praktiken zu befolgen, die ebenso schützend sind wie die in dieser Datenschutzerklärung beschriebenen Schutzmaßnahmen.",
        content30: "Drittanbieter: Wir beauftragen andere Unternehmen und Einzelpersonen mit der Ausführung von Aufgaben in unserem Namen. Beispiele für solche Funktionen sind: Analyse von Daten, Bereitstellung von Marketingunterstützung, Abwicklung von Zahlungen, Übermittlung von Inhalten sowie Bewertung und Steuerung von Kreditrisiken. Diese Drittanbieter haben Zugriff auf personenbezogene Daten, die zur Erfüllung ihrer Aufgaben erforderlich sind, dürfen diese jedoch nicht für andere Zwecke verwenden. Darüber hinaus müssen sie personenbezogene Daten in Übereinstimmung mit dieser Datenschutzerklärung und den geltenden Datenschutzgesetzen verarbeiten.",
        content31: "Unternehmensübertragungen: Da unser Unternehmen weiter wächst, können wir andere Unternehmen oder Dienstleistungen verkaufen oder kaufen. Bei solchen Transaktionen gehören Benutzerinformationen im Allgemeinen zu den übertragenen Geschäftsvermögenswerten, unterliegen jedoch weiterhin den Zusagen in einer bereits bestehenden Datenschutzerklärung (es sei denn, der Benutzer stimmt natürlich anders zu). Darüber hinaus würden im Falle einer Übernahme unseres Unternehmens oder im Wesentlichen aller seiner Vermögenswerte auch Benutzerinformationen übertragen. Schutz unseres Unternehmens und anderer: Wenn wir glauben, dass die Freigabe von Konto- und anderen persönlichen Daten angemessen ist, um dem Gesetz oder unseren behördlichen Verpflichtungen nachzukommen, unsere Nutzungsbedingungen und andere Vereinbarungen durchzusetzen oder anzuwenden oder die Rechte, das Eigentum oder die Sicherheit unserer Benutzer zu schützen oder andere Zeitpunkte geben wir das Konto und andere persönliche Daten frei. Dazu gehört der Austausch von Informationen mit anderen Unternehmen und Organisationen zum Schutz vor Betrug und zur Reduzierung des Kreditrisikos.",
        content32: "5. Internationale Übermittlung personenbezogener Daten",
        content33: "Wir können Ihre Daten außerhalb des Europäischen Wirtschaftsraums („EWR“) übermitteln. Wir werden geeignete Sicherheitsvorkehrungen treffen, um sicherzustellen, dass solche Übermittlungen den geltenden Datenschutzbestimmungen entsprechen, es sei denn, die Europäische Kommission hat bestätigt, dass das Land, in das die Daten übermittelt werden, ein angemessenes Schutzniveau bietet.",
        content34: "6. Sind meine persönlichen Daten sicher?",
        content35: "Wir haben unsere Systeme unter Berücksichtigung Ihrer Sicherheit und Privatsphäre entwickelt. Wir verwenden Verschlüsselungsprotokolle und Software, um die Sicherheit personenbezogener Daten bei der Übertragung zu schützen.",
        content36: "Bei der Erhebung, Speicherung und Offenlegung personenbezogener Daten ergreifen wir stets physische, elektronische und verfahrenstechnische Sicherheitsvorkehrungen. Aufgrund unserer Sicherheitsverfahren müssen wir möglicherweise Ihre Identität überprüfen, bevor wir personenbezogene Daten an Sie weitergeben.",
        content37: "Das Wichtigste, was Sie tun können, ist, die Passwörter Ihres persönlichen Kontos vor unbefugtem Zugriff zu schützen. Wir empfehlen, für Ihr Konto ein eindeutiges Passwort festzulegen, das sich von anderen Online-Konten unterscheidet. Und melden Sie sich unbedingt ab, nachdem Sie einen gemeinsam genutzten Computer verwendet haben.",
        content38: "7. Wie soll ich meine persönlichen Daten schützen?",
        content39: "Wenn Sie Fragen oder Einwände zur Art und Weise haben, wie wir personenbezogene Daten erheben und verarbeiten, wenden Sie sich bitte an den Kundendienst",
        content40: "Nachdem Sie der Verarbeitung Ihrer personenbezogenen Daten für einen bestimmten Zweck zugestimmt haben, können Sie Ihre Einwilligung jederzeit widerrufen und wir werden die Verarbeitung Ihrer Daten für diesen Zweck einstellen.",
        content41: "Darüber hinaus haben Sie vorbehaltlich der geltenden Gesetze das Recht, Zugang zu, Berichtigung und Löschung personenbezogener Daten sowie Datenübertragbarkeit zu verlangen. Sie können auch der Verarbeitung Ihrer personenbezogenen Daten durch uns widersprechen oder verlangen, dass die Verarbeitung Ihrer personenbezogenen Daten unter bestimmten Umständen eingeschränkt wird.",
        content42: "8. EU-DSGVO und britisches Datenschutzrecht – Rechtsgrundlage",
        content43: "Die EU-DSGVO und das britische Datenschutzrecht verlangen von uns, dass wir bei der Verwendung personenbezogener Daten die Rechtsgrundlagen einhalten. Unsere Grundlage hängt von den konkreten Zwecken ab, für die die personenbezogenen Daten verwendet werden. Zu diesen Grundlagen gehören:",
        content44: "Erfüllung eines Vertrags – um Ihnen Produkte oder Dienstleistungen bereitzustellen oder mit Ihnen darüber zu kommunizieren, einschließlich unserer Nutzung Ihrer personenbezogenen Daten zur Annahme und Bearbeitung von Bestellungen und zur Abwicklung von Zahlungen.",
        content45: "Unsere berechtigten Geschäftsinteressen und Benutzerinteressen – Wir erkennen und verhindern Betrug und Missbrauch, um die Sicherheit unserer Benutzer, unserer selbst oder anderer zu schützen.",
        content46: "Ihre Einwilligung – Zur Verarbeitung Ihrer personenbezogenen Daten für die Ihnen mitgeteilten spezifischen Zwecke benötigen wir Ihre Einwilligung. Sobald Sie uns Ihre Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten für einen bestimmten Zweck erteilt haben, können Sie Ihre Einwilligung jederzeit widerrufen und wir werden die Verarbeitung Ihrer Daten für diesen Zweck einstellen.",
        content47: "Einhaltung gesetzlicher Verpflichtungen – Wir verwenden Ihre personenbezogenen Daten gemäß den gesetzlichen Bestimmungen. Beispielsweise erfassen wir Bankkontoinformationen zur Identitätsprüfung.",
        content48: "Die oben genannten und weitere Rechtsgrundlagen richten sich nach den konkreten Zwecken, für die wir personenbezogene Daten nutzen.",
        content49: "9. Nutzungsbedingungen, Hinweise und Änderungen",
        content50: "Ihre Nutzung und etwaige Streitigkeiten über den Datenschutz unterliegen dieser Erklärung und unseren Nutzungsbedingungen. Wenn Sie Bedenken hinsichtlich des Datenschutzes dieser Plattform haben, kontaktieren Sie uns bitte mit einer detaillierten Erklärung und wir werden das Problem gerne für Sie lösen. Sie haben außerdem das Recht, sich an Ihre örtliche Datenschutzbehörde zu wenden.",
        content51: "Unser Geschäft verändert sich ständig, und damit auch unsere Datenschutzerklärung. Sie sollten unsere Website regelmäßig besuchen, um die neuesten Änderungen zu sehen. Wenn Sie mit den überarbeiteten Inhalten nicht einverstanden sind, sollten Sie den Zugriff sofort beenden. Nachdem die aktualisierte Version der Datenschutzrichtlinie veröffentlicht wurde, bedeutet Ihr fortgesetzter Zugriff, dass Sie mit dem aktualisierten Inhalt einverstanden sind und sich bereit erklären, die aktualisierte Datenschutzrichtlinie einzuhalten. Sofern nicht anders angegeben, gilt die aktuelle Datenschutzerklärung für alle Informationen, die wir über Sie und Ihr Konto speichern.",
    },
    serviceAgreement: {
        title: "Dienstleistungsvertrag",
        content1: "Diese Website ist eine Plattform speziell für Benutzer zur Durchführung von Transaktionen mit digitalen Vermögenswerten und zur Bereitstellung damit verbundener Dienste (im Folgenden als „der Dienst“ oder „der Dienst“ bezeichnet). Zur Vereinfachung der Beschreibung dieser Vereinbarung verwendet diese Website in dieser Vereinbarung gemeinsam „wir“ oder andere Ich-Pronomen. Solange es sich bei der natürlichen oder sonstigen Person, die sich auf der Website anmeldet, um einen Benutzer dieser Website handelt, wird im Folgenden zur Vereinfachung des Ausdrucks dieser Zustimmung „Sie“ oder eine andere zweite Person verwendet. Zur Vereinfachung des Ausdrucks dieser Vereinbarung werden wir und Sie in dieser Vereinbarung gemeinsam als „Parteien“ bezeichnet, und wir oder Sie werden einzeln als „Partei“ bezeichnet.",
        content2: "wichtiger Hinweis:",
        content3: "Wir weisen Sie hiermit ausdrücklich darauf hin:",
        content4: "1 Die digitalen Vermögenswerte selbst werden von keinem Finanzinstitut oder Unternehmen oder dieser Website ausgegeben",
        content5: "2 Die Märkte für digitale Vermögenswerte sind neu, unbestätigt und wachsen möglicherweise nicht.",
        content6: "3 Digitale Vermögenswerte werden hauptsächlich von Spekulanten in großem Umfang genutzt, auf den Einzelhandels- und Handelsmärkten ist die Nutzung relativ gering. Transaktionen mit digitalen Vermögenswerten sind äußerst riskant. Sie werden den ganzen Tag über kontinuierlich gehandelt, ohne Begrenzung für Anstieg und Rückgang, und die Preise können leicht vom Markt beeinflusst werden Entscheidungsträger und globale Regierungspolitik. Fluktuation;",
        content7: "4  Das Unternehmen behält sich das Recht vor, Ihr Konto jederzeit zu sperren oder zu kündigen, wenn das Unternehmen nach eigenem Ermessen feststellt, dass Sie gegen diese Vereinbarung verstoßen haben oder dass die auf dieser Website bereitgestellten Dienste oder Ihre Nutzung der auf dieser Website bereitgestellten Dienste gegen diese Vereinbarung verstoßen haben nach den Gesetzen Ihrer Gerichtsbarkeit illegal. , oder Ihre Nutzung der auf dieser Website bereitgestellten Dienste oder Transaktionen mit digitalen Vermögenswerten aussetzen oder beenden. Es ist Personen aus [Festlandchina, Taiwan, Israel, Irak, Bangladesch, Bolivien, Ecuador, Kirgisistan, Sewastopol und dem Vereinigten Königreich (Einzelbenutzer)] untersagt, die auf dieser Website bereitgestellten Vertragstransaktionsdienste zu nutzen. Die oben genannte Liste der Länder oder Regionen ändert sich je nach den Richtlinien und Produkttypen der verschiedenen Länder oder Regionen. Möglicherweise benachrichtigen wir Sie zu diesem Zeitpunkt nicht ausdrücklich, achten Sie daher bitte rechtzeitig auf Aktualisierungen dieser Vereinbarung.",
        content8: "Der Handel mit digitalen Vermögenswerten birgt extrem hohe Risiken und ist für die meisten Menschen nicht geeignet. Sie verstehen und sind sich darüber im Klaren, dass diese Transaktion zu einem teilweisen oder vollständigen Verlust führen kann. Daher sollten Sie den Betrag der Transaktion auf der Grundlage des Ausmaßes des Verlusts bestimmen, den Sie sich leisten können. Sie wissen und verstehen, dass digitale Vermögenswerte derivative Risiken mit sich bringen. Wenn Sie also Fragen haben, empfehlen wir Ihnen, zunächst die Hilfe professioneller Berater in Anspruch zu nehmen. Darüber hinaus bestehen neben den oben genannten Risiken auch Risiken, die nicht vorhersehbar sind. Sie sollten Ihre finanzielle Situation und die oben genannten Risiken sorgfältig abwägen und mit klarem Urteilsvermögen beurteilen, bevor Sie eine Entscheidung zum Kauf oder Verkauf digitaler Vermögenswerte treffen, und alle daraus resultierenden Verluste tragen. Wir übernehmen hierfür keine Verantwortung.",
        content9: "Bitte beachten Sie:",
        content10: "1 Sie verstehen, dass diese Website nur dazu dient, Informationen zu digitalen Vermögenswerten zu erhalten, Transaktionsparteien zu finden, Transaktionen mit digitalen Vermögenswerten auszuhandeln und durchzuführen. Diese Website ist an keiner Ihrer Transaktionen beteiligt, daher sollten Sie Ihr eigenes umsichtiges Urteilsvermögen walten lassen um die relevanten digitalen Vermögenswerte und/oder Transaktionen oder die Authentizität, Rechtmäßigkeit und Gültigkeit der Informationen zu ermitteln, und trägt die daraus resultierenden Verantwortlichkeiten und Verluste selbst.",
        content11: "2 Alle Meinungen, Nachrichten, Diskussionen, Analysen, Preise, Empfehlungen und sonstigen Informationen auf dieser Website sind allgemeine Marktkommentare und stellen keine Anlageberatung dar. Wir haften nicht für Verluste, die direkt oder indirekt aus dem Vertrauen auf diese Informationen entstehen, einschließlich, aber nicht beschränkt auf entgangenen Gewinn.",
        content12: "3 Diese Website behält sich das Recht vor, jederzeit eine eigene Entscheidung zu treffen oder den Inhalt zu ändern oder zu ändern. Wir haben angemessene Maßnahmen ergriffen, um die Richtigkeit der Informationen auf der Website sicherzustellen, können deren Richtigkeit jedoch nicht garantieren und übernehmen keine Verantwortung dafür Jeglicher Verlust oder Schaden, der durch die Informationen auf dieser Website oder das Fehlen von Informationen verursacht wird. Direkte oder indirekte Verluste, die durch Verzögerungen oder Fehler bei der Verbindung mit dem Internet, der Übertragung oder dem Empfang von Mitteilungen und Informationen entstehen.",
        content13: "4 Bei der Nutzung internetbasierter Handelssysteme bestehen auch Risiken, unter anderem Ausfälle von Software, Hardware und Internetverbindungen. Da wir keinen Einfluss auf die Zuverlässigkeit und Verfügbarkeit des Internets haben, übernehmen wir keine Verantwortung für Störungen, Verzögerungen und Verbindungsausfälle.",
        content14: "5 Es ist verboten, diese Website für illegale Handelsaktivitäten oder illegale Aktivitäten wie Geldwäsche, Schmuggel, Bestechung usw. zu nutzen. Wenn der Verdacht illegaler Transaktionen oder illegaler Aktivitäten festgestellt wird, ergreift diese Website verschiedene verfügbare Mittel, einschließlich aber Wir beschränken uns nicht nur auf das Einfrieren von Konten und die Benachrichtigung relevanter Parteien, Behörden usw., wir übernehmen nicht alle daraus resultierenden Verantwortlichkeiten und behalten uns das Recht vor, die Haftung gegenüber relevanten Personen geltend zu machen.",
        content15: "6 Es ist verboten, diese Website zu nutzen, um den Markt in böswilliger Absicht zu manipulieren, unzulässige Transaktionen durchzuführen und andere unethische Handelsaktivitäten durchzuführen. Wenn solche Vorfälle entdeckt werden, wird diese Website warnen, Transaktionen einschränken und alle unethischen Verhaltensweisen wie böswillige Preismanipulation und böswillige Einflussnahme auf die Website unterbinden Handelssystem. Wir übernehmen nicht die gesamte Haftung, die sich aus vorbeugenden Schutzmaßnahmen wie der Kontosperrung ergibt, und behalten uns das Recht vor, die Haftung gegenüber relevanten Personen geltend zu machen.",
        content16: "1. Allgemeine Grundsätze",
        content17: "1.1 „Benutzervereinbarung“ (im Folgenden als „diese Vereinbarung“ oder „diese Allgemeinen Geschäftsbedingungen“ bezeichnet) besteht aus dem Haupttext, den „Datenschutzbestimmungen“, der „Know Your Customer- und Anti-Geldwäsche-Richtlinie“ und anderen Richtlinien, die gelten wurden auf dieser Website veröffentlicht oder werden in Zukunft veröffentlicht. Sie bestehen aus verschiedenen Regeln, Erklärungen, Anweisungen usw.",
        content18: "1.2 Sie sollten diese Vereinbarung sorgfältig lesen, bevor Sie die auf dieser Website bereitgestellten Dienste nutzen. Wenn Sie etwas nicht verstehen oder es aus anderen Gründen erforderlich ist, wenden Sie sich bitte an einen professionellen Anwalt. Wenn Sie dieser Vereinbarung und/oder ihrer Änderung zu irgendeinem Zeitpunkt nicht zustimmen, beenden Sie bitte sofort die Nutzung der auf dieser Website bereitgestellten Dienste oder melden Sie sich nicht mehr auf dieser Website an. Sobald Sie sich auf dieser Website anmelden, die Dienste dieser Website nutzen oder ein anderes ähnliches Verhalten an den Tag legen, bedeutet dies, dass Sie den Inhalt dieser Vereinbarung verstanden haben und ihm vollständig zustimmen, einschließlich aller jederzeit von dieser Website vorgenommenen Änderungen an dieser Vereinbarung.",
        content19: "1.3 Sie können Mitglied dieser Website werden (im Folgenden „Mitglied“ genannt), indem Sie die entsprechenden Informationen gemäß den Anforderungen dieser Website ausfüllen und sich nach anderen relevanten Verfahren erfolgreich registrieren. Durch Klicken auf die Schaltfläche „Zustimmen“ während der Registrierung Sie unterzeichnen den Vorgang elektronisch. Form der Vereinbarung mit dem Unternehmen; oder wenn Sie während der Nutzung dieser Website auf eine Schaltfläche mit der Aufschrift „Zustimmen“ oder einer ähnlichen Bedeutung klicken oder die auf dieser Website bereitgestellten Dienste tatsächlich auf andere von dieser Website zugelassene Weise nutzen Dies bedeutet, dass Sie alle Bedingungen dieser Vereinbarung vollständig verstehen, ihnen zustimmen und sie akzeptieren. Das Fehlen Ihrer handschriftlichen Unterschrift hat keinen Einfluss auf die rechtliche Verbindlichkeit dieser Vereinbarung für Sie.",
        content20: "1.4 Nachdem Sie Mitglied dieser Website geworden sind, erhalten Sie ein Mitgliedskonto und ein entsprechendes Passwort. Sie sind für die Aufbewahrung des Mitgliedskontos und des Passworts verantwortlich und tragen die rechtliche Verantwortung für alle Aktivitäten und Veranstaltungen, die unter Ihrem Konto durchgeführt werden.",
        content21: "1.5 Nur Mitglieder, die Mitglieder dieser Website werden, können die von dieser Website bereitgestellte Handelsplattform für digitale Vermögenswerte für Transaktionen nutzen und andere auf dieser Website vorgesehene Dienste nutzen, die nur Mitgliedern zur Verfügung stehen; Nichtmitglieder können sich nur auf der Website anmelden und die Website durchsuchen und andere auf dieser Website vorgesehene Dienste. Dienste.",
        content22: "1.6 Durch die Registrierung und Nutzung aller auf dieser Website bereitgestellten Dienste und Funktionen wird davon ausgegangen, dass Sie die Informationen gelesen und verstanden haben und:",
        content23: "1.6.1 Akzeptieren Sie, dass Sie an alle Bedingungen dieser Vereinbarung gebunden sind.",
        content24: "1.6.2 Sie bestätigen, dass Sie über 18 Jahre alt sind oder das gesetzliche Mindestalter zum Abschluss von Verträgen gemäß den verschiedenen geltenden Gesetzen haben. Ihre Registrierung auf dieser Website, Ihre Verkäufe oder Käufe, die Veröffentlichung von Informationen usw. und die Annahme der Dienste dieser Website sollten konform sein Ihren Anforderungen entsprechen. Sie unterliegen der Gerichtsbarkeit über die relevanten Gesetze und Vorschriften eines souveränen Landes oder einer souveränen Region und haben die uneingeschränkte Möglichkeit, diese Bedingungen zu akzeptieren, Transaktionen abzuschließen und diese Website zur Durchführung von Transaktionen mit digitalen Vermögenswerten zu nutzen.",
        content25: "1.6.3 Sie garantieren, dass alle digitalen Vermögenswerte, die Ihnen gehören und an der Transaktion beteiligt sind, rechtmäßig erworben wurden und Eigentum sind.",
        content26: "1.6.4 Sie erklären sich damit einverstanden, dass Sie allein für Ihre eigenen Handels- oder Nichthandelsaktivitäten sowie für etwaige Gewinne oder Verluste verantwortlich sind.",
        content27: "1.6.5 Sie bestätigen, dass die von Ihnen bei der Registrierung gemachten Angaben wahrheitsgemäß und korrekt sind.",
        content28: "1.6.6 Sie erklären sich damit einverstanden, die Anforderungen aller relevanten Gesetze einzuhalten, einschließlich der Meldung etwaiger Handelsgewinne für Steuerzwecke.",
        content29: "1.6.7 Sie erklären sich damit einverstanden, sich zu keinem Zeitpunkt an Verhaltensweisen oder Aktivitäten zu beteiligen, die den Interessen dieser Website oder des Unternehmens schaden, unabhängig davon, ob sie mit den auf dieser Website bereitgestellten Diensten in Zusammenhang stehen oder nicht.",
        content30: "1.6.8 Diese Vereinbarung regelt nur die Rechte und Pflichten zwischen Ihnen und uns und umfasst nicht die Rechtsbeziehungen und Rechtsstreitigkeiten, die sich aus Transaktionen mit digitalen Vermögenswerten zwischen Benutzern dieser Website und anderen Websites und Ihnen ergeben.",
        content31: "2. Vertragsänderungen",
        content32: "Wir behalten uns das Recht vor, diese Vereinbarung von Zeit zu Zeit zu überarbeiten und auf der Website bekannt zu geben, ohne Sie gesondert zu benachrichtigen. Nach der Überarbeitung wird der Überarbeitungszeitpunkt unter [Zeitpunkt der letzten Aktualisierung] in dieser Vereinbarung markiert und dauert automatisch mit der Veröffentlichung auf der Website wirksam. Sie sollten von Zeit zu Zeit die Aktualisierungszeit und den aktualisierten Inhalt dieser Vereinbarung durchsehen und darauf achten. Wenn Sie mit den relevanten Änderungen nicht einverstanden sind, sollten Sie die Nutzung der Dienste dieser Website sofort einstellen; Ihre weitere Nutzung der Dienste dieser Website Website bedeutet, dass Sie die überarbeitete Vereinbarung akzeptieren und damit einverstanden sind, an sie gebunden zu sein. .",
        content33: "3. Registrierung",
        content34: "3.1 Registrierungsqualifikationen",
        content35: "Sie bestätigen und versprechen, dass Sie, wenn Sie den Registrierungsprozess abschließen oder die von dieser Website bereitgestellten Dienste tatsächlich auf andere von dieser Website zugelassene Weise nutzen, die Möglichkeit haben, diese Vereinbarung zu unterzeichnen und die Dienste dieser Website gemäß den geltenden Gesetzen zu nutzen. natürliche Person, juristische Person oder andere Organisation. Sobald Sie auf die Schaltfläche „Zur Registrierung zustimmen“ klicken, bedeutet dies, dass Sie oder Ihr autorisierter Vertreter dem Inhalt der Vereinbarung zugestimmt haben und von seinem Vertreter registriert wurden und die Dienste dieser Website genutzt haben. Wenn Sie nicht über die oben genannten Fachqualifikationen verfügen, tragen Sie und Ihr Bevollmächtigter alle daraus resultierenden Konsequenzen. Das Unternehmen behält sich das Recht vor, Ihr Konto zu kündigen oder dauerhaft zu sperren und Sie und Ihren Bevollmächtigten zur Rechenschaft zu ziehen.",
        content36: "3.2 Registrierungszweck",
        content37: "Sie bestätigen und versprechen, dass Ihre Registrierung auf dieser Website nicht dazu dient, gegen Gesetze und Vorschriften zu verstoßen oder die Reihenfolge der Transaktionen mit digitalen Vermögenswerten auf dieser Website zu stören.",
        content38: "3.3 Registrierungsprozess",
        content39: "3.3.1 Sie erklären sich damit einverstanden, eine gültige E-Mail-Adresse, Mobiltelefonnummer und andere Informationen anzugeben, die auf der Benutzerregistrierungsseite dieser Website erforderlich sind. Sie können die von Ihnen angegebene oder bestätigte E-Mail-Adresse, Mobiltelefonnummer oder andere von dieser Website zugelassene Methoden als Login verwenden Methode zum Aufrufen dieser Website. Falls erforderlich, müssen Sie in Übereinstimmung mit den einschlägigen Gesetzen und Vorschriften verschiedener Gerichtsbarkeiten Ihren richtigen Namen, Ihr Ausweisdokument und andere relevante Informationen angeben, die in Gesetzen und Vorschriften, Datenschutzklauseln und Klauseln zur Bekämpfung der Geldwäsche festgelegt sind, und die Registrierungsinformationen ständig aktualisieren eine zeitnahe, detaillierte und genaue Art und Weise. Erfordern. Alle ursprünglich eingegebenen Informationen werden als Registrierungsinformationen bezeichnet. Sie sind für die Authentizität, Vollständigkeit und Richtigkeit dieser Informationen verantwortlich und tragen alle daraus resultierenden direkten oder indirekten Verluste und nachteiligen Folgen.",
        content40: "3.3.2 Wenn die Gesetze, Vorschriften, Regeln, Anordnungen und sonstigen Vorschriften des souveränen Landes oder der Region, in der Sie sich befinden, Anforderungen an die Angabe eines echten Namens für Mobiltelefonnummern stellen, stimmen Sie zu, dass die angegebene registrierte Mobiltelefonnummer unter einem echten Namen registriert wurde. Wenn Wenn Sie es nicht in Übereinstimmung mit den Vorschriften bereitstellen, werden alle für Sie entstehenden Folgen direkter oder indirekter Verluste und nachteiliger Folgen sind von Ihnen zu tragen.",
        content41: "3.3.3 Wenn Sie die für die Registrierung erforderlichen Daten ordnungsgemäß, vollständig und wirksam angeben und überprüft haben, haben Sie einen Anspruch auf Erhalt der Kontonummer und des Passworts dieser Website. Mit Erhalt der Kontonummer und des Passworts dieser Website gilt Ihre Registrierung als erfolgt erfolgreich abgeschlossen und Sie können sich auf dieser Website als Mitglied anmelden.",
        content42: "3.3.4 Sie erklären sich damit einverstanden, von dieser Website gesendete E-Mails und/oder Kurznachrichten im Zusammenhang mit der Verwaltung und dem Betrieb dieser Website zu erhalten.",
        content43: "4. Service",
        content44: "Diese Website bietet über diese Website ausschließlich Online-Handelsplattformdienste für Ihre Handelsaktivitäten mit digitalen Vermögenswerten (einschließlich, aber nicht beschränkt auf den Handel mit digitalen Vermögenswerten und andere Dienste).",
        content45: "4.1 Serviceinhalt",
        content46: "4.1.1 Sie haben das Recht, die Echtzeit-Marktbedingungen und Transaktionsinformationen verschiedener digitaler Asset-Produkte auf dieser Website einzusehen, und Sie haben das Recht, über diese Website Anweisungen für digitale Asset-Transaktionen zu übermitteln und Transaktionen mit digitalen Assets abzuschließen.",
        content47: "4.1.2 Sie haben das Recht, die Informationen unter Ihrem Mitgliedskonto auf dieser Website einzusehen und die auf dieser Website bereitgestellten Funktionen für den Betrieb zu nutzen.",
        content48: "4.1.3 Sie haben das Recht, an den von dieser Website organisierten Website-Aktivitäten gemäß den auf dieser Website veröffentlichten Aktivitätsregeln teilzunehmen.",
        content49: "4.1.4 Diese Website verspricht, Ihnen weitere Dienste anzubieten.",
        content50: "4.2 Serviceregeln Sie verpflichten sich, die folgenden Serviceregeln dieser Website einzuhalten:",
        content51: "4.2.1 Sie müssen Gesetze, Vorschriften und Richtlinienanforderungen einhalten, die Rechtmäßigkeit der Quellen aller digitalen Vermögenswerte in Ihrem Konto sicherstellen und sich nicht an illegalen oder anderen Aktivitäten beteiligen, die den Rechten und Interessen dieser Website oder Dritter auf dieser Website schaden oder durch die Nutzung seiner Dienste, einschließlich, aber nicht beschränkt auf: Sie sind nicht darauf beschränkt, illegale, rechtswidrige oder die Rechte anderer verletzende Informationen zu senden oder zu empfangen, Schneeballsysteme oder andere schädliche Informationen oder Bemerkungen zu senden oder zu empfangen, E-Mails zu verwenden oder zu fälschen Header-Informationen auf dieser Website ohne die Genehmigung dieser Website usw.",
        content52: "4.2.2 Sie sollten sich an Gesetze und Vorschriften halten und Ihre Kontonummer, Ihr Login-Passwort, Ihr Fondspasswort, die bei der Registrierung an Sie gebundene Mobiltelefonnummer und den von Ihrem Mobiltelefon empfangenen Mobiltelefon-Bestätigungscode ordnungsgemäß verwenden und aufbewahren. Sie tragen die volle Verantwortung für alle Vorgänge und Folgen der Verwendung Ihres Konto- und Login-Passworts, Ihres Fondspassworts und Ihres Mobiltelefon-Verifizierungscodes. Wenn Sie feststellen, dass die Kontonummer, das Login-Passwort oder das Fondspasswort oder der Bestätigungscode dieser Website von Dritten ohne Ihre Genehmigung verwendet werden oder andere Probleme mit der Kontosicherheit vorliegen, sollten Sie diese Website unverzüglich und wirksam benachrichtigen und die Sperrung dieser Website beantragen der Dienst des Kontos dieser Website. . Diese Website hat das Recht, innerhalb einer angemessenen Frist Maßnahmen auf Ihre Anfrage zu ergreifen, diese Website übernimmt jedoch keine Verantwortung für die Folgen (einschließlich, aber nicht beschränkt auf etwaige Verluste, die Sie erleiden), die vor dem Ergreifen von Maßnahmen eingetreten sind. Ohne die Zustimmung dieser Website dürfen Sie Ihr Konto auf dieser Website nicht an andere spenden, ausleihen, leasen, übertragen oder anderweitig darüber verfügen.",
        content53: "4.2.3 Sie erklären sich damit einverstanden, für alle Aktivitäten verantwortlich zu sein, die unter Ihrem Konto und Passwort auf dieser Website stattfinden (einschließlich, aber nicht beschränkt auf die Offenlegung von Informationen, die Veröffentlichung von Informationen, Online-Klicks zur Zustimmung oder Einreichung verschiedener Regeln und Vereinbarungen, Online-Erneuerung von Vereinbarungen oder den Kauf von Vereinbarungen). Dienstleistungen usw.).",
        content54: "4.2.4 Wenn Sie auf dieser Website Transaktionen mit digitalen Vermögenswerten durchführen, dürfen Sie den normalen Ablauf von Transaktionen mit digitalen Vermögenswerten nicht böswillig beeinträchtigen oder die Reihenfolge der Transaktionen stören; Sie dürfen nicht in den normalen Betrieb dieser Website eingreifen oder die Nutzung dieser Website durch andere Benutzer beeinträchtigen Dienstleistungen mit technischen oder anderen Mitteln zu erbringen; Sie dürfen keine fiktiven oder böswilligen Verleumdungen des guten Willens dieser Website durch Fakten oder andere Mittel vornehmen.",
        content55: "4.2.5 Wenn Sie aufgrund von Online-Transaktionen eine Streitigkeit mit anderen Nutzern haben, dürfen Sie diese Website nicht auf außergerichtlichem oder administrativem Wege zur Bereitstellung relevanter Informationen auffordern.",
        content56: "4.2.6 Alle anfallenden Steuern sowie alle Hardware-, Software-, Service- und sonstigen Kosten, die während Ihrer Nutzung der auf dieser Website bereitgestellten Dienste anfallen, werden allein von Ihnen beurteilt und getragen.",
        content57: "4.2.7 Sie müssen sich an diese Vereinbarung und andere Servicebedingungen und Betriebsregeln halten, die von Zeit zu Zeit auf dieser Website veröffentlicht und aktualisiert werden, und haben das Recht, die Nutzung der auf dieser Website bereitgestellten Dienste jederzeit zu beenden.",
        content58: "4.3 Produktregeln",
        content59: "4.3.1 Durchsuchen Sie Transaktionsinformationen",
        content60: "Wenn Sie Transaktionsinformationen auf dieser Website durchsuchen, sollten Sie alle in den Transaktionsinformationen enthaltenen Inhalte sorgfältig lesen, einschließlich, aber nicht beschränkt auf, Preis, Provisionsvolumen, Bearbeitungsgebühr, Kauf- oder Verkaufsrichtung, und zwar erst, nachdem Sie alle darin enthaltenen Inhalte vollständig akzeptiert haben Die Transaktionsinformationen können per Knopfdruck getätigt werden.",
        content61: "4.3.2 Transaktionsdetails anzeigen",
        content62: "Sie können die entsprechenden Transaktionsaufzeichnungen über Ihr Konto einsehen.",
        content63: "5. Rechte und Pflichten dieser Website",
        content64: "5.1 Wenn Sie nicht über die in dieser Vereinbarung festgelegten Registrierungsqualifikationen verfügen, hat diese Website das Recht, Ihre Registrierung abzulehnen. Für diejenigen, die sich bereits registriert haben, hat diese Website das Recht, Ihr Mitgliedskonto zu kündigen. Diese Website behält sich das Recht vor, Sie oder Ihr bevollmächtigter Vertreter ist dafür verantwortlich. Stimmt. Gleichzeitig behält sich diese Website das Recht vor, darüber zu entscheiden, ob Ihre Registrierung unter anderen Umständen akzeptiert wird.",
        content65: "5.2 Wenn diese Website nach eigenem Ermessen feststellt, dass Sie oder die mit Ihrem Konto verbundenen Benutzer nicht für risikoreiche Investitionen geeignet sind, haben wir das Recht, Ihr Konto und die Nutzung aller damit verbundenen Konten zu sperren oder zu kündigen.",
        content66: "5.3 Wenn diese Website feststellt, dass der Kontobenutzer nicht der Erstregistrant des Kontos ist, hat sie das Recht, die Nutzung des Kontos auszusetzen oder zu beenden.",
        content67: "5.4 Wenn diese Website aufgrund technischer Tests, manueller Stichproben und anderer Testmethoden den begründeten Verdacht hat, dass die von Ihnen bereitgestellten Informationen falsch, unwahr, ungültig oder unvollständig sind, hat sie das Recht, Sie zu benachrichtigen, um die Informationen zu korrigieren oder zu aktualisieren oder die Bereitstellung auszusetzen oder zu beenden Diese Website dient Ihnen.",
        content68: "5.5 Diese Website behält sich das Recht vor, die auf dieser Website angezeigten Informationen zu korrigieren, wenn sich herausstellt, dass ein offensichtlicher Fehler vorliegt.",
        content69: "5.6 Diese Website behält sich das Recht vor, die Dienste dieser Website jederzeit zu ändern, auszusetzen oder zu beenden. Diese Website muss Sie nicht im Voraus benachrichtigen, um das Recht zur Änderung oder Beendigung von Diensten auszuüben. Wenn diese Website einen oder mehrere Dienste dieser Website einstellt Die Kündigung erfolgt von dieser Website aus auf der Website. Gültig ab dem Datum der Veröffentlichung der Kündigungsmitteilung.",
        content70: "5.7 Diese Website wird die erforderlichen technischen Mittel und Verwaltungsmaßnahmen ergreifen, um den normalen Betrieb dieser Website sicherzustellen, die notwendige und zuverlässige Handelsumgebung und Handelsdienstleistungen bereitzustellen und die Reihenfolge der Transaktionen mit digitalen Vermögenswerten aufrechtzuerhalten.",
        content71: "5.8 Wenn Sie sich ein Jahr lang nicht mit Ihrem Mitgliedskonto und Passwort auf dieser Website anmelden, hat diese Website das Recht, Ihr Konto zu kündigen. Nach der Löschung des Kontos hat diese Website das Recht, den entsprechenden Mitgliedsnamen für andere Benutzer zur Registrierung und Nutzung freizugeben.",
        content72: "5.9 Diese Website gewährleistet die Sicherheit Ihrer digitalen Vermögenswerte durch die Stärkung von Technologieinvestitionen, die Verbesserung von Sicherheitsvorkehrungen und anderen Maßnahmen und benachrichtigt Sie im Voraus, wenn vorhersehbare Sicherheitsrisiken in Ihrem Konto auftreten.",
        content73: "5.10 Diese Website hat das Recht, jederzeit alle Arten von Inhaltsinformationen auf dieser Website zu löschen, die nicht den Gesetzen und Vorschriften oder den Vorschriften dieser Website entsprechen. Diese Website muss Sie nicht im Voraus benachrichtigen, um diese Rechte auszuüben.",
        content74: "5.11 Diese Website hat das Recht, Sie aufzufordern, weitere Informationen oder Materialien in Übereinstimmung mit den Gesetzen, Vorschriften, Regeln, Anordnungen und anderen behördlichen Anforderungen Ihres souveränen Landes oder Ihrer Region bereitzustellen und angemessene Maßnahmen zu ergreifen, um die örtlichen behördlichen Anforderungen einzuhalten. Sie Wir sind zur Zusammenarbeit verpflichtet; diese Website hat das Recht, die Bereitstellung einiger oder aller Dienste dieser Website für Sie gemäß den Anforderungen der Gesetze, Vorschriften, Regeln, Anordnungen und anderen Spezifikationen Ihres souveränen Landes oder Ihrer Region auszusetzen oder dauerhaft einzustellen.",
        content75: "5.12 Diese Website behält sich das Recht vor, Ihr Konto nach eigenem Ermessen mit einer Frist von sieben Werktagen zu schließen. Daher haben Sie sieben Werktage Zeit, um Aufträge zu stornieren und Positionen zu schließen. Wenn Sie die Bestellung nicht storniert und die Position nach Ablauf der Frist nicht geschlossen haben, werden wir die Bestellung zwangsweise stornieren und die Position schließen und Ihnen die verbleibenden digitalen Vermögenswerte auf Ihrem Konto zurückgeben.",
        content76: "5.13 Um die Rechte und Interessen der Händler zu schützen, hat diese Website das Recht, unter besonderen Umständen (z. B. Systemausfall, Netzwerkausfall, extreme Marktbedingungen usw.) Anpassungen an Online-Produkten vorzunehmen, wie z. B.: Verträge über vorzeitige Lieferung und Abwicklung, Vertragsarten und Verträge für vorzeitige Lieferung und Abwicklung. Der Zeitraum sowie die Abwicklung und der Lieferpreis unterliegen der Ankündigung auf dieser Website.",
        content77: "6. Entschädigung",
        content78: "6.1 In keinem Fall übersteigt unsere Haftung Ihnen gegenüber für direkte Schäden die gesamten Servicegebühren, die wir Ihnen für Ihre Nutzung dieser Website für einen Zeitraum von drei (3) Monaten berechnet haben.",
        content79: "6.2 Wenn Sie gegen diese Vereinbarung oder andere Gesetze und Vorschriften verstoßen, müssen Sie uns eine Entschädigung in Höhe von mindestens 2 Mio bereit dazu.",
        content80: "7. Recht auf Unterlassung",
        content81: "Wir und Sie sind uns beide darüber im Klaren, dass die Rechtsbehelfe des Common Law für Ihren Vertragsbruch oder einen möglichen Vertragsbruch möglicherweise nicht ausreichen, um alle Verluste auszugleichen, die wir erleiden. Daher haben wir das Recht, eine einstweilige Verfügung zu beantragen, soweit dies nach Common Law oder Billigkeitsrecht zulässig ist im Falle einer Vertragsverletzung durch Sie oder einer möglichen Vertragsverletzung. Alle anderen Rechtsmittel.",
        content82: "8. Haftungsbeschränkung und Haftungsausschluss",
        content83: "8.1 Sie verstehen und stimmen zu, dass wir unter keinen Umständen für die folgenden Angelegenheiten verantwortlich sind:",
        content84: "8.1.1 Verlust von Einkommen;",
        content85: "8.1.2 Handelsgewinne oder Vertragsverluste;",
        content86: "8.1.3 Handelsgewinne oder Vertragsverluste;",
        content87: "8.1.4 Verlust erwarteter Geldersparnisse;",
        content88: "8.1.5 Verluste durch Informationsprobleme;",
        content89: "8.1.6 Verlust von Chancen, Goodwill oder Reputation;",
        content90: "8.1.7 Beschädigung oder Verlust von Daten;",
        content91: "8.1.8 die Kosten für den Kauf von Ersatzprodukten oder -dienstleistungen;",
        content92: "8.1.9 Alle indirekten, besonderen oder zufälligen Verluste oder Schäden, die aus unerlaubter Handlung (einschließlich Fahrlässigkeit), Vertragsbruch oder einem anderen Grund entstehen, unabhängig davon, ob diese Verluste oder Schäden für uns vernünftigerweise vorhersehbar sind oder nicht; unabhängig davon, ob wir über die Existenz solcher Verluste oder Schäden informiert wurden oder nicht im Voraus Möglichkeit von Verlust oder Beschädigung.",
        content93: "8.1.1 Die Artikel zu 8.1.9 sind unabhängig voneinander.",
        content94: "8.2 Sie verstehen und stimmen zu, dass wir nicht für Schäden verantwortlich sind, die Ihnen aufgrund eines der folgenden Umstände entstehen:",
        content95: "8.2.1 Bei Ihren konkreten Transaktionen kann es zu schwerwiegenden Gesetzes- oder Vertragsverstößen kommen.",
        content96: "8.2.2 Ihr Verhalten auf dieser Website kann illegal oder unethisch sein.",
        content97: "8.2.3 Kosten und Verluste, die durch den Kauf oder die Beschaffung von Daten, Informationen oder die Durchführung von Transaktionen über die Dienste dieser Website oder durch Ersatzhandlungen entstehen.",
        content98: "8.2.4 Ihr Missverständnis über die Dienste dieser Website.",
        content99: "8.2.5 Alle anderen Verluste im Zusammenhang mit den auf dieser Website bereitgestellten Diensten, die nicht von uns verursacht wurden.",
        content100: "8.3 Wir sind verantwortlich für die Wartung von Informationsnetzwerkgeräten, Ausfälle von Informationsnetzwerkverbindungen, Computer-, Kommunikations- oder andere Systemausfälle, Stromausfälle, Wetterbedingungen, Unfälle, Streiks, Arbeitskonflikte, Unruhen, Aufstände, Unruhen, unzureichende Produktivität oder Produktionsmaterialien, Feuer, Überschwemmungen , Stürme, Explosionen, Kriege, Bank- oder andere Partnergründe, Zusammenbruch des Marktes für digitale Vermögenswerte, staatliche Maßnahmen, Anordnungen von Justiz- oder Verwaltungsbehörden, andere Handlungen, die außerhalb unserer Kontrolle liegen oder die wir nicht kontrollieren können, oder Gründe Dritter Übernehmen Sie keine Verantwortung für die Unmöglichkeit oder Verzögerung der Bereitstellung oder Ihre Verluste.",
        content101: "8.4 Wir können nicht garantieren, dass alle auf dieser Website enthaltenen Informationen, Programme, Texte usw. absolut sicher sind und nicht durch Viren, Trojaner und andere Schadprogramme beeinträchtigt und zerstört werden. Wenn Sie sich daher anmelden und Dienste auf dieser Website nutzen, können wir nicht garantieren Website oder das Herunterladen und Verwenden heruntergeladener Programme, Informationen, Daten usw. liegen in Ihrer alleinigen Entscheidung und Sie tragen die Risiken und möglichen Verluste auf eigenes Risiko.",
        content102: "8.5 Wir geben keine Garantien oder Zusagen für Informationen, Produkte und Dienste von Websites Dritter, die mit dieser Website verlinkt sind, oder für andere Inhalte, die nicht unser Eigentum sind. Wenn Sie Dienste, Informationen und Dienste Dritter nutzen Die Entscheidung über Websites, Produkte usw. von Drittanbietern liegt allein bei Ihnen und Sie tragen die gesamte Verantwortung, die sich daraus ergibt.",
        content103: "8.6 Wir geben keine ausdrücklichen oder stillschweigenden Garantien für Ihre Nutzung der Dienste auf dieser Website, einschließlich, aber nicht beschränkt auf die Eignung der auf dieser Website bereitgestellten Dienste, keine Fehler oder Auslassungen, Kontinuität, Genauigkeit, Zuverlässigkeit und Eignung für eine bestimmte Person Zweck. Gleichzeitig übernehmen wir keinerlei Zusicherungen oder Garantien hinsichtlich der Gültigkeit, Genauigkeit, Korrektheit, Zuverlässigkeit, Qualität, Stabilität, Vollständigkeit und Aktualität der Technologie und Informationen, die in den auf dieser Website bereitgestellten Diensten zum Einsatz kommen. Ob Sie sich anmelden oder die auf dieser Website bereitgestellten Dienste nutzen, liegt in Ihrer persönlichen Entscheidung und Sie tragen Ihre eigenen Risiken und möglichen Verluste. Wir geben keine ausdrücklichen oder stillschweigenden Garantien hinsichtlich des Marktes, des Werts und des Preises digitaler Vermögenswerte. Sie verstehen und verstehen, dass der Markt für digitale Vermögenswerte instabil ist. Preise und Werte können jederzeit erheblich schwanken oder einbrechen. Der Handel mit digitalen Vermögenswerten ist Ihre persönliche Freiheit. Wählen und entscheiden Sie auf eigenes Risiko und mögliche Verluste.",
        content104: "8.7 Unsere in dieser Vereinbarung dargelegten Garantien und Zusagen sind die einzigen Garantien und Zusicherungen, die wir in Bezug auf diese Vereinbarung und die auf dieser Website bereitgestellten Dienste machen, und ersetzen alle Garantien und Zusagen, die auf andere Weise und Weise entstehen, sei es schriftlich, mündlich oder ausdrücklich von oder impliziert. Alle diese Garantien und Zusicherungen stellen nur unsere eigenen Verpflichtungen und Garantien dar und garantieren nicht, dass Dritte die Garantien und Verpflichtungen in dieser Vereinbarung einhalten.",
        content105: "8.8 Wir verzichten nicht auf Rechte, die nicht in dieser Vereinbarung aufgeführt sind, um unsere Schadensersatzhaftung im vollen Umfang des anwendbaren Rechts einzuschränken, auszuschließen oder aufzurechnen.",
        content106: "8.9 Nachdem Sie sich registriert haben, erklären Sie sich damit einverstanden, dass wir alle Vorgänge gemäß den in dieser Vereinbarung festgelegten Regeln durchführen, und alle daraus resultierenden Risiken werden von Ihnen getragen.",
        content107: "9. Vertragsbeendigung",
        content108: "9.1 Diese Website hat das Recht, alle Dienste dieser Website gemäß dieser Vereinbarung zu kündigen. Diese Vereinbarung endet an dem Tag, an dem alle Dienste dieser Website gekündigt werden.",
        content109: "9.2 Nach der Beendigung dieser Vereinbarung haben Sie kein Recht, von dieser Website zu verlangen, dass sie ihr weiterhin Dienste bereitstellt oder andere Verpflichtungen erfüllt, einschließlich, aber nicht beschränkt auf, von dieser Website zu verlangen, dass sie Informationen in ihrem ursprünglichen Website-Konto behält oder Ihnen gegenüber offenlegt an Sie oder einen Dritten. Der Dritte gibt alle Informationen weiter, die er nicht gelesen oder gesendet hat.",
        content110: "9.3 Die Kündigung dieser Vereinbarung hat keinen Einfluss auf die sonstigen Pflichten der nicht verletzenden Partei gegenüber der verletzenden Partei.",
        content111: "10. Geistige Eigentumsrechte",
        content112: "10.1 Alle auf dieser Website enthaltenen geistigen Leistungen umfassen unter anderem Website-Logos, Datenbanken, Website-Designs, Texte und Grafiken, Software, Fotos, Videos, Musik, Sounds und Kombinationen davon, Software-Zusammenstellung, zugehörige Quellcodes und Software (einschließlich kleiner Anwendungen). ) Die geistigen Eigentumsrechte an Programmen und Skripten liegen bei dieser Website. Es ist Ihnen nicht gestattet, die oben genannten Materialien oder Inhalte für kommerzielle Zwecke zu reproduzieren, zu verändern, zu kopieren, zu versenden oder zu nutzen.",
        content113: "10.2 Alle im Namen dieser Website enthaltenen Rechte (einschließlich, aber nicht beschränkt auf Firmenwert und Marken, Logos) gehören dem Unternehmen.",
        content114: "10.3 Ihre Zustimmung zu dieser Vereinbarung gilt als Ihre Initiative zur Übertragung des Urheberrechts an jeglicher Form von Informationen, die Sie auf dieser Website veröffentlichen, einschließlich, aber nicht beschränkt auf: Vervielfältigungsrechte, Vertriebsrechte, Verleihrechte, Ausstellungsrechte, Aufführungsrechte, Vorführungsrechte, Senderechte, Übertragungsrechte für Informationen im Internet, Verfilmungsrechte, Adaptionsrechte, Übersetzungsrechte, Zusammenstellungsrechte und andere übertragbare Rechte, die dem Urheberrechtsinhaber zustehen sollten, werden ausschließlich dieser Website kostenlos übertragen. Diese Website hat das Recht, eine unabhängige Klage einzureichen Klage gegen ein Subjekt wegen Verstoßes einreichen und volle Entschädigung erhalten. Diese Vereinbarung gilt für alle urheberrechtlich geschützten Werkinhalte, die Sie auf dieser Website veröffentlichen, unabhängig davon, ob der Inhalt vor oder nach der Unterzeichnung dieser Vereinbarung erstellt wurde.",
        content115: "10.4 Sie dürfen die geistigen Eigentumsrechte dieser Website oder anderer nicht rechtswidrig nutzen oder darüber verfügen, wenn Sie die Dienste dieser Website nutzen. Sie dürfen die auf dieser Website veröffentlichten Informationen in keiner Form veröffentlichen oder anderen Websites (und Medien) gestatten, diese zu nutzen.",
        content116: "11. Informationsschutz",
        content117: "Es gelten die Bestimmungen der gesondert auf dieser Website veröffentlichten „Datenschutzerklärung“.",
        content118: "12. Berechnung",
        content119: "Alle Ergebnisse der Transaktionsberechnung wurden von uns überprüft und alle Berechnungsmethoden wurden auf der Website veröffentlicht. Wir können jedoch nicht garantieren, dass die Nutzung der Website nicht unterbrochen wird oder fehlerfrei ist.",
        content120: "13. Exportkontrolle",
        content121: "Sie verstehen und erkennen an, dass es Ihnen gemäß den einschlägigen Gesetzen nicht gestattet ist, Materialien (einschließlich Software) auf dieser Website zu exportieren, erneut zu exportieren, zu importieren oder zu übertragen. Sie garantieren daher, dass Sie keines davon aktiv umsetzen, unterstützen oder daran teilnehmen werden die oben genannten Exporte oder damit verbundenen Aktivitäten, die gegen Gesetze und Vorschriften verstoßen. Übertragung oder andere Verstöße gegen geltende Gesetze und Vorschriften; wenn eine solche Situation entdeckt wird, sollten Sie uns dies aktiv und rechtzeitig melden und uns bei der Bewältigung unterstützen.",
        content122: "14. Übertragen",
        content123: "Die in dieser Vereinbarung festgelegten Rechte und Pflichten binden auch die Zessionare, Erben, Testamentsvollstrecker und Verwalter der Parteien, die von den Rechten und Pflichten profitieren. Sie dürfen es ohne unsere Zustimmung nicht an Dritte übertragen, aber wir können unsere Rechte und Pflichten aus dieser Vereinbarung jederzeit an Dritte übertragen und Sie davon benachrichtigen.",
        content124: "15. Teilbarkeit",
        content125: "Sollte eine Bestimmung dieser Vereinbarung von einem zuständigen Gericht für nicht durchsetzbar, ungültig oder rechtswidrig befunden werden, hat dies keinen Einfluss auf die Gültigkeit der übrigen Bestimmungen dieser Vereinbarung.",
        content126: "16. Nicht-Agentur-Beziehung",
        content127: "Nichts in dieser Vereinbarung soll so ausgelegt werden, dass wir als Ihr Vertreter, Treuhänder oder sonstiger Vertreter begründet, angedeutet oder anderweitig konstituiert werden, sofern in dieser Vereinbarung nichts anderes bestimmt ist.",
        content128: "17. Enthaltung",
        content129: "Der Verzicht durch uns oder Sie auf die Haftung einer Partei für Vertragsverletzungen oder andere in dieser Vereinbarung festgelegte Haftungen gilt nicht als Verzicht auf sonstige Haftungen für Vertragsverletzungen; die Nichtausübung von Rechten oder Rechtsbehelfen gilt in keiner Weise kann als Verzicht auf solche Rechte oder Rechtsbehelfe ausgelegt werden.",
        content130: "18. Titel",
        content131: "Alle Überschriften dienen lediglich der besseren Darstellung der Vereinbarung und dienen nicht dazu, den Inhalt oder Umfang der Vertragsbedingungen zu erweitern oder einzuschränken.",
        content132: "19. Anwendbares Recht",
        content133: "19.1 Bevor Sie eine Streitigkeit oder einen Anspruch geltend machen, erkennen Sie an und erklären sich damit einverstanden, diese Website zunächst per E-Mail zu kontaktieren, um die Streitigkeit informell beizulegen. Wir werden versuchen, Ihre Streitigkeit so schnell wie möglich intern beizulegen; beide Parteien vereinbaren, die Streitigkeit durch Verhandlungen (Diskussionen) in gutem Glauben beizulegen sind vertraulich und durch geltende Vorschriften geschützt und dürfen nicht als Beweismittel in einem Gerichtsverfahren verwendet werden.",
        content134: "19.2 Sie erkennen an und stimmen zu, dass im Falle von Streitigkeiten, Meinungsverschiedenheiten, Meinungsverschiedenheiten oder Ansprüchen, einschließlich der Existenz, Gültigkeit, Auslegung, Leistung, Verletzung oder Beendigung dieser Bedingungen oder Streitigkeiten, die sich aus oder im Zusammenhang mit diesen Bedingungen ergeben („Streitigkeiten“) ) legen die oben genannten Parteien die Streitigkeit dem Internationalen Schiedsgericht der Internationalen Handelskammer („ICC International Court of Arbitration“) gemäß der jeweils geltenden „ICC Rules of Arbitration“ vor. Der Ort des Schiedsverfahrens ist Belize und das anwendbare Recht des Schiedsverfahrens wird in Übereinstimmung mit den Schiedsregeln der Internationalen Handelskammer bestimmt. Sofern die Parteien nichts anderes vereinbart haben, wird gemäß der ICC-Schiedsgerichtsordnung nur ein Schiedsrichter ernannt. Der Schiedsrichter hat die ausschließliche Befugnis, über seine eigene Zuständigkeit zu entscheiden, einschließlich, aber nicht beschränkt auf, etwaiger Einwände gegen das Bestehen, den Umfang oder die Gültigkeit der Schiedsvereinbarung oder die Schiedsfähigkeit von Ansprüchen oder Gegenklagen. Jedes Schiedsverfahren wird in englischer Sprache durchgeführt. Unabhängig davon, wie das Schiedsverfahren durchgeführt wird, muss der Schiedsrichter einen schriftlichen Schiedsspruch erlassen, der angemessen und ausreichend ist, um den Schiedsspruch und die wesentlichen Schlussfolgerungen, sofern vorhanden, auf denen der Schiedsspruch beruht, zu erläutern. Der Schiedsspruch ist für die Parteien endgültig und bindend und kann vor jedem zuständigen Gericht durchgesetzt werden.",
        content135: "20. Wirksamkeit und Auslegung der Vereinbarung",
        content136: "20.1 Diese Vereinbarung tritt in Kraft, wenn Sie auf die Registrierungsseite dieser Website klicken, um der Registrierung zuzustimmen, den Registrierungsprozess abzuschließen und ein Konto und Passwort für diese Website zu erhalten. Sie ist sowohl für diese Website als auch für Sie bindend.",
        content137: "20.2 Das endgültige Auslegungsrecht dieser Vereinbarung liegt bei dieser Website.",
        content138: "Kennen Sie Ihre Kunden- und Anti-Geldwäsche-Richtlinien",
        content139: "1. Einleitung",
        content140: "1.1 Wir versprechen, die Gesetze und Vorschriften im Zusammenhang mit „Know Your Customer“ und der Bekämpfung der Geldwäsche sorgfältig einzuhalten und nicht vorsätzlich gegen die „Know Your Customer“- und Anti-Geldwäsche-Richtlinie zu verstoßen. Im Rahmen unserer angemessenen Kontrolle werden wir die erforderlichen Maßnahmen und Technologien ergreifen, um Ihnen sichere Dienste bereitzustellen und Sie so weit wie möglich vor Verlusten zu schützen, die durch das Geldwäscheverhalten von Straftätern verursacht werden.",
        content141: "1.2 Unsere „Know Your Customer“- und Anti-Geldwäsche-Richtlinie ist ein umfassendes internationales Richtliniensystem, das „Know Your Customer“- und Anti-Geldwäsche-Richtlinien für die verschiedenen Rechtsgebiete, denen Sie angehören, umfasst.",
        content142: "2. Kennen Sie Ihre Kunden- und Anti-Geldwäsche-Richtlinien:",
        content143: "2.1 Veröffentlichen Sie „Know Your Customer“- und Anti-Geldwäsche-Richtlinien und aktualisieren Sie diese von Zeit zu Zeit, um den Standards der entsprechenden Gesetze und Vorschriften zu entsprechen.",
        content144: "2.2 Verkünden und aktualisieren Sie einige Leitprinzipien und Regeln für den Betrieb dieser Website, und unsere Mitarbeiter werden Dienstleistungen gemäß den Leitlinien dieser Prinzipien und Regeln erbringen;",
        content145: "2.3 Entwerfen und vervollständigen Sie Verfahren zur internen Überwachung und Kontrolle von Transaktionen, wie z. B. die Überprüfung von Identitäten mit strengen Mitteln und die Organisation der Bildung eines professionellen Teams, das für die Arbeit zur Bekämpfung der Geldwäsche zuständig ist;",
        content146: "2.4 Verwenden Sie Risikopräventionsmethoden, um eine Due-Diligence-Prüfung und eine laufende Überwachung der Kunden durchzuführen.",
        content147: "2.5 Überprüfen und überprüfen Sie regelmäßig die durchgeführten Transaktionen;",
        content148: "2.6 Melden Sie verdächtige Transaktionen den zuständigen Behörden.",
        content149: "2.7 Identitätsnachweise, Adress- und Transaktionsaufzeichnungen werden mindestens sechs Jahre lang aufbewahrt und können den Aufsichtsbehörden ohne weitere Mitteilung an Sie vorgelegt werden.",
        content150: "3. Identitätsinformationen und Überprüfung",
        content151: "3.1 Identitätsinformationen",
        content152: "3.1.1 Aufgrund unterschiedlicher Vorschriften in verschiedenen Gerichtsbarkeiten und unterschiedlichen Unternehmenstypen kann der Inhalt Ihrer von uns erfassten Daten inkonsistent sein. Grundsätzlich werden die folgenden Informationen von registrierten Personen erfasst:",
        content153: "Grundlegende persönliche Informationen: Ihr Name, Ihre Adresse (und Ihre ständige Adresse, falls abweichend), Geburtsdatum und Nationalität sowie andere verfügbare Informationen. Die Identitätsüberprüfung sollte auf Dokumenten basieren, die von offiziellen oder ähnlichen Behörden ausgestellt wurden, wie z. B. Reisepässen, Personalausweisen oder anderen Ausweisdokumenten, die von verschiedenen Gerichtsbarkeiten verlangt und veranlasst werden. Die Überprüfung der von Ihnen angegebenen Adresse erfolgt durch geeignete Verfahren, wie z. B. die Überprüfung des Personenbeförderungstickets bzw. Zinsscheins oder die Überprüfung des Wählerverzeichnisses.",
        content154: "Gültiges Foto: Bevor Sie sich registrieren, müssen Sie ein Foto von sich vorlegen, auf dem Sie Ihren Ausweis an die Brust halten;",
        content155: "Kontaktdaten: Telefon-/Mobilnummer und/oder gültige E-Mail-Adresse.",
        content156: "3.1.2 Wenn Sie ein Unternehmen oder eine andere juristische Person sind, erfassen wir die folgenden Informationen, um Sie oder den Endbegünstigten Ihres Treuhandkontos zu identifizieren.",
        content157: "Unternehmensregistrierung und Registrierungsbescheinigung; Kopien der Satzung und des Memorandums des Unternehmens; detaillierte Bescheinigungsmaterialien über die Eigenkapitalstruktur und Eigentumsbeschreibung des Unternehmens, die den Vorstandsbeschluss des autorisierten Auftraggebers belegen, der die Eröffnung und Ausführung des Kontos auf dieser Website bestimmt; Unternehmen Geschäftsführer, die bei Bedarf vorgelegt werden müssen, Ausweisdokumente des Hauptaktionärs und der Person, die zur Zeichnung des Kontos auf dieser Website berechtigt ist; die Hauptgeschäftsadresse des Unternehmens, falls diese von der Postanschrift des Unternehmens abweicht, geben Sie die Postanschrift an. Wenn die lokale Adresse eines Unternehmens nicht mit seiner Hauptgeschäftsadresse übereinstimmt, gilt es als Kunde mit höherem Risiko und muss zusätzliche Unterlagen einreichen.",
        content158: "Abhängig von den unterschiedlichen Vorschriften in verschiedenen Gerichtsbarkeiten und verschiedenen Arten von Unternehmen benötigen wir weitere von Behörden ausgestellte Zertifizierungen und Dokumente sowie Dokumente, die wir für notwendig halten.",
        content159: "3.1.3 Wir akzeptieren nur englische oder chinesische Versionen von Identitätsinformationen. Wenn nicht, übersetzen Sie Ihre Identitätsinformationen bitte ins Englische und lassen Sie sie notariell beglaubigen.",
        content160: "3.2 Bestätigen Sie die Verifizierung",
        content161: "3.2.1 Wir verlangen von Ihnen, dass Sie den vollständigen Seiteninhalt Ihres Ausweisdokuments angeben.",
        content162: "3.2.2 Wir bitten um ein Foto von Ihnen, wie Sie Ihren Ausweis an die Brust halten.",
        content163: "3.2.3 Kopien der Belege sollten grundsätzlich mit den Originalbelegen verglichen werden. Eine Kopie ist jedoch akzeptabel, wenn ein geeigneter und vertrauenswürdiger Zertifizierer bescheinigen kann, dass die Kopie eine genaue und vollständige Reproduktion des Originaldokuments ist. Zu diesen Zertifizierern gehören Botschafter, Justizkommissare, örtliche Richter usw.",
        content164: "3.2.4 Die Anforderung zur Identifizierung des Endbegünstigten und der Kontrolle über das Konto besteht darin, zu bestimmen, welche Personen letztendlich den direkten Kunden besitzen oder kontrollieren, und/oder festzustellen, dass laufende Transaktionen im Namen anderer ausgeführt werden. Wenn es sich um ein Unternehmen handelt, sollte die Identität der Hauptaktionäre (z. B. derjenigen, die 10 % oder mehr der Stimmrechte halten) überprüft werden. Im Allgemeinen gilt der Besitz von 25 % der Anteile als mit einem normalen Risiko verbunden, und die Identität des Aktionärs muss überprüft werden; der Besitz von 10 % der Anteile oder der Besitz weiterer Stimmrechte oder Aktien gilt als Hochrisikosituation und die Identität des Aktionärs ist nachzuweisen.",
        content165: "4. Überwachen Sie Transaktionen",
        content166: "4.1 Wir legen von Zeit zu Zeit Höchstgrenzen für tägliche Transaktionen und Währungsabhebungen fest und passen diese an, basierend auf der Sicherheit und den tatsächlichen Transaktionsbedingungen.",
        content167: "4.2 Wenn Transaktionen bei einem registrierten Benutzer häufig vorkommen oder unangemessene Umstände vorliegen, wird unser professionelles Team sie bewerten und entscheiden, ob sie verdächtig sind;",
        content168: "4.3 Wenn wir aufgrund unseres eigenen Ermessens feststellen, dass eine Transaktion verdächtig ist, können wir restriktive Maßnahmen ergreifen, wie z. B. die Aussetzung der Transaktion, die Ablehnung der Transaktion oder sogar die Rückabwicklung der Transaktion, wenn möglich, so schnell wie möglich und die Meldung an die zuständige Behörde, aber wird Sie nicht benachrichtigen;",
        content169: "4.4 Wir behalten uns das Recht vor, Registrierungsanträge von Personen aus Ländern, die nicht den internationalen Anti-Geldwäsche-Standards entsprechen, oder von Personen, die als politisch exponierte Personen gelten könnten, abzulehnen. Wir behalten uns das Recht vor, verdächtige Transaktionen nach unserem alleinigen Ermessen auszusetzen oder zu beenden jederzeit, aber das tun wir nicht. Pflichten oder Verantwortlichkeiten, die Ihnen geschuldet werden, verletzen.",
    },
    register: {
        formTitle1: "erstellen",
        formTitle2: "Registrieren Sie sich mit Ihrer E-Mail-Adresse oder Mobiltelefonnummer",
        formTitle3: "Konto",
        field1: "Bestätigungscode",
        field2: "Erhalten",
        field3: "Bestätige das Passwort",
        field4: "Einladungscode",
        field41: "（Optional）",
        field5: "Ich habe gelesen und stimme zu",
        field6: "Nutzungsbedingungen",
        placeholder1: "Bitte geben Sie den Bestätigungscode ein",
        content7: "Bitte geben Sie den Bestätigungscode ein",
        content12: "Bitte geben Sie die Telefonnummer ein",
        content13: "Bitte Passwort eingeben",
        content14: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        content18: "Bitte geben Sie das Passwort erneut ein",
        content19: "Bitte stimmen Sie den Nutzungsbedingungen zu",
        content20: "Bitte geben Sie den korrekten Bestätigungscode ein",
        content23: "Ich habe den Bestätigungscode immer noch nicht erhalten？",
        content24: "Bitte geben Sie unten den Bestätigungscode ein",
        registerSuccess: "Registrierung erfolgreich",
        content25: "Passwörter sind inkonsistent",
        content26: "Bitte geben Sie die richtige E-Mail-Adresse ein",
        content27: "Passwort-Reset erfolgreich"
    },
    download: {
        content1: "Handeln Sie jederzeit und überall",
        content2: "Perfekt kompatibel mit mehreren Terminals, um jederzeit die Transaktionsanforderungen verschiedener Szenarien zu erfüllen",
        content3: "Überprüfen Sie jederzeit die Marktbedingungen in Echtzeit",
        content4: "Kaufen und verkaufen Sie ganz einfach BTC, ETH, XRP und andere digitale Vermögenswerte",
        content5: "Erhalten Sie Frühwarnungen über Preisänderungen für die Kryptowährungen, die Ihnen wichtig sind",
        content6: "Sehen Sie sich Bitcoin-Spot-Darlehen, Futures-Darlehen und Optionspreise an",
        content7: "Vergleichen Sie die Kryptowährungspreise an verschiedenen Börsen",
        title: "herunterladen",
        install: "Installationshinweise",
        one: "1、Erlauben Sie das Herunterladen von Beschreibungsdateien",
        two: "2、Beschreibungsdatei heruntergeladen",
        three: "3、Gehen Sie auf Ihrem Telefon zu [Einstellungen] und suchen Sie nach [Allgemein].",
        four: "4、Profile und Gerätemanager",
        five: "5、Klicken Sie auf das Profil",
    },
    identity: {
        noAuthentication: {
            title: "Junior-Zertifizierung",
            pleaseSelectYourCountry: "Bitte wählen Sie Ihr Land",
            name: "Name",
            enterName: "Bitte geben Sie Ihren Namen ein",
            city: "Stadt",
            enterCity: "Bitte geben Sie Ihre Stadt ein",
            IDNumber: "ID-Nummer",
            enterIDNumber: "Bitte geben Sie Ihre ID-Nummer ein",
        },
        noAdvancedAuthentication: {
            title: "Erweiterte Zertifizierung",
            infoCountry: "Land / Region",
            infoName: "Name",
            infoNumber: "ID-Nummer",
            infoAddress: "Adresse",
        },
        text: "nicht zertifiziert",
        recertification: "Rezertifizierung",
        certification: "Zertifizierung",
        authenticationInProgress: "verifizie",
        inAuthentication: "Zertifizierung läuft",
        uploadTheFrontOfTheDocument: "Laden Sie die Vorderseite des Ausweises hoch",
        uploadTheReverseSideOfTheDocument: "Laden Sie die Rückseite des Zertifikats hoch",
        uploadAPhotoOfAHandHeldID: "Laden Sie ein Foto Ihres Personalausweises in der Hand hoch",
        pleaseUpload: "Bitte ",
        pleaseCompleteOrdinaryCertificationFirst: "Es muss auf den Erfolg der primären Zertifizierungsprüfung gewartet werden",
        refused: "abgelehnt",
        reason: "Grund",
        succeeded: "gelungen",
        uploading: "Hochladen",
        newTitle: "Wählen Sie das Land aus, in dem Ihr Ausweis ausgestellt wurde",
        pleaseOrdinaryCertificationFirst: "Bitte führen Sie zunächst die Primärzertifizierung durch",
        text1: "Avatar ändern",
        text2: "Spitzname",
        text3: "Konto",
        text4: "Änderung des Spitznamens",
        text5: "neuer Spitzname",
        text6: "Geben Sie Ihren neuen Spitznamen ein",
        text7: "Altes Passwort",
        text8: "Geben Sie Ihr altes Passwort ein",
        text9: "Neues Kennwort",
        text10: "Gib dein neues Passwort ein",
        text11: "Bestätige das Passwort",

        text12: "Geben Sie Ihr neues Passwort erneut ein",
        text13: "neuer Briefkasten",
        text14: "Bestätigungscode",
        text15: "Bitte geben Sie den Bestätigungscode ein",
        text16: "Deine Handynummer",
        text17: "Neue Handynummer",
        text18: "Bitte geben sie ihre Telefonnummer ein",
        text19: "Bestätigungscode für Mobiltelefone",
        text20: "Junior-Zertifizierung",
        text21: "Bitte überprüfen Sie Ihre Identitätsinformationen",
        text22: "Land",
        text23: "Stadt",
        text24: "Geben Sie Ihre Stadt ein",
        text25: "Gib deinen Namen ein",
        text26: "Ihre ID-Nummer",
        text27: "Geben Sie Ihre ID-Nummer ein",
        text28: "Foto auf der Vorderseite des Personalausweises",
        text29: "Klicken Sie hier, um Ihre Dokumente hochzuladen",
        text30: "erneut hochladen",
        text31: "hochladen",
        text32: "Halten Sie ein Ausweisfoto bereit",
        text33: "Klicken Sie hier, um Ihr Betriebsausweisfoto hochzuladen",
        text34: "Sie haben Google Authenticator gebunden",
        text35: "Schlüssel",
        text36: "Bitte geben Sie den Google-Bestätigungscode ein",
        text37: "schicken",
        text38: "Kann nicht leer sein",
        text39: "Das E-Mail-Format ist falsch",
        text40: "Zwei Passwörter sind inkonsistent",
        text41: "Erfolgreich kopiert",
        text42: "Das Kopieren ist fehlgeschlagen",
        text43: "Avatar-Modifikation",
        text44: "Kennwort ändern",
        text45: "E-Mail-Bindung",
        text46: "E-Mail ändern",
        text47: "Handybindung",
        text48: "Mobiltelefon ändern",
        text49: "Google-Bestätigung",
        text50: "Binden Sie Google Authenticator",
        text51: "Geben Sie den Google-Bestätigungscode ein",
        text52: "speichern",
        text53: "Bestätigen Sie die Übermittlung",
        text54: "Überarbeiten",
        text55: "Bindung",
        text56: "Deaktivieren Sie die Google-Verifizierung",
        text57: "Nächster Schritt",
        text58: "Beenden",
        text59: "Die Google-Verifizierung ist gebunden",
        text60: "Die Google-Verifizierung wurde entbündelt",
        text61: "Die Primärzertifizierung wurde eingereicht",
        text62: "Erweiterte Zertifizierung eingereicht",
        text63: "Kein Bild hochgeladen",
        text64: "Erneut senden",
        text65: "Bitte geben Sie einen Spitznamen ein",
        text66: "Spitzname erfolgreich gespeichert",
        text67: "Noch keine Bilder hochgeladen",
        text68: "Passwort-Reset abgeschlossen",
        text69: "E-Mail erfolgreich geändert",
        text70: "Avatar erfolgreich gespeichert",
        text71: "Bild hochladen",
        text72: "Ihr Postfach",
        text73: "Geben Sie Ihre neue E-Mail-Adresse ein",
        text74: "Ihr Name",
        text75: "Gehen Sie zur Authentifizierung",
        text76: "Bitte überprüfen Sie Ihr Ausweisfoto"
    },
    retrievePassword: {
        formTitle1: "Passwort zurücksetzen",
        formTitle2: "Zur Sicherheit Ihres Vermögens sind Transaktionen innerhalb von 24 Stunden nach Änderung des Passworts verboten.",
        formTitle3: "Passwort abrufen",
        formTitle4: "Bestätigen Sie die Änderungen",
        formTitle5: "Bitte geben Sie das alte Passwort ein",
        formTitle6: "Bitte geben Sie ein neues Passwort ein"
    },
    home: {
        title1: "Die beliebteste Handelsplattform für Kryptowährungen",
        title3: "warum ist ",
        title4: " Es handelt sich um eine leistungsstarke Open-Source-Blockchain-Plattform, die für die Unterstützung und den Betrieb einer sicheren, konformen und vorhersehbaren digitalen Infrastruktur entwickelt wurde.",
        field1: "Etablierte Börse",
        field2: "Finanzdienstleistungen für digitale Vermögenswerte",
        field3: "Dutzende Millionen Benutzer",
        field4: "Einrichtung mehrerer Anlegerschutzfonds",
        field5: "Schneller Handel",
        field6: "CME Gruop ist in der Lage, Aufträge mit extrem geringer Latenz für das ultimative Handelserlebnis abzugleichen.",
        title5: "Markt Trend",
        title6: "Mehr sehen",
        table1: "Währung",
        table2: "Letzter Preis",
        table3: "24 Stunden lang Höhen und Tiefen",
        table4: "24-Stunden-Handelsvolumen",
        table5: "24-Stunden-Transaktionsvolumen",
        table6: "Zitate",
        title7: "um ",
        title8: " Wir sind bestrebt, den Benutzern die fortschrittlichsten Handelstools, innovative Technologie und einen beispiellosen Qualitäts-Kundenservice zu bieten.",
        title9: "Der Bereich der digitalen Assets verändert sich mit jedem Tag. Nur wenn wir weitsichtig sind und schnelle Fortschritte machen, können wir mit dem Tempo der technologischen Innovation Schritt halten.",
        title901: " Bereitstellung der fortschrittlichsten Handelsdienste für digitale Vermögenswerte für unsere Benutzer und globalen Liquiditätsanbieter.",
        title10: "Wir sind der festen Überzeugung, dass jeder Benutzer das beste Handelserlebnis verdient.",
        title11: "Sicherheit, der Sie vertrauen können",
        title12: "Unsere ausgefeilten Sicherheitsmaßnahmen und SAFU-Fonds schützen Ihre digitalen Vermögenswerte vor allen Risiken. ",
        title13: "Beste Transaktionsraten",
        title14: "Vorzugstransaktionspreise, wettbewerbsfähige VIP-Rechte und erstklassige Dienstleistungen.",
        title15: "Kundendienst rund um die Uhr",
        title16: "Im 24/7-Allwetter-Vollzeitbetriebsmodus werden wir Ihre relevanten Anfragen so schnell wie möglich beantworten.",
        title17: "0 Gebührenhandel",
        title18: "Nutzen Sie mehrere Zahlungsmethoden, um digitale Währungen ohne Bearbeitungsgebühren, sicher und schnell zu handeln.",
        title19: "Handeln Sie jederzeit und überall",
        title20: "Perfekt kompatibel mit mehreren Terminals, um jederzeit die Transaktionsanforderungen verschiedener Szenarien zu erfüllen",
        title21: "Überprüfen Sie jederzeit die Marktbedingungen in Echtzeit",
        title22: "Kaufen und verkaufen Sie ganz einfach BTC, ETH, XRP und andere digitale Vermögenswerte",
        title23: "Erhalten Sie Frühwarnungen über Preisänderungen für die Kryptowährungen, die Ihnen wichtig sind",
        title24: "Sehen Sie sich Bitcoin-Spot-Darlehen, Futures-Darlehen und Optionspreise an",
        title25: "Vergleichen Sie die Kryptowährungspreise an verschiedenen Börsen",
        title26: "Jetzt registrieren",
        title27: "Dollar",
        title28: "24-Stunden-Handelsvolumen",
        title29: "Integrierter Liquiditätsanbieter",
        title30: "Benutzer",
        title31: "Mehr",
        title32: "Binance Loan fügt CTSI und KSM als Sicherheiten hinzu und führt BUSD- und USDT-Zinssenkungsrabatte ein!",
        title33: "Unser Vorteil",
        title34: "sicher und stabil",
        title35: "Ein erstklassiges technisches Team, umfassender Sicherheitsschutz, unabhängige Forschung und Entwicklung einer Hochgeschwindigkeits-Transaktionssynthese-Engine können auch bei massiven gleichzeitigen Transaktionen stabil und zuverlässig arbeiten.",
        title36: "Professionelle Compliance",
        title37: "Ein professionelles Betriebsteam mit langjähriger Erfahrung in den Bereichen Blockchain und Finanzen verfügt über eine konforme Lizenz für den Handel mit digitalen Vermögenswerten und eine 100-prozentige Reservegarantie.",
        title38: "Benutzer zuerst",
        title39: "Sich dem globalen Markt stellen, mehrere Sprachen unterstützen, rund um die Uhr laufen, starker Community-Support und professioneller Kundenservice.",
        title40: "Hochleistung",
        title41: "300.000 Transaktionen pro Sekunde und Auftragsantwortzeit von weniger als 1 Millisekunde.",
        title42: "über uns",
        title43: "Handeln Sie jederzeit und überall",
        title44: "Perfekt kompatibel mit mehreren Terminals, um jederzeit die Transaktionsanforderungen verschiedener Szenarien zu erfüllen",
        title45: "（Zum Herunterladen den Code scannen）",
        title46: "Nachrichten zu digitalen Währungen",
        title47: "unser Geschäftspartner",
        title48: "Verliererliste",
        title49: "24h Umsatz",
        title50: "24h Reichweite"
    },
    quotation: {
        marketTrading: "Zitate",
        turnover: "Volumen",
        optional: "Optional",
        contractMarket: "Vertragsmarkt",
        currencyPair: "Währungspaar",
        latestPrice: "Schlusskurs",
        upsAndDowns: "Angebotsänderung",
        highest: "Höchste",
        lowest: "am niedrigsten",
        transactionAmount: "Transaktionsvolumen",
        operate: "arbeiten",
        buy: "Handel",
        topTurnovers: "Transaktionsliste",
        topGainers: "Gainer-Liste",
        tradingPair: "Handelspaar",
        buyOrder: "Kaufen",
        sellOrder: "Verkaufsauftrag"
    },
    accountChange: {
        demo: "demo",
        realAccount: "tatsächliches Konto",
        demoAccount: "Demokonto",
        accountRole: "Kontorolle",
        pleaseSelect: "bitte auswählen",
        AccountChangeType: "Art der Kontoänderung",
        AccountType: "Konto Typ",
        currencyType: "Währungstyp",
        allType: "Alle Arten",
        allAccount: "Alle Konten",
        allCoin: "Alle Währungen",
        coin: "Währung",
        amount: "Betrag ändern",
        balance: "Kontostand",
        time: "Zeit",
        title: "Kontoänderungsaufzeichnungen",
        filtrar: "Filter",
        check: "wechseln zu",
        showOnly: "Nur Währungsbestände anzeigen",
        holdAssets: "Währungssaldo",
        accountList: "Kontoliste"
    },
    contractTransaction: {
        chart: "Diagramm",
        basicEdition: "Basisversion",
        depthMap: "Tiefenkarte",
        second: "Zweite",
        minute: "Punkt",
        day: "Himmel",
        week: "Woche",
        month: "Mond",
        open: "offen",
        trade: "Handel",
        buyLong: "lange kaufen",
        buyShort: "Kurzkauf",
        cannotBuy: "Diese Währung unterstützt keine Liefervertragstransaktionen",
        cannotBuy1: "Diese Währung unterstützt keinen ewigen Handel",
        tradingAssets: "Handelsaktiva",
        amountAvailable: "Verfügbare Menge",
        timeStr: "Zeit",
        transactionPeriod: "Handelszeitraum",
        quantity: "Menge",
        enterQuantity: "Bitte Menge eingeben",
        buyIn: "Geh lang",
        latestTransaction: "Letzte Transaktion",
        time: "Zeit",
        direction: "Richtung",
        price: "Preis",
        sell: "kurz",
        positionOrder: "Lieferauftrag",
        historicalOrders: "Historische",
        product: "Produkt",
        realAccount: "(echter Account)",
        demoAccount: "(Demokonto)",
        openingTime: "Bestellzeitpunkt",
        openingPrice: "Eröffnungskurs",
        curretPrice: "Derzeitiger Preis",
        liquidationPrice: "Schlusskurs",
        profitOrLoss: "Gewinn-und Verlust",
        endTime: "Geschäftsschluss",
        areYouSureToBuy: "Sind Sie sicher, dass Sie kaufen möchten?",
        areYouSureToLongBuy: "Sind Sie sicher, dass Sie lange gehen möchten?",
        areYouSureToShortBuy: "Sind Sie sicher, dass Sie Leerverkäufe tätigen möchten?",
        successfullyBought: "Erfolgreich kaufen",
        minBuy: "Mindesteinkauf",
        assets: "Aktiva",
        withdraw: "Abheben",
        entrustedOrder: "Befehl",
        total: "gesamt",
        continueToPlaceOrder: "Geben Sie weiterhin eine Bestellung auf",
        orderConfirmation: "Bestellbestätigung",
        buyRu: "kaufen",
        sellChu: "verkaufen",
        areYouSureToSell: "Sind Sie sicher, dass Sie verkaufen möchten?",
        operation: "arbeiten",
        buyCover: "Abdeckungsposition",
        commitSuccess: "Transaktion Erfolgreich",
        onlyEnterIntegers: "Es können nur ganze Zahlen eingegeben werden",
        trend: "Trend",
        lotteryTime: "Bestellschluss",
        countdown: "Countdown",
        period: "Ausgabe",
        cycle: "Zeitraum",
        unlimited: "Unbegrenzt",
        put: "Put",
        call: "Call",
        pending: "Auf Provision",
        unopened: "Noch nicht gezeichnet",
        opened: "Preis ausgelost",
        settlementPrice: "Abrechnungspreis",
        rateOfReturn: "Rendite",
        currentPositions: "Aktuelle Position",
        positionQuantity: "Positionsmenge",
        guarantee: "Marge",
        text1: "Call",
        text2: "Put",
        text3: "Ich warte auf den Kauf",
        text4: "Bestellmenge",
        text5: "Die Anzahl der Transaktionen",
        text6: "Stornieren",
        text7: "Lieferzeit",
        text8: "Stornierungserinnerung",
        text9: "Sind Sie sicher, dass Sie die Bestellung stornieren möchten?",
        text10: "Unzulässige Menge",
        text11: "Bestellerinnerung",
        text12: "Sind Sie sicher, dass Sie bullisch kaufen?",
        text13: "Sind Sie sicher, dass Sie den Put kaufen möchten?",
        text14: "Stornierung erfolgreich"
    },
    mining: {
        title1: "Die weltweit führende Blockchain-Plattform für das Mining digitaler Assets",
        title2: "Offen und transparent · sicher und stabil",
        title3: "Bitte wählen Sie die Währung aus",
        title4: "Annualisierter Bergbauertrag",
        title5: "Himmel",
        title6: "Bergbauzeit",
        title7: "Produktdetails",
        btn: "Meins jetzt",
        btn2: "Schließung",
        tabs1: "im Gange",
        tabs2: "eingelöst",
        table1: "Produktname",
        table2: "Bergbauzeit",
        table3: "Bergbauzeit",
        table4: "Mining-Betrag",
        table5: "Ablaufdatum",
        table6: "Fällt bis zur Reife aus",
        table7: "Bestellstatus",
        egularMining: "Regelmäßig Bergbau betreiben",
        inProgress: "im Gange",
        redeemed: "eingelöst",
        redemption: "Rückzahlung",
        regularMining: "Regelmäßig Bergbau betreiben",
        earlyRedemption: "vorzeitige Rückzahlung",
        need: "brauchen",
        handlingFee: "Bearbeitungsgebühr",
        redemptionConfirmationMessage: "Bitte bestätigen Sie, ob die Einlösung im Voraus erfolgen soll.",
        form1: "Annualisierter Bergbauertrag",
        form2: "Bergbauzeit",
        form3: "Bergbauwährung",
        form4: "Abonnementbetrag (Minimum/Maximum)",
        form5: "Gebühr für vorzeitige Rücknahme: ",
        form6: "Kaufpreis",
        form7: "Verfügbares Vermögen",
        form8: "Kaufpreis",
        form9: "Verfügbares Vermögen",
        form10: "Bitte geben Sie den Kaufbetrag ein",
        form11: "alle",
        form12: "Geschätzter Umsatz",
        form13: "Es stehen nicht genügend Mittel zur Verfügung",
        form14: "Bitte geben Sie den Kaufbetrag ein",
        form15: "Meine Bestellung",
        form16: "Einkommen",
        form17: "Bestelldetails",
        form18: "Hinweise zur vorzeitigen Rückzahlung:",
        form19: "Abonnementbetrag (Minimum)",
        form20: "Abonnementbetrag (maximal)",
        form21: "Menge gehalten",
        form22: "Zeit kaufen",
        form23: "Zeit der Erlösung",
        form24: "über",
        form25: "Abonnement erfolgreich",
        form26: "Einlösung erfolgreich",
        form27: "Einfaches Finanzmanagement mit über 25 % Rendite, über dem Markt liegenden Leistungen, und Sie können auch nach dem Kauf Prämien erhalten",
        form28: "Laden Sie Freunde ein, um eine Belohnung von 50 USDT zu erhalten, und Vertragsgutscheine können auch 20 % Provision einbringen."
    },
    assets: {
        content1: "Kontoguthaben",
        content2: "Der heutige Verdienst",
        content3: "aktuelles Konto",
        content4: "Das eigentliche Konto ist Ihr tatsächliches Kontoguthaben, das für Transaktionen und Abhebungen verwendet werden kann.",
        content5: "Münzen abheben",
        content6: "Zahlen Sie Münzen ein",
        content7: "Austausch",
        content8: "überweisen",
        content9: "bitte auswählen",
        content10: "Demokonto",
        content11: "tatsächliches Konto",
        content12: "Vermögenswerte erwerben",
        content13: "Sind Sie sicher, dass Sie zu einem echten Konto wechseln möchten?",
        content14: "Sind Sie sicher, dass Sie zu einem Demokonto wechseln möchten?",
        content15: "Erfolg haben",
        table1: "Währung",
        table2: "Menge",
        table3: "einfrieren",
        table4: "Verfügbar",
        dialog1: "Produktdetails",
        dialog2: "1. Dieses Guthaben darf nur im Demokontomodus verwendet werden",
        dialog3: "2. Um Missbrauch vorzubeugen, ist nur eine Einlösung innerhalb eines Monats zulässig.",
        dialog4: "3. Beim Erhalt von Vermögenswerten wird der Betrag der erhaltenen Vermögenswerte zum ursprünglichen Betrag addiert und der Positionsstatus wird nicht geändert.",
        dialog5: "4. Bei weiteren Fragen wenden Sie sich bitte an den Kundenservice",
        dialog6: "Schließung",
        dialog7: "Holen Sie sich jetzt Demo-Assets",
        dialog8: "hinein transferieren",
        dialog9: "raus transferieren",
        dialog10: "schalten",
        dialog11: "Bewertung",
        dialog12: "Demo-Assets",
        dialog13: "mein Konto",
        dialog14: "Gesamtkontovermögen",
        dialog15: "Vermögensübersicht",
        dialog16: "Gesamtvermögensbewertung",
        dialog17: "Geschätzter Gesamtwert",
        dialog18: "Der heutige Verdienst",
        dialog19: "Gelder einfrieren",
        dialog20: "Sie können jeden Monat eine simulierte Vermögensquote erhalten",
        dialog21: "erhalten",
        dialog22: "Sie haben erfolgreich empfangen",
        dialog23: '30 Tage kumuliertes Einkommen',
        dialog24: "Finanzprodukt",
        dialog25: "Mein Finanzmanagement",
        dialog26: "Historisches Finanzmanagement",
        dialog27: "Geschichtsaufzeichnung",
        dialog28: "Finanzierungsunterlagen"
    },
    validator: {
        dialog14: "Validator",
        dialog15: "Validator binden",
        dialog16: "Münzprüferschalter",
        dialog17: "Bestätigungscode",
        dialog18: "Validator-QR-Code",
        dialog19: "Validierungsschlüssel",
        dialog20: "speichern",
        dialog21: "Bitte wählen Sie einen Validator aus",
        dialog22: "Schlüssel kopieren",
        dialog23: "Erfolgreich gespeichert",
        dialog24: "Bitte geben Sie den Bestätigungscode ein",
        dialog25: "Freundliche Erinnerung: Bevor Sie das Speichern bestätigen, öffnen Sie bitte die entsprechende APP und scannen Sie den untenstehenden QR-Code oder fügen Sie den Schlüssel manuell hinzu!",
        dialog26: "Die Aktivierung Ihres Authentifikators bietet maximale Sicherheit für Ihr Konto.",
        dialog27: "Sie müssen jedes Mal, wenn Sie sich anmelden oder Geld abheben, einen eindeutigen Verifizierungscode eingeben, auf den Sie über Ihr Mobiltelefon zugreifen können.",
        dialog28: "Validator einrichten",
        dialog29: "helfen",
        dialog30: "Sie haben den Authentifizierungscode nicht gebunden. Erhalten Sie einen stärkeren Schutz mit einem Authentifikator.",
        dialog31: "Der Validator wurde noch nicht eingerichtet. Bitte richten Sie ihn zuerst ein",
        dialog32: "Gehe zu den Einstellungen"
    },
    recharge: {
        title1: "Sie sind sich nicht sicher, wie Sie mit digitaler Währung einkaufen können?",
        title2: "Nur drei einfache Schritte!",
        title3: "Wählen Sie im Dropdown-Menü die digitale Währung aus, die Sie kaufen möchten, und geben Sie die Kaufmenge bzw. den Kaufbetrag ein.",
        title4: "Wählen Sie Ihre bevorzugte Zahlungsmethode",
        title5: "Nach Bestätigung der Zahlung wird die von Ihnen gekaufte digitale Währung automatisch auf Ihr Konto übertragen.",
        title6: "Bitte wählen Sie die Währung aus, die Sie aufladen möchten",
        title7: "Einzahlungsprotokoll",
        table1: "Währung",
        table2: "Menge",
        table3: "Kettenname",
        table4: "Zustand",
        table5: "Grund",
        table6: "Zeit",
        table7: "Gutschein ansehen",
        dialog1: "Bitte geben Sie unten den Bestätigungscode ein",
        dialog2: "Zahlungscode",
        dialog3: "Kopieren",
        dialog4: "Adresse",
        dialog5: "Einzahlungsbetrag",
        dialog6: "Screenshot der Transaktion",
        dialog7: "Wichtiger Hinweis",
        dialog8: "请填写充币数量",
        dialog9: "Bild hochladen",
        dialog10: "Bitte geben Sie Inhalte ein",
        dialog11: "Bitte laden Sie Screenshots vom Aufladen hoch",
        dialog12: "Nicht überprüft",
        dialog13: "abgelehnt",
        dialog14: "Hinterlegt",
        tip1: "erfolgreich eingereicht",
        tip2: "Erfolgreich kopiert",
        tip3: "Das Kopieren ist fehlgeschlagen",
        tip4: "Bitte wählen Sie die Währung aus, die Sie aufladen möchten",
        tip5: "1. Die oben genannte Einzahlungsadresse ist die offizielle Zahlungsadresse der Plattform. Bitte suchen Sie nach der offiziellen Einzahlungsadresse der Plattform. Sie sind für den Geldverlust verantwortlich, der durch eine fehlerhafte Einzahlung verursacht wird;",
        tip6: "2. Bitte stellen Sie sicher, dass Ihr Computer und Ihr Browser sicher sind, um zu verhindern, dass Informationen manipuliert werden oder verloren gehen.",
        tip7: "3. Nachdem Sie die oben genannte Adresse aufgeladen haben, muss der gesamte Netzwerkknoten dies bestätigen, bevor das Konto empfangen werden kann.",
        tip8: "4. Bitte wählen Sie das oben genannte Token-System und die oben genannte Währung aus und überweisen Sie dann den entsprechenden Betrag zur Einzahlung. Bitte übertragen Sie keine anderen unabhängigen Vermögenswerte, da diese sonst nicht abgerufen werden.",
        tip9: "QR-Code speichern",
        tip10: "Einzahlen",
        tip11: "Wichtiger Hinweis",
        tip12: "Offsite-Links",
        tip13: "QR-Code aufladen",
        tip14: "Nächster Schritt",
        tip15: "Aufladeaufzeichnung",
        tip16: "Details zum Aufladen",
        tip17: "Einzahlungsadresse",
        tip18: "Schnelle Zahlung",
        tip20: "Mangelhaftes Gleichgewicht",
        tip22: "Eingereicht, wartet auf Bestätigung",
        tip23: "Warten auf Zahlung",
        tip24: "Menge",
        tip25: "Die Zahlung wurde übermittelt und wartet auf die Blockbestätigung",
        tip26: "Zahlung abgelehnt",
        tip27: "Zahlungsfehler",
        tip28: "Bezahlung erfolgreich",
        bankChannel: "Bankkartenkanal",
        tip29: "Historische Recherchen",
        tip30: "Währungsliste",
        tip31: "Netzwerk auswählen",
        tip32: "Wenn Sie diese Währung aufladen, laden Sie das Guthaben bitte nur über die unten aufgeführten unterstützten Netzwerke auf. Wenn Sie über andere Netzwerke aufladen, geht das Guthaben verloren.",
        tip33: "Wenn Sie diese Währung abheben, nehmen Sie die Auszahlung bitte nur über die unten aufgeführten unterstützten Netzwerke vor. Wenn Sie über andere Netzwerke abheben, geht das Geld verloren.",
        tip34: "Schnelles Aufladen",
        tip35: "Zum Aufladen den Code scannen",
        tip36: "Manuelles Aufladen",
        tip37: "Aufladenetzwerk",

        tip38: "Aufladeadresse",
        tip39: "Aufladebetrag",
        tip40: "Hochladen des Transaktionsbelegs",
        tip41: "Bild hochladen",
        tip42: "Bestätigen Sie die Übermittlung",
        tip43: "bestätige Zahlung",
        tip44: "Hinweis: Bitte bestätigen Sie die Adresse, die Sie zum Aufladen benötigen. Wir haften nicht für Geldverluste, wenn Sie diese falsch ausfüllen.",
        tip45: "erinnern",
        tip46: "Adresse erfolgreich kopiert!",
        tip47: "Das Kopieren der Adresse ist fehlgeschlagen!",
        tip48: "Unzureichendes verfügbares Guthaben",
        tip49: "Die Aufladung wurde erfolgreich übermittelt und wartet auf die Bestätigung in der Kette.",
        tip50: "Das Aufladen ist fehlgeschlagen",
        tip51: "Der Aufladebetrag ist falsch",
        tip52: "Verbindung fehlgeschlagen",
        tip53: "Wallet nicht angemeldet",
        tip54: "Verbindung abgelehnt",
        tip55: "Erfolgreich übermittelt, wartet auf Überprüfung und Genehmigung durch den Kundendienst",
        tip56: "Bitte laden Sie einen Screenshot des Transaktionsbelegs hoch",
        tip57: "Übermittlung fehlgeschlagen",
        tip58: "Aufladeaufzeichnung",
        tip59: "Abhebungsprotokoll",
        tip60: "Währung aufladen",
        tip61: "Auszahlungswährung",
        tip62: "Aufladeadresse",
        tip63: "Screenshot zeigt",
        tip64: "Klicken Sie, um das Bild zu vergrößern",
        tip65: "Aufladezeit",
        tip66: "Grund für die Ablehnung",
        tip67: "Auszahlungsbetrag",
        tip68: "Erhaltener Betrag",
        tip69: "Kettenname",
        tip70: "Auszahlungsadresse",
        tip71: "Auszahlungszeit",
        tip72: "Schließung",
        tip73: "Screenshot der Transaktion",
        tip74: "Ausstehende Bewertung",
        tip75: "abgelehnt",
        tip76: "vollendet",
        tip77: "abgelehnt",
        tip78: "Geprüft",
        tip79: "Ausgezahlt",
        tip80: "Extrakt",
        tip81: "Übertragungsnetzwerk",
        tip82: "Adresse/Rechnung",
        tip83: "Alle zurückziehen",
        tip84: "Bitte heben Sie kein Geld direkt an die Crowdfunding- oder ICO-Adresse ab, da Sie sonst die vom Crowdfunding oder ICO ausgegebenen Token nicht erhalten können.",
        tip85: "Ankunftsmenge",
        tip86: "Auszahlung bestätigen",
        tip87: "Falsche Auszahlungsadresse",
        tip88: "Falscher Auszahlungsbetrag",
        tip89: "Der abzuhebende Betrag beträgt mindestens",
        tip90: "Der maximale Auszahlungsbetrag beträgt",
        tip91: "Auszahlungsantrag wurde eingereicht",
        tip92: "Adressliste",
        tip93: "Sie haben noch keine Adresse hinzugefügt",
        tip94: "Fügen Sie es jetzt hinzu",
        tip95: "Adresse hinzufügen",
        tip96: "Neue Adresse hinzufügen",
        tip97: "bitte auswählen",
        tip98: "Adresse",
        tip99: "Bitte wählen Sie ein Übertragungsnetzwerk aus",
        tip100: "Bitte Adresse eingeben",
        tip101: "Bitte Adresse eingeben",
        tip102: "in der Transaktion",
    },
    fundExchange: {
        title1: "Wie kann ich Geld umtauschen?",
        title2: "Wählen Sie die entsprechende Währung aus, die umgerechnet werden soll.",
        title3: "Wählen Sie die entsprechende Währung zur Umrechnung aus.",
        title4: "Geben Sie den umzutauschenden Betrag ein und klicken Sie auf „Senden“, um den Geldumtausch abzuschließen.",
        title5: "aus",
        title6: "ankommen",
        title7: "Tauschmenge",
        title8: "Tauschrate",
        title9: "Verfügbar",
        title10: "Bitte geben Sie die Einlösemenge ein",
        title11: "Es gibt kein entsprechendes Handelspaar für die Börse.",
        title12: "Bitte geben Sie die einzulösende Menge ein",
        title13: "Einlösung erfolgreich",
        title14: "Austauschdatensatz",
        table1: "Verwenden Sie Währung",
        table2: "Menge",
        table3: "Tauschrate",
        table4: "Geld wechseln",
        table5: "Quittung",
        table6: "Zeit",
        title15: "aus",
        title16: "Derzeit sind keine Assets verfügbar. Bitte wählen Sie ein anderes aus"
    },
    fundTransfer: {
        title1: "Wie überweise ich Geld?",
        title2: "Wählen Sie das Girokonto aus, das übertragen werden soll.",
        title3: "Wählen Sie das Konto aus, das Sie übertragen möchten.",
        title4: "Geben Sie den Überweisungsbetrag ein und klicken Sie auf „Senden“, um die Überweisung abzuschließen.",
        title5: "Währung",
        title6: "Menge übertragen",
        title7: "Bitte geben Sie den zu überweisenden Betrag ein",
        title8: "Übertragungsprotokoll",
        title9: "Bitte wählen Sie den Wallet-Typ aus",
        title10: "Bitte wählen Sie die Währung aus",
        title11: "Bitte wählen Sie den Wallet-Typ aus",
        title12: "Bitte wählen Sie die Währung aus",
        title13: "Bitte geben Sie den zu überweisenden Betrag ein",
        title14: "erfolgreich eingereicht",
        table1: "Konto abbuchen",
        table2: "Währung überweisen",
        table3: "Menge übertragen",
        table4: "Überweisungskonto",
        table5: "Zeit",
        table6: "überweisen",
        table7: "Geldtransfer",
        table8: "Übertragen an",
        table9: "Übertragungsprotokoll",
        table10: "Übertragungsdetails",
        table11: "Übertragung erfolgreich",
        table12: "Die Übertragung ist fehlgeschlagen",
        table13: "Das Girokonto ist nicht übertragbar"
    },
    withdrawal: {
        title1: "Wichtiger Hinweis",
        title2: "1. Um Arbitrage zu verhindern, können Sie eine Währungsabhebung beantragen, wenn das Transaktionsvolumen das Limit erreicht.",
        title3: "2. Der Antrag auf Währungsabhebung wird innerhalb von 24 Stunden nach Einreichung gutgeschrieben. Wenn die Währungsabhebung nicht nach der erwarteten Abhebungszeit eintrifft, wenden Sie sich bitte an den Online-Kundendienst.",
        title4: "3. Nach Einreichung des Auszahlungsantrags werden die Gelder eingefroren, da die Auszahlung im Gange ist und die Gelder vorübergehend vom System zurückgehalten werden. Dies bedeutet nicht, dass Sie den Vermögenswert verloren haben oder dass eine Anomalie im Vermögenswert vorliegt.",
        title5: "Währung",
        title6: "Wallet-Adresse",
        title7: "Anzahl der abgehobenen Münzen",
        title8: "Aufzeichnung der Münzabhebung",
        title9: "Bitte wählen Sie die Währung aus",
        title10: "Bitte Kettentyp auswählen",
        title101: "",
        title11: "Bitte eingeben",
        title12: "Wallet-Adresse",
        title13: "erfolgreich eingereicht",
        title14: "in Prüfung",
        title15: "Rezension erfolgreich",
        title16: "Prüfungsfehler",
        title17: "Ausgezahlt",
        title18: "Bitte geben Sie Ihre Wallet-Adresse ein oder fügen Sie sie ein",
        title19: "Bitte geben Sie den Auszahlungsbetrag ein",
        title20: "Bearbeitungsgebühr",
        title21: "Tatsächliche Ankunftszeit",
        table1: "Währung",
        table2: "Anzahl der abgehobenen Münzen",
        table3: "Kettenname",
        table4: "Wallet-Adresse",
        table5: "Zustand",
        table6: "Grund",
        table7: "Bewerbungszeit",
        table8: "Münzen abheben",
        table81: "Münzen abheben",
        table9: "Auszahlungsadresse",
        table10: "Bitte wählen Sie die Währung aus, die Sie abheben möchten",
        table11: "Bitte stellen Sie sicher, dass Sie eintreten",
        table12: "Adresse (beginnt normalerweise mit „0x“)",
        table13: "Abhebbare Münzen (Bearbeitungsgebühr abgezogen)：",
        table14: "Währung wählen",
        table15: "Auf Probleme stoßen? Sofortige Rückmeldung",
        table16: "Gleichgewicht",
        table17: "Details zur Auszahlung",
        password: "Passwort",
        passwordTips: "Bitte Passwort eingeben",
        passwordAg: "Bestätige das Passwort",
        passwordAgTips: "Bitte geben Sie das Passwort erneut ein",
        oldPassword: "Altes Passwort",
        oldPasswordTips: "Bitte geben Sie das alte Passwort ein",
        newPassword: "Neues Kennwort",
        newPasswordTips: "Bitte geben Sie ein neues Passwort ein",
        passwordNotSame: "Passwörter sind inkonsistent",
        withdrawPasswordSet: "Auszahlungspasswort festlegen",
        withdrawPasswordModify: "Auszahlungspasswort ändern",
        withdrawPassword: "Passwort zurückziehen",
        withdrawPasswordTips: "Bitte geben Sie das Auszahlungspasswort ein",
        setWithdrawPasswordFirst: "Bitte legen Sie zunächst ein Auszahlungspasswort fest",
        withdrawPasswordSetSuccess: "Installation erfolgreich",
        withdrawPasswordModifySuccess: "Erfolgreich geändert",
        submit: "einreichen",
    },
    customer: {
        content1: "Onlineservice",
        content2: "Kundendienst",
        content3: "Bitte geben Sie Ihre Kontaktinformationen ein",
        content4: "Bitte geben Sie Inhalte ein",
        content5: "schicken",
        content6: "Das Senden ist fehlgeschlagen, die Netzwerkverbindung ist fehlgeschlagen",
        content7: "Bild",
        content8: "Nachricht ist leer",
        content9: "mein Anteil",
        content10: "Einen Link teilen",
        content11: "Bild speichern",
        content12: "mein Einkommen",
        content13: "Levelbeschreibung",
        content14: "Teamgröße",
        content15: "Einladungseinnahmen",
        content16: "Regelbeschreibung",
        content17: "Mitgliedschaftsstufe",
        content18: "Der Aufladebetrag ist größer als",
        content19: "Teamebene",
        content20: "Teamebene und Einkommen",
        content21: "Um die Bedingungen zu erfüllen",
        content22: "Vertriebserträge",
        content23: "Level-Team",
        content24: "Ebene, Einladung",
        content25: "gültige Mitglieder",
        content26: "Primäreinkommen",
        content27: "Nebenverdienst",
        content28: "Einkommen der Stufe 3",
    },
    standardContract: {
        increaseMargin: "Marge erhöhen",
        increaseAmount: "Anzahl erhöhen",
        Pleaseenterthemargin: "Bitte geben Sie den zu erhöhenden Margenbetrag ein",
        Pleaseenterthecorrect: "Bitte geben Sie den korrekten Einzahlungsbetrag ein",
        Increasemarginsuccessfully: "Marge erfolgreich steigern",
        takeProfitPriceWrong: "Take-Profit-Preis falsch",
        stoppriceerror: "Preisfehler stoppen",
        thetakeprofit1: "Der Take-Profit-Preis muss höher sein als der Marktpreis",
        thetakeprofit2: "Der Stop-Loss-Preis muss niedriger sein als der Marktpreis",
        thetakeprofit3: "Der Take-Profit-Preis muss niedriger sein als der Marktpreis",
        thetakeprofit4: "Der Stop-Loss-Preis muss höher sein als der Marktpreis",
        thetakeprofit5: "Falsche Menge",
        estimatedprofitloss: "Geschätzter Gewinn und Verlust",
        positionAmount: "Anzahl der Positionen",
        closeAmount: "Schlussmenge",
        whenthelatest: "Wenn der letzte Preis erreicht ist",
        whenthelatest2: ", wird die Marktpreis-Take-Profit-Order ausgelöst, um die aktuelle Position zu schließen. Erwarteter Gewinn und Verlust",
        whenthelatest3: ", wird die Markt-Stop-Loss-Order ausgelöst, um die aktuelle Position zu schließen. Erwarteter Gewinn und Verlust",
        contract: "Vertrag",
        ordercanceledsuccessfully: "Bestellung erfolgreich storniert",
        successfullyordered: "erfolgreich bestellt",
        positionopeningreminder: "Erinnerung an die Positionseröffnung",
        wrongquantity: "Falsche Menge",
        quantitycannot: "Die Menge darf nicht größer sein als",
        modificationstoploss: "Änderung von Stop-Profit und Stop-Loss erfolgreich",
        positionclosedsuccessfully: "Position erfolgreich geschlossen",
        orderCancellation: "Auftragsstornierung",
        orderCancellation2: "Sind Sie sicher, dass Sie die Bestellung stornieren möchten?",
        tobeexecuted: "Ermordet werden",
        expired: "Abgelaufen",
        failed: "fehlgeschlagen",
        contractTransaction: "Unbefristeter Vertrag",
        increase: "Zunahme",
        openPosition: "Volles Lager",
        isolatedPosition: "Isolierte Lage",
        limitOrder: "Preislimit",
        marketOrder: "Marktpreis",
        pleaseSelectLeverage: "Bitte Hebelwirkung auswählen",
        pleaseEnterQuantity: "Bitte Menge eingeben",
        canOpenMore: "Bitte Menge eingeben",
        contractAmount: "Vertragssumme",
        openingFee: "Bearbeitungsgebühr",
        currentPosition: "Position",
        historicalPositions: "Historische Transaktionen",
        currentCommission: "Aktueller Auftrag",
        historicalCommission: "Aktueller Auftrag",
        buyPrice: "Kaufpreis",
        income: "profitieren",
        quantity: "Menge",
        marginRate: "Margensatz",
        initialMargin: "Ursprünglicher Abstand",
        estimatedPriceParity: "Zwangsliquidationspreis",
        operate: "arbeiten",
        finishQuantity: "Transaktionsvolumen",
        averagePrice: "durchschnittlicher Transaktionspreis",
        pleaseEnterLeverage: "Bitte geben Sie den Hebel ein",
        takeProfitAndStopLoss: "Gewinn mitnehmen und Verlust stoppen",
        takeProfit: "Nehmen Sie Gewinn mit",
        stopLoss: "Stop-Loss",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Geschlossene Position",
        marketLquidation: "Der Marktpreis ist flach",
        cancel: "Bestellung stornieren",
        enterBuyPrice: "Bitte geben Sie den Kaufpreis ein",
        areYouSureLquidation: "Sind Sie sicher, die Position zu schließen?",
        confirm: "Sicher",
        enterStopWinPrice: "Bitte geben Sie den Take-Profit-Preis ein",
        enterStopLossPrice: "Bitte geben Sie den Stop-Loss-Preis ein",
        commissionTime: "Provisionszeit",
        commissionPrice: "Provisionspreis",
        commissionQuantity: "Gesamtbetrag der Provisionen",
        volumeAlreadyTraded: "Betrag abgeschlossen",
        areYouSureToWinOrLoss: "Sind Sie sicher, dass Sie den Gewinn und den Verlust stoppen möchten?",
        maxQuantity: "Maximale Position",
        saleQuantity: "Position geschlossen",
        salePrice: "durchschnittlicher Schlusskurs",
        saleTime: "Zeit",
        succeeded: "gelungen",
        cancelled: "Abgesagt",
        piece: "offen",
        triggerPrice: "Auslösepreis",
        setWin: "Legen Sie Take-Profit fest",
        setLoss: "Stop-Loss festlegen",
        enterWinQuantity: "Bitte geben Sie den Take-Profit-Betrag ein",
        enterLossQuantity: "Bitte geben Sie den Stop-Loss-Betrag ein",
        planCommission: "Planungskommission",
        enterWinTriggerPrice: "Bitte geben Sie den Take-Profit-Triggerpreis ein",
        enterLossTriggerPrice: "Bitte geben Sie den Stop-Loss-Triggerpreis ein",
        insufficientQuantity: "Unzureichende Menge",
        enterTriggerPrice: "Bitte geben Sie den Auslösepreis ein",
        tips1: "Ihr Orderpreis ist höher als der aktuelle Preis und wird direkt zum aktuellen Marktpreis ausgeführt. Sind Sie sicher, dass Sie kaufen möchten?",
        tips2: "Ihr Orderpreis ist niedriger als der aktuelle Preis und wird direkt zum aktuellen Marktpreis ausgeführt. Sind Sie sicher, dass Sie kaufen?",
        type: "Typ",
        increaseQuantity: "Position erhöhen",
        reduceQuantity: "Stellen reduzieren",
        limitCommission: "Limit-Order",
        tips3: "Der Stop-Loss-Preis muss niedriger sein als der Marktpreis",
        tips4: "Der Take-Profit-Preis muss höher sein als der Marktpreis",
        tips5: "Der Stop-Loss-Preis muss höher sein als der Marktpreis",
        tips6: "Der Take-Profit-Preis muss niedriger sein als der Marktpreis",
        tips7: "Der Stop-Loss-Preis sollte niedriger sein als der Marktpreis und niedriger als der Orderpreis",
        tips8: "Der Take-Profit-Preis sollte größer sein als der Orderpreis (kann kleiner als der Marktpreis sein)",
        tips9: "Der Stop-Loss-Preis sollte über dem Marktpreis und über dem Orderpreis liegen",
        tips10: "Der Take-Profit-Preis sollte kleiner sein als der Orderpreis (kann größer als der Marktpreis sein)",
        accountEquity: "Kontostand",
        unrealizedPL: "nicht realisierter Gewinn oder Verlust",
        tips11: "Sind Sie sicher, dass Sie Recht haben?",
        tips12: "Marktpreis",
        tips13: "Schließen Sie alle Positionen",
        tips14: "Nicht noch einmal erinnern",
        profitRate: "Schlussrendite",
        openTime: "Öffnungszeit",
        unwindTime: "Geschäftsschluss",
        dealTime: "Transaktionszeit",
        amountClose: "Betrag der geschlossenen Position",
        long: "Kaufen",
        short: 'Verkürzung',
        orderQuantity: "Bestellmenge",
        entrustedMargin: "anvertraute Marge",
    },
    spotGoods: {
        spotGoods: "Tauschen",
        sellPrice: "Verkaufspreis",
        orderRecord: "Flash-Einlösungsdatensatz",
        transactionTime: "Transaktionszeit",
        maxBuy: "zum Kauf verfügbar",
        maxSell: "Steht zum Verkauf",
        purchaseQuantity: "Kaufmenge",
        slippage: "Schlupf",
        convertTips: "Wir schließen die Transaktion zum günstigsten Preis mit einer Slippage von 0-5 % ab.",
        changePrice: "Aktueller Börsenpreis",
        consume: "verbrauchen",
        get: "erhalten",
        changeTips: "Sind Sie sicher, dass Sie den Gutschein einlösen möchten?",
        confirm: "Bestätigen Sie die Einlösung"
    },
    news: {
        title: "Nachrichtenzentrum",
        redMore: "mehr lesen",
    },
    credit: {
        credit: "Kredit",
        creditScore: "Kreditpunktzahl",
        latestEntry: "Neuester Eintrag",
        liveUpdate: "Live-Update",
        systemEvaluation: "Systembewertung",
        time: "Datum",
        name: "Name",
        reason: "Grund",
        scoreStory: "Kredit-Score-Datensatz"
    },
    borrowing: {
        borrowing: "Darlehen",
        diversifiedMarket: "Diversifizierte Märkte",
        totalSupply: "Gesamtangebot",
        totalBorrowings: "Gesamtverbindlichkeiten",
        tips1: "Dies ist der Gesamtbetrag, den Sie bis zum Kreditlimit gegen Ihre Sicherheiten ausleihen können.",
        tips2: "Ihr stabiler Zinssatz bleibt während der gesamten Laufzeit Ihres Darlehens gleich. Empfohlen für Langzeitversorgung und Benutzer, die Vorhersehbarkeit mögen.",
        totalSupplys: "Gesamtangebot",
        supplyAPR: "Versorgungsrate",
        totalBorrowings: "Gesamtkreditaufnahme",
        borrowAPR: "Sollzins",
        check: "Überprüfen",
        market: "Markt",
        availableLiquidity: "Verfügbare Liquidität",
        currencyPrice: "aktueller Währungspreis",
        supplyAssets: "Versorgungsgüter",
        tips3: "Hinweis: Bei dem unten aufgeführten Zinssatz handelt es sich um einen Festzinssatz. Nach Ablauf der Lieferfrist wird der Kapitalbetrag zuzüglich der Erträge Ihrem Konto gutgeschrieben.",
        supplyCycle: "Versorgungszyklus",
        minimumSupply: "Mindestversorgung",
        supply: "liefern",
        borrowedAssets: "geliehene Vermögenswerte",
        tips4: "Wichtig zu wissen: Um Geld zu leihen, müssen Sie etwaige Sicherheiten oder Nachweise vorlegen,",
        tips5: "Bitte beachten Sie die Beschreibung des Hypothekenvermögens.",
        repaymentCycle: "Rückzahlungszyklus",
        BorrowableAmount: "Verfügbarer Kreditbetrag",
        borrow: "Darlehen",
        accountInformation: "Kontoinformationen",
        walletBalance: "Brieftaschenguthaben",
        canSupply: "Verfügbar",
        AmountCanBeBorrowed: "Verfügbarer Gesamtkreditbetrag",
        borrowedAmount: "Geliehener Betrag",
        remainingAmount: "Die verbleibende Summe",
        getBorrowednAmount: "Kreditlimit erhalten",
        supplyOrder: "Lieferauftrag",
        borrowedOrder: "Darlehensauftrag",
        supplyAmount: "Liefermenge",
        expectedProfits: "Erwartete Rückkehr",
        supplyTime: "Lieferzeit",
        expirationTime: "Ablaufdatum",
        borrowingAmount: "Kreditbetrag",
        dueRepayment: "Rückzahlungsbetrag",
        borrowingTime: "Veröffentlichkeitsdatum",
        repaymentTime: "Rückzahlungszeit",
        repay: "Rückzahlung",
        supplyCurrency: "Angebotswährung",
        buyNow: "Kaufe jetzt",
        supplyRecords: "Lieferunterlagen",
        borrowRecords: "Kreditunterlagen",
        completed: "vollendet",
        borrowNow: "Jetzt ausleihen",
        borrowCurrency: "Währung leihen",
        borrowMin: "am kleinsten",
        borrowMax: "maximal",
        borrowRange: "Kreditbetrag (Minimum/Maximum)",
        tips6: "Bitte zahlen Sie rechtzeitig zurück. Bei Überfälligkeit wird ein zusätzlicher täglicher Betrag (Kapital + Zinsen) berechnet.",
        tips7: "Überfälligkeitsgebühr.",
        pleaseEnterBorrowAmount: "Bitte geben Sie den Kreditbetrag ein",
        interest: "Interesse",
        tips8: "Sind Sie sicher, dass Sie die Rückzahlung im Voraus leisten möchten?",
        borrowChannel: "Ausleihkanal",
        tips9: "Beginnen wir damit, Sie kennenzulernen",
        name: "Name",
        lastName: "Nachname",
        birthDate: "Geburtsdatum",
        tips10: "Ein paar weitere Informationen, wie lautet Ihre Adresse?",
        familyAddress: "Heimatadresse (einschließlich Wohneinheitsnummer)",
        city: "Stadt",
        country: "Nation",
        postalCode: "Postleitzahl",
        pleaseEnterYourHomeAddress: "Bitte geben Sie Ihre Heimatadresse ein",
        tips11: "Erzählen Sie uns von Ihrer finanziellen Situation",
        tips12: "Möglicherweise müssen wir diese Informationen später bestätigen. Versuchen Sie daher, so genau wie möglich zu sein",
        yourMonthlyIncome: "Ihr monatliches Einkommen",
        enterYourMonthlyIncome: "Bitte geben Sie Ihr monatliches Einkommen ein",
        extraIncomeOptional: "Zusätzliches Einkommen (optional)",
        enterExtraIncome: "Bitte tragen Sie Ihr Nebeneinkommen ein",
        monthlyRentOrMortgagePayment: "Monatliche Miete oder Hypothekenzahlung",
        enterMonthlyRent: "Bitte geben Sie Ihre monatliche Miete oder Hypothekenzahlung ein",
        tip13: "Teilen Sie uns mit, ob Sie eine Hypothek haben",
        tip14: "Wird keine Hypothekenbürgschaft gewählt, ist die Wahrscheinlichkeit, die Prüfung zu bestehen, gering.",
        isThereMortgage: "Gibt es eine Hypothek?",
        selectIsThereMortgage: "Bitte wählen Sie aus, ob eine Hypothek besteht",
        selectMortgageType: "Bitte wählen Sie die Hypothekenart aus",
        yes: "Ja",
        no: "NEIN",
        uploadScreenshotOfTransaction: "Bitte laden Sie Transaktions-Screenshots hoch",
        tip15: "Lass uns in Kontakt bleiben",
        tip16: "Wir können Sie unter dieser Nummer kontaktieren, wenn wir weitere Informationen zu Ihrem Kreditantrag benötigen",
        tip17: "Ich habe meine Einwilligung gelesen und elektronisch unterschrieben",
        tip18: "Nutzungsbedingungen und elektronische Einwilligung",
        tip19: "Dazu gehört auch Ihre Einwilligung, Ihnen Informationen über uns zuzusenden",
        tip20: "Nutzungsbedingungen für Produkte und Dienstleistungen",
        tip21: "Nachdem Sie die Informationen eingegeben haben, warten Sie bitte auf die Bewertung und Überprüfung des Systems.",
        phoneNumber: "Telefonnummer",
        enterPhoneNumber: "Bitte geben sie ihre Telefonnummer ein",
        tip22: "Bitte aktivieren Sie das Kontrollkästchen, um den Nutzungsbedingungen und der elektronischen Einwilligungserklärung zuzustimmen",
        inReview: "in Prüfung",
        applicationAmount: "Gelten",
        reasonForRejection: "Grund für die Ablehnung",
        reapply: "Erneut bewerben",
        applyStatus: "Bewerbungsstatus",
        currentOrder: "aktueller Auftrag",
        interestRate: "Zinsrate"
    },
    yuebao: {
        filed1: "investieren",
        filed2: "Mitnahme",
        filed3: "Tägliches Einkommen",
        filed4: "Teambeschleunigung",
        filed5: "Hinterlegt",
        filed6: "Die Einnahmen von gestern",
        filed7: "Gesamtumsatz",
        filed8: "Verfügbares Vermögen",
        filed9: "Bitte geben Sie den Betrag ein",
        filed10: "Liquiditätsabbau",
        filed11: "Einführung",
        field12: "Mindesteinkaufspreis",
        field13: "Das Beste, was Sie kaufen können",
        filed14: "Abonnement",
        filed15: 'regulär',
        filed16: 'annualisierter Zinssatz',
    },
    tipsPopover: {
        title: "Installieren Sie diese Anwendung",
        press: "entsprechend",
        and: "Und",
        scrren: "Zum Startbildschirm hinzufügen",
        hasInstall: "(Falls installiert, schließen Sie bitte das Fenster)",
    },
    follow: {
        title1: "Folgeplan",
        follow_text1: "Veranlassen Sie eine Folgebestellung",
        follow_text2: "Veröffentlichungsverlauf",
        follow_text3: "Titel",
        follow_text4: 'Handelspaar',
        follow_text5: 'Kaufdauer',
        follow_text6: 'Bestellzeitpunkt',
        follow_text7: 'Veröffentlichkeitsdatum',
        follow_text8: 'Anzahl der Follower',
        follow_text9: 'Bestellbetrag',
        follow_text10: 'arbeiten',
        follow_text11: 'einladen',
        follow_text12: 'Folgen Sie der Bestellanweisung',
        follow_text13: 'Bestellbedingungen festlegen',
        follow_text14: 'Bedingungen für folgende Bestellungen',
        follow_text15: 'fester Betrag',
        follow_text16: 'Prozentsatz',
        follow_text17: 'Eingeladene Leute',
        follow_text18: 'Die ID der anderen Partei',
        follow_text19: "Bestätigen Sie, um der Bestellung zu folgen",
        follow_text20: "Ihr Bestellbetrag (optional)",
        follow_text21: "Bitte geben Sie einen Titel ein",
        follow_text22: "Bitte wählen Sie ein Handelspaar aus",
        follow_text23: "Bitte wählen Sie die Bestellrichtung aus",
        follow_text24: "Bitte wählen Sie die Kaufdauer aus",
        follow_text25: "Bitte wählen Sie die Bestellzeit aus",
        follow_text26: "Bitte Kaufmenge auswählen",
        follow_text27: "Die maximale Einkaufsmenge darf nicht kleiner sein als die minimale Einkaufsmenge",
        follow_text28: "Mindesteinkauf",
        follow_text29: "Die meisten kaufen",
        follow_text30: "Kopierauftrag erfolgreich erstellt",
        follow_text31: "Liste der Handelspaare",
        follow_text32: "Habe die Bestellung bereits befolgt",
        follow_text33: "Bitte geben Sie die Informationen zum eingeladenen Teilnehmer ein",
        follow_text34: "Eingeladen",
        follow_text35: "hat mich eingeladen",
        follow_text36: "Geschichte kopieren",
        follow_text37: "Bestellmengenbereich"
    },
    followHistory: {
        title1: 'Veröffentlichungsverlauf',
        title2: "Geschichte kopieren",
        follow_h_text1: "Der Gesamtbetrag der Bestellung",
        follow_h_text2: "Gesamtzahl der Personen, die Befehle befolgen",
        follow_h_text3: "Anzahl der Veröffentlichungen",
        follow_h_text4: "Gewinnquote",
        follow_h_text5: "Häufigkeit, mit der Befehlen Folge geleistet wird",
        follow_h_text6: "Profitieren Sie von Folgeaufträgen",
        follow_h_text7: "Richtung",
        follow_h_text8: "gewinnen",
        follow_h_text9: "Defizit",
    },
    "asset.index.1579E8B41F8D5134": "mein Vermögen",
    "asset.index.B3F6F05Dfbe431Cd": "Simulation",
    "asset.index.B9Aeab4Ee8F33746": "Geschätzter Gesamtwert",
    "asset.index.583Df8Bd541Eb7E8": "Ertragstrends",
    "asset.index.B1254815D133Cc26": "7 Tage",
    "asset.index.2F615F6C029F01B7": "30 Tage",
    "asset.index.29439Fb58E4Bbe22": "Abheben",
    "asset.index.831Ab31568A78285": "Einzahlen",
    "asset.index.39F7A0D48D12E8C9": "überweisen",
    "asset.index.B99063C5689Beaec": "Tauschen",
    "asset.index.E1Dc620F2F038B2A": "erhalten",
    "asset.index.972A0B4C2B4393D0": "mein Konto",
    "asset.index.9Dfb0Fb334620733": "Verfügbar",
    "asset.index.Cdc5Fbd26790Cb47": "gefrorene Menge",
    "asset.index.3D8132D72Ae965Cd": "Bewertung",
    "asset.receive.825503Cc430559Cb": "Vermögenswerte erwerben",
    "asset.receive.54154A0553A535B7": "Demo-Assets",
    "asset.receive.F2E57B00A9C5D129": "1.Dieses Guthaben darf nur im Demokontomodus genutzt werden",
    "asset.receive.Ea0D1D52989299Bd": "2.Um Missbrauch vorzubeugen, ist nur eine Einlösung innerhalb eines Monats zulässig.",
    "asset.receive.090E0037Aa235D3D": "3.Durch den Erhalt von Vermögenswerten wird der Betrag der ursprünglich erhaltenen Vermögenswerte addiert und der Positionsstatus nicht geändert.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Bei weiteren Fragen wenden Sie sich bitte an den Kundenservice",
    "asset.receive.Df5B3A511D986575": "Holen Sie sich jetzt Demo-Assets",
    "asset.records.1Fa02E8187A39592": "letzten drei Monaten",
    "asset.records.7B291C37333Fe34D": "von nah nach fern",
    "asset.records.A476Cd1Ec868Fc5F": "letzten drei Monaten,",
    "asset.records.9D5755A842E55Bb7": "von nah nach fern,",
    "asset.records.E3Fa05E607203Cdc": "Einzahlen",
    "asset.records.B4F206C6Bec08Dea": "Abheben",
    "asset.index.Df5B3A511D986576": "Erfolgreich empfangen",
    "deposit.depositSpeedy.Efe81D0E0174239B": "Bezahlverfahren",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Fiat-Währung",
    "deposit.depositSpeedy.16658A855F07D171": "Menge",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Sicher",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Kann nicht leer sein",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Minimale Aufladung",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Maximale Aufladung",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Zahlen Sie USDT ein",
    "deposit.depositWallet.551640C76F4Ca4A1": "Währung aufladen",
    "deposit.depositWallet.B404C543712B71D5": "Aufladenetzwerk",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Einzahlungsadresse",
    "deposit.depositWallet.D3980D54Acf242Db": "QR-Code speichern",
    "deposit.depositWallet.A06E84801D6582Be": "Aufladebetrag",
    "deposit.depositWallet.Daaaeb1B7B22B126": "Hinweis",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Aufladen erfolgreich",
    "deposit.depositWallet.4F48Abbafce532Bb": "Erfolgreich übermittelt, wartet auf die Bewertung durch den Kundenservice",
    "deposit.depositWallet.A88C481228811696": "Sie haben es abgelehnt, das Netzwerk zu wechseln",
    "deposit.depositWallet.C6Ebf27895C21932": "Bitte öffnen Sie das TRON-Wallet-Plug-in manuell und melden Sie sich an",
    "deposit.index.B5491C8Dd09D8183": "Wählen Sie die Währung aus, die Sie aufladen möchten",
    "deposit.index.2F784B2298D39987": "Schnelles Aufladen",
    "deposit.index.0Daa03Dcdcabd837": "Offsite-Links",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Auszahlungsbestätigung",
    "withdraw.bottomSheet.A086Fa20082C849D": "Bitte stellen Sie sicher, dass die folgenden Informationen korrekt sind",
    "withdraw.bottomSheet.Bf8B184D32971501": "Auszahlungsnetzwerk",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "Adresse",
    "withdraw.bottomSheet.F9Db93B87E08763B": "Menge",
    "withdraw.bottomSheet.4Ad26475002878C0": "Bearbeitungsgebühr",
    "withdraw.index.A96454041169Af6D": "Währung wählen",
    "withdraw.index.2A563B7081Dd5B83": "Auszahlungsadresse",
    "withdraw.index.B189C2Ef713Cfff4": "Anzahl der abgehobenen Münzen",
    "withdraw.index.558F35140E84B562": "Verfügbar：",
    "withdraw.index.F4E759087E12Cb77": "Alle zurückziehen",
    "withdraw.index.5Cbd9A6B284D25Ae": "Passwort für den Fonds",
    "withdraw.index.B89Cbe4Bfc33Bb41": "Bearbeitungsgebühr:",
    "withdraw.index.C886De8D8A27026E": "Wichtiger Hinweis",
    "withdraw.index.Eac7C5A4095F5606": "1.Um Arbitrage zu verhindern, können Sie eine Währungsabhebung beantragen, wenn das Transaktionsvolumen das Kontingent erreicht.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.Der Auszahlungsantrag wird innerhalb von 24 Stunden nach Einreichung gutgeschrieben. Wenn die Auszahlung nicht nach der erwarteten Auszahlungszeit eingeht, wenden Sie sich bitte an den Online-Kundendienst.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Nach Einreichung des Auszahlungsantrags sind die Gelder eingefroren, da die Auszahlung im Gange ist und die Gelder vorübergehend vom System zurückgehalten werden. Dies bedeutet nicht, dass Sie den Vermögenswert verloren haben oder dass eine Anomalie mit dem Vermögenswert vorliegt.",
    "withdraw.index.4639F3A6E07C00B3": "Nächster Schritt",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Bitte geben Sie Ihre Wallet-Adresse ein oder fügen Sie sie ein",
    "withdraw.index.25768141C8Bdea91": "Bitte geben Sie den Auszahlungsbetrag ein",
    "withdraw.index.97A85734Cd1B7Bf0": "Bitte geben Sie das Fondspasswort ein",
    "withdraw.index.39C7D74B33241C78": "Der Auszahlungsbetrag ist falsch",
    "withdraw.index.86A5D17908197525": "Auszahlungsbestätigung, warten auf Überprüfung, um Münzen freizugeben",
    "withdraw.index.48Fb603C6638C7F6": "Bitte legen Sie ein Fondspasswort fest",
    "withdraw.index.873Ade9A3Bacc2F7": "Hinweis",
    "bill.index.5Bbb2A6Ac68D6B2E": "tatsächliches Konto",
    "bill.index.75751E0Eb8261C40": "Demokonto",
    "bill.index.Cbc853B37Ecc2E97": "Art der Kontoänderung",
    "bill.index.4A0Cacddeb2E38B1": "Konto Typ",
    "bill.index.F16217028E098B2F": "Währung",
    "bill.index.A620Dbe6F06B78Ba": "Alle Arten",
    "bill.index.2C3C148Bb94D9B3F": "Alle Konten",
    "c2C.index.Ab45695E4Bf1E382": "C2C-Transaktion",
    "c2C.index.2702Dc911Dfea762": "ich möchte kaufen",
    "c2C.index.D737D5B8367A53C4": "Ich möchte verkaufen",
    "c2C.index.C0C540A3Ba9Abe01": "Volumen",
    "c2C.index.D8644E8D60C8Bd8A": "Einzelpreis",
    "c2C.index.Ebb8670B203D3604": "Grenze",
    "c2C.index.F8C6245A2Ebf1D27": "Alle Bankkarten",
    "c2C.index.F8C6245A2Ebf1D28": "Währungseinzug",
    "c2C.index.F8C6245A2Ebf1D29": "Bankkarte",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Kaufen",
    "c2C.index.Cc3Aa8141Da9Ec9F": "verkaufen",
    "c2C.index.67A39834582Ad99A": "Keine Daten",
    "c2C.order.04C23810B47F77F7": "nach Betrag",
    "c2C.order.6905365902E0A73D": "nach Menge",
    "c2C.order.3F0Ecebbd9D2F23B": "Verfügbar",
    "c2C.order.16853Bda54120Bf1": "alle",
    "c2C.order.Ad3335796F2C95F7": "Pauschalbetrag",
    "c2C.order.61092E27A6879318": "Um die Sicherheit von Vermögenswerten zu schützen, stärken Sie bitte Ihr Bewusstsein für Prävention!",
    "c2C.order.0F51881F0Fb920B2": "Handelsinformationen",
    "c2C.order.Dd74Ceca85E5Bc65": "Zahlungsfrist",
    "c2C.order.De3341388A59073F": "30 Minuten",
    "c2C.order.D334B597A352167F": "Spitzname des Verkäufers",
    "c2C.order.D334B597A352167G": "Spitzname des Käufers",
    "c2C.order.9D5F89F99Ced890D": "Mittel der Transaktion",
    "c2C.order.8Adf755B14Bc6B7C": "Bitte lesen Sie zuerst Folgendes",
    "c2C.order.686B16F454A7338B": "银Machen Sie bei Bankkartenüberweisungen keine Bemerkungen, da sonst das Geld nicht freigegeben wird und das Konto direkt gesperrt wird. Nach der Zahlung müssen Sie ein neues Diagramm mit den Transaktionsdetails bereitstellen (wenn das P-Diagramm oder versteckte Transaktionsdetails an die Plattform gemeldet werden, um das Konto einzufrieren).",
    "c2C.order.9Bec8Fba867B739B": "Wählen Sie die Zahlungsmethode",
    "c2C.order.Cf2D892C419Fea36": "Mindestabnahme",
    "c2C.order.7290A6B7456A03E0": "Größter Kauf",
    "c2C.orderConfirm.F7E99B8C203E2B07": "Bestelldetails",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Auftrag wurde generiert",
    "c2C.orderConfirm.0F5433503F3845Ce": "Bitte bezahlen Sie den Verkäufer innerhalb der Countdown-Zeit.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Warten auf die Zahlung des Käufers ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "Verkäufer kontaktieren",
    "c2C.orderConfirm.8E8140D7Ab607B25": "Verkäufer kontaktieren",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "Die digitale Währung des Verkäufers ist nun auf dem Treuhandkonto hinterlegt, Sie können gerne bezahlen  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Echter Name des Käufers：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "7x24-Stunden-Kundendienst ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "Bestellnummer",
    "c2C.orderConfirm.726C51702F70C486": "Erstellungszeitpunkt",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Kontaktieren Sie den Kundendienst",
    "c2C.orderConfirm.103408Fbbe30287F": "Bestellung stornieren",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Ich habe die Zahlung bestätigt",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Bereits bezahlt",
    "c2C.orderConfirm.387Ca3625B260E4E": "Ihre bezahlte Bestellung",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "Transaktion abbrechen",
    "c2C.orderConfirm.76C86566Cc3751C0": "Bestellung storniert",
    "c2C.orderConfirm.205B349751D176B4": "Bezahlung erfolgreich",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "Bezahlverfahren",
    "c2C.paymentAdd.C87E865Df6800304": "Konfiguration der Zahlungsmethode",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Art der Zahlungsmethode",
    "c2C.paymentAdd.06910Bddf002695F": "tatsächlicher Name",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Paypal-Konto",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Bankkontonummer/Kartennummer",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Währungsname",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "QR-Code bezahlen",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Klicken Sie zum Hochladen",
    "c2C.paymentAdd.6Daae340F2E9718A": "Bank Name",
    "c2C.paymentAdd.347751415C3Da489": "Kettenname",
    "c2C.paymentAdd.15B3627Faddccb1D": "Anmerkung",
    "c2C.paymentAdd.86Da96Cbd274058F": "Hinweis: Wenn Sie digitale Währungen verkaufen, wird dem Käufer die von Ihnen gewählte Zahlungsmethode angezeigt. Bitte bestätigen Sie, dass die Informationen korrekt ausgefüllt sind.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "speichern",
    "c2C.paymentAdd.90D2A3A5C07179C0": "Bitte geben Sie Ihren richtigen Namen ein",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Bitte geben Sie Ihre ein",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Bitte geben Sie Ihren Banknamen ein",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Bitte geben Sie Ihren Kettennamen ein",
    "c2C.paymentAdd.94F034F0E01439E0": "Bitte geben Sie Ihre Adresse ein",
    "c2C.paymentAdd.B12E035663A42B32": "Alle Bankkarten",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "Währungseinzug",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Bitte laden Sie den Zahlungscode hoch",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Erfolgreich hinzugefügt",
    "c2C.paymentDetail.86Da96Cbd274058F": "Hinweis: Wenn Sie digitale Währungen verkaufen, wird dem Käufer die von Ihnen gewählte Zahlungsmethode angezeigt. Bitte bestätigen Sie, dass die Informationen korrekt ausgefüllt sind.",
    "c2C.paymentDetail.C4C613Da60226De4": "Erfolgreich geändert",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C-Zahlungsmethode",
    "c2C.records.9E36893D5736Abb4": "Bestellliste",
    "c2C.records.4B53F9A5B13B3235": "Ausstehende Zahlung",
    "c2C.records.4B53F9A5B13B3236": "Transaktion abbrechen",
    "c2C.records.4B53F9A5B13B3237": "Bereits bezahlt",
    "contract.index.9706Df877458F0Be": "Volles Lager",
    "contract.index.623Fc7D3D40Aa479": "Ursprünglicher Abstand",
    "contract.index.F92Dce1169330387": "Der Marktpreis ist flach",
    "contract.index.3598B97Dd28Dbf20": "Bitte geben Sie die Menge (Stück) ein",
    "contract.index.6E1Fae821Dc0B797": "Nachhaltig",
    "contract.index.Eb07Eb0C37C14575": "Lieferung",
    "contract.index.Eb6492F19B1C1Fb6": "Historische Transaktionsdetails",
    "contract.index.873Ade9A3Bacc2F7": "Hinweis",
    "contract.index.Ff63B0F93D39F50B": "Sind Sie sicher, dass Sie Recht haben?",
    "contract.index.Ff63B0F93D39F50D": "Geh lang",
    "contract.index.Ff63B0F93D39F50E": "kurz",
    "contract.index.Ff63B0F93D39F50F": "Benehmen",
    "contract.index.Ff63B0F93D39F50G": "Alle Positionen schließen?",
    "customer.dialog.3D410C88E8A24Fe8": "Nickname bestätigen",
    "customer.dialog.4B3340F7872B411F": "Stornieren",
    "customer.dialog.549B1456Bb5F98B3": "Bitte geben Sie ihren Spitznamen ein",
    "customer.dialog.B773B3E45Ccfad00": "Spitzname ist leer",
    "customer.index.7308C6B849D159E9": "Bilddetails",
    "delivery.callDialog.C74F562C3Fe91Fe3": "erfolgreich bestellt",
    "delivery.callDialog.B8C98F42Caa15Ca5": "Richtung",
    "delivery.callDialog.B79A581Dae543974": "lange kaufen",
    "delivery.callDialog.B79A581Dae543975": "Kurzkauf",
    "delivery.callDialog.3B26F0F7E90773F3": "Eröffnungskurs",
    "delivery.callDialog.65Fb252Bd94E1039": "Geschätzter Umsatz",
    "delivery.index.B483Ba4Fce633539": "Lieferung",
    "delivery.index.224Dd6Cb58D58249": "Handelszeitraum",
    "delivery.index.28158F517E902788": "Mindesteinkauf",
    "delivery.index.A1232162B14Bec76": "Lieferauftrag",
    "delivery.index.445B1D3176D9C69D": "Historische Orden",
    "delivery.index.Ab6Cb86E28653C5C": "Lieferung",
    "delivery.index.20A7A6F14D3698C0": "Öffnungszeit",
    "delivery.index.Ec6821A49395Ddc2": "Geschäftsschluss",
    "delivery.index.254Fdc665C391743": "Schlusskurs",
    "delivery.index.2A0C5E68B44A472A": "Gewinn-und Verlust",
    "delivery.index.E05B33309F6D236A": "Bitte Menge eingeben",
    "delivery.index.B2899E073Cb355D9": "Mindestkauf",
    "download.description.869467A93C7Dd4A1": "Installationshinweise",
    "download.description.C80C1Edd6Bee12Bc": "1.Erlauben Sie das Herunterladen von Beschreibungsdateien",
    "download.description.2F5Bc180F92642B4": "2.Beschreibungsdatei heruntergeladen",
    "download.description.3C3B849F6Ccf712F": "3.Geben Sie auf Ihrem Telefon [Einstellungen] ein und die heruntergeladene Beschreibungsdatei wird unter den Kontoinformationen angezeigt.",
    "download.description.86Affd862985E045": "4.Klicken Sie hier, um die Beschreibungsdatei herunterzuladen",
    "download.description.32832492Bfc1D95E": "5.Klicken Sie zum Installieren",
    "download.index.245883C80F181C4A": "herunterladen",
    "download.index.Ef3De8Cb2722937A": "Beginnen Sie jederzeit und überall mit dem Handel",
    "download.index.F4A87Eaeed691638": "Bleiben Sie über unsere APP mit den neuesten Nachrichten auf dem Laufenden",
    "download.index.Ad3Aba52D0D39D01": "Laden Sie das Installationspaket herunter",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Freundliche Tipps",
    "download.safariGuide.2Cc68Aaadf623477": "Bitte besuchen Sie Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Sie besuchen eine dezentrale Website. Bitte starten Sie die Safari-Anwendung",
    "download.safariGuide.06Da016F3E2F79Bd": "Link kopieren",
    "finance.index.B034B367545D5236": "Globale, qualitativ hochwertige Projekte",
    "finance.index.6Ebfac2C0A9228B0": "Über 1,6 Millionen aktive Nutzer, 122,4 Milliarden US-Dollar",
    "finance.index.52Bdcaf19659363D": "Liquiditätsabbau",
    "finance.index.D66924272B031Bf5": "Super hohes Jahreseinkommen",
    "finance.index.34789D88Afcefa64": "Artikel ansehen",
    "finance.index.E12676B95654D21E": "Kreditdarlehen",
    "finance.index.4C924Cfd89A11Ac7": "Entdecken Sie weitere Handelsmöglichkeiten",
    "finance.index.C251D4B594Da2848": "AI",
    "finance.index.4E53Cf2E5F173Dd3": "One-Click-Leasing, intelligente KI-Transaktion",
    "finance.records.Cce168301447D1Ba": "im Gange",
    "finance.records.4587B6Bd7Cfa4454": "eingelöst",
    "finance.records.18685418E3A50147": "Bergbau",
    "finance.records.Df9Fd438F97Cc908": "Himmel",
    "finance.records.E23Cb03C5Ad359E5": "der Begriff",
    "finance.records.Bd87Dac6Cba7Eba8": "Kaufmenge",
    "finance.records.2D0Fff99265E8B1A": "Profitabel",
    "finance.records.2D0Fff99265E8B1B": "Gewinn bei Fälligkeit",
    "finance.records.024F5F0B851782De": "Bergbauzeit：",
    "finance.records.3143A26D3F983896": "Zeit der Erlösung：",
    "finance.records.96574Aaf8Db85Ead": "vorzeitige Rückzahlung",
    "finance.records.531E1829203181Ac": "Sind Sie sicher, dass Sie den Gutschein vorzeitig einlösen möchten?",
    "finance.records.9205843B8923Dac3": "Einlösung erfolgreich;",
    "loan.applyCallback.B9258Ec8746A74D8": "Ausleihkanal",
    "loan.applyForm.7B8Ed340400A6E2B": "Name",
    "loan.applyForm.549A44Bae725F4A2": "Nachname",
    "loan.applyForm.2A27E61375E7Fea8": "Geburtsdatum",
    "loan.applyForm.89925B019D537738": "E-Mail-Adresse",
    "loan.applyForm.Afaeb32Cb4D62E28": "Heimatadresse (einschließlich Wohneinheitsnummer)",
    "loan.applyForm.E05B5D049B64B040": "Stadt",
    "loan.applyForm.B166E4E8Fe9513Fa": "Enter verification code",
    "loan.applyForm.83Aaede109E395F8": "Postleitzahl",
    "loan.applyForm.647Ed21332A4968B": "Ihr monatliches Einkommen",
    "loan.applyForm.Cf0A9B845D34D4B4": "Zusätzliches Einkommen (optional)",
    "loan.applyForm.7048F618Acc6A54C": "Monatliche Miete oder Hypothekenzahlung",
    "loan.applyForm.15C1Bba18Ae18545": "Teilen Sie uns mit, ob Sie eine Hypothek haben",
    "loan.applyForm.3921E90625A84E36": "Wird keine Hypothekenbürgschaft gewählt, ist die Wahrscheinlichkeit, die Prüfung zu bestehen, gering.",
    "loan.applyForm.262Eec4F8906B0F6": "Gibt es eine Hypothek?",
    "loan.applyForm.8634Eb21B6910F21": "Bitte wählen Sie die Hypothekenart aus",
    "loan.applyForm.A4897B3A331B5E61": "Transaktionsgutschein",
    "loan.applyForm.6623384C683De457": "Lass uns in Kontakt bleiben",
    "loan.applyForm.A8360Bdba86834Cb": "Wir können Sie unter dieser Nummer kontaktieren, wenn wir weitere Informationen zu Ihrem Kreditantrag benötigen",
    "loan.applyForm.C1C373F385A4C7B3": "Telefonnummer",
    "loan.applyForm.1B9785Badb91191F": "Nachdem Sie die Informationen eingegeben haben, warten Sie bitte auf die Bewertung und Überprüfung des Systems.",
    "loan.applyForm.8755B9C761D4080C": "Ich habe meine Einwilligung gelesen und elektronisch unterschrieben",
    "loan.applyForm.3E987B787468148C": "Nutzungsbedingungen und elektronische Einwilligung",
    "loan.applyForm.Bc54E76B61C50Fb9": "Bitte lesen Sie die Vereinbarung und stimmen Sie ihr zu",
    "loan.applyForm.89E158384F634Eb8": "zurückkehren",
    "loan.applyForm.Efa5A4548E2F3510": "Heimatadresse",
    "loan.applyForm.4Cadf1787541B35D": "Ja",
    "loan.applyForm.Fab408D2F4100447": "NEIN",
    "loan.applyForm.3Bad7A307480Cc56": "Das E-Mail-Format ist falsch.",
    "loan.applyForm.306322F49B36855D": "Der Antrag wurde eingereicht. Bitte warten Sie auf die Überprüfung durch den Kundendienst",
    "loan.applyForm.3C6E0E858C995Ff9": "Bitte laden Sie den Transaktionsbeleg hoch",
    "loan.applyForm.2Bf443476B271376": "Es können bis zu 4 Bilder hochgeladen werden",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Ausleihkanal",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Kreditlimit erhalten",
    "loan.applyQuota.9E0780F9352C7273": "Brieftaschenguthaben",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Verfügbarer Gesamtkreditbetrag",
    "loan.applyQuota.033A05462195B080": "Geliehener Betrag",
    "loan.applyQuota.9Aa89B036Ef4821D": "Die verbleibende Summe",
    "loan.applyQuota.5656Fb52Ef17A557": "Kreditlimit erhalten",
    "loan.applyQuota.6Ff6702B4C917D40": "in Prüfung",
    "loan.applyQuota.6Ff6702B4C917D41": "abgelehnt",
    "loan.applyQuota.6Ff6702B4C917D42": "gelungen",
    "loan.applyQuota.0Bad0E857Ea4A952": "Bewerbungszeit",
    "loan.applyQuota.3E7E56E2De2Cc546": "Antragsbetrag",
    "loan.applyQuota.8F1D170D9E54Af5B": "Bewerbungsfortschritt",
    "loan.applyQuota.6167Ca035B93Caf3": "Grund",
    "loan.applyQuota.410C7954F9A3981D": "Erneut bewerben",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Bitte wählen Sie die Währung aus",
    "loan.index.4C10307B22E885C3": "Entdecken Sie mehr Handelsmöglichkeiten und schaffen Sie Wohlstand",
    "loan.index.Ae8D37Dea2B3717B": "Zinsrate",
    "loan.index.1Bb54625682E85Db": "Regeleinführung",
    "loan.index.5Bd31Fab681C6E9D": "Mehr sehen",
    "loan.index.111F95F404814327": "Kreditwährung",
    "loan.index.6Bc2723B3Bec106D": "Darlehen",
    "loan.index.3200106280E6E370": "Zinsrate",
    "loan.index.049A6Ccb6C81A635": "Kreditbetrag (min./max.)",
    "loan.productDetails.5037Ad86Ab4B7E02": "Darlehen",
    "loan.productDetails.37181C74559Db182": "Befehl ",
    "loan.productDetails.5802Aaf06A1E4755": "Liquidität",
    "loan.productDetails.89Aea9Cf9337C40F": "Ausleihende Menge",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Menge fällig",
    "loan.productDetails.C65F329Ef14C4A3D": "Betrag der geliehenen Münzen",
    "loan.productDetails.3A74667815Adc8Bf": "maximal",
    "loan.productDetails.Ab851D8351573227": "Himmel",
    "loan.productDetails.Ddf82D8A2C8F46A0": "Rückzahlungszyklus",
    "loan.productDetails.C105E5562005Aeb1": "Himmel",
    "loan.productDetails.3F346Be2137F0756": "Kreditbetrag (min./max.)",
    "loan.productDetails.2707F78Df864E194": "Interesse",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Bitte zahlen Sie rechtzeitig zurück. Bei Überfälligkeit wird ein zusätzlicher täglicher Betrag (Kapital + Zinsen) berechnet.",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% ist eine Überfälligkeitsgebühr",
    "loan.productDetails.4D94Faa033Be7356": "Ich habe zugestimmt",
    "loan.productDetails.62429A957A22780A": "Servicevereinbarung zum Münzverleih",
    "loan.productDetails.Bc54E76B61C50Fb9": "Bitte lesen Sie die Vereinbarung und stimmen Sie ihr zu",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Jetzt ausleihen",
    "loan.productDetails.59246A0Bf081D398": "am wenigsten",
    "loan.productDetails.Ec3F1172B71Cd36C": "Mindestbetrag",
    "loan.productDetails.7Ccc4Cf1D81930B3": "größte Menge",
    "loan.productDetails.071488598990Aa68": "Bitte lesen Sie die Vereinbarung und stimmen Sie ihr zu;",
    "loan.productDetails.579Dd216D3339119": "Der Kredit war erfolgreich;",
    "loan.records.4951B36Fd0D2A831": "Kredit- und Kredithistorie",
    "loan.records.Bb0Aaa30514B7Cba": "derzeitiger Rekord",
    "loan.records.0F8B0A7D80769051": "Rückzahlungshistorie",
    "loan.records.D4B42E43D88De6F1": "Geschichtsaufzeichnung",
    "loan.records.Cb1847D6281897E6": "Kreditbetrag",
    "loan.records.C7Bfcacfc2Adea58": "Rückzahlungsbetrag",
    "loan.records.8B369164D5Fea340": "Leihdauer:",
    "loan.records.Ab2402Ad3Ffd35E7": "Darlehen vorzeitig abbezahlen",
    "loan.records.B7A0Ca0C195D0Cd1": "Veröffentlichkeitsdatum：",
    "loan.records.42014Bdcfd9C411B": "Rückzahlungszeit：",
    "loan.records.A0Acfaee54F06458": "vollendet",
    "loan.records.67A39834582Ad99A": "Keine Daten",
    "loan.records.6A687Ff5B8F1598F": "Sind Sie sicher, dass Sie die Rückzahlung im Voraus leisten möchten?",
    "loan.records.7C20D7008D0Eae86": "Rückzahlung erfolgreich",
    "mining.bottomSheet.Ba91637D32Ccba04": "Liquiditätsabbau",
    "mining.bottomSheet.7De3D636A165E008": "Rendite",
    "mining.bottomSheet.F3003345B189D683": "Bergbauzeit",
    "mining.bottomSheet.3E4C0A8C533F5848": "Münztyp",
    "mining.bottomSheet.Adcba368993Ae65E": "Abonnementbetrag (Minimum/Maximum)",
    "mining.bottomSheet.94743B24E4Ed4166": "Unbegrenzt",
    "mining.bottomSheet.286944Fedd211257": "Verfügbares Vermögen",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Geschätzter Umsatz:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Meins jetzt",
    "mining.bottomSheet.Bb1493C567C114Dd": "Freundliche Tipps：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Gebühr für vorzeitige Rücknahme",
    "mining.index.8B2Bba1422Eed6F0": "Schaffen Sie zig Milliarden Vermögen, 100 % Nettoeinkommen",
    "mining.index.42997E300086Ee89": "höchste Rendite",
    "mining.index.510F74F42052Ecce": "Bergbauprodukte",
    "mining.index.B853F96626106912": "Mining",
    "mining.productList.37181C74559Db182": "Befehl",
    "mining.productList.Aac5B810004C46C8": "regulär",
    "mining.productList.D2Fb4F78C387D888": "Sie haben erfolgreich abgebaut",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Bestellung bestätigen",
    "quantify.bottomSheet.6915303B698303B1": "Roboter",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Mietbetrag",
    "quantify.bottomSheet.Fbd931286Be387Aa": "Zyklus",
    "quantify.bottomSheet.9Ad84Db481807844": "unbegrenzt",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Tägliche Rendite",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "Datum der Wertstellung",
    "quantify.bottomSheet.88008603Ba7Bde64": "Kauf erfolgreich",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Erhalten Sie ab sofort eine Umsatzbeteiligung！",
    "quantify.bottomSheet.C778A674C4A054F9": "prüfe die Reihenfolge",
    "quantify.bottomSheet.685Dad322B7029B0": "Rückgabetransaktion",
    "quantify.buyForm.Acf8560A04334E95": "Kaufpreis",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Überblick",
    "quantify.buyForm.8788Df2B625E97B5": "Roboter",
    "quantify.buyForm.50Ca63B45E881D50": "Zeitpunkt der Dividendenzahlung",
    "quantify.buyForm.Fcda03Adbc5Fca97": "täglich",
    "quantify.buyForm.Afb89504Fc399189": "Bitte geben Sie die Abnahmemenge ein",
    "quantify.buyForm.1435Afdfb4Ec0596": "Mindestabnahmemenge",
    "quantify.buyForm.1B97F1730Bacd839": "Maximale Einkaufsmenge",
    "quantify.index.32F56C9B7D0E250A": "Zeitraum: unbestimmt",
    "quantify.index.E34F028D00904630": "Preis",
    "quantify.index.E34F028D00904631": ", tägliche Rendite",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Treuhandbetrag",
    "quantify.productDetail.53635B53Bca31Dfa": "Geschätztes Tageseinkommen",
    "quantify.productDetail.F9E46449A6269E09": "Kumuliertes Einkommen",
    "quantify.productDetail.98Fc46B1B8C993B2": "Hosting bestellen",
    "quantify.productDetail.93624Bb045830966": "Jetzt mieten",
    "quantify.records.0C16A18C32B9Cf4A": "Bestelldatensatz",
    "quantify.records.B191F5614B0E2312": "Kaufhistorie",
    "quantify.records.6A06033B86A73F7C": "Einlösungsprotokoll",
    "quantify.records.45E0F7E71611618E": "Kumuliertes Einkommen",
    "quantify.records.45E0F7E71611618F": "Gewinn bei Fälligkeit",
    "quantify.records.Aae1201E38C4Baaa": "Läuft",
    "flash.index.C41Fd4F2Fdfa594B": "verbrauchen",
    "flash.index.1Ac59Fa2218E3B00": "Verfügbar:",
    "flash.index.Bf7233A660821549": "erhalten",
    "flash.index.548E775Dd67C7472": "Umtausch bestätigen",
    "flash.index.066A2449Bd616F03": "Bitte geben Sie die Einlösemenge ein",
    "flash.index.Fca26Fcf9C06563F": "Flash-Einlösung erfolgreich",
    "message.index.40031524233F4564": "Nachrichtenzentrum",
    "message.index.8E5586210Fd44D69": "Ständige Positionserinnerung",
    "message.index.Bcd3F470E88C197D": "Noch keine Benachrichtigung",
    "message.index.23A557Fbce545879": "Dauerhafte Liquidationsmitteilung",
    "message.index.B5E19B36B5125550": "Auszahlungserinnerung",
    "message.index.716A981465138334": "Aufladeerinnerung",
    "message.index.884474Ebff2977Ba": "System Information",
    "message.index.08Cc7D24752Ec42C": "Onlineservice",
    "message.index.1D820A6A19Ab025D": "7 x 24 Stunden Online-Handbuchservice",
    "more.index.29881E0B5Df58Bf7": "Mehr Funktionen",
    "more.index.C77Ee35911D10D9D": "Gemeinsame Funktionen",
    "more.index.3558F69B0260Be61": "Handelsfunktion",
    "more.index.7243Fe610Ed98Cec": "echter Account",
    "more.index.7243Fe610Ed98Ced": "Demokonto",
    "more.index.693C7B35Bdf3E3A3": "Finanzen",
    "more.index.F855997Fac114554": "Hilfezentrum",
    "more.index.Ae9A1252278Da742": "helfen",
    "more.index.Ace012504Bc529E0": "Information",
    "more.index.05682Dbef5Eeb0A8": "Kundendienst",
    "more.index.C7Af227A5D5C7Bb9": "Sie haben zu einem Live-Konto gewechselt",
    "more.index.D818A65592694786": "Sie sind auf ein Demokonto umgestiegen",
    "notice.details.B360807Dbe727E6E": "über uns",
    "notice.details.92F2552F695E70E3": "über uns",
    "notice.details.7Aaceaf69B67F3D1": "Regeleinführung",
    "notice.index.6128438Be34Bcb9F": "Ewige Ankündigung",
    "notice.index.71627F5Eb7128261": "Widerrufsbelehrung",
    "notice.index.2Dce570Fda841705": "Benachrichtigung zum Aufladen",
    "notice.index.228A7C9C6448A9B1": "Systembenachrichtigung",
    "setting.dialog.8B4F5B74660Deabe": "Farbkonfiguration",
    "setting.dialog.Bfc7D25486Ebc1A9": "Grün steigt und Rot fällt",
    "setting.dialog.796Ea0E1A82C320B": "Rot steigt und Grün fällt",
    "setting.index.8Ddc5864E1739466": "Präferenzen",
    "setting.index.F5205801D57D6727": "Sprache",
    "setting.index.2E2C12Dd0Cc1B63B": "Notierungswährung",
    "setting.index.322D8496Aec3D918": "Versionsnummer",
    "setting.index.Aa7E6C204C751Cfa": "Abmelden",
    "share.index.75Bc38Bbf7D2E766": "Freunde einladen",
    "share.index.45575Eb5D42213E6": "mein Einladungscode",
    "share.index.29F67F0644A78Bf3": "Kopieren",
    "share.index.19Cc65Bb6Bb98Fe7": "Mein Einladungslink",
    "share.index.5372712F162Fb78B": "Anzahl der direkten Untergebenen",
    "share.index.C88D6B95E3Ab7D6F": "Gesamtzahl der Untergebenen",
    "share.index.Bb5Fa5471Aeecc62": "Provisionsrabatt für direkte Untergebene",
    "share.index.426B6Bac9A5F3D4F": "Gesamtrabatt",
    "share.index.197E4A428F0401C9": "Rabattdatensätze anzeigen",
    "share.records.88A6B2E2B74B13C4": "Provisionsprotokoll",
    "share.records.B671A35Ffdc93B89": "Abzug",
    "transfer.index.B17617A869C03Ca5": "Geldbörse",
    "transfer.index.E4116Dcc6F6C7270": "Übertragung erfolgreich",
    "transfer.index.48Fb603C6638C7F6": "Bitte legen Sie ein Fondspasswort fest",
    "transfer.records.Eb0E39Eb5911D418": "erhalten",
    "transfer.records.977554Be0Aa65052": "Flash-Austauschzeit：",
    "transfer.records.9Aa33884Aa400B7C": "Transferzeit：",
    "form1.forget.76636Db8Fae5B124": "Ihr Postfach",
    "form1.forget.35354B9Cca1Bb0E6": "E-Mail-Bestätigungscode",
    "form1.forget.604A0B11E916F387": "Passwort",
    "form1.forget.9Cb1C9C51E7503D6": "Bestätige das Passwort",
    "form1.forget.3A4F7E04B008453C": "Deine Telefonnummer",
    "form1.forget.Cf11Dce5B4713B5A": "Bestätigungscode für Mobiltelefone",
    "form1.forget.3012Edba57D4F297": "Bitte geben Sie ihre E-Mail-Adresse ein",
    "form1.forget.F1Ee1D97E869E447": "Bitte geben Sie den Bestätigungscode ein",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Bitte geben Sie Ihr Passwort ein",
    "form1.forget.C6Cb174Da411D5F7": "Bitte geben Sie Ihre Mobiltelefonnummer ein",
    "form1.forget.947B3B21Ee45E582": "schicken",
    "form1.forget.3E0580F1E8630Df6": "Zwei Passwörter sind inkonsistent",
    "form1.forget.C5A61836B8Cc74C6": "Passwortänderung erfolgreich weitergeleitet...",
    "form1.forget.B96D072Bc8962565": "Erneut senden",
    "form1.login.2B72C27Ff828Cfc4": "Ihr Passwort",
    "form1.login.D7C6B872Af9Cb17F": "Passwort merken",
    "form1.login.0Fff44C827A4F3B6": "Anmeldung",
    "form1.login.1B7F2C1Ede3990Cc": "Jetzt registrieren",
    "form1.login.F657F7Be1Cc33Bd2": "vergessen Sie das Passwort",
    "form1.login.2074A1Eec4B088B0": "Wallet-Login",
    "form1.login.1B8938Fbcc3C9B56": "Anmeldung erfolgreich und Weiterleitung...",
    "form1.login.07162A7Bb9310555": "Bitte installieren Sie die ERC20-Wallet",
    "form1.login.766969966Ae151Dc": "Bitte installieren Sie die TRC20-Wallet",
    "form1.login.B88171E1Ad4C50Bf": "Sie haben es abgelehnt, das Netzwerk zu wechseln",
    "form1.register.Aaef0710Cb4Db554": "Passwort erneut eingeben",
    "form1.register.54Dfea0415C708Ff": "Einladungscode",
    "form1.register.6839940Dac08C794": "registrieren",
    "form1.register.9901A3B5550Aa58E": "Sie haben bereits ein Konto? Anmeldung",
    "form1.register.2548Ca53785252Ea": "Bitte geben Sie Ihren Einladungscode ein",
    "form1.register.Dd71Fd65900Dd03E": "Bitte lesen Sie die Registrierungsservicevereinbarung und stimmen Sie zu,",
    "form1.register.7975613909490B77": "Registrierung erfolgreich und Weiterleitung...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Wählen Sie eine Brieftasche",
    "userc.certification.2Af9511640153D2E": "Junior-Zertifizierung",
    "userc.certification.177B191D8E0Fa84D": "nicht zertifiziert",
    "userc.certification.601218D4D572E98A": "verifizie",
    "userc.certification.6E37C51Ba9012Fc8": "Nicht bestanden",
    "userc.certification.1B9B563E05C96474": "Erweiterte Zertifizierung",
    "userc.certification.1418B83803Ab56B4": "Bitte schließen Sie zunächst die Primärzertifizierung ab",
    "userc.creditScore.0B149112601Acc4D": "Kredit",
    "userc.creditScore.88Ac7F84E106C5F6": "Kreditpunktzahl",
    "userc.creditScore.3E1D6E0E7F1E6155": "Systembewertung",
    "userc.index.62Eac58D04Bbca0F": "Persönliches Zentrum",
    "userc.index.F4Cac53F93Cb2Aff": "simulierter Handel",
    "userc.index.42F409Fdf3559773": "Sicherheitscenter",
    "userc.index.1B8E211009807Db8": "Authentifizierung",
    "userc.index.Bdfcf21Eb8F2Eb70": "Kontoänderungsaufzeichnungen",
    "userc.index.6C18D67F6D19586B": "Plattformeinführung",
    "userc.index.807A814Ed58A93Bc": "Cache leeren",
    "userc.index.E43Daad59759611C": "nicht zertifiziert",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Login-Passwort ändern",
    "userc.secureCenter.1F37Ba427Debf24A": "Kontobindung",
    "userc.secureCenter.F9A5Ffa750664004": "Binden Sie Google Authenticator",
    "userb.certificationForm.7685Af7D30043Cd6": "Junior-Zertifizierung",
    "userb.certificationForm.7685Af7D30043Cd7": "Erweiterte Zertifizierung",
    "userb.certificationForm.70E37C35Abc3F462": "Land",
    "userb.certificationForm.F92C25A68E4D08C5": "Stadt",
    "userb.certificationForm.0Ccc6387B38E0318": "Ihr Name",
    "userb.certificationForm.94C67Bba370Fdd46": "amtliches Kennzeichen",
    "userb.certificationForm.B2D4C7Fffe79258E": "Post",
    "userb.certificationForm.57554268761Fb47A": "Laden Sie die Vorderseite Ihres Ausweises hoch",
    "userb.certificationForm.D79E90201F5319Bd": "Laden Sie die Rückseite Ihres Ausweises hoch",
    "userb.certificationForm.9C6Bad08Af29E20E": "Laden Sie ein Bild Ihres Personalausweises hoch",
    "userb.certificationForm.F96B62Dfa31Cde45": "Bestätigen Sie die Übermittlung",
    "userb.certificationForm.2125D78Ea8C6D287": "Upload-Anforderungen",
    "userb.certificationForm.0D64864D3076A824": "Beispiel ansehen",
    "userb.certificationForm.35287Deb72281E87": "1.Muss in der Lage sein, die Ausweisinformationen deutlich zu lesen.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.Der Inhalt des Fotos ist wahr und gültig und es ist keine Änderung gestattet.",
    "userb.certificationForm.25A90660554319F9": "3.Die ID-Nummer und der Name müssen deutlich sichtbar sein und JPG / JPEG / PNG unterstützen",
    "userb.certificationForm.5A9Aefbc03C778F7": "Bitte eingeben",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Übermittlung erfolgreich, bitte warten Sie auf die Überprüfung",
    "userb.certificationForm.744Bd07D8Abd2513": "Bitte laden Sie die Vorderseite Ihres Ausweises hoch",
    "userb.certificationForm.6De1C319130F7Fef": "Bitte laden Sie die Rückseite Ihres Ausweises hoch",
    "userb.certificationForm.79F8E8B172E30C8A": "Bitte laden Sie ein Foto Ihres Personalausweises hoch",
    "userb.certificationForm.46412E16D28A4753": "Das Hochladen des Fotos ist fehlgeschlagen",
    "userb.changeDialog.676B0Adca1F8Eea4": "Kennwort ändern",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "Telefonnummer",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "Münzprüferschalter",
    "userb.modifyInfoDialog.62149E566814154C": "Validator auswählen",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Öffnen Sie die Authentifizierungs-App und tippen Sie auf +",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "Wählen Sie „QR-Code scannen“ oder „Eingabetaste“.",
    "userb.modifyInfoDialog.93F094E2B4369472": "Scannen Sie den QR-Code oder geben Sie den Schlüssel ein",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "Schließen Öffnen",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Altes Fondspasswort",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Neues Fondspasswort",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Bestätigen Sie das neue Fondspasswort",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Bestätigen Sie das Fondspasswort",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Bitte geben Sie Ihr altes Fondspasswort ein",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Bitte geben Sie Ihr neues Fondspasswort ein",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Bitte geben Sie Ihr Fondspasswort ein",
    "userb.modifyInfoDialog.258A534498Fe984F": "Bitte bestätigen Sie Ihr Fondspasswort",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Kontobindung",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Passwort für den Fonds",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "bestätigen",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Validator ist geschlossen",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Die Google-Bestätigung wurde erfolgreich aktiviert",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Das Fondspasswort wurde erfolgreich geändert",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Fondspasswort erfolgreich festgelegt",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Bindung erfolgreich",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Fehler beim Bestätigungscode",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Beispiel zum Hochladen eines Zertifikats",
    "userb.veBottomSheet.6F38A0D5858633Ee": "dunkler, sauberer Hintergrund",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Ausreichende Beleuchtung",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Ausrichtungshilfsrahmen",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Fotos sind klar",
    "userb.veBottomSheet.50B741382E71F103": "Mögliche Gründe für das Scheitern",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "Die Schutzhülle des Ausweises wurde nicht entfernt",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "Der Hintergrund wird durch Unordnung beeinträchtigt",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "Der Ausweis ist reflektierend",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "Das Licht ist zu dunkel bzw. wird durch den Schatten des Mobiltelefons verdeckt",
    "userb.veBottomSheet.1A75D2Fc2614592B": "Ausweis in der Hand",
    "home.home.C3525Eb110Db58Aa": "Mehr",
    "home.home.29761532Fc8Ebecc": "Handel",
    "home.home.F15Ea99Bfa6E1848": "Sicherheitszertifizierung, kaufen Sie mit Vertrauen",
    "home.home.46E1Ab39Bae7182A": "Schnell",
    "home.home.Fde2Eb7F6F3Dd800": "Schnelle Transaktionen und einfache Bedienung",
    "home.home.Ea4756Bc1642E0F1": "Name",
    "home.home.B6B1127Ede1C97B4": "letzter Preis",
    "home.home.3587Ad870Eb0Ab86": "24h Reichweite",
    "home.home.Cb1A4E14Ec2Cd2B5": "Entdecken",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "Edelmetalle",
    "home.home.5Fc1474Dbf74Ac1A": "Agrarprodukte",
    "home.home.135318F4664F7773": "Energie",
    "kline.kLine.C96Ff250Cf6B8B84": "24h Höchstpreis",
    "kline.kLine.6E8Be64F19D74565": "24-Stunden-Handelsvolumen",
    "kline.kLine.08A4Bfbe26A0F262": "24h niedrigster Preis",
    "kline.kLine.8701Deb075066331": "24-Stunden-Handelsvolumen (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 Punkt",
    "kline.kLine.B49Cca240E013124": "5 Punkte",
    "kline.kLine.F27A90Be97Ba3D6C": "15 Mark",
    "kline.kLine.Ff3E62395B694384": "30 Punkte",
    "kline.kLine.C1993F9C6Db1A674": "1 Stunde",
    "kline.kLine.Fd2Cbbae405E284C": "1 Tag",
    "kline.kLine.C9Cd71904395042C": "Januar",
    "kline.kLine.7D8127Ec18C7B8C7": "Kaufen",
    "kline.kLine.E3D3Ffdb9B3E5636": "Menge (Stück)",
    "kline.kLine.6834Fec1838D8029": "Preis (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "Verkaufsauftrag",
    "kline.kLine.B9Bea1A9A88D1419": "Kaufen",
    "kline.kLine.D36Cfa96Ee2B343B": "Verkürzung",
    "other.languageRateSelect.6897784800834C1B": "Spracheinstellungen",
    "other.languageRateSelect.6897784800834C1C": "Bitte wählen Sie die Preiswährung aus",
    "other.navigationBar.F6E62F9642018Cbb": "Titelseite",
    "other.navigationBar.0Ec227161A47B49D": "Zitate",
    "other.navigationBar.29761532Fc8Ebecc": "Handel",
    "other.navigationBar.693C7B35Bdf3E3A3": "Finanzen",
    "other.navigationBar.9734C54620Eb09Af": "Aktiva",

    "home.index.25Aa4Fc745150030": "König der Kryptowährungen",
    "home.index.2D6Ecf8Faaa8B124": "Schneller, besser, stärker",
    "home.index.1Db26267D1041223": "Handeln Sie ganz einfach mit über 1.700 Kryptow?hrungen",
    "home.index.F8E18C90C3E35618": "Jetzt registrieren",
    "home.index.29761532Fc8Ebecc": "Handel",
    "home.index.D8A95E4506487452": "Beliebte Verträge",
    "home.index.5Bd31Fab681C6E9D": "Mehr sehen",
    "home.index.010Ae07A09Caf221": "Handelspaar",
    "home.index.F4D875118E6D6Dbb": "Letzter Preis",
    "home.index.3Dfdfb3D6599100E": "24h Reichweite",
    "home.index.28Fb39Bd41E54242": "Trend",
    "home.index.B47A3911Bacd94C6": "Gainer-Liste",
    "home.index.712910D574F43D95": "Stabiles Einkommen",
    "home.index.5507Fd830D7Bf51F": "Top-Strategien, leicht zu kopieren",
    "home.index.7De3D636A165E008": "Rendite",
    "home.index.B853F96626106912": "Gehen Sie in den Bergbau",
    "home.index.213B5C96Be7Cd1F8": "Tage, annualisierter Zinssatz",
    "home.index.213B5C96Be7Cd1F9": "Maximal k?uflich",
    "home.index.Dc710Cffe6313Bb5": "überprüfen Sie die Details",
    "home.index.B92Ffc837Ecaa1Dd": "Nachricht",
    "home.index.6C18D67F6D19586B": "Plattformeinführung",
    "home.index.6342E3C33Cb48Cd5": "Digitale W?hrung ist ein Tauschmittel, das kryptografische Prinzipien nutzt, um die Transaktionssicherheit zu gew?hrleisten. Im Gegensatz zu Fiat-W?hrungen wie dem US-Dollar haben digitale W?hrungen keine physische Form. Beliebte digitale W?hrungen wie Bitcoin (BTC), Ethereum (ETH) und Polkadot (DOT) nutzen Blockchain als zugrunde liegende Technologie, um als dezentrales digitales Hauptbuch zu fungieren. Alle digitalen W?hrungstransaktionen werden in der Blockchain aufgezeichnet und k?nnen nach Best?tigung und Verifizierung nicht mehr ge?ndert werden. Im Gegensatz zu herk?mmlichen W?hrungen, bei denen Banken zentralisierte Hauptbücher führen, finden digitale W?hrungstransaktionen auf ?ffentlichen Blockchains statt, auf die jeder zugreifen kann.",
    "home.index.198Efc4254683Eef": "Darüber hinaus kann laut Konsensmechanismus jeder digitale W?hrungstransaktionen überprüfen und zur Blockchain hinzufügen, wodurch eine Dezentralisierung erreicht wird. Da führende globale Unternehmen wie Samsung, BlackRock, Morgan Stanley und Alphabet investiert haben, glauben die Menschen, dass die Blockchain-Technologie das globale Finanzsystem v?llig ver?ndern wird. Die digitale W?hrung ?ffnet Ihnen die Tür zur Welt des dezentralen Finanzwesens und erm?glicht es Ihnen, die unendlichen M?glichkeiten modernster Technologie zu erkunden.",
    "home.index.E710339D9576292E": "Unser Vorteil",
    "home.index.F9Ce8B2C63885B26": "sicher und stabil",
    "home.index.C4D0A2C9Ff0305Ce": "Das Spitzentechnikteam entwickelt unabhängig eine Hochgeschwindigkeits-Transaktionssynthese-Engine, die auch bei massiven gleichzeitigen Transaktionen stabil und zuverlässig arbeiten kann.",
    "home.index.2E8708Ee3F11Cc1A": "Professionell und zuverl?ssig",
    "home.index.Bb12Cbcf89C56C5C": "Ein professionelles Betriebsteam mit langj?hriger Erfahrung in den Bereichen Blockchain und Finanzen verfügt über eine konforme Lizenz für den Handel mit digitalen Verm?genswerten und eine 100-prozentige Reservegarantie.",
    "home.index.Aa4723Bfbf6808F4": "Mit Herz servieren",
    "home.index.1Bf0204B7D3Ee230": "Sich dem globalen Markt stellen, mehrere Sprachen unterstützen, rund um die Uhr laufen, starker Community-Support und professioneller Kundenservice.",
    "home.index.2E7Dfd53D26F7224": "Hochleistung",
    "home.index.1C65453103B5Fcc3": "300.000 Transaktionen pro Sekunde und Auftragsantwortzeit von weniger als 1 Millisekunde.",
    "home.index.85Ac49Ece9Dcc80B": "Handeln Sie jederzeit und überall!",
    "home.index.2799E18842E4Ebe9": "Er?ffnen Sie sofort eine Position, unterstützt von der OKEX-App und der Webseite!",
    "home.index.2Bf52Ce75E29Fc88": "Scannen Sie den Code, um ihn jetzt herunterzuladen",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple-Version",
    "home.index.04Ee893775563F22": "Produktservice",
    "home.index.04Ee893775563F23": "Finanzverwaltung",
    "home.index.04Ee893775563F24": "zurückkehren",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "Kaufen Sie Bitcoin, Ethereum und",
    "homepage.text1_2": "über 1000 Kryptowährungen",
    "homepage.text2": "Ihre Reiseerfahrung mit Kryptowährungen",
    "homepage.text4": "Finanzmanagement mit hohen Zinssätzen",
    "homepage.text5": "Stabiles Finanzmanagement mit hoher jährlicher Rendite",
    "homepage.text6": "Belohnungen teilen",
    "homepage.text7": "Schnelle Transaktionen und einfache Bedienung",
    "homepage.text8": "Mehr anzeigen",
    "homepage.text9": "Sofortiger Umtausch",
    "homepage.text10": "Sicherheitszertifizierung, kaufen Sie mit Vertrauen",
    "homepage.text11": "Erstklassiges Technikteam, umfassende Sicherheit",
    "homepage.text12": "Schutz, Unabhängigkeit",
    "homepage.text13": "Professionell und zuverlässig",
    "homepage.text14": "Professionelles Betriebsteam mit langjähriger Erfahrung in Blockchain und Finanzen",
    "homepage.text15": "Mit Herz dienen",
    "homepage.text16": "Globaler Markt, Unterstützung für mehrere Sprachen, 24/7 Betrieb",
    "homepage.text17": "Hohe Leistung",
    "homepage.text18": "300.000 Transaktionen pro Sekunde und eine Bestellantwortzeit von weniger als 1 Millisekunde",
}
